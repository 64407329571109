import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { userById, userDocumentList, customerAddressList } from '../../actions/users.action';

function UserView({ handleComponent, dispatch, id, userInfoShow, customerDocList, customerAddresList }) {

    const [currentTabStatus, setCurrentTabStatus]        = useState('basic_details');
    const [userRole, setUserRole]                        = useState();

    useEffect(() => dispatch(userById(id)), []);
    useEffect(() => dispatch(userDocumentList(id)), []);
    useEffect(() => dispatch(customerAddressList(id)), []);
    useEffect(() => setUserRole(userInfoShow?.role), [userInfoShow])

    // console.log(userInfoShow,"-------   userInfoShow?");

    return (
        <>
        {id == userInfoShow?.id &&
            <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
                <div className="overlayer"></div>
                <div className="modal-dialog modal-dialog-right modal-dialog-for-add-user" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-user font-size-22 mr-2'></i> User Details</h6>
                        </div>
                        <div className="modal-body">
                            <div className="row addNew">
                                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <h4 className='mt-1 pb-0 mb-3'>User Details</h4> */}
                                            <div className="row row-sm">
                                                <div className="col-lg-4 col-xl-3 col-md-4 col-sm-12 col-xs-12 account-setting-tab-menu">
                                                <div className="box-widget widget-user border border-bottom-0">
                                                    <div className="widget-user-header testbgpattern1" />
                                                    <div className="widget-user-image">
                                                        {
                                                            userInfoShow?.profile_photo ? 
                                                            <img alt="Profile Photo" className="rounded-circle" src={userInfoShow?.profile_photo} /> :
                                                            <img alt="Profile Photo" className="rounded-circle" src="/img/1.jpg" />
                                                        }
                                                    </div>
                                                    <div className="card-body text-center mt-5">
                                                        <div className="item-user pro-user">
                                                            <h4 className="pro-user-username tx-15 pt-2 mt-4">{userInfoShow?.first_name + ' ' + userInfoShow?.last_name}</h4>
                                                            <p className="pro-user-username tx-14 mb-1">{userInfoShow?.mobile_number}</p>
                                                            <h6 className="pro-user-desc tx-12 mb-2 font-weight-normal text-muted"></h6>
                                                            <p className={`text-center tx-16 list-group-item card-title`}>
                                                            <i className="bx bxs-user"></i> Role : {userRole}
                                                            <div className="float-right" style={{width:'25%'}} />
                                                            </p> 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group mt-0">
                                                    <button type='button' onClick={() => setCurrentTabStatus("basic_details") } className={`text-left list-group-item ${currentTabStatus=="basic_details" && 'active'}`}>
                                                    <i className="bx bxs-user-detail"></i> Basic Details 
                                                    <div className="float-right" style={{width:'25%'}} />
                                                    </button> 
                                                    <button type='button' onClick={() => setCurrentTabStatus("address") } className={`text-left list-group-item ${currentTabStatus=="address" && 'active'}`}>
                                                    <i class='bx bxs-map' ></i> Address
                                                    <div className="float-right" style={{width:'25%'}} />
                                                    </button> 
                                                    <button type='button' onClick={() => setCurrentTabStatus("documents") } className={`text-left list-group-item ${currentTabStatus=="documents" && 'active'}`}>
                                                    <i class='bx bxs-file-doc'></i> Documents 
                                                    <div className="float-right" style={{width:'25%'}} />
                                                    </button> 
                                                
                                                    {userRole == "customer" &&<>
                                                    <button type='button' onClick={() => setCurrentTabStatus("business_details") } className={`text-left list-group-item ${currentTabStatus=="business_details" && 'active'}`}>
                                                    <i class='bx bx-buildings' ></i> Business Details 
                                                        <div className="float-right" style={{width:'25%'}} />
                                                    </button> 
                                                    
                                                    </>
                                                    }
                                                    
                                                    {userRole === "affiliate" || userRole === "operation" ? <>

                                                    <button type='button' onClick={() => setCurrentTabStatus("bank_details") } className={`text-left list-group-item ${currentTabStatus=="bank_details" && 'active'}`}>
                                                    <i class='bx bxs-bank'></i> Bank Details
                                                        <div className="float-right" style={{width:'25%'}} />
                                                    </button>

                                                    <button type='button' onClick={() => setCurrentTabStatus("affiliate_capability") } className={`text-left list-group-item ${currentTabStatus=="affiliate_capability" && 'active'}`}>
                                                    <i class='bx bx-sitemap'></i> Affiliate Capability
                                                        <div className="float-right" style={{width:'25%'}} />
                                                    </button>

                                                    <button type='button' onClick={() => setCurrentTabStatus("device_details") } className={`text-left list-group-item ${currentTabStatus=="device_details" && 'active'}`}>
                                                    <i class='bx bx-mobile-alt'></i> Device Details
                                                        <div className="float-right" style={{width:'25%'}} />
                                                    </button>

                                                    </>
                                                    :""}
                                                </div>                               
                                                </div>
                                                <div className="col-lg-8 col-xl-9 col-md-8 col-sm-12 col-xs-12 mt-4 mt-md-0">
                                                    <div className="border account-setting-tab br-7">
                                                        {/* ---------------------------basic details section */}
                                                        <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="basic_details" && 'active'}`} >
                                                            <div className="card-header border-bottom"><h4 className="card-title mb-0"> User Details </h4></div>
                                                            <div className="p-4">
                                                                <table className="table table-bordered stable-vcenter">
                                                                    <tbody>
                                                                        {userRole != 'customer' && userRole &&
                                                                            <tr>
                                                                                <th className='w-50 font-weight-bold' style={{ color: "black" }}>HTB Employee Id: </th>
                                                                                <td>{userInfoShow?.user_number}</td>
                                                                            </tr>
                                                                        }

                                                                        {userRole == 'customer' && userRole &&
                                                                            <tr>
                                                                                <th className='w-50 font-weight-bold' style={{ color: "black" }}>HTB Customer Id: </th>
                                                                                <td>{userInfoShow?.user_number}</td>
                                                                            </tr>}
                                                                        <tr>
                                                                            <th className='w-50 font-weight-bold' style={{ color: "black" }}>Name: </th>
                                                                            <td>{userInfoShow?.first_name + ' ' + userInfoShow?.last_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className='w-50 font-weight-bold' style={{ color: "black" }}>Email: </th>
                                                                            <td>{userInfoShow?.email}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className='w-50 font-weight-bold' style={{ color: "black" }}>Mobile Number: </th>
                                                                            <td>{userInfoShow?.mobile_number}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className='w-50 font-weight-bold' style={{ color: "black" }}>Reference: </th>
                                                                            <td>{userInfoShow.reference?.first_name+' '+userInfoShow.reference?.last_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className='w-50 font-weight-bold' style={{ color: "black" }}>Reference Role: </th>
                                                                            <td>{userInfoShow.reference?.role}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className='w-50 font-weight-bold' style={{ color: "black" }}>Status: </th>
                                                                            <td>{userInfoShow.status}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            </div> 
                                                        {/*---------------------------- basic details */}

                                                        {/*---------------------------- customer Business Details section */}
                                                        <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="business_details" && 'active'}`} >
                                                        <div className="card-header border-bottom"><h4 className="card-title mb-0"> Business Details </h4></div>
                                                            <>
                                                                <div className='p-4'>
                                                                    <table className='table table-bordered'>
                                                                        <thead>
                                                                            <tr>
                                                                            <th>Owner</th>
                                                                            <th>Business Name</th>
                                                                            <th style={{width:"50%"}}>Address</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr>
                                                                        <td> {userInfoShow?.business?.owner_first_name+" "+userInfoShow?.business?.owner_last_name}</td>
                                                                        <td> {userInfoShow?.business?.name}</td>
                                                                    
                                                                        <td className='' >
                                                                            {userInfoShow?.business?.address?.locality}, {userInfoShow?.business?.address?.address}, {userInfoShow?.business?.address?.landmark}, {userInfoShow?.business?.address?.pincode}, {userInfoShow?.business?.address?.city}, {userInfoShow?.business?.address?.state}, {userInfoShow?.business?.address?.country}
                                                                            {/* <p><strong>Latitude</strong> - {userInfoShow?.business?.address?.latitude} | <strong>Longitude</strong> - {userInfoShow?.business?.address?.longitude}</p> */}
                                                                        </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                </div>
                                                            </>
                                                        </div> 
                                                        {/*----------------------------  Documents section end */}

                                                        {/*---------------------------- customer Documents section */}
                                                        <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="documents" && 'active'}`} >
                                                        <div className="card-header border-bottom"><h4 className="card-title mb-0"> Document Details </h4></div>
                                                            <div className="p-4">
                                                                    <table className='table table-bordered'>
                                                                        <thead>
                                                                            <tr>
                                                                            <th>#</th>
                                                                            <th>Document Name</th>
                                                                            <th>Document Number</th>
                                                                            <th>View Files</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {customerDocList.length === 0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}
                                                                        {customerDocList && customerDocList.map((doc,i) => (
                                                                        <>
                                                                            <tr>
                                                                            <td> {i+1}</td>
                                                                            <td> {doc.name}</td>
                                                                            <td>{doc?.number}</td>
                                                                            <td>
                                                                                <div><a href={doc?.file} target="_blank" className='btn btn-primary btn-sm btn-flat mb-4 mt-2'>preview</a></div>
                                                                            </td>
                                                                            </tr>
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                    </table>
                                                            
                                                            </div>
                                                        </div> 
                                                        {/*----------------------------  Documents section end */}

                                                        {/*----------------------------  customer address section */}
                                                        {/* <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="customer_address" && 'active'}`} >
                                                            <div className="card-header border-bottom"><h4 className="card-title mb-0"> Customer Address </h4></div>
                                                            <div className="p-4">
                                                                <table className='table table-bordered'>
                                                                    <thead>
                                                                    <th style={{width:"10%"}}>#</th>
                                                                    <th>Address</th>
                                                                    </thead>
                                                                    <tbody>
                                                                    {customerAddresList?.length === 0 && 
                                                                    <tr><td className='text-center' colSpan={6}>data not available</td></tr>	
                                                                    }
                                                                        
                                                                    {customerAddresList && customerAddresList.map((showAdress,i) => (<>
                                                                        <tr>
                                                                            <td>{i+1}</td>
                                                                            <td>
                                                                            {showAdress.locality}, {showAdress.address}, {showAdress.landmark}, {showAdress.pincode}, {showAdress.city}, {showAdress.state}, {showAdress.country}
                                                                            <p><strong>Latitude</strong> - {showAdress.latitude} | <strong>Longitude</strong> - {showAdress.longitude}</p>
                                                                            </td>
                                                                        </tr>
                                                                            </>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>  */}
                                                        {/*---------------------------- customer address section end */}

                                                        {/*---------------------------- Bank Details section */}
                                                        <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="bank_details" && 'active'}`} >
                                                        <div className="card-header border-bottom"><h4 className="card-title mb-0"> Bank Details </h4></div>
                                                            <div className='p-4'>
                                                                <table className='table table-bordered'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Bank Name</th>
                                                                            <th>Account Number</th>
                                                                            <th>IFSC Code</th>
                                                                            <th>Branch Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {userInfoShow?.bank_details && userInfoShow?.bank_details.map((bankDetails,i) => (
                                                                    <>
                                                                        <tr>
                                                                            <td>{i+1}</td>
                                                                            <td>{bankDetails?.bank_name}</td>
                                                                            <td>{bankDetails?.account_number}</td>
                                                                            <td>{bankDetails?.ifsc_code}</td>
                                                                            <td>{bankDetails?.branch_name}</td>
                                                                        </tr>
                                                                    </>
                                                                    ))}
                                                                    {userInfoShow?.bank_details.length === 0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div> 
                                                        {/*----------------------------  Bank Details section end */}

                                                        {/*---------------------------- Affiliate Capability section */}
                                                        <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="affiliate_capability" && 'active'}`} >
                                                        <div className="card-header border-bottom"><h4 className="card-title mb-0"> Affiliate Capability</h4></div>
                                                            <div className='p-4'>
                                                                {/* <h3 className="font-size-14 text-monospace text-section">Bank Details:</h3> */}
                                                                {/* {userInfoShow?.bank_details.length === 0 && <div className='alert alert-warning'>data not available</div>} */}
                                                                <table className='table table-bordered'>
                                                                <thead>
                                                                    <tr>
                                                                    <th>#</th>
                                                                    <th>State</th>
                                                                    <th>City</th>
                                                                    <th style={{width:"30%"}}>Pincode</th>
                                                                    <th style={{width:"30%"}}>Categories</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                userInfoShow?.affiliate_capability?.length!=0 && userInfoShow?.affiliate_capability?.map((pdata, index) => (
                                                                    <tr key={index}>
                                                                    <td>{index+1}</td>
                                                                    <td> {pdata?.state}</td>
                                                                    <td> {pdata?.city}</td>
                                                                    <td style={{ width: '20%' }}>{
                                                                        pdata?.pincode.map((pin, index) =>(
                                                                            <span key={index}>{pin}, </span>
                                                                        ))
                                                                    }</td> 
                                                                    <td>{
                                                                        pdata?.category.map((categories, index) =>(
                                                                        <p key={index}>{categories}, </p>
                                                                        ))
                                                                    }</td>
                                                                    </tr>
                                                                ))
                                                                }
                                                                {userInfoShow?.affiliate_capability?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
                                                                </tbody>
                                                                </table>
                                                            </div>
                                                        </div> 
                                                        {/*----------------------------  Affiliate Capability section end */}
                                                        
                                                        {/*---------------------------- Address section */}
                                                        <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="address" && 'active'}`} >
                                                        <div className="card-header border-bottom"><h4 className="card-title mb-0">Address</h4></div>
                                                            <div className='p-4'>
                                                                <table className='p-4 table table-bordered'>
                                                                    <thead>
                                                                    <th>#</th>
                                                                    <th>Address</th>
                                                                    </thead>
                                                                    <tbody>
                                                                    {customerAddresList?.length==0 && <tr><td className='text-center alert alert-warning' colSpan={6}>data not available</td></tr>}	

                                                                    {customerAddresList && customerAddresList.map((showAdress,i) => (
                                                                        <>
                                                                        <tr key={i}>
                                                                            <td>{i+1}</td>
                                                                            <td className='' >
                                                                            {showAdress.locality}, {showAdress.address}, {showAdress.landmark}, {showAdress.pincode}, {showAdress.city}, {showAdress.state}, {showAdress.country}
                                                                            <p><strong>Address Type</strong> - {showAdress.address_type} | <strong>Default Address</strong> - {showAdress.default_address}</p>
                                                                            </td>
                                                                        </tr>
                                                                        </>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div> 
                                                        {/*----------------------------  Affiliate Address section end */}

                                                        {/*----------------------------  Device section start */}
                                                        <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="device_details" && 'active'}`} >
                                                            <div className="card-header border-bottom"><h4 className="card-title mb-0"> Device Details </h4></div>
                                                            <div className="p-4">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Device Name</label>
                                                                        <input value={userInfoShow?.device?.name} type="text" className="form-control" name="device_name" autoComplete='new-password' readOnly/>
                                                                    </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Device Id</label>
                                                                        <input value={userInfoShow?.device?.id} type="text" className="form-control" name="device_id" autoComplete='new-password' readOnly/>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        {/*----------------------------  Address section end */}
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/* <h4 className='mt-1 pb-0 mb-3'>Business Details</h4>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="card shadow-none border p-3 position-relative">
                                        <h3 className="font-size-14 text-muted text-monospace">Business Name:</h3>
                                        <div><i className='bx bx-building font-size-16 mr-2'></i>{userInfoShow?.business?.name}</div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h5 className='mt-3 pb-0 mb-3'>Documents</h5>
                            {customerDocList && customerDocList.map((doc) => (
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="card shadow-none p-3 border position-relative mb-4">
                                            <h3 className="font-size-14 text-muted text-monospace">Documents:</h3>
                                            <div><i className='bx bx-user font-size-16 mr-2'></i>Name: {doc.name}</div>
                                            <div><i className='bx bx-phone font-size-16 mr-2'></i>Number: {doc?.number}</div>

                                        </div>
                                    </div>
                                </div>
                            ))} */}



                        </div>
                        <div className="modal-footer">
                            <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

function mapStateToProps(state) {
    const userInfoShow = state.users.users.userById;
    let { customerAddressById } = state.users.users;
    let customerDocList = state.users.users.customerDocList;
    let customerAddresList = state.users.users.customerAddressList;
    
    // console.log(customerDocList,"---customerDocList");

    return {
        userInfoShow,
        customerDocList,
        customerAddresList
    }
}

export default connect(mapStateToProps)(UserView);