import React,{useState, useEffect, useMemo} from 'react'
import MainLayout from '../layouts/main.component'
import { useHistory } from "react-router-dom"
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { addProduct } from '../../actions/product.action'
import { categoryList } from '../../actions/category.action'
import { attributesListShow } from '../../actions/attributes.action'
import {removeSpace, checkImage, convertToArray, addPaisa} from '../../helpers/common-function.helper'
import { clubbedBuyingRange, productStatus, taxType, taxPercentage, stockStatus, unitType} from '../../helpers/product.helper'


const ImportUsers = ({dispatch, categories, attributesList, capability}) => {

  const [csvData, setCsvData] = useState()
  const history = useHistory()
  const [viewProduct, setViewProduct] = useState(false)
  const [viewProductData,setViewProductData] = useState('')
  const [loader,setLoader] = useState(true)
  const [search, setSearch] = useState('')
  const [saveSheet, setSaveSheet]  = useState(false)

  useEffect(() => {
    document.title = 'Import Users'
    dispatch(categoryList())
    dispatch(attributesListShow())
    if(localStorage.getItem('importUsers') && localStorage.getItem('saveImportUsers') == 'true'){
      setCsvData(JSON.parse(localStorage.getItem('importUsers')))
      setLoader(false)
    }else{
      setLoader(false)
    }
  },[])



  /* -------------------------------
				CONVERT🙂 "CSV to JSON"
  ----------------------------------*/
  function handleFiles(files) {
    setLoader(true)
    if (window.FileReader) {
      getAsText(files[0]); 
      document.getElementById('uploadFile').value = ''
    } else {
      setLoader(false)
      alert('FileReader are not supported in this browser.');
    }
  }
  
  function getAsText(fileToRead) {
    var reader = new FileReader()
    reader.onload = loadHandler
    reader.onerror = errorHandler
    fileToRead !== undefined && reader.readAsText(fileToRead); 
  }

  function errorHandler(evt) {
    if (evt.target.error.name == "NotReadableError") {
      alert("Cannot read file !");
      setLoader(false)
    }
  }
    
  function loadHandler(event) {
    var csv = event.target.result;
    CSVToArray(csv);
  }
  
  function CSVToArray(strData, strDelimiter) {
    strDelimiter = (strDelimiter || ",")
    var objPattern = new RegExp((
    "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + 
    "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +       
    "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi") 
    var arrData = [[]]
    var arrMatches = null

    while (arrMatches = objPattern.exec(strData)) {
      var strMatchedDelimiter = arrMatches[1] 
      if (strMatchedDelimiter.length && (strMatchedDelimiter != strDelimiter)) {
          arrData.push([]);
      }
      if (arrMatches[2]) {
          var strMatchedValue = arrMatches[2].replace(
          new RegExp("\"\"", "g"), "\"");
      } else {
          var strMatchedValue = arrMatches[3]
      }
      arrData[arrData.length - 1].push(strMatchedValue)
    }
    
    var validateSheet   = arrData[1].filter(field => field == 'Brand')
    var validateSheet_2 = arrData[1].filter(field => field == "HSN Code")
    if(!validateSheet.length || !validateSheet_2.length || !arrData.length){
      setLoader(false)
      return alert('Buddy! 🙂 Upload Right Product Listing File')
    }

    arrData.splice(0,2)

     
    function validateField(field,mandatory = true, fieldName, number = false, maxLength, minlength, maxValue, maxValueMessage, minValue, minValueMessage){
      if(number) field = field?.replace(' ','')
      if(!field?.length && mandatory == true) errorList.push({ from : fieldName, error: `Add <b>${fieldName}</b>.` })
      else if(!field?.length && mandatory == false) warningList.push({ from : fieldName, warning: `Add <b>${fieldName}</b>.` })
      else if(number == false && field == parseInt(field)) errorList.push({ from : fieldName, error: `Enter Valid value "<b>${field}</b>".` })
      else if(number == true && field != parseFloat(field))  errorList.push({ from : fieldName, error: `Enter Valid value "<b>${field}</b>".` })
      else if(minlength != undefined && field.length < minlength) errorList.push({ from : fieldName, error: `Enter Valid value "<b>${field}</b>".` })
      else if(minlength != undefined && field.length > maxLength) errorList.push({ from : fieldName, error: `Enter Valid value "<b>${field}</b>".` })
      else if(maxValue !=  undefined && number && parseInt(field) > parseInt(maxValue)) errorList.push({ from : fieldName, error: maxValueMessage == undefined ? `Enter Valid value "<b>${field}</b>".` : maxValueMessage})
      else if(maxValue !=  undefined && number && parseInt(field) < parseInt(minValue)) errorList.push({ from : fieldName, error: minValueMessage == undefined ? `Enter Valid value "<b>${field}</b>".` : maxValueMessage})
    }
    
    
    if(categories && attributesList){
      for(var i = 0; i < arrData.length; i++){
        var errorList          = [] //ERROR LIST
        var warningList        = [] //WARNING LIST
        var productVariationId = null
        var isVariation        = false

        if(arrData[i][0] == 'TRUE'){
          if(!removeSpace(arrData[i][8]).length){
            for(x = 1; x < 20; x++){
              if(arrData[i-x].v_status == 'TRUE' && arrData[i-x].hsn_code.length){
                isVariation        = true
                productVariationId = i-x
                x = 20
              }else if(arrData[i-x].v_status == 'FALSE'){
               x = 20
              }
            }
          }
        }


        //ATTRIBUTE FILTER
        var attributeFinal = []
        var attributeName  = removeSpace(arrData[i][26]).split(',').filter(data => data.length )
        var attributeValue = removeSpace(arrData[i][27]).split(';').filter(data => data.length )
        if(attributeName.length !== attributeValue.length){
          errorList.push({
            from : "Attribute",
            error: "Every <b>Attribute</b> must have value & Every <b>Attribute</b> value must have <b>Attribute Name</b>"
          })
        }
        
        for(var x = 0; x < attributeName.length; x++){
          validateField(attributeName[x], false, 'Attribute')
          validateField(attributeValue[x], 'ignore', 'Attribute')

          var attr      = attributeName[x]
          var attrName  = attributesList.filter(data => removeSpace(data.name).toLowerCase() == attr.toLowerCase())
          !attrName.length && errorList.push({
            from : "Attribute",
            error: `This Attribute Name "<b>${attributeName[x]}</b>" is not present in our data.`,
          })

          var attrValue = attributeValue[x]?.split(',').filter(data => data.length ).map(data => {
            var attCount = attributesList.filter(attrdata => removeSpace(data).toLowerCase() == attrdata.name.toLowerCase())
            !attCount.length && errorList.push({
              from : "Attribute",
              error: !attCount.length ? `This Attribute Value "<b>${data}</b>" is not present in our data."` : (attrName[0]?.id == attCount[0]?.parent ? null : `This Attribute Value "<b>${data}</b>" Parent "<b>${attributeName[x]}</b>" is not present in our data."`)
            })
            return attCount.length ? attCount[0].name : data
          })

          attributeFinal.push({
            name     : attrName.length ? attrName[0].name : attr, 
            value    : attrValue?.toString()
          })
        }


        //CLUBBED BUYING
        var clubbedBuying    = []
        var windowPriceRange = removeSpace(arrData[i][29]).split(',').filter( data => data.length ) 
        var windowPrice      = removeSpace(arrData[i][30]).split(',').filter( data => data.length )

        if(windowPriceRange.length !== windowPrice.length){ //VALIDATE CB RANGE & PRICE
          errorList.push({
            from : "Clubbed Buying Price Range",
            error: "Every <b>Range</b> must have Price & Every <b>Price</b> must have <b>Range</b>"
          })
        }else{
          for(var x = 0; x < windowPriceRange.length; x++){
            clubbedBuying.push({ range:windowPriceRange[x], price: addPaisa(windowPrice[x]) })
            validateField(windowPrice[x], false, 'Clubbed Buying', true, undefined, undefined, removeSpace(arrData[i][11]), `<b>${windowPriceRange[x]}</b> Range price <b>${windowPrice[x]}</b> is greater than listing price ${arrData[i][11]}`)

            if(windowPriceRange[x].includes('-')){ //VALIDATE CB RANGE FLOW
              windowPriceRange.map((data, index) => {
                var check_range = data.replace('-','')
                check_range     =  parseInt(check_range)
                var current_range = windowPriceRange[x].replace('-','')
                current_range     = parseInt(current_range)
                
                if(x < index && current_range > check_range && data.includes('-')){
                  errorList.push({
                    from : "Clubbed Buying Price Range",
                    error: `<b>Price Range</b> must be in increment order "${data}"`
                  })
                }
              })
            }else if(windowPriceRange[x].includes('>')){
              if(windowPriceRange.length != x+1){
                errorList.push({
                  from : "Clubbed Buying Price Range",
                  error: `<b>Price Range</b> something wrong with this range "${windowPriceRange[x]}"`
                })
              }

              windowPriceRange.map((data, index) => {
                var check_range = data.replace('>','')
                check_range     =  parseInt(check_range)
                var current_range = windowPriceRange[x].replace('>','')
                current_range     = parseInt(current_range)
                
                if(x < index && current_range > check_range && data.includes('>')){
                  errorList.push({
                    from : "Clubbed Buying Price Range",
                    error: `<b>Price Range</b> must be in increment order "${data}"`
                  })
                }
              })
            }
          }
        }


        //CLUBBED BUYING CITY
        const cityField = removeSpace(arrData[i][33])
        validateField(cityField, false, 'Clubbed Buying City', false, 500, 3)
        if(cityField.toLowerCase() !== 'lucknow'){
          errorList.push({
            from : "Clubbed Buying City",
            error: "Currently only <b>Lucknow City</b> is allowed"
          })
        }


        //CLUBBED BUYING PINCODE
        const pinCodeField = removeSpace(arrData[i][32], true, true, true)
        validateField(pinCodeField, false, 'Clubbed Buying Pin Code', true, 500, 4)
        if(!pinCodeField.includes('2260')){ 
          errorList.push({
            from : "Clubbed Buying Pin Code",
            error: "Currently only <b>Lucknow City Pincode</b> are allowed"
          })
        }
        

        //CHECK EMPTY CLUBBED BUYING FILEDS
        if(arrData[i][28] == "yes"){ 
          if(!removeSpace(arrData[i][29], true, true, true).length || !removeSpace(arrData[i][30], true, true, true).length || removeSpace(arrData[i][31]).length < 3 || removeSpace(arrData[i][32]).length < 3 || removeSpace(arrData[i][33]).length < 3){
            errorList.push({
              from : "Clubbed Buying",
              error: "All Fields Required *."
            })
          }
        }
        

      
        
        //IMAGE
        var imageFinal    = []
        var imageOther    = removeSpace(arrData[i][23],true,false,true).split(',').filter(data => data.length )
        var featuredImage = removeSpace(arrData[i][22],true,false,true)
          
        for(var x = 0; x < imageOther.length; x++){
          var otherImageLink = imageOther[x]
          validateField(otherImageLink, 'ignore', 'Other Image', false, 200, 10)
          !checkImage(otherImageLink) && errorList.push({
            from : "Other Image",
            error: `Invalid Image <a href="${removeSpace(otherImageLink,true,false,true)}" target="_blank">${removeSpace(otherImageLink,true,false,true)}</a>`,
          })
          imageFinal.push({ 
            link     : otherImageLink, 
            featured : 'no'
        })}      

        imageFinal.push({
          link     : featuredImage,
          featured : 'yes'
        }) 
        !checkImage(featuredImage) && errorList.push({
          from : "Featured Image",
          error: `Invalid Featured Image <a href="${featuredImage}" target="_blank">${featuredImage}</a>`,
        })
        validateField(featuredImage, true, 'Featured Image', false, 200, 10)
              

        //CATEGORY
        const categoryFinal = []
        if(!isVariation){
          const categoryField = removeSpace(arrData[i][5])
          const subCategoryField = removeSpace(arrData[i][6])
          const leafField = removeSpace(arrData[i][7])

          let category      = `${categoryField},${subCategoryField},${leafField}`
              category      = category.split(',').filter(data => data.length )
          
          validateField(categoryField, true, 'Category', false, 50, 3)
          validateAndAddCategory(categoryField, 'Category - #Bazaar')
          validateField(subCategoryField, true, 'Sub-Category', false, 50, 3)
          validateAndAddCategory(subCategoryField, 'Sub-Category')
          validateField(leafField, true, 'Leaf', false, 50, 3)
          validateAndAddCategory(leafField, 'Leaf')

          function validateAndAddCategory(name, fieldName){
            var catName      = name
            var categoryInfo = categories.filter(data => removeSpace(data.name).toLowerCase() == catName.toLowerCase())
            categoryFinal.push({ 
              name        : categoryInfo.length ? categoryInfo[0].name : catName, 
              category_id : categoryInfo[0]?.id,
              slug        : categoryInfo[0]?.slug
            })

            categoryInfo?.map(data => {
              if(data.status == 'false'){
                errorList.push({
                  from : fieldName,
                  error: `This ${fieldName} "<b>${data.name}</b>" is Inactive. Please! Change it to Active in Admin Panel.`,
                })
              }
            })
            
            if(!categoryInfo.length){
              errorList.push({
                from : fieldName,
                error: `This ${fieldName} "<b>${catName}</b>" is not present in our data.`,
              })
            }
          }
        }


        //SELLER VALIDATE
        if(!isVariation) validateField(removeSpace(arrData[i][2]), 'ignore', 'Seller', false, 30)
        

        //STATUS
        if(!isVariation){
          const statusField = removeSpace(arrData[i][1])
          validateField(statusField, true, 'Status', false, 30, 3)
          if(!productStatus.filter(status => status.toLowerCase() == statusField.toLowerCase()).length){ 
            errorList.push({
              from : "Status",
              error: `Invalid Product Status "<b>${statusField}</b>" is not present in our data.`,
            })
          }
        }

    
        //BRAND
        const brandField = removeSpace(arrData[i][3])
        const brand      = attributesList.filter(data => data.parent == '' && data.name.toLowerCase() == 'brand')[0].id
        const brandName  = attributesList.filter(data => data.parent == brand && data.name.toLowerCase() == brandField.toLowerCase())
        if(!isVariation){
          validateField(brandField, true, 'Brand', false, 30, 3)
          if(!brandName.length && brandField.length > 1){
            errorList.push({
              from : "Brand",
              error: `This Brand "<b>${brandField}</b>" is not listed with us.`,
            })
          }
        }
        

        //KEYWORDS
        if(!isVariation){
          const keywords = removeSpace(arrData[i][4])
          validateField(keywords,false, 'Keywords', false, 30) 
        }

        //HSN CODE
        if(!isVariation) validateField( removeSpace(arrData[i][8]), true, "HSN Code", true, 10, 3)


        //PRODUCT NAME
        validateField( removeSpace(arrData[i][9], true, false, false), true, 'Product Name', false, 150, 5 ) 


        //PRICE 
        const mrp_price = removeSpace(arrData[i][10])
        const listing_price = removeSpace(arrData[i][11])
        const cost_price = removeSpace(arrData[i][12])
        
        validateField(mrp_price, true, 'MRP Price', true, undefined, undefined, undefined, undefined, 1) //MRP PRICE
        validateField(cost_price, true, 'Cost Price', true, undefined, undefined, mrp_price, '<b>Cost Price</b> is high than <b>MRP price</b>.',1) //COST PRICE
        validateField(listing_price, true, 'Listing Price', true, undefined, undefined, mrp_price, `<b>Listing price</b> can't be greater then <b>MRP Price</b>.`,1) //LISTING PRICE
        if(parseInt(listing_price) < parseInt(cost_price)){
          warningList.push({
            from: 'Listing Price',
            warning: '<b>Cost Price</b> is high than <b>Listing price</b>.'
          })
        }


        //TAX
        const tax_type_field       = removeSpace(arrData[i][13], true, false)
        const tax_percantage_field =  removeSpace(arrData[i][14], true, true, true)
        validateField(tax_type_field, true,'Tax Type', false, 50, 2)
        validateField(tax_percantage_field, true,'Tax Percentage', true, undefined, undefined, 100)

        if(!taxType.filter(type => type.toLowerCase() == tax_type_field.toLowerCase()).length){ 
          errorList.push({
            from : "Tax Type",
            error: `Invalid Tax Type "<b>${tax_type_field}</b>" is not present in our data.`,
          })
        }

        if(!taxPercentage.filter(percentage => percentage == parseInt(tax_percantage_field)).length){ 
          errorList.push({
            from : "Tax Percentage",
            error: `Invalid Tax Percentage "<b>${tax_percantage_field}</b>" is not present in our data.`,
          })
        }


        //ORDER LIMIT
        const min_order_limit_field = removeSpace(arrData[i][15])
        const max_order_limit_field = removeSpace(arrData[i][16])
        validateField(min_order_limit_field,false,"Minimum Order Limit", true, undefined, undefined, 100, undefined, 1)
        validateField(max_order_limit_field,false,"Maximum Order Limit", true, undefined, undefined,10000, undefined, 1)


        //STOCK 
        const stock_quantity = removeSpace(arrData[i][17])
        const stock_status   = removeSpace(arrData[i][18])
        validateField(stock_quantity, false,"Stock Quantity", true, undefined, undefined,undefined, undefined, 1)
        validateField(stock_status,false,"Stock Status", false)
        if(!stockStatus.filter(status => status.toLowerCase() == stock_status.toLowerCase()).length){ 
          errorList.push({
            from : "Stock Status",
            error: `Stock Status "<b>${stock_status}</b>" is not present in our data.`,
          })
        }


        //SKU
        const sku_field = removeSpace(arrData[i][19], true, true, true)
        validateField(sku_field, true, "SKU ID", false, 16, 10)
        if(sku_field.length && !sku_field.includes('HBSK')){
          errorList.push({
            from : "SKU ID",
            error: `Invalid SKU ID "<b>${sku_field}</b>".`,
          })
        }


        //UNIT
        const unit_value_field = removeSpace(arrData[i][20])
        const unit_type_field  = removeSpace(arrData[i][21], true, true, true)
        validateField(unit_value_field,true, "Unit Value", true, undefined, undefined,10000)
        validateField(unit_type_field,true, "Unit Type", false)
        if(!unitType.filter(type => type.toLowerCase() == unit_type_field.toLowerCase()).length){ 
          errorList.push({
            from : "Unit Type",
            error: `Unit Type "<b>${unit_type_field}</b>" is not present in our data.`,
          })
        }


        const poductInfo = {
          "productVariationId" : productVariationId,
          "errorList"  : errorList,
          "warningList": warningList,
          "id"         : i,
          "v_status"   : removeSpace(arrData[i][0]),
          "seller"   	 : !removeSpace(arrData[i][2]) ? "HashTagBazzar" : removeSpace(arrData[i][2]),
          "hsn_code"   : removeSpace(arrData[i][8]),
          "status"     : removeSpace(arrData[i][1]),
          "keywords"   : removeSpace(arrData[i][4]),
          "brand"      : brandName.length ? brandName[0].name : removeSpace(arrData[i][3]),
          "categories" : categoryFinal,
          "variations" : [
            {
              "name"                 : removeSpace(arrData[i][9], true, false),
              "sku_id"               : removeSpace(arrData[i][19]),
              "unit"                 :
                {
                  "name"             : removeSpace(arrData[i][21]),
                  "value"            : removeSpace(arrData[i][20])
                },
              "stock_status"         : removeSpace(arrData[i][18]),
              "total_stock_quantity" : removeSpace(arrData[i][17]),
              "price"                :
                {
                  "mrp"              : addPaisa(removeSpace(arrData[i][10])),
                  "listing_price"    : addPaisa(removeSpace(arrData[i][11])),
                  "purchase_price"   : addPaisa(removeSpace(arrData[i][12]))
                },
              "tax"                  :
                {
                  "type"             : removeSpace(arrData[i][13], true,false),
                  "percentage"       : removeSpace(arrData[i][14])
                },
              "order_size"           :
                {
                  "min"              : removeSpace(arrData[i][15]),
                  "max"              : removeSpace(arrData[i][16])
                },
              "content"              : 
                {
                  "description"      : removeSpace(arrData[i][24]),
                  "features"         : removeSpace(arrData[i][25])
                },
              "attributes"           : attributeFinal,
              "images"               : imageFinal,
              "videos"               : [],
              "is_clubbed_buying"    : removeSpace(arrData[i][28]),
              "clubbed_buying"       : 
                {
                  "price_window"     : clubbedBuying,
                  "price"            :
                    {
                      "min"          : addPaisa(removeSpace(arrData[i][30]).split(',')[removeSpace(arrData[i][30]).split(',').length - 1]),
                      "max"          : addPaisa(removeSpace(arrData[i][11]))
                    },
                  "pincode"          : removeSpace(arrData[i][32]),
                  "city"             : removeSpace(arrData[i][33])
                },
              "deals"                :
                {
                  "is_exclusive"     : removeSpace(arrData[i][34]),
                  "discount"         : 
                    {
                      "percentage"   : removeSpace(arrData[i][35])
                    },
                  "start_time"       : `${removeSpace(arrData[i][36])}T${removeSpace(arrData[i][37])}:00.000Z`,
                  "end_time"         : `${removeSpace(arrData[i][38])}T${removeSpace(arrData[i][39])}:00.000Z`
                }
            }
          ]
        }
        if(!isVariation){
          arrData[i] = poductInfo
        }else{
          arrData[productVariationId].variations.push(poductInfo.variations[0])
          errorList = errorList?.map(error => ({ ... error, from: `<u>Variation ${arrData[productVariationId].variations.length -1}</u> -  ${error.from}` }))
          errorList?.map(error => arrData[productVariationId].errorList.push(error))
          warningList = warningList?.map(warning => ({ ... warning, from: `<u>Variation ${arrData[productVariationId].variations.length -1}</u> -  ${warning.from}` }))
          warningList?.map(warning => arrData[productVariationId].warningList.push(warning))
          arrData[i] = {hsn_code:'ignore', export: productVariationId + 1 }
        }
      }
    }
    
    setLoader(false)
    localStorage.setItem('importUsers',JSON.stringify(arrData))
    if(saveSheet) localStorage.setItem('saveImportUsers', true)
    else localStorage.setItem('saveImportUsers', false)
    setCsvData(arrData)
  }
  
  function CSV2JSON(csv) {
    var array = CSVToArray(csv);
    var objArray = [];
    for (var i = 1; i < array.length; i++) {
        objArray[i - 1] = {};
        for (var k = 0; k < array[0].length && k < array[i].length; k++) {
            var key = array[0][k];
            objArray[i - 1][key] = array[i][k]
        }
    }

    var json = JSON.stringify(objArray);
    var str = json.replace(/},/g, "},\r\n");

    return str
  }


  //Clear Product
  function clearProduct(){
    localStorage.removeItem('importUsers')
    setCsvData('')
    document.getElementById('uploadFile').value = ''
  }
  

  return (
    <MainLayout>

      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Import Users</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Banner</div>
			</nav>


      {/* DELETE PRODUCT */}
			{viewProduct && <ViewProduct componentHandle={setViewProduct} data={viewProductData}/>}


      {/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Import <span className="badge badge-primary ml-2">{csvData?.length? csvData.length : 0 }</span></div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0 d-flex"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search Product" /> 
            </div> 

            {csvData && <>
              <button className="btn btn-warning ml-2 tooltip_custom" data-tooltip="Import Another Product CSV" onClick={() => document.getElementById('uploadFile').click()}><i className='bx bx-import font-size-17'></i> Import</button>
              <button className="btn btn-light ml-2" onClick={clearProduct}><i className='bx bxs-trash font-size-16 mr-1'></i> Clear</button>
            </>}
          </div>   
        </div>
      </div>

      <input type="file" className="d-none" id="uploadFile" onChange={e => handleFiles(e.target.files)} accept="text/csv"/>


      <div className="row">
        {/* BANNER LIST */}
        <div className="col-md-12">
          <div className="card card-body userlist-table">

            {/* PRODUCT - IMPORT */}
            {!loader && !csvData &&
            <div className='text-center p-5'>
              {/* <button className="btn btn-warning" onClick={() => document.getElementById('uploadFile').click()}><i className='bx bx-import font-size-17'></i> Import Product </button> */}
              <button className="btn btn-warning" onClick={() => alert(`🥴 Don't use me. I am not Updated.`)}><i className='bx bx-import font-size-17'></i> Import Users </button>
              <label className='mt-3 text-secondary d-block cursor-pointer'><input type='checkbox' defaultChecked={saveSheet} onChange={e => setSaveSheet(e.target.checked == true ? true : false)}/> Save Sheet</label>
              {/* <div className='mt-1 h6 text-secondary'>Hello, <b>Buddy</b> 🙂 I only accept .csv file</div> */}
              <span className='text-secondary border bg-warning-transparent rounded px-3 py-1'>🥴 Don't use me. I am not Updated.</span>
            </div>}

            {loader && 
            <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
              <div>Wait <b>Buddy</b>...</div>
              <div className="spinner-grow" role="status"/>
            </div>}

            {/* PRODUCT - LIST */}
            {csvData && !loader && 
              <>
                <style>{/* SEARCH */}
                  {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                      visibility:hidden;
                      position:absolute !important
                  }`}
                </style>
                {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}

                <table className="table category_table table-bordered text-center border-0 text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className='text-center'>#</th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Category</th>  
                      <th>View</th>  
                      <th><i className='bx bx-store font-size-22'></i></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    {csvData.map( (pData,i) => <ProductRow capability={capability} key={i} id={i} pData={pData} productList={csvData} dispatch={dispatch} setViewProduct={setViewProduct} setViewProductData={setViewProductData}/>)}
                  </tbody>
                </table>
              </>
            }
            
          </div>
        </div>
			</div>  

    </MainLayout>
  )
}



/* -------------------------------
					  PRODUCT ROW
----------------------------------*/
const ProductRow = ({pData, id, dispatch, setViewProduct, setViewProductData, capability}) => {
  const [isValidProdouct, setIsValidProdouct] = useState(true)
  const [variation, setVariation]             = useState(false)
  const [errorVisible, setErrorVisible]       = useState(true)
  const [productAddStatus, setProductAddStatus]= useState('')
  const [addWithError, setAddWithError]        = useState(false)

  useMemo(async ()=>{
    if(!pData?.export && pData?.sku_id?.length < 1){
      setIsValidProdouct(false)
    }
  },[])

  
  async function addproduct(){
    const productData = JSON.parse(localStorage.getItem('importUsers'))[id]
    var variationQty  = productData.variations.length
    delete productData.id
    delete productData.errorList
    delete productData.warningList
    delete productData.productVariationId
    delete productData.v_status

   
    
    if(addWithError){
      var confirmIt =  window.confirm("Buddy! 🤯 Your are adding Product with error")
      if(!confirmIt) return setAddWithError(false)
    }

    // toast.loading('Checking Product ...',{toastId:'checking_product'})
    // for(var i = 0 ; i < productData.variations.length; i++){

    // }
    
    // var response = await axios.get(`/products/search/${query}?status=all&byType=all`)
    // var data     = await response.data
    // await toast.dismiss('checking_product')

    // if(!data.length){
      await toast.promise(
        dispatch(addProduct(productData)),
        {
          pending: 'Product adding...',
          success: 'Successfully! Product Added',
          error: `Product not added`
        }
      );
      setProductAddStatus(true)
    // }
  }


  // useEffect(() => {
  //   if(pData.errorList?.length){
  //     var audio = new Audio(errrAudio);
  //     audio.play();
  //   }
  // },[pData])
  

  return(
    <>
      <tr name={!pData?.export ? pData?.variations[0]?.name.toLowerCase() : ''} className={!isValidProdouct ? "invalid_product postion-relative" : "position-relative"}>

        <td className="text-center">
          {pData.errorList?.length > 0 && errorVisible && isValidProdouct && /* ERROR LIST */
          <ol className='error_box text-left text-light'>
            <i className='bx bxs-x-circle close-btn' onClick={() => setErrorVisible(false)}></i>
            {pData.errorList.map((data,index) => <li key={index}> <div><strong className='text-danger' dangerouslySetInnerHTML={{__html: data.from}}/>: <span dangerouslySetInnerHTML={{__html: data.error}}></span></div> </li>)}
            {pData.warningList.map((data,index) => (<li key={index}> <div><strong className='text-warning' dangerouslySetInnerHTML={{__html: data.from}}/>: <span dangerouslySetInnerHTML={{__html: data.warning}}></span></div> </li> ))}
          </ol>}
        </td>

        {pData?.export &&
          <td colSpan={10}><b className='text-secondary'>Successfully!</b> This Variation Added to <b> - "Product {pData.export}"</b></td>
        }

        {!pData?.export &&
          <>
            <td>
              <div className="d-flex align-items-center mb-2 last-mb-0">
                <img 
                  className="rounded avatar-md mr-2 border" 
                  src={
                    pData.variations[0].images?.filter(data => data.featured == 'yes').length ?
                    pData.variations[0].images?.filter(data => data.featured == 'yes')[0].link:
                    pData.variations[0].images[0]?.link
                  }
                /> 
                <div className="text-left">
                    <div className="font-weight-500 white-space-normal">{pData.variations[0].name}</div>
                  <div className="mr-3 d-flex justify-content-start align-items-center">
                    {pData.status === 'pending' ? <span className="badge badge-warning text-capitalize">{pData.status}</span>:''}
                    {pData.status === 'published' ? <span className="badge badge-success text-capitalize">{pData.status}</span>:''} 
                    {pData.status === 'drafts' ? <span className="badge badge-secondary text-capitalize">{pData.status}</span>:''}
                    {pData.status === 'review' ? <span className="badge badge-primary text-capitalize">{pData.status}</span>:''}
                    {pData.variations.filter(data => data.deals.is_exclusive == 'yes').length > 0 && 
                    <div className="badge badge-teal-transparent text-capitalize ml-1">Exclusive</div>}
                    <span className="text-secondary ml-3">SKU: {pData.variations[0].sku_id}</span> 
                  </div>
                  { pData.variations.length > 1 &&
                    <span onClick={() => setVariation(variation? false : true)} className="btn btn-outline-indigo pt-0 pb-0 pl-2 pr-2 mt-2 font-size-13">
                      {pData.variations.length - 1} Variations Available
                    </span>
                  }
                </div>
              </div>
            </td>

            {/* COLUMN PRICE */}
            <td>
              <div>&#8377; {pData.variations[0].price.listing_price}</div>
              <del className="text-secondary">&#8377; {pData.variations[0].price.mrp}</del>
            </td>

            {/* COLUMN CATEGORY */}
            <td>
              <div className="d-flex flex-wrap">
                {pData?.categories.map((category, index) => (
                  category.category_id == null ? 
                  <span key={index} className="tag tag-danger mb-1 mr-1 tooltip_custom" data-tooltip="InValid Category"><del>{category.name}</del></span> :
                  <span key={index} className="tag mb-1 mr-1">{category.name}</span> 
                ))}
              </div>
            </td>

            <td>
              <button onClick={() => {setViewProduct(true); setViewProductData(pData)}} className="btn btn-sm btn-white" data-title="View Json"><i className='las la-eye h4 m-0 text-primary'></i></button>
            </td>

            <td>
              <div className='d-flex flex-column'>
                {capability?.product?.import_view && !capability?.product?.import ? null : !errorVisible && <label className='text-secondary mb-1 text-monospace cursor-pointer'><input disabled={productAddStatus != '' ? true : false} checked={addWithError} type='checkbox' onChange={e => setAddWithError(!addWithError)}/> Add With Error</label>}
                {productAddStatus === true  && <button className={`btn btn-success ${addWithError ? 'mb-2 w-100' : ''}`}><i className='bx bx-check-double'></i> Product Addded</button>}
                {productAddStatus === false && <button className={`btn btn-danger ${addWithError ? 'mb-2 w-100' : ''}`} onClick={addproduct}><i className='bx bx-x'></i> Not Added</button>}
                {capability?.product?.import_view && !capability?.product?.import ? null : productAddStatus === '' && errorVisible || productAddStatus === '' && addWithError ? <button className={`btn btn-outline-primary animated ${addWithError ? 'mb-2 bounceIn w-100' : ''}`} onClick={addproduct}>+ Add / Update</button> : null}
                {capability?.product?.import_view && !capability?.product?.import && !pData.errorList.length  ? <button className='btn btn-outline-success center'><i className='bx bx-check font-size-18 mr-1'></i> Ready</button> : null}
                {!errorVisible && <button onClick={() => setErrorVisible(true)} className='btn btn-outline-danger align-items-center d-flex ml-auto mr-auto bounceIn animated'><i className='bx bx-bug font-size-18 d-inline-block mr-1'></i> Show Error <span className='badge badge-danger ml-2'>{pData.errorList?.length}</span></button>}
              </div>
            </td>
          </>
        }
      </tr>

      {/* VARIATIONS */}
			{variation && 
				<tr className="variation_data animated fadeIn" name={pData.variations[0]?.name.toLowerCase()}>
					<td colSpan={10} className="count_ignore">
						{pData.variations.filter((data,index) => index !== 0).map((vData,i) => (
							<div className="d-flex align-items-center mb-2 last-mb-0" key={i}>
								<img 
									className="rounded avatar-md mr-2 border" 
									src={
										vData.images?.filter(data => data.featured == 'yes').length ?
										vData.images?.filter(data => data.featured == 'yes')[0].link:
										vData.images[0]?.link
									}
								/> 
								<div className="text-left">
									<div className="font-weight-bold white-space-normal">{vData.name}</div>
									<div className="mr-3 d-flex justify-content-start">
										<del>₹{vData.price.listing_price}</del> 
										<span className="ml-2">₹{vData.price.purchase_price}</span>, 
										<span className="text-secondary ml-3">SKU: {vData.sku_id}</span> 
									</div>
								</div>
							</div>
						))}
					</td>
				</tr>
			}
    </>
  )
}



/* -------------------------------
					VIEW PRODUCT
----------------------------------*/
const ViewProduct = ({data, componentHandle}) => {

  return(
    <div className="modal show" style={{display:'block'}}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content tx-size-sm">
          <div className="modal-header">
            <h2 className="font-size-16">{data.variations[0].name}</h2>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => componentHandle(false)}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
					<div className="modal-body simple-modal overflow-auto tx-center pd-y-20 pd-x-20">
            <div className="text-left">
              {/* <pre> */}
                <code>
                {JSON.stringify(data)}
                </code>
              {/* </pre> */}
            </div>
					</div>
				</div>
			</div>
		</div>
  )
}

function mapStateToProps(state){
  const { categories }     = state.categories
  const { attributesList } = state.attributes
  let capability          = state?.auth?.currentUser?.capability
  capability              = capability ? JSON.parse(capability) : null

	return {
    categories,
    attributesList,
    capability
	}
}

export default connect(mapStateToProps)(ImportUsers)