import { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { ROUTE_PLAN_DELIVERY_LIST_CLEAR } from '../../../actions/types'
import { getDeliveryRouteList, getDeliveryRouteOrderList } from '../../../actions/shipping.action';


function RouteVehiclePlanRoute({ handleComponent, dispatch, deliveryRoutePlanOrderList}) {

  const [btnLoader, setBtnLoader]                         = useState(false)
  const [loader, setLoader]                               = useState(false)
  const [vehicleNumber, setVehicleNumber]                 = useState(1)

  
  const OnSetVehicleNumber = () => {
      var elements = [];
      for(var i =1; i <= vehicleNumber; i++){
          elements.push(
          <div className='col-md-4'>
            <div className='form-group'>
              <label>Max Load / Vehicle  - {i} <span className='vehicle-map-order-btn'><button className='btn btn-warning btn-sm'> map order</button></span></label>
              <input type='number' className='form-control' />
            </div>
          </div>
          );
      }
      return elements;
  }

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right modal-dialog-for-route-vehicle-plan">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-plus font-size-22 mr-2'></i> Vehicle Plan</h6>
          </div>
            <div className="modal-body">

              <div className='row'>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label>Order Weight</label>
                    <input type='text' className='form-control' defaultValue={deliveryRoutePlanOrderList?.weight}/>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label>Total Delivery</label>
                    <input type='text' className='form-control' defaultValue={deliveryRoutePlanOrderList?.totalOrder}/>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label>No. of Vehicle</label>
                    <input type='number' defaultValue={vehicleNumber} min={1} max={3} onChange={(e) => setVehicleNumber(e.target.value)} className='form-control'/>
                  </div>
                </div>
              </div>

              <hr/>
              <div className='row'>
                {<OnSetVehicleNumber/>}                
              </div>
                          
              {loader &&
              <div className="d-flex mx-auto justify-content-center flex-column mt-4 mb-3 align-items-center">
                <div>Wait <b>Buddy</b>...</div>
                <div className="spinner-grow" role="status" />
              </div>
              }
              
            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => {handleComponent(false); }}>Close</div>
              <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Add Route' : <span className="spinner-border spinner-border-sm mx-3"></span>}
              </button>
            </div>

        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  const { countryList, stateList, cityList, deliveryRoutePlanList, deliveryRoutePlanOrderList } = state.shipping

  return {    
    countryList,
    stateList,
    cityList,
    deliveryRoutePlanList,
    deliveryRoutePlanOrderList
  };
}

export default connect(mapStateToProps)(RouteVehiclePlanRoute);