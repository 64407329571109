import { PRODUCT_BY_ID, PRODUCT_NEW_LOG, GET_PRODUCT_LOG, PRODUCT_LIST, SET_MESSAGE, PRODUCT_ADD, CLEAR_PRODUCT, PRODUCT_SEARCH, EXPORT_PRODUCT} from "./types";
import productService from "../services/product.service"
import { handleErrors } from "../helpers/common-function.helper"


export const productListShow = (page, limit, sortBy, status) => async (dispatch) => {
	return productService.productList(page, limit, sortBy, status).then(
		(data)  => { dispatch({ type: PRODUCT_LIST, payload:data }) },
		(error) => handleErrors(error, dispatch)
	)
}


export const exportProduct = (page, limit, sortBy, status) => async (dispatch) => {
	return productService.productList(page, limit, sortBy, status).then(
		(data)  => { dispatch({ type: EXPORT_PRODUCT, payload:data }) },
		(error) => handleErrors(error, dispatch)
	)
}


export const getProductById = (id) => async (dispatch) => {
	return productService.viewProduct(id).then(
		(data)  => { dispatch({ type:PRODUCT_BY_ID, payload:data }) },
		(error) => handleErrors(error, dispatch)
	)
}

export const updatePriceFromExcel = (id) => async (dispatch) => {
	return productService.onUpdatePriceFromExcel(id).then(
		(data)  => {},
		(error) => handleErrors(error, dispatch)
	)
}


export const clearProduct = () => (dispatch) => dispatch({type:CLEAR_PRODUCT})

export const deleteProduct = (id) => async (dispatch) => {
	return productService.deleteProduct(id).then(
		(res)   => {},
		(error) => handleErrors(error, dispatch)
	)
}

export const deleteVariation = (productId, variationId) => async (dispatch) => {
	return productService.deleteVariation(productId, variationId).then(
		(res)   => {},
		(error) => handleErrors(error, dispatch)
	)
}


export const addProduct = (data) => async (dispatch) =>{
	return productService.addNewProduct(data).then(
		(res)   => { dispatch({ type: PRODUCT_ADD, payload: res }); return res },
		(error) => { handleErrors(error, dispatch, false); return error.response.data }
	)
}


export const updateProduct = (pId,vId,data) => async (dispatch) =>{
	return productService.updateProduct(pId,vId,data).then(
		(res)   => {},
		(error) => handleErrors(error, dispatch)
	)
}


export const searchProduct = (query, FilterData) => (dispatch) =>{
	return productService.searchProduct(query, FilterData).then(
		(data)  => { dispatch({ type : PRODUCT_SEARCH, payload: data }) },
		(error) => handleErrors(error, dispatch)
	)
}


export const addNewVariation = (productID, vData) => (dispatch) => {
	return productService.addNewVariation(productID, vData).then(
		(data)  => {},
		(error) => handleErrors(error, dispatch)
	)
} 


export const addProductLog = (data) => (dispatch) => {
	return productService.addProductLog(data).then(
		(res)   => {},
		(error) => handleErrors(error, dispatch)
	)
}


export const getProductLog = (productId) => (dispatch) => {
	return productService.getProductLog(productId).then(
		(res)   => { dispatch({ type: GET_PRODUCT_LOG, payload: res }) },
		(error) => handleErrors(error, dispatch)
	)
}