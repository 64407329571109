import { CUSTOMER_LIST, SELLER_LIST, SELLER_ADD, USER_BY_ID, CUSTOMER_SEARCH, CUSTOMER_ADDRESS, NEW_CUSTOMER, EXPORT_CUSTOMER_LIST, CUSTOMER_DOC_LIST, CUSTOMER_DOC_ADD, CUSTOMER_ADDRESS_ADD, EDIT_CUSTOMER, CUSTOMER_ADDRESS_LIST,CUSTOMER_BANK_DETAIL_LIST, CUSTOMER_BANK_DETAIL_ADD,CUSTOMER_BANK_DETAIL_EDIT, CUSTOMER_ADDRESS_UPDATE,AFFILIATE_SEARCH_LIST} from "../actions/types";

const initialState = { 
  users: {
    newCustomer              : null,
    editCustomer             : null,
    customerList             : null,
    affiliateSearchList      : null,
    exportUserList           : null,
    userById                 : null,   
    sellerList               : null,
    customerSearch           : null,
    addSeller : {
      basicDetails           : null,
      pickUpAddress          : null,
      businessDetails        : null,
      bankDetails            : null
    },
    customerDocList          : null,
    customerDocAdd           : null,
    customerAddressById      : null,
    customerAddressAdd       : null,
    customerAddressUpdate    : null,
    customerAddressList      : null,

    customerBankDetailList   : null,
    customerBankDetailAdd    : null,
    customerBankDetailUpdate : null,

  }
}

export default function foo(state = initialState, action) {
  const { type, payload } = action;
  
  switch (type) {
    case CUSTOMER_SEARCH:
      return {
        users: {
          ...state.users,
          customerSearch: payload,
          customerList: {
            ... state.users.customerList,
            users: payload
          }
        }
      }
      case AFFILIATE_SEARCH_LIST:
      return {
        users:{
          ... state.users,
          affiliateSearchList:payload,
        }
      }
    case CUSTOMER_LIST:
      return {
        users:{
          ... state.users,
          customerList:payload,
        }
      } 
    case EXPORT_CUSTOMER_LIST:
      return {
        users:{
          ... state.users,
          exportUserList:payload,
        }
      } 
    case USER_BY_ID:
      return {
        users:{
          ... state.users,
          userById:payload,
        }
      }
    case SELLER_LIST:
      return {
        users:{
          ... state.users,
          sellerList:payload,
        }
      }
    case SELLER_ADD:
      return {
        users : {
          ... state.users,
          addSeller : {
            basicDetails : payload,
          }
        } 
      }    
    case CUSTOMER_ADDRESS:
      return{
        users:{
          ...state.users,
          customerAddressById: payload
        }
      }
    case CUSTOMER_ADDRESS_ADD:
      return {
        users:{
          ... state.users,
          customerAddressAdd:payload,
        }
      } 
      case CUSTOMER_ADDRESS_UPDATE:
      return {
        users:{
          ... state.users,
          customerAddressUpdate:payload,
        }
      } 
    case CUSTOMER_ADDRESS_LIST:
        return {
          users:{
            ... state.users,
            customerAddressList:payload,
          }
        } 
    case NEW_CUSTOMER:
      return{
        users:{
          ...state.users,
          newCustomer: payload
        }
      }
    case EDIT_CUSTOMER:
      return{
        users:{
          ...state.users,
          editCustomer: payload
        }
      }
    case CUSTOMER_DOC_LIST:
      return {
        users:{
          ... state.users,
          customerDocList:payload,
        }
      } 
      case CUSTOMER_DOC_ADD:
      return {
        users:{
          ... state.users,
          customerDocAdd:payload,
        }
      } 
      case CUSTOMER_BANK_DETAIL_LIST:
        return {
          users:{
            ... state.users,
            customerBankDetailList:payload,
          }
        } 
      case CUSTOMER_BANK_DETAIL_ADD:
      return {
        users:{
          ... state.users,
          customerBankDetailAdd:payload,
        }
      } 
      case CUSTOMER_BANK_DETAIL_EDIT:
      return {
        users:{
          ... state.users,
          customerBankDetailUpdate:payload,
        }
      } 

    default:
      return state;
  }
}