import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { editPincode, getPincodeById } from '../../../actions/shipping.action'


function CityEdit({ dispatch, handleComponent, id, pincodeById, refreshList, country, state, city }) {

  useEffect(() => {
    dispatch(getPincodeById(id))
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }
    await toast.promise(dispatch(editPincode(data, id)), {
      pending: 'Adding ...',
      success: 'Successfully! Addded',
      error: 'Sorry! not added'
    })
    handleComponent(false)
    refreshList()
  }

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-edit-alt font-size-22 mr-2'></i> Edit Pincode</h6>
          </div>
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              {pincodeById ?
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input type='hidden' name='country' value={country} />
                    <input type='hidden' name='state' value={state} />
                    <input type='hidden' name='city' value={city} />
                    <div className="input_wrap w-100">
                      <input name="pincode" type="text" defaultValue={pincodeById.pincode} placeholder=" " required />
                      <label>Pincode Number</label>
                    </div>
                    <small className='text-secondary'>Example: 226020, 226065</small>
                  </div>
                  <div className='col-md-12 mb-3'>
                    <select className='form-control' name='status' defaultValue={pincodeById.status} required>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                :
                <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
                  <div>Wait <b>Buddy</b>...</div>
                  <div className="spinner-grow" role="status" />
                </div>
              }
            </div>
            {pincodeById &&
              <div className="modal-footer">
                <div className="btn ripple btn-outline-danger" onClick={() => handleComponent(false)} data-dismiss="modal" type="button">Close</div>
                <button type='submit' className="btn ripple btn-primary">+ Add</button>
              </div>}
          </form>
        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  const { pincodeById } = state.shipping;
  return {
    pincodeById
  };
}

export default connect(mapStateToProps)(CityEdit)