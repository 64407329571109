import { EMAIL_LIST, SET_MESSAGE, EMAIL_BY_NAME, EMAIL_ADD, EMAIL_EDIT, SMS_LIST, SMS_BY_NAME, SMS_ADD, SMS_EDIT, FIREBASE_NOTIFICATION_LIST,FIREBASE_NOTIFICATION_ADD,FIREBASE_NOTIFICATION_EDIT,FIREBASE_NOTIFICATION_BY_NAME,WHATSAPP_LIST,WHATSAPP_BY_NAME,WHATSAPP_ADD ,WHATSAPP_EDIT} from "./types"
import messageTemplatesService from "../services/message-templates.service"
import { handleErrors } from "../helpers/common-function.helper"



export const getEmailList = () => async (dispatch) => {
  return messageTemplatesService.EmailList().then(
    (data)  => { dispatch({ type: EMAIL_LIST, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const getEmailByName = (name) => async (dispatch) => {
  return messageTemplatesService.emailByName(name).then(
    (data)  => { dispatch({ type: EMAIL_BY_NAME, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const addEmailTemplate = (data) => async (dispatch) => {
  return messageTemplatesService.addEmailTemp(data).then(
    (res)   => { dispatch({ type: EMAIL_ADD, payload: res.data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const updateEmailTemplate = (id, data) => async (dispatch) => {
  return messageTemplatesService.editEmailTemp(id, data).then(
    (data)  => { dispatch({ type: EMAIL_EDIT, payload: data, }); },
    (error) => handleErrors(error, dispatch)
  );
};

export const deleteEmailTemplate = (catId) => async (dispatch) => {
  return messageTemplatesService.deleteEmailTemp(catId).then(
    (data)  => { dispatch(getEmailList()) },
    (error) => handleErrors(error, dispatch)
  );
}



export const getSmsList = () => async (dispatch) => {
  return messageTemplatesService.SmsList().then(
    (data)  => { dispatch({ type: SMS_LIST, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const getSmsByName = (name) => async (dispatch) => {
  return messageTemplatesService.smsByName(name).then(
    (data)  => { dispatch({ type: SMS_BY_NAME, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const addSmsTemplate = (data) => async (dispatch) => {
  return messageTemplatesService.addSmsTemp(data).then(
    (res)   => {dispatch({ type: SMS_ADD, payload: res.data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const updateSmsTemplate = (id, data) => async (dispatch) => {

  return messageTemplatesService.editSmsTemp(id, data).then(
    (data)  => { dispatch({ type: SMS_EDIT, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const deleteSmsTemplate = (catId) => async (dispatch) => {
  return messageTemplatesService.deleteSmsTemp(catId).then(
    (data)  => { dispatch(getSmsList()) },
    (error) => handleErrors(error, dispatch)
  );
}


// ------------------------------ FIREBASE NOTIFICATION
export const getFirebaseNotificationList = () => async (dispatch) => {
  return messageTemplatesService.firebaseNotificationList().then(
    (data)  => { dispatch({ type: FIREBASE_NOTIFICATION_LIST, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const getFirebaseNotificationByName = (name) => async (dispatch) => {
  return messageTemplatesService.firebaseNotificationByName(name).then(
    (data)  => { dispatch({ type: FIREBASE_NOTIFICATION_BY_NAME, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const addFirebaseNotificationTemplate = (data) => async (dispatch) => {
  return messageTemplatesService.addFirebaseNotificationTemp(data).then(
    (res)   => { dispatch({ type: FIREBASE_NOTIFICATION_ADD, payload: res.data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const updateFirebaseNotificationTemplate = (id, data) => async (dispatch) => {

  return messageTemplatesService.editFirebaseNotificationTemp(id, data).then(
    (data)  => { dispatch({ type: FIREBASE_NOTIFICATION_EDIT, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const deleteFirebaseNotificationTemplate = (id) => async (dispatch) => {
  return messageTemplatesService.deleteFirebaseNotificationTemp(id).then(
    (data)  => { dispatch(getFirebaseNotificationList()) },
    (error) => handleErrors(error, dispatch)
  );
}

export const sendNotification = (data) => async (dispatch) => {
  return messageTemplatesService.sendNotification(data).then(
    (res)   => { },
    (error) => handleErrors(error, dispatch)
  );
};

// ------------------------------ WHATSAPP
export const getWhatsappList = () => async (dispatch) => {
  return messageTemplatesService.whatsappList().then(
    (data)  => { dispatch({ type: WHATSAPP_LIST, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const getWhatsappByName = (name) => async (dispatch) => {
  return messageTemplatesService.whatsappByName(name).then(
    (data)  => { dispatch({ type: WHATSAPP_BY_NAME, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const addWhatsappTemplate = (data) => async (dispatch) => {
  return messageTemplatesService.addWhatsapp(data).then(
    (res)   => { dispatch({ type: WHATSAPP_ADD, payload: res.data, }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const updateWhatsappTemplate = (id, data) => async (dispatch) => {
  return messageTemplatesService.editWhatsapp(id, data).then(
    (data)  => { dispatch({ type: WHATSAPP_EDIT, payload: data, }); },
    (error) => handleErrors(error, dispatch)
  );
};

export const deleteWhatsappTemplate = (id) => async (dispatch) => {
  return messageTemplatesService.deleteWhatsapp(id).then(
    (data)  => { dispatch(getWhatsappList()) },
    (error) => handleErrors(error, dispatch)
  );
}