import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; 
import { addEmailTemplate } from '../../../actions/message-templates.action';
import MainLayout from '../../layouts/main.component';


const AddEmail = ({dispatch}) => {

  const [btnLoader, setBtnLoader] = useState(false)
  const history                   = useHistory()
  document.title                  = 'Add Email Template'

  const onSubmit = async (e) => { 
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 

    await toast.promise(dispatch(addEmailTemplate(data)),{
      pending: 'Adding Template...',
      success: 'Successfully! Add Template',
      error: {
        render(){
          setBtnLoader(false)
          return 'Template not added'
        }
      }
    })  
    setTimeout(async () => history.push('/message-template/email/view/'+encodeURI(data.name)), 1000)
  }

  return (
    <MainLayout>

      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Add Email Template</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item" onClick={()=> history.push('/message-template/email/list')}>Email Templates</div>
        <div className="breadcrumb-item active">Add</div>
      </nav>


      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">Add Email Template</div>
        <button disabled={btnLoader} className="btn btn-primary" type="submit" onClick={() => document.querySelector('.add_template_btn').click()}>
          {!btnLoader ? '+ Add Template' : <span className="spinner-border spinner-border-sm mx-5"></span>}
        </button>
      </div>

      <div className='card card-body animated fadeIn'>
        <form onSubmit={ (e) => {setBtnLoader(true); onSubmit(e)}}>  

          <button className='d-none add_template_btn' type='sumbit'>Submit Form</button> 

          <div className="row">
            <div className="col-md-4">
              <div className="input_wrap w-100">
                <input name='name' type="text"  placeholder=" "/>
                <label>Name</label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="input_wrap w-100">
                <input name='sender_name' type="text"  placeholder=" "/>
                <label>Sender Name</label>
              </div>
            </div>

            <div className="col-md-4">
              <div className="input_wrap w-100">
                <input name='sender_email' type="text"  placeholder=" "/>
                <label>Sender Email</label>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="input_wrap w-100">
                <input name='subject' type="text"  placeholder=" "/>
                <label>Subject</label>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <div className="form-group mb-0"> 
                <label className="main-content-label tx-11 m-0">Content</label> 
              </div>
              <SunEditor name="content" lang="en" height="200" />
            </div>

          </div>
        </form> 
      </div>
    </MainLayout>
  )
}

function mapStateToProps(state) {
  return{}
}

export default connect(mapStateToProps)(AddEmail);