import React, { useEffect, useState, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' 
import MainLayout from '../layouts/main.component'
import { addProduct, addProductLog } from '../../actions/product.action'
import { attributesListShow } from '../../actions/attributes.action'
import { categoryList } from '../../actions/category.action'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { toast } from 'react-toastify'
import { convertToArray, centerInput, sunEditorSetting, removeSpace, handleErrors } from '../../helpers/common-function.helper'
import { unitType, stockStatus, deliveryTime, clubbedBuyingRange, productStatus, taxType, taxPercentage, maxVariaiton } from '../../helpers/product.helper'
import {customerSearch} from '../../actions/users.action'
import { getCountryList, getStateList, getCityList, getPincodeList } from '../../actions/shipping.action'

//IMAGE - "FilePond"
import { FilePond, File, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize)


/* -------------------------------
					 ADD PRODUCT
----------------------------------*/
const AddProduct = ({dispatch, categories, attributesList, productAdd, userId, capability, supplierSearchResult, countryList, stateList, cityList, pincodeList}) => {
	
	useEffect(() =>{
		document.title = "Add New Product"
		dispatch(categoryList())
		dispatch(attributesListShow())
		//MANAGE TAB INDEX
		setInterval(() => document.querySelectorAll('.no-tab-index *,.no-tab-index, [data-disable="true"] *').forEach(e => e.tabIndex = -1), 2000)
	},[])

	const [handleSupplierComponent, setHandleSupplierComponent]	= useState(false);
	const [supplierData, setSupplierData]						= useState([]);	
	const [search, setSearch]                         			= useState('') //SEARCH CATEGORY
	const [variationManagment, setVariationManagment] 			= useState([]) //PRODUCT VARIATION
	const [stopClick, setStopClick]                   			= useState(false)
	const history     = useHistory()
	const sellerRef   = useRef()
	const hsn_code    = useRef()
	const status      = useRef()
	const keywords    = useRef()
	const brand       = useRef()	

	/* CATEGORIES
   ============================= */
	const [categoriesData, setCategoriesData] = useState({})
	const category_list = (e) =>{
		if(e.target.checked){
			setCategoriesData(data => ({
				... data,
				[e.target.name]:{
					"name"          : e.target.name,
					"category_id"   : e.target.value,
					"slug"          : e.target.getAttribute('slug')
				}	
			}))
		}else{
			setCategoriesData(data => ({
				... data,
				[e.target.name]:null
			}))
		}
	}

	
	/* VARIATIONS "add or remove" TAB MANAGEMENT
  ============================================== */
	const [variationActive, setvariationActive] = useState()

	let initialVariation = { //VARIATION "default value"
		0:<ProductVariation setStopClick={setStopClick} dispatch={dispatch} attributesList={attributesList} id={0} variationManagment={setVariationManagment}/>
	}
	
	const [variations, setVariations] = useState(initialVariation) //VARIATIOINS
	var variationToArray = convertToArray(variations)

	useMemo(()=>{
		setVariations(initialVariation)
	},[attributesList])

	function addVariation(){ //ADD VARIATIONS
		if(convertToArray(variations).length > maxVariaiton) return alert('😡 Maximum Variation can be 10')
		setVariations({
			...variations,
			[variationToArray.length]:<ProductVariation setStopClick={setStopClick} dispatch={dispatch} attributesList={attributesList} id={variationToArray.length} variationManagment={setVariationManagment}/>
		})
	}

	function removeVariation(id){ //REMOVE VARIATIONS
		document.getElementById('variation'+id).parentNode.className += ' zoomOut animated'
		setTimeout( () => { setVariations( { ...variations,[id]:null } ) }, 400 )
		setVariationManagment( data => ( { ...data, [id]:null } ) )

		// "VARIATION BUTTONS" - IF ACTIVE VARIATION BUTTON REMOVED THEN SET FIRST BUTTON ACTIVE
		{variationActive == 'variation'+id && setvariationActive(undefined)}
	}
	

	/* ==============================
		  	ADD PRODUCT "SUBMIT 😊" 
	=================================*/
	const addNewProduct = async (variationData) => {

		const bodyData = {
			"supplier"   	: supplierData,
			"hsn_code"   	: hsn_code?.current?.value,
			"status"    	: status?.current?.value,
			"keywords"   	: removeSpace(keywords?.current?.value),
			"brand"      	: brand?.current?.value,
			"categories" 	: convertToArray(categoriesData).filter(data => data !== null),
			"variations" 	: convertToArray(variationData).filter(data => data !== null)
		}

		toast.loading('Product adding...', {toastId: 'product_promise'})
		localStorage.setItem('addProductBackup', JSON.stringify(bodyData))

		if(supplierData.length==0) {
			toast.error('Supplier is required')
			toast.dismiss('product_promise')
			setStopClick(false)	
			return
		}

		const response = await dispatch(addProduct(bodyData))
		toast.dismiss('product_promise')
		if(response.errors) {setStopClick(false); return document.querySelectorAll('.add_main_product_btn').forEach(data => data.setAttribute('disabled','true'))}
		toast.success('Successfully! Product Added')
		localStorage.removeItem('addProductBackup')
	}

	const submitData = () => {
		setStopClick(true)
		document.querySelectorAll('.add_main_product_btn').forEach(data => {data.removeAttribute('disabled'); data.click()})	
	}	
	
	useEffect(async() => {
		if(!productAdd?.data?.success[0]?.data?.id) return
		const produtLog = {
			"productId"   : productAdd?.data?.success[0]?.data?.id,
			"notes"       : `Product Added by Admin Panel ${productAdd?.data?.success[0]?.data.variation.length > 1 ? `with ${productAdd?.data?.success[0]?.data.variation.length - 1} Variations` : ''}`,
			"updatedBy"   : userId
		}
		await dispatch(addProductLog(produtLog))
		setTimeout(()=>history.push('/products/view/'+productAdd?.data?.success[0]?.data?.id),1000)
	}, [productAdd])


	//FIANL PRODUCT SUBMIT
	useEffect(() => {
		if(convertToArray(variationManagment).filter(fdata => fdata !== null).length == variationCount){
			addNewProduct(variationManagment)
			setVariationManagment([])
		}
	},[variationManagment])

	//VARIATION COUNT 
	const [variationCount, setVariationCount] = useState(convertToArray(variations).filter(data => data !== null).length)
	useEffect(()=>{
		setVariationCount(convertToArray(variations).filter(data => data !== null).length)
	},[variations])

	const onMapSupplierDelete = (sId) => {
		var filteredArray = supplierData.filter(function(e) { return e.id !== sId })
		setSupplierData(filteredArray); 
		return
	}

	return (
		<MainLayout>

			{/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i>Back</div>
				<div className="mr-auto page_title">Add Product</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/products') }>Products</div>
				<div className="breadcrumb-item active">Add New Product</div>
			</nav>

			{/* LOADER */}
			{!variations && 
			<div className="d-flex justify-content-center flex-column align-items-center mt-5">
				<div>Wait <b>Buddy</b>...</div>
				<div className="spinner-grow" role="status"/>
			</div>}
			
			{/* BODY */}
			<div className="container p-0 pb-2 mb-4 mt-4">
				<div className="row">

					<div className="col-md-9">

						<div className="panel panel-primary p-0 product_tab card_layout" id="product_info_box">
							<div id='addProductVTabs' className=" tab-menu-heading  tab-border border-bottom shadow-base position-relative z-index-1">
								<div className="tabs-menu1">
									<ul className="nav panel-tabs main-nav-line">
										<li><div href="#productDetails" className="nav-link pl-4 pr-4 active cursor-pointer" data-toggle="tab">Product Details</div></li>
										<li><div href="#variations" className="nav-link pl-4 pr-4 cursor-pointer" data-toggle="tab">Add Variations</div></li>
									</ul>
								</div>
							</div>

							<div className="panel-body p-0 border-0 mt-0 tabs-menu-body main-content-body-right border">
								<div className="tab-content variation_tab overflow-unset">
									<div className="tab-pane active" id="productDetails">
											{variationToArray[0]}
									</div>
									
									<div className="tab-pane" id="variations">
										<div className="d-flex no-tab-index overflow-auto pt-3 pb-3 pr-3 pl-3 shadow-sm variation-btns z-index-10 position-relative">

											{/* VARIATION TABS FUNCTIONALITY MANAGMENT WITH CSS */}
											<style>
												{variationActive == undefined && `.variation_btn:nth-of-type(1) > .btn{background:#003df2;color:white}`}
												{variationActive == undefined && `.variation_box > div:nth-of-type(1){display:block}`}
												{`${variationActive &&`#${variationActive}`}{background:#003df2;color:white}`}
												{`${variationActive &&`.${variationActive}`}{display:block}`}
											</style>

											{ variationToArray.map((data,index) => {
													if(index !== 0 && data !== null){
														return(
															<div key={index} className="variation_btn position-relative text-nowrap mr-3 zoomIn animated">
																<div id={`variation${index}`} onClick={(e) => setvariationActive(e.target.id)} className="btn btn-outline-primary"> Variation</div>
																<i className='bx bxs-x-circle cursor-pointer' data-id={index} onClick={(e) => removeVariation(e.target.getAttribute('data-id'))}></i>
															</div>
														)
													}
											})}

											<div onClick={addVariation} className="btn btn-outline-primary badge-primary-transparent text-nowrap"> + Add Variation</div>
										</div>

										{ variationToArray.filter(data => data != null).length <= 1 &&
											<p className="p-5 text-center font-weight-500 text-secondary d-flex align-items-center justify-content-center">
												Now, Add Product Variations <i className='bx bxs-smile font-size-20 text-primary ml-2'></i>
											</p>
										}

										<div className="variation_box">
											{ variationToArray.map((data,index) => {
													if(index !== 0 && data !== null){
														return  <div key={index} className={`variation${index}`}>{data}</div>
													}
											})}	
										</div>

									</div>
								</div>
							</div>
						
						</div>

					</div>
					
					{/* Form Sidebar */}
					<div className="col-md-3" id='addProductRightSide'>

						<div className="card p-3">
							<div className="form-group">
								<span className="font-weight-500">Status</span>
								<select onFocus={centerInput} tabIndex={1} name="status" className="form-control" ref={status}>
									{productStatus.filter(status => status.name != 'all').map((status,i) => {
										if(status?.showItems.filter(item => capability?.product[item] == true).length){
											return <option key={i} value={status.name}>{status.label}</option>
										}
									})}
								</select>
							</div>			

							<h6>Supplier <button type='button' className='btn btn-warning btn-xs float-right' onClick={() => setHandleSupplierComponent(true)}><small>List &amp; Add</small></button></h6>				
							<table className='table table-bordered addproduct-price-table'>
								<thead>
									<th>#</th>
									<th>Name</th>
									<th>State</th>
									<th>City</th>
									<th>-</th>
								</thead>
								<tbody>
									{ supplierData && supplierData.map((supData,i) => (
										<tr>
											<td>{i+1}</td>
											<td>{supData?.supplierInfo?.first_name} {supData?.supplierInfo?.last_name}</td>
											<td>{supData?.state}</td>
											<td>{supData?.city}</td>
											<td><button type='button' onClick={() => onMapSupplierDelete(supData.id)} className="btn btn-sm btn-white" title="Delete Supplier"><i className='bx bx-trash h4 m-0 text-danger'></i></button></td>
										</tr>
									))
									}

									{supplierData.length==0 && <tr><td className='text-center' colSpan={4}>data not available</td></tr>}
									
								</tbody>
							</table>	

							<div className="input_wrap w-100">
								<input onFocus={centerInput} ref={brand} type="text" placeholder=" "/>
								<label>Brand</label>
							</div>

							<div className="input_wrap w-100 mt-3">
								<input onFocus={centerInput} ref={hsn_code} type="text" placeholder=" " className='text-uppercase'/>
								<label>HSN Code</label>
							</div>

							<div className='mt-4'>
								<div className='select-box'>
									<label>Keywords</label>
									<textarea rows={4} onFocus={centerInput} className="form-control" ref={keywords}/>
								</div>					
							</div>

							<span className="text-secondary mt-2">Add Keywords like: <b className="font-weight-500 text-dark">one,two</b></span>
							<button disabled={stopClick} tabIndex={2} className="btn btn-primary mt-3 add_product_btn" onClick={e => submitData()}>
								{stopClick ? <span className="spinner-border spinner-border-sm"></span> : `+ Add Product`}
							</button>
						</div>


						{/* CATEGORY */}
						<div className="card pt-3 pl-3 pr-3 pb-2 mb-0">
							<h2 className="font-size-15">Category <span className="badge-primary-transparent badge_circle ml-1">{convertToArray(categoriesData)?.length}</span></h2>
							
							<style>{/* CATEGORY SEARCH */}
								{`.search_box + ul li:not([data-name${search.length ? `*="${search}"`: ''}]){
										display:none;
								}`}
							</style>
							<input tabIndex={0} onFocus={centerInput} type="search" onChange={(e) => setSearch(e.target.value.toLowerCase())} className="form-control search_box" placeholder="Search Category"/>
							
							<ul className="category no-tab-index">{/* ----- CATEGORY */}
								{categories && categories.filter(res => res.parent=='').map((catData,index) => (
									<Category key={index} catData={catData} categories={categories} category_list={category_list}/>
								))}
							</ul>

							<div className="d-flex flex-wrap">
								{convertToArray(categoriesData)?.filter(data => data !== null)?.map((data,index) => (
									<span key={index} className="tag mb-1 mr-1 cursor-pointer" onClick={() => document.querySelector(`li[data-name='${data.name.toLowerCase()}']`).scrollIntoView()}>{data.name}</span>
								))}
							</div>
						</div>

					</div>
				</div>

			</div>

			{/* SUPPLIER */}
			{handleSupplierComponent && <SupplierComponent dispatch={dispatch} handleComponent={setHandleSupplierComponent} setSupplierData={setSupplierData} supplierData={supplierData} supplierSearchResult={supplierSearchResult} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList}/>}

			{/* PRICES COMPONENT */}
			{/* {handlePricesComponent && <PricesComponent dispatch={dispatch} handleComponent={setHandlePricesComponent} pricesData={pricesData} setPricesData={setPricesData} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList}/> } */}



		</MainLayout>
	)
}


/* -------------------------------
					 ADD SUPPLIER COMPONENT
----------------------------------*/
const SupplierComponent = ({dispatch, handleComponent, setSupplierData, supplierData, supplierSearchResult, countryList, stateList, cityList, pincodeList}) => {

	const [searchCustomer, setSearchCustomer]           	= useState('')
	const [searching, setSearching]             			= useState(false)
	const [supplierSeachOption, setSupplierSearchOption]  	= useState([])
	const [supplierId, setSupplierId]                       = useState()
	const [supplierInfo, setSupplierInfo]                   = useState()
	const [addressCountryId, setAddressCountryId]           = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               = useState([]);
	const [pincodeInArray, setPincodeInArray]               = useState([])
	const [isCheckAll, setIsCheckAll] 						= useState(false);
	
	// useEffect(async () => {onGetCity(addressStateId)}, [])
	useEffect(async () => {  onGetState(addressCountryId)	}, [])
	// useEffect(async () => {setPincodeInArray(pincodeList?.data)}, [pincodeList])

	// useEffect(async () =>{
	// 	if(!searchCustomer.length) return 
	// 	setSearching(true)
	// 	await dispatch(customerSearch(searchCustomer,'supplier')) 
	// 	await setSearching(false)
	// }, [searchCustomer] )

	useEffect(async () =>{
		if(!searchCustomer?.length) return 
		setSearching(true)
		let filterBody = {"role" : "supplier"};
		if(!isNaN(searchCustomer)) {
		  filterBody={
			...filterBody,
			searchKey : "mobile_number",
			searchValue: searchCustomer
		  }
		} else {
		  filterBody={
			...filterBody,
			"name" : searchCustomer
		  }
		}  
	
		await dispatch(customerSearch("q",filterBody))
		await setSearching(false)
	  }, [searchCustomer] )

	useEffect(() => {
		var formateCustomerOptions = supplierSearchResult?.map((option,index) => ({
		  value: option,
		  label: `${option.first_name} ${option.last_name} "${option.mobile_number}" -  #${option.user_number}`
		}))
		setSupplierSearchOption(formateCustomerOptions)
	},[supplierSearchResult])

	const onGetState = async (addressCountryId) => {
		// setAddressCountryId(addressCountryId)
		dispatch(getStateList(addressCountryId))
	}

	const onGetCity = (stateId) => {
		setAddressStateId(stateId)
		dispatch(getCityList(addressCountryId, stateId))
	}

	const onGetPincode = (cityId) => {
		dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
	}

	const onSelectPincode = (e) => {
		var pincode_array = [...pincodeInArray];
		if (e.target.checked) {
		  pincode_array = [...pincodeInArray, e.target.value];
		} else {
		  pincode_array.splice(pincodeInArray.indexOf(e.target.value), 1);
		}
		setPincodeInArray(pincode_array);    
	}

	const onMapSupplier = async (e) => {
		e.preventDefault()
		const formData = new FormData(e.target)    
		const data = {}
		for (let entry of formData.entries()) {
			data[entry[0]] = entry[1]
		}

		const bodyData = {
			country             : "India",
			state               : data.state,
			city                : data.city,
			pincode             : pincodeInArray,
			id            		: supplierId,
			supplierInfo		 
		}

		if(bodyData.pincode.length==0) {toast.error('Pincode is required'); return;}
		if(!bodyData.id) {toast.error('Supplier is required');return}
		if(supplierData.find(e => e.id == supplierId)) {toast.error('Already added');return}

		setSupplierData([...supplierData, bodyData])
		console.log(supplierData)
	}

	const onMapSupplierDelete = (sId) => {
		var filteredArray = supplierData.filter(function(e) { return e.id !== sId })
		setSupplierData(filteredArray); 
		return
	}

	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setPincodeInArray(pincodeList?.data?.map(li => li.pincode));
		if (isCheckAll) {
			setPincodeInArray([]);
		}
	};

	return (
        <>
			<div className="modal" aria-hidden="true" style={{ display: 'block' }}>
				<div className="overlayer"></div>
				<div className="modal-dialog modal-dialog-right modal-dialog-for-add-route-plan" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h6 className="modal-title d-flex align-items-center"><i className='bx bx-user font-size-22 mr-2'></i> Supplier's Details</h6>
						</div>
						<div className="modal-body">
							<form onSubmit={(e) => onMapSupplier(e) }>
							<div className='row'>
								<div className='col-md-12 mb-3'>
									<table className='table table-bordered'>
										<thead>
											<th>#</th>
											<th>Name</th>
											<th>Country</th>
											<th>State</th>
											<th>City</th>
											<th>Pincode</th>
											<th>action</th>
										</thead>
										<tbody>
											{ supplierData.length!=0 && supplierData?.map((supData, index) => (
												<tr key={index}>
													<td>{index+1}</td>
													<td>{supData?.supplierInfo?.first_name} {supData?.supplierInfo?.last_name}</td>
													<td>{supData.country}</td>
													<td>{supData.state}</td>
													<td>{supData.city}</td>
													<td>{
														supData.pincode.map((pin) =>(
															<span>{pin}, </span>
														))
													}</td>
													<td><button type='button' onClick={() => onMapSupplierDelete(supData.id)} className="btn btn-sm btn-white" title="Delete Supplier"><i className='bx bx-trash h4 m-0 text-danger'></i></button></td>
												</tr>
											))
											}

											{supplierData.length==0 && <tr><td className='text-center' colSpan={7}>data not available</td></tr>}
											
										</tbody>
									</table>
								</div>
								{/* <div className='col-md-4'>
									<div className="form-group">
										<select className="form-control" required={true} name="country" id="status" onChange={(e) => {onGetState(e.target.selectedOptions[0].getAttribute('name'))}}>
										<option value="" selected>Select Country</option>
										{countryList?.data?.map((country, i) => (
											<option key={i}  value={country.name} name={country.id}>{country.name}</option>
										))}
										</select>
									</div>
								</div> */}

								<div className='col-md-4'>
									<div className="form-group">
										<select className="form-control" required={true} name="state" id="status" onChange={(e) => { setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
										<option value="" selected>Select State</option>
										{stateList?.data?.map((state, i) => (
											<option key={i} value={state.name} name={state.id}>{state.name}</option>
										))}
										</select>
									</div>
								</div>

								<div className='col-md-4'>
									<div className="form-group">
										<select className="form-control" required={true} name="city" id="status" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
										<option value="" selected>Select City</option>
										{cityList?.data?.map((data, i) => (
											<option key={i} value={data.name} name={data.id}>{data.name}</option>
										))}
										</select>
									</div>
								</div>

								<div className='col-md-12'>
									<div className="form-group">
										<label>Pincodes </label>
										<div className='row'>
										<div className='col-md-12'>
											{pincodeList?.data?.length==0 && <div className='alert alert-warning'>Pincodes not available</div>}

											<label>
												<input
													type="checkbox"
													name="selectAll"
													id="selectAll"
													onChange={() => handleSelectAll()}
													isChecked={isCheckAll}
												/> Select All
											</label>
										</div>
										
										{
											pincodeList?.data?.length!=0 && pincodeList?.data?.map((pn, i) => (
											<>
												<div className='col-md-2'>
													<label>
														<input type='checkbox' 
															defaultValue={pn.pincode} 
															onChange={(e) => onSelectPincode(e)}
															checked={pincodeInArray.includes(pn.pincode)}
															// checked={console.log(pincodeInArray.includes(pn.pincode))}
														/> 
														{pn.pincode}    
													</label>
												</div>
											</>                 
											))
											
										}
										</div>                    
									</div>
								</div>

								<div className='col-md-12'>
									<div className="card pl-3 mb-2 pr-3 pt-2 pr-2  pb-3 border position-relative z-index-10">
										<div className="d-flex justify-content-between align-items-center mb-2 mt-1">
											<h3 className="font-size-14 text-muted font-weight-400 mb-1 mt-2"><i className='bx bx-user'></i> Supplier:</h3>											
										</div>
										<Select
											isMulti				= {false}  
											isLoading			= {searching}
											closeMenuOnSelect	= {true}
											onInputChange		= {e => {setSearchCustomer(e);}}
											onChange			= {e => {setSupplierId(e?.value?._id || e?.value?.id);setSupplierInfo(e?.value);}}
											autoFocus			= {true}
											options				= {supplierSeachOption}
											className			= "basic-multi-select react-select"
											placeholder			= "Search User..."
										/>
									</div>
									<button type="submit" className="btn ripple btn-primary float-right">Add Supplier</button>
								</div>
							</div>	
							</form>						
						</div>
						<div className="modal-footer">
							<div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>							
						</div>
					</div>
				</div>
			</div>
        </>
    )
}



/* -------------------------------
				 CLUBBED BUYING
----------------------------------*/
const ClubbedBuyingField = ({priceWindow, deleteClubbedBuyingField,focus, id, defaultData}) =>{
	const [price, setPrice]           =  useState()
	const [option, setOption]         = useState()
	const [trackRange, setTrackRange] = useState(defaultData?.range)
	const priceRef = useRef();

	useEffect(() => {
		setOption(clubbedBuyingRange.map(data => ({value:data, label:data})))
	},[])

	useEffect(() =>{
		priceWindow(prevValue => ({
			...prevValue,
			[id]: {
				"range": trackRange ? trackRange : '',
				"price": removeSpace(priceRef.current.value, true, true, true, true)
			}
		}))
	},[priceRef.current?.value, trackRange])

	return(	
		<div className="mt-4 mb-3 ml-0 mr-0 pt-3 pb-2 pl-3 pr-3 custom-legend">
			<div className="row">
				<div className="col-12 d-flex align-items-end">
					<div className="font-size-a14 text-secondary custom-legend-heading">Price Range</div>
					<button type='button' onClick={() => deleteClubbedBuyingField(id)} className="btn no-tab-index btn-outline-light custom-legend-heading ml-auto bx bxs-x-circle"></button>
				</div>

				{/* PRICE RANGE */}
				<div className="col-md-6 mb-2">
					<CreatableSelect
						autoFocus={focus}
						onFocus={centerInput}
						defaultValue = {{value:defaultData?.range, label: defaultData?.range}}
						options={option}
						onChange={e => setTrackRange(e.value)}
						className="react-select"
						required={true}
					/>
				</div>

				{/* PRICE */}
				<div className="col-md-6 mb-2">
					<div className="input_wrap w-100">
						<input onFocus={centerInput} defaultValue={defaultData?.price} ref={priceRef} type="text" onChange={e => setPrice(e.target.value)}  placeholder=" " required/>
						<label>Price</label>
					</div>
				</div>
			</div>
		</div>
	)
}




/* -------------------------------
				     VIDEO
----------------------------------*/
const VideoComponent = ({setVideoLinks, deleteVideo, videoLinks, id}) => {
	const [link, setLink] = useState()

	useEffect(() =>{
		setVideoLinks(prevLink => ({
			...prevLink,
			[id]: link
		})) 
	},[link])

	return(
		<div className="col-md-6 mt-3 animated fadeInDown">
			<div className="d-flex m-0 hover-box">
				<div className="input-group-prepend">
					<span className='input-group-text hover-showBefore'><i className='bx bx-link'></i></span>
					<span className='input-group-text cursor-pointer hover-showAfter' onClick={() => deleteVideo(id)}><i className='bx bxs-x-circle'></i></span>
				</div>
				<div className="input_wrap w-100">
					<input onFocus={centerInput} onChange={e => setLink(e.target.value)} type="text" placeholder=" "/>
					<label>Other Video ID</label>
				</div>
			</div>
		</div>
	)
}




/* -------------------------------
				     ATTRIBUTE
----------------------------------*/
const AttributeComponent = ({attributesList,deleteAttribute,attributeValue,id}) =>{
	const [options, setOptions]    = useState()
	const [attrName, setAttName]   = useState()
	const [attrValue, setAttValue] = useState()
	const attributeNameRef         = useRef()
	const [attributeValueManage, setAttributeValueManage] = useState()

	const handleAttributeValue = (e) => {
    setAttributeValueManage(e)
		setAttValue(e)
  };

	useEffect(() =>{
		attributeValue(prevValue => ({
			...prevValue,
			[id]: {
				"name": attrName,
				"value":attrValue
			}
		})) 
	},[attrName, attrValue])

	function handleAttribute(event){
		setAttributeValueManage(null)
		var index = event.target.selectedIndex;
    var optionElement = event.target.childNodes[index]
    var option =  optionElement.getAttribute('id');
		var optionData = attributesList && attributesList.filter(attr => attr.parent == option);
		if(optionData.length){
			setOptions([]);
			optionData.map((attr,index) => (
				setOptions(prev => ([
					... prev,
					{
						value:attr.name,
						label:attr.name
					}
				]))
			))
		}else{
			setOptions()
		}
	}


	return(
		<div className='col-md-12 row mt-2 animated fadeInDown'>
			<div className='col-md-1'>
				<button type='button' onClick={() => deleteAttribute(id)} className="btn btn-outline-light bx bxs-x-circle no-tab-index"></button>
			</div>
			<div className="col-md-5 mb-2">
				<div className='select-box'>
					<label>Product Attributes</label>
					<select onFocus={centerInput} ref={attributeNameRef} onChange={e => {setAttName(e.target.value); handleAttribute(e);}} name="attributes_name" className="form-control">
						<option value=''>Select..</option>
						{attributesList && attributesList.filter(attr => attr.parent == '').map((attr,index) => (
							<option key={index} id={attr.id} value={attr.name}>{attr.name}</option>
						))}
					</select>
				</div>
			</div>
			<div className="col-md-6 mb-2">
				<div className='select-box'>
					<Select 
						onFocus={centerInput}
						isMulti
						value={attributeValueManage}
						closeMenuOnSelect={false}
						onChange={(e) => {handleAttributeValue(e)}}
						options={options}
						className="basic-multi-select react-select"
					/>
					<label className='z-index-unset-imp'>Attribute Options</label>
				</div>
			</div>
		</div>
	)
}




/* -------------------------------
								CATEGORY
----------------------------------*/
const Category = (tableData) => {
  const { catData, categories, category_list} = tableData;
  return (
    <>
			<li data-name={catData.name.toLowerCase()} data-title={catData.name} className='title-top'>
				<label>
					<input value={catData.id} name={catData.name} slug={catData.slug} type="checkbox" onChange={(e) => category_list(e)} className="form-control" />{catData.name}
				</label>
			</li>
      <SubCategory key={catData.id+1} parent={catData.name} categories={categories} id={catData.id} ar={1} category_list={category_list}/> 
    </>
  )
}




/* -------------------------------
						SUB-CATEGORY
----------------------------------*/
const SubCategory = (tableSubData) => {
  const { categories, id , ar, category_list, parent} = tableSubData;
  var arrowRows = [];
  for (var i = 1; i <= ar; i++) {
      arrowRows.push(<i className="ion-md-remove font-size-13"> </i> );
  }
  return (
    <>
      {categories.filter(res=> res.parent===id).map((catData2, index) => (
          <ul key={catData2.id+index}>
						<li data-name={catData2.name.toLowerCase()} data-title={parent} className='title-top'>
							<label>
								<input value={catData2.id} name={catData2.name} slug={catData2.slug} type="checkbox" onChange={(e) => category_list(e)} className="form-control" />{catData2.name}
							</label>
						</li>
            <SubCategory key={catData2.id+1}  parent={`${parent} -> ${catData2.name}`} categories={categories} id={catData2.id} ar={ar+1} category_list={category_list}/> 
          </ul>
        ))
      }
    </>
  )
}


/* -------------------------------
						PRICES 
----------------------------------*/
const PricesComponent = ({id, dispatch, handleComponent, pricesData, setPricesData}) => {

	const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               	= useState([]);
	const [pincodeInArray, setPincodeInArray]               	= useState([])
	const [costPriceTrack, setCostPriceTrack]       			= useState()
	const [listingPriceTrack, setListingPriceTrack] 			= useState()
	const [stateList, setStateList]								= useState([]);
	const [cityList, setCityList]								= useState([]);
	const [pincodeList, setPincodeList]							= useState([]);
	const [variationPrice, setVariationPrice]                 	= useState(true)
	const [variationClubbedBuying, setVariationClubbedBuying] 	= useState(true)
	const [isCheckAll, setIsCheckAll] 							= useState(false);

	const is_clubbed_buying    = useRef()	
	const mrp                  = useRef()
	const listing_price        = useRef()
	const purchase_price       = useRef()

	useEffect(async () => {
		const stateListRes = await axios.get(`/shipping/state?country=${addressCountryId}&sort=ASC&statusall&start=1&limit=50`);
		setStateList(stateListRes.data.data)
	}, [])

	// useEffect(async () => {
	// 	const cityListRes = await axios.get(`/shipping/city?country=${addressCountryId}&state=${addressStateId}&sort=ASC&statusall&start=1&limit=50`);
	// 	setCityList(cityListRes.data.data)
	// }, [])

	//CLUBBED BUYING FUNCTIONALITY
	const [clubbedBuying, setClubbedBuying] 			= useState(true);// CLUBBED BUYING "yes" - "no"
	const [clubbedBuyingField, setClubbedBuyingField] 	= useState([]); //PRICE RANGE FIELD
	const [priceWindow, setPriceWindow] 				= useState([]);//STORE PRICE RANGE VALUE ON CHANGE
	
	var clubbedBuyingFieldTag = <ClubbedBuyingField focus={false} defaultData={null} deleteClubbedBuyingField={deleteClubbedBuyingField} key={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} id={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} priceWindow={setPriceWindow}/>
	
	
	function addclubbedBuyingField(defaultData){ // ADD PRICE RANGE FIELD
		if(!defaultData) setClubbedBuyingField(clubbedBuyingField => clubbedBuyingField.concat(<ClubbedBuyingField focus={true} defaultData={null} deleteClubbedBuyingField={deleteClubbedBuyingField} key={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} id={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} priceWindow={setPriceWindow}/>));
		else setClubbedBuyingField(clubbedBuyingField => clubbedBuyingField.concat(<ClubbedBuyingField focus={false} defaultData={defaultData} deleteClubbedBuyingField={deleteClubbedBuyingField} key={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} id={clubbedBuyingField[clubbedBuyingField?.length - 1]?.key ? clubbedBuyingField[clubbedBuyingField?.length - 1]?.key + 1: clubbedBuyingField.length} priceWindow={setPriceWindow}/>));
	}

	useEffect(() => setClubbedBuyingField([clubbedBuyingFieldTag]),[])


	function deleteClubbedBuyingField(id){
		setTimeout(() => {
			var clubbedBuyingFieldLength = null;
			setClubbedBuyingField(data => data?.filter(d => id !== d.props.id))
			setPriceWindow(data => {delete data[id]; return data})
			setClubbedBuyingField(data => { clubbedBuyingFieldLength = data.length; return data })
			if(clubbedBuyingFieldLength == 0) setClubbedBuyingField([clubbedBuyingFieldTag])
		},100)
	}

	function addclubbedBuyingFieldWithFormula(removeOldRange){
		if(!listingPriceTrack) return alert(`Buddy! 😇 first add "Listing price"`)
		if(!costPriceTrack) return alert(`Buddy! 😇 first add "Cost price"`)
		let onlineListingPrice = prompt('Enter Online Listing Price')
		if(!onlineListingPrice) return 
		if(onlineListingPrice != parseFloat(onlineListingPrice)) return alert('Enter valid "Online Listing Price')
		if(removeOldRange) {setClubbedBuyingField([]); setPriceWindow({})}
		
		setTimeout(() => {
			var rangeWithFormula = []
			var percentage = fl(listingPriceTrack) > 5000 ? 200 : 100
			function fl(data){return parseFloat(data)}  

			rangeWithFormula.push({ "range": "1-25", 	"price": fl(onlineListingPrice) < fl(listingPriceTrack) ? fl(fl(onlineListingPrice) - (fl(onlineListingPrice)/percentage)).toFixed(2):  fl(listingPriceTrack).toFixed(2)})

			rangeWithFormula.push({ "range": "26-50", 	"price": Number(fl(onlineListingPrice) - (fl(onlineListingPrice) - fl(costPriceTrack)) * 0.05).toFixed(2)})
			rangeWithFormula.push({ "range": "51-100", 	"price": Number(fl(onlineListingPrice) - (fl(onlineListingPrice) - fl(costPriceTrack)) * 0.08).toFixed(2)})
			rangeWithFormula.push({ "range": "101-200", "price": Number(fl(onlineListingPrice) - (fl(onlineListingPrice) - fl(costPriceTrack)) * 0.11).toFixed(2)})
			rangeWithFormula.push({ "range": "201-300", "price": Number(fl(onlineListingPrice) - (fl(onlineListingPrice) - fl(costPriceTrack)) * 0.15).toFixed(2)})
			rangeWithFormula.push({ "range": "301-400", "price": Number(fl(onlineListingPrice) - (fl(onlineListingPrice) - fl(costPriceTrack)) * 0.20).toFixed(2)})
			rangeWithFormula.push({ "range": "401-500", "price": Number(fl(onlineListingPrice) - (fl(onlineListingPrice) - fl(costPriceTrack)) * 0.30).toFixed(2)})
			rangeWithFormula.push({ "range": ">500", 	"price": Number(fl(onlineListingPrice) - (fl(onlineListingPrice) - fl(costPriceTrack)) * 0.50).toFixed(2) })

			rangeWithFormula.map(rData => addclubbedBuyingField(rData))
		}, 200)
	}

	// const onGetCity = (stateId) => {
	// 	// console.log("dfds")
	// 	dispatch(getCityList(addressCountryId, stateId))
	// }

	const onGetCity = async (stateId) => {
		// console.log("dfds")
		const cityListRes = await axios.get(`/shipping/city?country=${addressCountryId}&state=${stateId}&sort=ASC&statusall&start=1&limit=50`)
		setCityList(cityListRes.data.data)
	}

	const onGetPincode = async (cityId) => {
		// dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
		const pincodeListRes = await axios.get(`/shipping/pincode?country=${addressCountryId}&state=${addressStateId}&city=${cityId}&sort=ASC&status=all&start=1&limit=50`)
		setPincodeList(pincodeListRes.data.data)
	}

	const onSelectPincode = (e) => {
		var pincode_array = [...pincodeInArray];
		if (e.target.checked) {
		  pincode_array = [...pincodeInArray, e.target.value];
		} else {
		  pincode_array.splice(pincodeInArray.indexOf(e.target.value), 1);
		}
		setPincodeInArray(pincode_array);    
	}

	const findCommonPincode = (array1, array2) => {
		for(let i = 0; i < array1.length; i++) {
			for(let j = 0; j < array2.length; j++) {				 
				if(array1[i] === array2[j]) {				 
					return array1[i];
				}
			}
		}		 
		// Return if no common element exist
		return false;

	}

	const onMapPrices = (e) => {
		// var myDiv = document.getElementById('PriceDiv');
		// myDiv.innerHTML = variableLongText;
		// myDiv.scrollTop = 0;
		// document.getElementById('PriceDiv').scrollIntoView();


		e.preventDefault()
		const formData = new FormData(e.target)    
		const data = {}
		for (let entry of formData.entries()) {
			data[entry[0]] = entry[1]
		}


		const bodyData = {
			index 				: data.index,
			country             : "India",
			state               : data.state,
			city                : data.city,
			pincode             : pincodeInArray,
			price  : {
				mrp 			: mrp.current.value,
				listing_price 	: listing_price.current.value,
				purchase_price 	: purchase_price.current.value,
			},
			is_clubbed_buying  	: "yes", //is_clubbed_buying,
			clubbed_buying       : 
			{
				"price_window"     : id != 0 && variationClubbedBuying ? data[0].clubbed_buying.price_window : convertToArray(priceWindow),
				"price"            :
					{
						"min"          : id != 0 && variationClubbedBuying ? removeSpace(data[0].clubbed_buying.price.min, true, true, true, true) : removeSpace(convertToArray(priceWindow)[convertToArray(priceWindow).length - 1].price, true, true, true, true),
						"max"          : id != 0 && variationPrice ? removeSpace(data[0].price.listing_price, true, true, true, true) : removeSpace(listing_price.current.value, true, true, true, true)
					}										
			}
		}

		if(bodyData.pincode.length==0) {toast.error('Pincode is required'); return;}	
		if(pricesData.find(e => e.city == data.city)) {toast.error(`${data.city} Already added`);return}
		setPricesData([...pricesData, bodyData])
	}

	const onHandlePriceComponent = (h) => {		
		document.getElementById("mainHeader").classList.remove('product-zindex-minus');
		document.getElementById("horizontalMain").classList.remove('product-zindex-minus');
		document.getElementById("addProductRightSide").classList.remove('product-zindex-minus');
		document.getElementById("addProductVTabs").classList.remove('product-zindex-minus');
		handleComponent(h)
		return
	}

	const onMapPriceDelete = (index) => {
		var filteredArray = pricesData.filter(function(e) { return e.index !== index })
		setPricesData(filteredArray); 
		return
	}

	const handleSelectAll = (e) => {
		setIsCheckAll(!isCheckAll);
		setPincodeInArray(pincodeList?.map(li => li.pincode));
		if (isCheckAll) {
			setPincodeInArray([]);
		}
	};

	return (
        <>
			<div id='PriceDiv' className="modal" aria-hidden="true" style={{ display: 'block' }}>
				<div className="overlayer"></div>
				<div className="modal-dialog modal-dialog-right modal-dialog-for-add-route-plan" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h6 className="modal-title d-flex align-items-center">₹ - Prices</h6>
						</div>
						<form onSubmit={(e) => onMapPrices(e) }>
						<div className="modal-body">
							<table className='table table-bordered'>
								<thead>
									<tr>
										<th>#</th>
										<th>Prices</th>
										<th>Clubbed Buying</th>
										<th>State</th>
										<th>City</th>
										<th style={{width:"50%"}}>Pincode</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{
										pricesData.length!=0 && pricesData?.map((pdata, index) => (
											<tr key={index}>
												<td>{index+1}</td>
												<td>
													<p><strong>MRP - </strong> {pdata.price.mrp}</p>
													<p><strong>Listing Price - </strong> {pdata.price.listing_price}</p>
													<p><strong>Cost Price - </strong> {pdata.price.purchase_price}</p>
												</td>
												<td>
													{
														pdata.clubbed_buying.price_window.map((cbWindow, index) =>(
															<p key={index}>{index+1} - range : {cbWindow.range}, price : {cbWindow.price}</p>
														))
													}
												</td>
												<td> {pdata.state}</td>
												<td> {pdata.city}</td>
												<td>{
													pdata.pincode.map((pin, index) =>(
														<span key={index}>{pin}, </span>
													))
												}</td>
												<td><button type='button' onClick={() => onMapPriceDelete(pdata.index)} className="btn btn-sm btn-white" title="Delete Supplier"><i className='bx bx-trash h4 m-0 text-danger'></i></button></td>
											</tr>
										))
									}

									{pricesData.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
									
								</tbody>
							</table>
							<hr/>
							<div className="row mt-3">

								{/* <div className='col-md-4'>
									<div className="form-group">
										<select className="form-control" required={true} name="country" id="status" onChange={(e) => {onGetState(e.target.selectedOptions[0].getAttribute('name'))}}>
										<option value="" selected>Select Country</option>
										{countryList?.data?.map((country, i) => (
											<option key={i}  value={country.name} name={country.id}>{country.name}</option>
										))}
										</select>
									</div>
								</div> */}

								<div className='col-md-4'>
									<div className="form-group">
										<select className="form-control" required={true} name="state" id="status" onChange={(e) => {setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
										<option selected>Select State</option>
										{stateList && stateList?.map((state, i) => (
											<option key={i} value={state.name} name={state.id}>{state.name}</option>
										))}
										</select>
									</div>
								</div>
								  
								<div className='col-md-4'>
									<div className="form-group">
										<input type='hidden' name='index' defaultValue={(Math.random() * 1000).toFixed()} />
										<select className="form-control" required={true} name="city" id="status" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
										<option value="" selected>Select City</option>
										{cityList && cityList?.map((data, i) => (
											<option key={i} value={data.name} name={data.id}>{data.name}</option>
										))}
										</select>
									</div>
								</div>

								<div className='col-md-12'>
									<div className="form-group">
										<label>Pincodes</label>
										<div className='row'>
											<div className='col-md-12'>
												{pincodeList?.length==0 && <div className='alert alert-warning'>Pincodes not available</div>}
												<label>
													<input
														type="checkbox"
														name="selectAll"
														id="selectAll"
														onChange={() => handleSelectAll()}
														isChecked={isCheckAll}
													/> Select All
												</label>
											</div>
											{
												pincodeList?.length!=0 && pincodeList?.map((pn) => (
												<>
													<div className='col-md-2'>
														<label>
														<input 
															type='checkbox' 														
															defaultValue={pn.pincode} 
															onChange={(e) => onSelectPincode(e)}
															checked={pincodeInArray.includes(pn.pincode)}
														/> {pn.pincode}    
															
														</label>
													</div>
												</>                 
												))												
											}
										</div>                    
									</div>
								</div>




								<div className="col-md-4 d-flex">
									<div className="input-group-prepend">
										<span className="input-group-text">₹</span> 
									</div>
									<div className="input_wrap w-100">
										<input onFocus={centerInput} ref={mrp} onChange={e=>removeSpace(e.target.value, true,true,true,true)} type="text" placeholder=" " required/>
										<label>MRP</label>
									</div>
								</div>

								<div className="col-md-4 d-flex">
									<div className="input-group-prepend">
										<span className="input-group-text">₹</span> 
									</div>
									<div className="input_wrap w-100">
										<input onFocus={centerInput} ref={listing_price} onChange={e=> {setListingPriceTrack(e.target.value); removeSpace(e.target.value, true,true,true,true)}} type="text" placeholder=" " required/>
										<label>Listing Price</label>
									</div>
								</div>

								<div className="col-md-4 d-flex">
									<div className="input-group-prepend">
										<span className="input-group-text">₹</span> 
									</div>
									<div className="input_wrap w-100">
										<input onFocus={centerInput} ref={purchase_price} onChange={e=> {setCostPriceTrack(e.target.value); removeSpace(e.target.value, true,true,true,true)}} type="text" placeholder=" " required/>
										<label>Cost price</label>
									</div>
								</div>

								{/* CLUBBED BUYING */}
								<div className="col-md-8">
									<div id={`clubbed_buying`}>
										<div className="position-relative z-index-9 pt-5">
											{/* HEADING */}
											<div className="align-items-center d-flex justify-content-between heading">
													<div className="form-check d-flex align-items-center">
														<input ref={is_clubbed_buying} onChange={e => setClubbedBuying(e.target.checked ? true: false)} defaultChecked={true} className="form-check-input mt-0 no-tab-index" type="checkbox" name="clubbed_buying_status" id="clubbed_buying_status"/>
														<label className="form-check-label cursor-pointer" htmlFor="clubbed_buying_status">Clubbed Buying</label>
														<div className="dropdown ml-3 z-index-150 position-relative">
															<button type="button" className="btn btn-outline-primary dropdown-toggle font-size-12 p-0 px-2 py-1" data-toggle="dropdown">
																Add Range
															</button>
															<div className="dropdown-menu">
																<div className="dropdown-item" onClick={() => addclubbedBuyingField()}>Add Range</div>
																<div className="dropdown-item" onClick={() => addclubbedBuyingFieldWithFormula()}>Add Range with Formula</div>
																{/* <div className="dropdown-item" onClick={() => addclubbedBuyingFieldWithFormula(true)}>Remove Old &amp; Add Range with Formula</div> */}
															</div>
														</div>
													</div>
													{/* {id != 0 &&
														<label className="custom-switch cursor-pointer">
															<small className="mr-2 text-secondary">Same As Main</small>
															<input onFocus={centerInput} onChange={() => setVariationClubbedBuying(!variationClubbedBuying)} type="checkbox" defaultChecked={variationClubbedBuying} className="custom-switch-input"/>
															<span className="custom-switch-indicator"></span> 
														</label>
													} */}
											</div>

											<div className={!clubbedBuying ? 'clubbed_buying_disabled' : ''}>
												{/* PRICE RANGE */}
												<div	className='position-relative z-index-100'>
													{clubbedBuyingField}
												</div>
											</div>
										</div>
									</div>	
								</div>


							</div>						
											
						</div>
						<div className="modal-footer text-center">
							<button type="submit" className="btn ripple btn-primary">Add Price</button>
							<div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => onHandlePriceComponent(false)}>Close</div>							
						</div>
						</form>
					</div>
				</div>
			</div>
        </>
    )

}


/* -------------------------------
					PRODUCT VARIATION
----------------------------------*/
const ProductVariation = ({attributesList, id, variationManagment, dispatch, setStopClick}) => {

	const [featuredImage, setFeaturedImage]         			= useState([])
	const [otherImages, setOtherImages]             			= useState([])
	const [imageUrl, setImageUrl]                   			= useState([])
	const [tab, setTab]                             			= useState('Description')
	const [pricesData, setPricesData] 							= useState([]);
	const [handlePricesComponent, setHandlePricesComponent] 	= useState(false);
	const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               	= useState([]);
	// const [pincodeInArray, setPincodeInArray]               	= useState([])

	// useEffect(async () => {onGetCity(addressStateId)}, [])

	const onGetCity = (stateId) => {
		// console.log("dfds")
		dispatch(getCityList(addressCountryId, stateId))
	}
	

	// ATTRIBUTE FUNCTIONALITY
	const [attributeValue, setAttributeValue] = useState([]) //STORE ON CHANGE Attribute Value
	const [attribute, setAttribute] = useState([]) // ADD MORE BUTTON FUNCTIONALITY
	const attrubteTag = <AttributeComponent deleteAttribute={deleteAttribute} key={attribute[attribute?.length - 1]?.key ? attribute[attribute?.length - 1]?.key + 1: attribute.length} id={attribute[attribute?.length - 1]?.key ? attribute[attribute?.length - 1]?.key + 1: attribute.length} attributesList={attributesList} attributeValue={setAttributeValue}/>
	const addAttributes = (e) =>{ // ADD ATTRIBUTE ON CLICK "ADD MORE"
		setAttribute(attribute => attribute.concat(attrubteTag))
	}

	function deleteAttribute(id){
		setTimeout(() => {
			var attributeLength;
			var attrRangeRemove;
			setAttributeValue(attr => {attrRangeRemove = attr;  delete attrRangeRemove[id]; return attrRangeRemove})
			setAttribute(data => data?.filter((d,i) => id !== d.props.id))
			setAttribute(data => { attributeLength = data.length; return data })
			if(attributeLength == 0) setAttribute([attrubteTag])
		},100)
	}


	//VIDEO FUNCTIONALITY
	const [videoLinks, setVideoLinks] = useState([]) //STORE ON CHANGE Attribute Value
	const [videoList, setVideoList] = useState([]) // ADD MORE BUTTON FUNCTIONALITY
	const videoTag = <VideoComponent deleteVideo={deleteVideo} key={videoList[videoList?.length - 1]?.key ? videoList[videoList?.length - 1]?.key + 1: videoList.length} id={videoList[videoList?.length - 1]?.key ? videoList[videoList?.length - 1]?.key + 1: videoList.length} videoLinks={videoLinks} setVideoLinks={setVideoLinks}/>
	const addVideo = (e) =>{ // ADD ATTRIBUTE ON CLICK "ADD MORE"
		if(videoList.length > 2) return true //alert('Buddy! 😡 what are you doing???????????')
		setVideoList(videos => videos.concat(videoTag))
	}
	
	useEffect(() => addVideo(), [])
	function deleteVideo(id){
		setTimeout(() => {
			var videoLength;
			var videoRemove;
			setVideoLinks(attr => {videoRemove = attr;  delete videoRemove[id]; return videoRemove})
			setVideoList(data => data?.filter((d,i) => id !== d.props.id))
			setVideoList(data => { videoLength = data.length; return data })
			if(videoLength == 0) addVideo()
		},100)
	}
	
	const onHandlePriceComponent = (h) => {		
		document.getElementById("mainHeader").classList.add('product-zindex-minus');
		document.getElementById("horizontalMain").classList.add('product-zindex-minus');
		document.getElementById("addProductRightSide").classList.add('product-zindex-minus');
		document.getElementById("addProductVTabs").classList.add('product-zindex-minus');
		setHandlePricesComponent(h)
		return
	}



	// FUNCTION ==> UPLODED IMAGE TO SERVER TO GET URL
	function uploadImage(data, featured, name){
		toast.loading(<>Wait! Image Uploading ...</>, { toastId:'uploading' })
		
		data.map((iData, index) => {
			var imgInfo = new FormData();
			imgInfo.append('file', iData.file);
			imgInfo.append('foldername', 'p');

			if(iData.fileSize > 1000000){ //CHECK SIZE
				setStopClick(false)	
				return toast.error(<><b>{id == 0 ? 'Main Variation' : `Variation ${id}`}:</b> 😢 Image size greater than 1MP </>)
			}
			
		axios.post("/file/upload", imgInfo) //UPLOAD TO SERVER 
			.then((response) => {
				setImageUrl(prevValue => ({
					...prevValue,
					[name + index]: {
						"link":response.data,
						"featured": featured
					}
				}))
			}).catch(err =>{	//CATCH ERROR
				setStopClick(false)	
				if(typeof err?.response?.data != 'string'){
					handleErrors(err, dispatch)
				}else{
					toast.error(<><b>{id == 0 ? 'Main Variation' : `Variation ${id}`}:</b> {featured == 'yes' ? 'Featured' : 'Other'} Image Error</>)
				}
			})
		})
		
		setTimeout(() => toast.dismiss('uploading'),1000)
	}

	const name                 = useRef()
	const sku_id               = useRef()
	const unit_name            = useRef()
	const unit_value           = useRef()
	const weight           	   = useRef()
	const stock_status         = useRef()
	const total_stock_quantity = useRef()	
	const tax_percentage       = useRef()
	const tax_type             = useRef()
	const order_size_min       = useRef()
	const order_size_max       = useRef()
	const description          = useRef()
	const features             = useRef()
	const descriptionSunEditor = sunEditor => description.current = sunEditor
	const featuresSunEditor    = sunEditor => features.current = sunEditor

	
	/* ==============================
		  ADD VARIATION "SUBMIT 😊" 
	=================================*/
	function addVariation(e){
		e.preventDefault()
		setImageUrl([])

		//UPLOAD IMAGE
		uploadImage(featuredImage,'yes','feature')
		uploadImage(otherImages,'no','other')

		//VIDEO LINK
		var videoLinksKeys = Object.keys(videoLinks)
		var finalVideoLink = Object.values(videoLinks).map((link, index) => {
			if(!link || link == null) return
			var is_hero_video = 'no' 
			if(videoLinksKeys[index] == 'is_hero_video') is_hero_video = 'yes'
			return  {
				"link": link,
				"is_hero_video": is_hero_video
			}
		})?.filter(video => video != null)

		//ATTRIBUTES VALUE CHANGE FORMATE
		var attributeValueData = convertToArray(attributeValue)
		attributeValueData     = attributeValueData.filter(attr => attr?.name?.length && attr?.value?.length)
		const attrFinalData    = []
		attributeValueData?.map((data,i) => {
			var attrValue = ''
			data.value?.map((vdata,index) => {
				attrValue = attrValue+(index > 0 ? ",":"")+vdata.value			
			})
			const attrValueFormate={
				"name" : data.name,
				"value" : attrValue
			}
			attrFinalData.push(attrValueFormate)				
		})


		var imageLength = featuredImage.length + otherImages.length;
		var imageCheck = 0;
		var finalImageData = [];

		// if(!variationClubbedBuying && is_clubbed_buying.current.checked  && convertToArray(priceWindow).filter(field => !field.range || !field.price).length){
		// 	setStopClick(false)
		// 	return toast.error('Enter Valid "Clubbed Buying Range"')
		// }

		var submitVariationFinal = setInterval(() => {
			if( imageCheck == imageLength){
				variationManagment(data => {
					if(id != 0 && data[0] || id == 0){
						clearInterval(submitVariationFinal)
						return {
							... data,
							[id]:{
								"name"                 : id != 0 && variationName ? data[0].name : name.current.value,
								"sku_id"               : sku_id.current.value,
								"weight"               : weight.current.value,
								"unit"                 :
									{
										"name"             : unit_name.current.value,
										"value"            : unit_value.current.value
									},
								"stock_status"         : id != 0 && variationOtherDetails ? data[0].stock_status : stock_status.current.value,
								"total_stock_quantity" : id != 0 && variationOtherDetails ? data[0].total_stock_quantity : total_stock_quantity.current.value,								
								"tax"                  :
									{
										"percentage"       : id != 0 && variationTax ? data[0].tax.percentage : tax_percentage.current.value,
										"type"             : id != 0 && variationTax ? data[0].tax.type       : tax_type.current.value
									},
								"order_size"           :
									{
										"min"              : id != 0 && variationPrice ? data[0].order_size.min : order_size_min.current.value,
										"max"              : id != 0 && variationPrice ? data[0].order_size.max : order_size_max.current.value
									},
								"content"              : 
									{
										"description"      : id != 0 && variationContent ? data[0].content.description : description.current.getContents(),
										"features"         : id != 0 && variationContent ? data[0].content.features : features.current.getContents()
									},
								"attributes"           : id != 0 && variationAttributes ? data[0].attributes : attrFinalData,
								"images"               : id != 0 && variationImages ? data[0].images : convertToArray(finalImageData),								
								"videos"               : id != 0 && variationVideos ? data[0].videos : finalVideoLink,
								"prices"			   : pricesData									
							}
						}
					}
					return data
				})	
			}
		}, 500)

		var checkImageUploadedOrNot = 
			setInterval(() => {
				setImageUrl(imageUrl => {

					if(convertToArray(imageUrl).length  == imageLength){
						clearInterval(checkImageUploadedOrNot)
						imageCheck = imageLength
						finalImageData = imageUrl
						return imageCheck, finalImageData
					}

					return imageUrl
				})
			},500);
	}

	useEffect(() => {
		if(attributesList) setAttribute([attrubteTag])
		document.getElementById('refresh_spy_scroll').click()
	},[attributesList])

	// FUNCTIONAITY ==> SAME AS MAIN "In Variation"
	const [variationName, setVariationName]                   = useState(true)
	const [variationPrice, setVariationPrice]                 = useState(true)
	const [variationTax, setVariationTax]                     = useState(true)
	const [variationAttributes, setVariationAttributes]       = useState(true)
	const [variationOtherDetails, setVariationOtherDetails]   = useState(true)
	const [variationImages, setVariationImages]               = useState(true)
	const [variationVideos, setVariationVideos]               = useState(true)
	const [variationContent, setVariationContent]             = useState(true)	

	function sidebarNav(e){
		e.preventDefault();
		e.stopPropagation(); 
		document.querySelector(e.target.getAttribute('href')).scrollIntoView()
	}

	const onMapPriceDelete = (index) => {
		var filteredArray = pricesData.filter(function(e) { return e.index !== index })
		setPricesData(filteredArray); 
		return
	}

	return(
		<>
		<form className="d-flex variation_content" data-main={id == 0 && true} onSubmit={e => addVariation(e)}>
			<button type='submit' disabled tabIndex={-1} className="add_main_product_btn d-none">Add&nbsp;Product</button>
			<div id="refresh_spy_scroll"></div>

			<div id={`product_details`+id} className="list-group p_details shadow-sm productDetails_sidebar">
				<nav className="nav nav-pills flex-column">
					<a className="nav-link" onClick={e => sidebarNav(e)} href={`#details_tab`+id}>Details</a>
					<nav className="nav nav-pills flex-column">
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#detail_price`+id}>Price</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#other_details`+id}>Other Details</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#detail_image`+id}>Images &amp; Videos</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#product_description`+id}>Description</a>
						<a className="nav-link ml-3 my-1" onClick={e => sidebarNav(e)} href={`#detail_attribute`+id}>Attribute</a>
					</nav>
					{/* <a className="nav-link mt-1" onClick={e => sidebarNav(e)} href={`#clubbed_buying`+id}>Clubbed Buying</a> */}
				</nav>
			</div>
			
			<div data-spy="scroll" data-target={`#product_details`+id} data-offset="0" className="scrollspy-product position-relative pl-4 pr-4 pb-4">
				<div id={`details_tab`+id}>

					<div className="pt-4 d-flex">
						<div className="input_wrap w-100">
							<input autoFocus={id == 0 ? true : false} onFocus={centerInput} ref={name} data-disable={id != 0 ? variationName : false} type="text" placeholder=" "/>
							<label>Product Name</label>
						</div>
						
						{id != 0 &&
							<label className="custom-switch cursor-pointer">
								<small className="mr-2 text-secondary">&nbsp;&nbsp;Same&nbsp;As&nbsp;Main</small>
								<input onFocus={centerInput} onChange={() => setVariationName(!variationName)} type="checkbox" defaultChecked={variationName} className="custom-switch-input"/>
								<span className="custom-switch-indicator"></span> 
							</label>
						}
					</div>

					{/* PRICE */}					
					<div id={`detail_price`+id}>
						<div className="form-group mb-0 heading pt-3">
							<div className="d-flex justify-content-between"> 
								<span className="mr-2 mb-2 heading-simple">Price <button type='button' className='ml-1 btn btn-warning btn-xs float-right' onClick={() => onHandlePriceComponent(true)}><small>List &amp; Add</small></button></span>
								{id != 0 &&
									<label className="custom-switch cursor-pointer">
										<small className="mr-2 text-secondary">Same As Main</small>
										<input onFocus={centerInput} onChange={() => setVariationPrice(!variationPrice)} type="checkbox" defaultChecked={variationPrice} className="custom-switch-input"/>
										<span className="custom-switch-indicator"></span> 
									</label>
								}
							</div>
						</div>
						<table className='table table-bordered addproduct-price-table'>
							<thead>
								<tr>
									<th>#</th>
									<th>Prices</th>
									<th>Clubbed Buying</th>
									<th>State</th>
									<th>City</th>
									<th>Pincode</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{
									pricesData.length!=0 && pricesData?.map((pdata, index) => (
										<tr key={index}>
											<td>{index+1}</td>
											<td>
												<p><strong>MRP - </strong> {pdata.price.mrp}</p>
												<p><strong>Listing Price - </strong> {pdata.price.listing_price}</p>
												<p><strong>Cost Price - </strong> {pdata.price.purchase_price}</p>
											</td>
											<td>
												{
													pdata.clubbed_buying.price_window.map((cbWindow, index) =>(
														<p key={index}>{index+1} - range : {cbWindow.range}, price : {cbWindow.price}</p>
													))
												}
											</td>
											<td> {pdata.state}</td>
											<td> {pdata.city}</td>
											<td style={{ width: '20%' }}>{
												pdata.pincode.map((pin, index) =>(
													<span key={index}>{pin}, </span>
												))
											}</td> 
											<td><button type='button' onClick={() => onMapPriceDelete(pdata.index)} className="btn btn-sm btn-white" title="Delete Supplier"><i className='bx bx-trash h4 m-0 text-danger'></i></button></td>
										</tr>
									))
								}

								{pricesData.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}		
								
							</tbody>
						</table>
					</div>

					{/* TAX DETAILS */}
					<div id={`detail_tax`+id}>
						<div className="form-group mb-0 heading pt-5">
							<div className="d-flex justify-content-between"> 
								<span className="mr-2 mb-2 heading-simple">Tax Details</span>
								{id != 0 &&
									<label className="custom-switch cursor-pointer">
										<small className="mr-2 text-secondary">Same As Main</small>
										<input onFocus={centerInput} onChange={() => setVariationTax(!variationTax)} type="checkbox" defaultChecked={variationTax} className="custom-switch-input"/>
										<span className="custom-switch-indicator"></span> 
									</label>
								}
							</div>
						</div>

						<div className="row" data-disable={id != 0 ? variationTax : false}>
							<div className="col-md-6 d-flex flex-column mt-1">
								<div className='select-box'>
									<label>Tax Type</label>
									<select onFocus={centerInput} ref={tax_type} className='form-control'>
										{taxType.map((tt) => (
											<option value={tt}>{tt}</option>
										))}
									</select>
								</div>
							</div>

							<div className="col-md-6 d-flex flex-column text-right mt-1">
								<div className='select-box'>
									<label>Tax Percentage</label>
									<select onFocus={centerInput} ref={tax_percentage} className='form-control'>
										<option value="0">0%</option>
										<option value="5">5%</option>
										<option value="12">12%</option>
										<option value="18">18%</option>
										<option value="28">28%</option>
								</select>
								</div>
							</div>
						</div>
					</div>

					{/* OTHER DETAILS */}
					<div id={`other_details`+id}>
						<div className="form-group mb-0 heading pt-5" id={`other_details`+id}>
							<div className="d-flex justify-content-between"> 
								<span className="mr-2 mb-2 heading-simple">Other Details</span>
								{id != 0 &&
									<label className="custom-switch cursor-pointer">
										<small className="mr-2 text-secondary">Same As Main</small>
										<input onFocus={centerInput} onChange={() => setVariationOtherDetails(!variationOtherDetails)} type="checkbox" defaultChecked={variationOtherDetails} className="custom-switch-input"/>
										<span className="custom-switch-indicator"></span> 
									</label>
								}
							</div>
						</div>

						<div className="row mt-1">

							<div className="row m-0 p-0" data-disable={id != 0 ? variationOtherDetails : false}>
								<div className='m-0 col-md-6'>
									<div className="input_wrap w-100">
										<input onFocus={centerInput} ref={order_size_min} type="number" defaultValue={1} placeholder=" "/>
										<label>Minimum - Order Limit</label>
									</div>
								</div>
								
								<div className='m-0 col-md-6'>
									<div className="input_wrap w-100">
										<input onFocus={centerInput} ref={order_size_max} defaultValue={100} type="number" placeholder=" "/>
										<label>Maximum - Order Limit</label>
									</div>
								</div>

								<div className='m-0 col-md-6 mt-3'>
									<div className="input_wrap w-100">
										<input onFocus={centerInput} ref={total_stock_quantity} defaultValue={1000} type="number" placeholder=" "/>
										<label>Available Stock - Quantity</label>
									</div>
								</div>

								<div className="col-md-6 m-0 mt-3">
									<div className='select-box'>
										<label>Stock Status</label>
										<select onFocus={centerInput} ref={stock_status} className="form-control text-capitalize">
											{stockStatus.map((option,i) => <option key={i} value={option}>{option}</option>)}
										</select>
									</div>
								</div>
							</div>

							<div className='m-0 mt-3 col-md-6'>
								<div className="input_wrap w-100">
									<input onFocus={centerInput} ref={sku_id} type="text" placeholder=" " className='text-uppercase'/>
									<label>SKU</label>
								</div>
							</div>

							<div className="d-flex m-0 mt-3 col-md-6">
								<div className="input_wrap w-100">
									<input onFocus={centerInput} ref={unit_value} type="text" placeholder=" "/>
									<label>Unit</label>
								</div>
								<div className="input-group-append">
									<select onFocus={centerInput} ref={unit_name} className="input-group-text form-control h-100 min-width-100">
										{unitType.map((option,i) => <option key={i} value={option}>{option}</option>)}
									</select>
								</div>
							</div>

							<div className='m-0 mt-3 col-md-6'>
								<div className="input_wrap w-100">
									<input onFocus={centerInput} ref={weight} type="number" placeholder=" " className='text-uppercase'/>
									<label>Weight</label>
								</div>
							</div>

						</div>
					</div>

					{/* PRODUCT IMAGE & VIDEOS */}
					<div id={`detail_image`+id}>
						{/* IMAGES */}
						<div className="form-group mb-0 heading pt-5">
							<div className="d-flex justify-content-between"> 
								<span className="mr-3 heading-simple center">Images <span className='title-top ml-1' data-title='Only [png, jpg, jpeg] Image Allowed'><i className='bx bx-info-circle'></i></span></span>
								{id	 != 0 &&
									<label className="custom-switch cursor-pointer">
										<small className="mr-2 text-secondary">Same As Main</small>
										<input onFocus={centerInput} onChange={() => setVariationImages(!variationImages)} type="checkbox" defaultChecked={variationImages} className="custom-switch-input"/>
										<span className="custom-switch-indicator"></span> 
									</label>
								}
							</div>
						</div>
						
						<div className="row" data-disable={id != 0 ? variationImages : false}>
							<div className="col-md-6 no-tab-index">
								<div className="text-center font-size-16 text-black-50 "><small>Featured Image</small></div>
								<FilePond
									files={featuredImage}
									onupdatefiles={(e) => setFeaturedImage(e)}
									maxFiles={1}
									maxFileSize={'1MB'}
									acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
									labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
								/>
							</div>
							<div className="col-md-6 other-images no-tab-index">
									<div className="text-center font-size-16 text-black-50"><small>Other Images</small></div>
									<FilePond
										files={otherImages}
										allowReorder={true}
										onupdatefiles={(e) => setOtherImages(e)}
										allowMultiple={true}
										maxFiles={8}
										maxFileSize={'1MB'}
										acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
										labelIdle='Drag & Drop Image or <span class="filepond--label-action">Browse</span>'
									/>
							</div>
						</div>
						
						{/* VIDEOS */}
						{/* <div className="form-group mb-0 heading pt-5">
							<div className="d-flex justify-content-between"> 
							<span className="mr-3 heading-simple center">
								Videos 
								<span className='title-top ml-1' data-title='Add YouTube Video ID'><i className='bx bx-info-circle'></i></span>
								<div onClick={addVideo} data-disable={id != 0 ? variationVideos : false} className="btn btn-outline-primary no-tab-index ml-2 font-size-12 p-0 px-2 py-1">
									<i className='bx bx-plus'></i>Add More
								</div>
							</span>
								{id	 != 0 &&
									<label className="custom-switch cursor-pointer">
										<small className="mr-2 text-secondary">Same As Main</small>
										<input onFocus={centerInput} onChange={() => setVariationVideos(!variationVideos)} type="checkbox" defaultChecked={variationVideos} className="custom-switch-input"/>
										<span className="custom-switch-indicator"></span> 
									</label>
								}
							</div>
						</div>

						<div className="row" data-disable={id != 0 ? variationVideos : false}>
							<div className="col-md-6 mt-3">
								<div className="d-flex m-0">
									<div className="input-group-prepend">
										<span className='input-group-text'><i className='bx bx-link'></i></span>
									</div>
									<div className="input_wrap w-100">
										<input onFocus={centerInput} type="text" onChange={e => setVideoLinks(prevLink => ({ ...prevLink, ['is_hero_video']: e.target.value }))} placeholder=" "/>
										<label>Featured Video ID</label>
									</div>
								</div>
							</div>
							{videoList}
						</div> */}

					</div>

					{/*TAB - DESCRIPTION & FEATURES */}						
					<div id={`product_description`+id}>
						<div className="panel panel-primary p-0 product_tab pt-5" id={`product_description`+id}>
							<div className=" tab-menu-heading  tab-border border-bottom">
								<div className="tabs-menu1 d-flex justify-content-between align-items-center">
									<ul className="nav panel-tabs main-nav-line" data-disable={id != 0 ? variationContent : false}>
										<li><a className={`nav-link cursor-pointer pl-2 pr-2 ${tab == "Description" ? 'active text-primary' : ''}`} onClick={() => setTab('Description')}>Description</a></li>
										<li><a className={`nav-link cursor-pointer pl-2 pr-2 ${tab == "Features" ? 'active text-primary' : ''}`} onClick={() => setTab('Features')}>Features</a></li>
									</ul>
									{id	 != 0 &&
										<label className="custom-switch cursor-pointer">
											<small className="mr-2 text-secondary">Same As Main</small>
											<input onFocus={centerInput} onChange={() => setVariationContent(!variationContent)} type="checkbox" defaultChecked={variationContent} className="custom-switch-input"/>
											<span className="custom-switch-indicator"></span> 
										</label>
									}	
								</div>
							</div>

							<div data-disable={id != 0 ? variationContent : false} className="panel-body p-0 pt-3 border-0 mt-0 tabs-menu-body main-content-body-right border">
								<div className={`no-tab-index ${tab != "Description" ? "d-none" : ""}`}>
									<SunEditor 
										placeholder="Product description..." 
										setOptions={{ height: 210, buttonList: sunEditorSetting }}   
										getSunEditorInstance={descriptionSunEditor}
										lang="en"
									/>
								</div>
								<div className={`no-tab-index ${tab != "Features" ? "d-none" : ""}`}>
									<SunEditor 
										placeholder="Product features..." 
										setOptions={{ height: 210, buttonList: sunEditorSetting }}   
										getSunEditorInstance={featuresSunEditor}
										lang="en"
									/>
								</div>
							</div>
						</div>
					</div>

					{/* ATTRIBUTES */}	
					<div id={`detail_attribute`+id}>
						<div className="form-group mb-0 heading pt-5 mb-2 pb-1">
							<div className="d-flex justify-content-between"> 
								<div className="attribute_heading">
									<span className="mr-3 heading-simple">Attributes</span>
									<div data-disable={id != 0 ? variationAttributes : false} onClick={() => addAttributes()} className="btn btn-outline-primary no-tab-index font-size-12 p-0 px-2 py-1"><i className='bx bx-plus'></i>Add More</div>
								</div>
								{id	 != 0 &&
									<label className="custom-switch cursor-pointer">
										<small className="mr-2 text-secondary">Same As Main</small>
										<input onFocus={centerInput} onChange={() => setVariationAttributes(!variationAttributes)} type="checkbox" defaultChecked={variationAttributes} className="custom-switch-input"/>
										<span className="custom-switch-indicator"></span> 
									</label>
								}
							</div>
						</div>

						<div className="position-relative z-index-100" data-disable={id != 0 ? variationAttributes : false}>
							<div className="row">
								{attribute.length ? attribute : <span className='text-secondary text-center w-100'>Loading Attribute...</span>}
							</div>
						</div>
					</div>
				</div>
							
			</div> 			
		</form>

		{/* PRICES COMPONENT countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList}*/}
		{handlePricesComponent && <PricesComponent id={id} dispatch={dispatch} handleComponent={setHandlePricesComponent} pricesData={pricesData} setPricesData={setPricesData}/> }
		
		</>
	)
}



function mapStateToProps(state){
	const { categories }     									= state.categories
	const { attributesList } 									= state.attributes
	const { productAdd }     									= state.products.products
	let customerSearchResult        							= state.users.users.customerSearch
	const { countryList, stateList, cityList, pincodeList, } 	= state.shipping
	
	const userId             									= state?.auth?.currentUser?.id;
	let capability          									= state?.auth?.currentUser?.capability
 	capability              									= capability ? JSON.parse(capability) : null
	return {
			categories,
			attributesList,
			productAdd,
			userId,
			capability,
			countryList,
			pincodeList,
			stateList,
			cityList,
			supplierSearchResult : customerSearchResult
	};
}


export default connect(mapStateToProps)(AddProduct);