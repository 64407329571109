import './App.css'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { history } from './helpers/history'
import { AuthRoute, ProtectedRoute } from "./routes/route"
import { modulesList } from './helpers/user.helper'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { clearCache } from './helpers/common-function.helper'
import { updateUser } from './actions/users.action'


//AUTHORIZATION
import Login from './components/auth/login.component'
import ForgotPassword from './components/auth/forgot-password.component'

//DASHBOARD
import Dashboard from './components/dashboard/index'

//CATEGORY
import CategoryList from './components/categories/category-list.component'

//MESSAGE TEMPLATES
import EmailList from './components/message-templates/email/email-list.component'
import addEmail from './components/message-templates/email/add-email.component'
import viewEmail from './components/message-templates/email/view-email.component'
import editEmail from './components/message-templates/email/edit-email.component'

import smsList from './components/message-templates/sms/sms-list.component'
import addSms from './components/message-templates/sms/add-sms.component'
import editSms from './components/message-templates/sms/edit-sms.component'
import ViewSMS from './components/message-templates/sms/view-sms-component'

import AddNotification from './components/message-templates/firebase-notification/add-firebase-notification.component'
import EditNotification from './components/message-templates/firebase-notification/edit-firebase-notification.component'
import NotificationList from './components/message-templates/firebase-notification/firebase-notification-list.component'
import ViewFirebaseNotification from './components/message-templates/firebase-notification/view-firebase-notification.component'

import SendNotification from './components/message-templates/send-notification/send-notification.component'
import WhatsappList from './components/message-templates/whatsapp/whatsapp-list.component'

//USER
import CustomerList from './components/users/user-list.component'
import RolesCapabilities from './components/users/roles-capabilities/roles-capabilities.component'
import ImportUsers from './components/users/import-user.component'

//ERRORS
import errors from './components/errors/errors.component'

//ACTIONS
import { clearMessage } from "./actions/message.action"
import { currentUser } from "./actions/auth.action"

//Products
import ProductList from './components/products/product-list.component'
import ViewProduct from './components/products/view-product.component'
import EditProduct from './components/products/edit-product.component'
import AddProduct from './components/products/add-product.component'
import ImportProuduct from './components/products/import-product.component'
// import UploadImge from './components/products/upload-image.component'

// ATTRIBUTES
import AttributesList from './components/products/attributes/attributes-list.component'

//ORDER
import OrderList from './components/orders/order-list.component'
import InvoiceGenerator from './components/orders/invoice-generator.component'
import RoutePlanList from './components/orders/route-plan/route-plan-list.component'

//SHIPPING
import CountryList from './components/setting/shipping/country-list.component'
import StateList from './components/setting/shipping/state-list.component'
import ShippingIndex from './components/setting/shipping'
// SHIPPING ROUTE
import RouteList from './components/setting/route/route-list.component'


function App({ dispatch, capability, isUserLogged }) {
  history.listen((location) => dispatch(clearMessage()))
  const [isUser, setIsUser] = useState()

  useEffect(() => {
    if (Cookies.get('htb:sess')) {
      dispatch(currentUser())
      setIsUser(isUserLogged)
      setTimeout(() => isUserLogged == null && Cookies.get('htb:sess') ? setIsUser(false) : null, 1500)
    } else {
      const queryParams = new URLSearchParams(window.location.search)
      const accountEmail = queryParams.get('e');
      const accountPassword = queryParams.get('p');
      setIsUser(false)
      history.push(`/login${accountEmail ? `?e=${accountEmail}&p=${encodeURIComponent(accountPassword)}` : ''}`)
    }
  }, [])


  //UPDATE APP
  if (localStorage.getItem('refresh')) clearCache()

  //DETECT ONLINE
  window.addEventListener('online', () => {
    toast.dismiss('offline')
    toast.success('Internet connected', {
      toastId: 'online',
      hideProgressBar: true,
    })
  });

  //DETECT OFFLINE
  window.addEventListener('offline', () => toast.loading('Internet not connected',
    {
      toastId: 'offline',
      autoClose: false,
      position: "top-center",
      hideProgressBar: false,
      closeOnClick: false,
      draggable: false,
      progress: undefined,
      closeButton: false
    }
  ))


  //ADD MISSING MODULE IN "ADMIN"
  useEffect(async () => {
    if (isUserLogged?.role == "admin" || isUserLogged?.role == "superadmin") {
      var formateCapabilities = {}
      modulesList.map(module => {
        formateCapabilities[module.module_name] = {}
        module.components.map(component => {
          formateCapabilities[module.module_name][component.name] = false
          formateCapabilities[module.module_name]['module_enable'] = false
        })
      })

      var userCapabilitiesCheck = capability || {}
      var capabilitesModule = Object.keys(userCapabilitiesCheck)

      capabilitesModule.map((data, i) => {
        if (formateCapabilities[data]) {
          var capabilitesComponentKey = Object.keys(userCapabilitiesCheck[data])
          var capabilitesComponentValue = Object.values(userCapabilitiesCheck[data])
          capabilitesComponentKey.map((cData, index) => {
            if (formateCapabilities[data][cData] != undefined) {
              formateCapabilities[data][cData] = userCapabilitiesCheck[data][cData]
            }
          })
        }
      })

      if (JSON.stringify(formateCapabilities).indexOf('false') != -1) {
        const submitData = {
          "first_name": isUserLogged?.first_name,
          "last_name": isUserLogged?.last_name,
          "email": isUserLogged?.email,
          "mobile_number": isUserLogged?.mobile_number,
          "role": isUserLogged?.role,
          "status": isUserLogged?.status,
          "capability": JSON.stringify(formateCapabilities).replaceAll('false', 'true')
        }

        await toast.promise(
          dispatch(updateUser(isUserLogged.id, submitData, isUserLogged?.role)),
          {
            pending: 'Permissions Updating...',
            success: 'Successfully! Permissions Updated',
            error: 'Permissions not Updated'
          }
        )
        setTimeout(() => {
          toast.loading('Browser Refreshing...')
          setTimeout(() => window.location.reload(), 2500)
        }, 1500)
      }
    }
  }, [isUserLogged])


  return (
    capability || isUser == false ?
      <>
        <Router history={history}>
          <Switch>
            <Redirect exact from="/" to="dashboard" />
            <Redirect exact from="/" to="dashboard" />
            <AuthRoute exact path="/login" component={Login} />
            <AuthRoute exact path="/forgot-password" component={ForgotPassword} />

            {/* MESSAGE TEMPLATES */}
            {/* {capability?.email_template?.module_enable ? <ProtectedRoute exact path="/message-template/email/list" component={EmailList} /> : null}
            {capability?.email_template?.add ? <ProtectedRoute exact path="/message-template/email/addnew" component={addEmail} /> : null}
            {capability?.email_template?.view ? <ProtectedRoute exact path="/message-template/email/view/:name" component={viewEmail} /> : null}
            {capability?.email_template?.edit ? <ProtectedRoute exact path="/message-template/email/edit/:name" component={editEmail} /> : null} */}

            {/* 
            {capability?.sms_template?.module_enable ? <ProtectedRoute exact path="/message-template/sms/list" component={smsList} /> : null}
            {capability?.sms_template?.add ? <ProtectedRoute exact path="/message-template/sms/addnew" component={addSms} /> : null}
            {capability?.sms_template?.edit ? <ProtectedRoute exact path="/message-template/sms/edit/:name" component={editSms} /> : null}
            {capability?.sms_template?.view ? <ProtectedRoute exact path="/message-template/sms/view/:name" component={ViewSMS} /> : null} */}

            {/* {capability?.firebase_notification_template?.module_enable ? <ProtectedRoute exact path="/message-template/firebase-notification/list" component={NotificationList} /> : null}
            {capability?.firebase_notification_template?.add ? <ProtectedRoute exact path="/message-template/firebase-notification/addnew" component={AddNotification} /> : null}
            {capability?.firebase_notification_template?.edit ? <ProtectedRoute exact path="/message-template/firebase-notification/edit/:name" component={EditNotification} /> : null}
            {capability?.firebase_notification_template?.view ? <ProtectedRoute exact path="/message-template/firebase-notification/view/:name" component={ViewFirebaseNotification} /> : null} */}

            {/* <ProtectedRoute exact path="/message-template/send-notification" component={SendNotification} /> */}
            
            {capability?.whatsapp_template?.view ? <ProtectedRoute exact path="/message-templates/whatsapp/list" component={WhatsappList} />: null}

            {/* PRODUCTS */}
            {capability?.product?.module_enable ? <ProtectedRoute exact path="/products" component={ProductList} /> : null}
            {capability?.product?.view ? <ProtectedRoute exact path="/products/view/:id" component={ViewProduct} /> : null}
            {capability?.product?.edit ? <ProtectedRoute exact path="/products/edit/:id" component={EditProduct} /> : null}
            {capability?.product?.add ? <ProtectedRoute exact path="/products/addnew" component={AddProduct} /> : null}
            {capability?.product?.import || capability?.product?.import_view ? <ProtectedRoute exact path="/products/import" component={ImportProuduct} /> : null}
            {/* <ProtectedRoute exact path="/products/upload-image" component={UploadImge}/> */}

            {/* USER */}
            {capability?.user?.module_enable ? <ProtectedRoute exact path="/users" component={CustomerList} /> : null}
            {capability?.capability?.module_enable ? <ProtectedRoute exact path="/users/roles-capabilities" component={RolesCapabilities} /> : null}
            {<ProtectedRoute exact path="/users/import" component={ImportUsers} />}

            {/* ATTRIBUTES */}
            {capability?.attribute?.module_enable ? <ProtectedRoute exact path="/products/attributes" component={AttributesList} /> : null}            

            {/* CATEGORIES */}
            {capability?.category?.module_enable ? <ProtectedRoute exact path="/products/categories" component={CategoryList} /> : null}

            {/* ORDERS */}
            {capability?.orders?.module_enable ? <ProtectedRoute exact path="/orders" component={OrderList} /> : null}
            {capability?.orders?.invoice_generator ? <ProtectedRoute exact path="/orders/invoice-generator" component={InvoiceGenerator} /> : null}
            {capability?.route_setting?.module_enable ?<ProtectedRoute exact path="/orders/route-plan/list" component={RoutePlanList} /> : null}

            <ProtectedRoute exact path="/dashboard" component={Dashboard} />

            {/* SETTING */}
            <ProtectedRoute exact path="/setting/shipping/country" component={CountryList} />
            <ProtectedRoute exact path="/setting/shipping/state" component={StateList} />
            <ProtectedRoute exact path="/setting/shipping" component={ShippingIndex} />
            <ProtectedRoute exact path="/setting/route" component={RouteList} />

            <Route path="*" component={errors} />
          </Switch>
        </Router>
      </>
      : null
  )
}


function mapStateToProps(state) {
  const isUserLogged = state.auth.currentUser
  let capability = state?.auth?.currentUser?.capability
  capability = capability ? JSON.parse(capability) : null
  return {
    capability,
    isUserLogged
  }
}

export default connect(mapStateToProps)(App);