import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { login } from "../../actions/auth.action"
import moment from 'moment'
import { toast } from 'react-toastify'
import { decryptWithAES } from '../../helpers/common-function.helper'

const Login = ({ dispatch, message }) => {
  const history = useHistory()
  const [wish, setWish] = useState()
  const queryParams = new URLSearchParams(window.location.search);
  const accountEmail = queryParams.get('e');
  const accountPassword = queryParams.get('p') && decryptWithAES(queryParams.get('p'));

  useEffect(() => {
    document.title = 'Login'
    var timeIs = moment(new Date).format('lll')
    if (timeIs.indexOf('AM') != -1) setWish('Good Morning')
    else if (timeIs.indexOf('PM') != -1) setWish('Good Afternoon')
    if (accountEmail && accountPassword) document.querySelector('#signin_btn').click()
  }, [])


  const LoginSubmit = async (e) => {
    e.preventDefault()
    dispatch(login(e.target.email.value, e.target.password.value))
  }

  return (
    <div className="row no-gutter">
      <div className="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-image center">
        <div className="position-relative z-index-100">
          <div className='center'>
            <a href="index.html"><img src="/favicon.png" className="sign-favicon" alt="logo" /></a>
            <h1 className="main-logo ml-1 mr-0 my-auto tx-28 text-white">HashTagBazaar</h1>
          </div>
          <div className='bg-white shadow-sm w-100 p-3 rounded mt-2 font-size-16 text-dark text-center'>Admin Panel</div>
        </div>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-5 bg-white">
        <div className="login d-flex align-items-center py-2">
          <div className="container p-0">
            <div className="row">
              <div className="col-md-10 col-lg-10 col-xl-9 mx-auto">
                {message && (
                  <div className="form-group">
                    {message.errors && message.errors.map((err) => (
                      <div className="alert alert-danger" role="alert" key={err.message}>
                        {err.message}
                      </div>
                    ))}
                  </div>
                )}
                <div className="card-sigin">
                  <div className="main-signup-header">
                    <h2 className='font-weight-bold'>{wish}</h2>
                    <h5 className="font-weight-semibold mb-4">Please sign in to continue.</h5>

                    <form onSubmit={(e) => LoginSubmit(e)}>
                      <div className="form-group">
                        <label>Email</label>
                        <input name="email" defaultValue={accountEmail} className="form-control" placeholder="Enter your email" type="text" />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input name="password" defaultValue={accountPassword} className="form-control" placeholder="Enter your password" type="password" />
                      </div>
                      <button className="btn btn-main-primary btn-block" id="signin_btn" type='submit'>Sign In</button>
                    </form>
                  </div>
                  <div className="main-signin-footer mt-5">
                    <p><a href="#!" onClick={() => history.push("/forgot-password")}>Forgot password?</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const { message } = state.message;
  // const { currentUser }   = state.auth;  
  return {
    message,
    // sessionUser: currentUser
  };
}

export default connect(mapStateToProps)(Login);