import { CUSTOMER_LIST, SELLER_LIST, SELLER_ADD, SET_MESSAGE, USER_BY_ID, CUSTOMER_SEARCH, CUSTOMER_ADDRESS, NEW_CUSTOMER, EXPORT_CUSTOMER_LIST, CUSTOMER_DOC_LIST, CUSTOMER_DOC_ADD, CUSTOMER_ADDRESS_ADD, EDIT_CUSTOMER, CUSTOMER_ADDRESS_LIST,CUSTOMER_BANK_DETAIL_LIST, CUSTOMER_BANK_DETAIL_ADD,CUSTOMER_BANK_DETAIL_EDIT, CUSTOMER_ADDRESS_UPDATE,AFFILIATE_SEARCH_LIST } from "./types"
import usersService from "../services/users.service"
import { handleErrors } from "../helpers/common-function.helper"



//USER LIST
export const userList = (page, limit, sortBy, roleShow) => async (dispatch) => {
  return usersService.userList(page, limit, sortBy, roleShow).then(
    (data) => { dispatch({ type: CUSTOMER_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//EXPORT USER LIST
export const exportUserListF = (page, limit, sortBy, roleShow) => async (dispatch) => {
  return usersService.userList(page, limit, sortBy, roleShow).then(
    (data) => { dispatch({ type: EXPORT_CUSTOMER_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//USER BY ID
export const userById = (id) => async (dispatch) => {
  return usersService.userById(id).then(
    (data) => { dispatch({ type: USER_BY_ID, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

//USER ADD
export const addUser = (data, role) => async (dispatch) => {
  return usersService.addUser(data).then(
    (data) => { dispatch({ type: NEW_CUSTOMER, payload: data.success[0].data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//USER UPDATE
export const updateUser = (id, data, role) => async (dispatch) => {
  return usersService.updateUser(id, data).then(
    (data) => { dispatch({ type: EDIT_CUSTOMER, payload: data.success[0].data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//USER DELETE
export const deleteUser = (catId, role) => async (dispatch) => {
  return usersService.deleteUser(catId).then(
    (data) => { },
    (error) => handleErrors(error, dispatch)
  )
}


//USER SEARCH
export const customerSearch = (q, filterUser) => async (dispatch) => {
  console.log(filterUser,"----filterUser");
  return usersService.searchCustomer(q, filterUser).then(
    (data) => { dispatch({ type: CUSTOMER_SEARCH, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

//Affiliate Search
export const AffiliateSearch = (q, filterAffiliateUser) => async (dispatch) => {
  return usersService.searchCustomer(q, filterAffiliateUser).then(
    (data) => { dispatch({ type: AFFILIATE_SEARCH_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}


//USER ADDRESS
export const customerAddress = (id) => async (dispatch) => {
  return usersService.customerAddress(id).then(
    (data) => { dispatch({ type: CUSTOMER_ADDRESS, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}
//USER ADDRESS ADD
export const customerAddressAdd = (id) => async (dispatch) => {
  return usersService.customerAddressAdd(id).then(
    (data) => { dispatch({ type: CUSTOMER_ADDRESS_ADD, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const customerAddressUpdate = (id, data) => async (dispatch) => {
  return usersService.customerAddressUpdate(id, data).then(
    (data) => { dispatch({ type: CUSTOMER_ADDRESS_UPDATE, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

//USER ADDRESS LISTS
export const customerAddressList = (userId) => async (dispatch) => {
  return usersService.customerAddressList(userId).then(
    (data) => { dispatch({ type: CUSTOMER_ADDRESS_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

//USER ADDRESS DELETE
export const customerAddressDelete = (userId) => async (dispatch) => {
  return usersService.customerAddressDelete(userId).then(
    (data) => { },
    (error) => handleErrors(error, dispatch)
  )
}

//USER DOCUMENTS LISTS
export const userDocumentList = (userId) => async (dispatch) => {
  return usersService.userDocList(userId).then(
    (data) => { dispatch({ type: CUSTOMER_DOC_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

//USER DOCUMENTS ADD
export const userDocumentAdd = (body) => async (dispatch) => {
  return usersService.userDocAdd(body).then(
    (data) => { dispatch({ type: CUSTOMER_DOC_ADD, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

//USER DELETE
export const userDocumentDelete = (catId, role) => async (dispatch) => {
  return usersService.userDocDelete(catId).then(
    (data) => { },
    (error) => handleErrors(error, dispatch)
  )
}

//USER BANK DETAILS
export const userBankDetailList = (userId) => async (dispatch) => {
  return usersService.userBankDetailList(userId).then(
    (data) => { dispatch({ type: CUSTOMER_BANK_DETAIL_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const userBankDetailAdd = (data) => async (dispatch) => {
  return usersService.userBankDetailAdd(data).then(
    (data)=>{dispatch({type:CUSTOMER_BANK_DETAIL_ADD, payload: data})},
    (error)=> handleErrors(error, dispatch)
    )
}

export const userBankDetailUpdate = (id, data) => async (dispatch) => {
  return usersService.userBankDetailUpdate(id, data).then(
    (data) => {dispatch({type: CUSTOMER_BANK_DETAIL_EDIT, payload: data})},
    (error) => handleErrors(error, dispatch)
  )
}

export const userBankDetailDelete = (id) => async (dispatch) => {
  return usersService.userBankDetailDelete(id).then(
    (data) => { },
    (error) => handleErrors(error,dispatch)
  )
}

// ------------------------------------------------- FOR SELLER
export const sellerList = () => async (dispatch) => {
  return usersService.sellerList().then(
    (data) => { dispatch({ type: SELLER_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  );
};

export const addSeller = (data) => async (dispatch) => {
  return usersService.addSeller(data).then(
    (data) => { dispatch({ type: SELLER_ADD, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
}

export const updateSeller = (id, data) => async (dispatch) => {
  return usersService.updateSeller(id, data).then(
    (data) => { dispatch(sellerList()); dispatch({ type: SET_MESSAGE, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
}

export const deleteSeller = (id) => async (dispatch) => {
  return usersService.deleteSeller(id).then(
    (data) => { dispatch(sellerList()); dispatch({ type: SET_MESSAGE, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  );
}
