export const clubbedBuyingRange = ['1-25', '26-50', '51-100', '101-200', '201-300', '301-400', '401-500', '>500'] //CLUBBED BUYING RANGE
export const taxType            = ['CGST/SGST', 'IGST'] //TAX TYPE
export const taxPercentage      = [0,5,12,18,28] //TAX PERCENTAGE
export const stockStatus        = ['available', 'sold out'] //STOCK STATUS
export const unitType           = ['Sack', 'Box', 'Meter', 'Bundle', 'Pack', 'Jar', 'Bucket', 'Tin'] //UNIT TYPE
export const paginationLimit    = [25, 50, 75, 100]
export const maxVariaiton       = 10 //MAXIMUM VARIATION
export const deliveryTime       = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29]

export const productStatus      = [
  {name: 'all',        label: 'All',       showItems:['all']},
  {name: 'pending',    label: 'Pending',   showItems:['all', 'pending']},
  {name: 'published',  label: 'Published', showItems:['all', 'published']},
  {name: 'review',     label: 'Review',    showItems:['all', 'review']},
  {name: 'drafts',     label: 'Drafts',    showItems:['all', 'drafts']},
]