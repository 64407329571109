import { useState, useEffect } from "react"
import { connect } from "react-redux"
import exportFromJSON from 'export-from-json'
import moment from 'moment'
import Select from 'react-select'
import { exportOrderReceiving } from '../../actions/orders.action'
import { categoryList } from "../../actions/category.action"
import { EXPORT_ORDER_RECEIVING_LIST } from "../../actions/types"
import { roles } from '../../helpers/user.helper'
import {customerSearch} from '../../actions/users.action'

//COUNTRY
import { getCountryList, getStateList, getCityList, getPincodeList } from '../../actions/shipping.action'

const ExportOrderReceiving = ({handlePopup, dispatch, exportOrderReceivingList, stateList, cityList, pincodeList, SearchAffiliateResult}) => {
	const [loader, setLoader]                                  = useState(false)
  const [page, setPage]                                      = useState(1)
	const [limit, setLimit]                                    = useState(10)
	const [sortBy, setSortBy]                                  = useState('desc')
  const [exportData, setExportData]                          = useState([])
  const [isExported, setIsExported]                          = useState(false)
  const [totalPageShow, setTotalPageShow]                    = useState(0)
	const [orderDate, setOrderDate]                            = useState();
  const [searchRefRole, setSearchRefRole]                    = useState('affiliate')
  const [searchUserOrder, setSearchUserOrder]                = useState()
  const [customerRefSeachOption, setCustomerRefSearchOption] = useState([])
  const [searching, setSearching]                            = useState(false)
  const [status, setStatus]                                  = useState(false)
  const [filterData, setFilterData]                          = useState("")

  const loadOrderList = async(page=1, e) => {
    setLoader(true);
    let data = {}
    if(page<=1) {
      const formData = new FormData(e.target);      
      for(let entry of formData.entries()) {
        data[entry[0]] = entry[1]
      }
      data={
        ...data,
        searchKey : "delivery_date",
        searchValue: orderDate ? moment(orderDate).utcOffset('+05:30').format('DD-MMM-YYYY'): "",
        page      
      }  
      setFilterData(data);
    }
    await dispatch(exportOrderReceiving("q", page > 1 ? filterData : data));
  }

  useEffect(() => dispatch(categoryList()), [])

  useEffect(async () => {
    if(!exportOrderReceivingList) return

    var formateList = []
    var totalPage   = Math.ceil(exportOrderReceivingList?.total / limit)
    setTotalPageShow(totalPage)
    
    exportOrderReceivingList?.orders?.map((pData, index) => (
      pData.items.map((iData,i) => {
       
        formateList.push({
          'S. No.'                    : exportData.length + formateList.length + 1,
          'Order No.'                 : pData.order_number,
          'Order Date'                : moment(pData.createdAt).utcOffset('+05:30').format('MM/DD/YYYY'),
          'Type of Sale'              : "B2B",
          'New / Repeat Customer'     : "---",
          'Customer Mobile No.'       : pData.user.mobile_number,
          'Customer Name'             : pData.user.first_name+ ' ' + pData.user.last_name,
          'Customer ID'               : pData.user.user_number,
          'Customer Billing Address'  : `${pData.billing_address.address} ${pData.billing_address.locality} ${pData.billing_address.city}  ${pData.billing_address.state} -  ${pData.billing_address.pincode}`,
          'Customer Shipping Address' : `${pData.shipping_address.address} ${pData.shipping_address.locality} ${pData.shipping_address.city}  ${pData.shipping_address.state} -  ${pData.shipping_address.pincode}`,
          'PIN Code'                  : pData.shipping_address.pincode,
          'SKU ID'                    : iData.variation?.sku_id,
          'Product Name'              : iData.variation?.name,
          'Actual Supplier Name'      : "---",
          'Quantity'                  : iData?.quantity,
          'Payment Mode'              : pData.payment_mode,
          'Team Member - Referral'    : pData?.affiliate?.first_name ? pData?.affiliate?.first_name+ ' ' + pData?.affiliate?.last_name : "",  
          'FSSAI Needed'              : "---",
          'Delivery Date'             : pData?.delivery_date,
          'Status'                    : pData?.status,
          'Delivered'                 : "---" ,         
          'Remarks'                   : pData?.remarks          
        })
      })
    ))

    setExportData(data => data.concat(formateList))
    if(page + 1 <= totalPage){
      setPage(page => page + 1)
      await loadOrderList(page + 1, "")
    }else{
      setLoader(false);
      setIsExported(true)
    }
  },[exportOrderReceivingList])


  function exportToCSV(downloadType){
    const fileName   = `Order Receiving - ${moment(Date.now()).utcOffset('+05:30').format('lll')}`
    const exportType = exportFromJSON.types[downloadType]
    let data         = exportData
    exportFromJSON({ data, fileName, exportType })
  }

  async function refreshComponent(close){
    setIsExported(false)
    setPage(1)
    setLoader(false)
    setExportData([])
    await dispatch({
      type: EXPORT_ORDER_RECEIVING_LIST,
      payload: null
    })
    // loader && !isExported && window.location.reload()
    close && await handlePopup(false)
  }

  useEffect(() => {
    var formateCustomerOptions = SearchAffiliateResult?.map((option, index) => ({
      value: option?.id,
      label: `${option?.first_name} ${option?.last_name} "${option?.mobile_number}" -  #${option?.user_number}`,
      
    }))
    setCustomerRefSearchOption(formateCustomerOptions)
  }, [SearchAffiliateResult])

  const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               	= useState();

  useEffect(()=>{
    dispatch(getStateList(addressCountryId));
  },[])

  const onGetCity = (stateId) => {
  dispatch(getCityList(addressCountryId, stateId));
  }

  const onGetPincode = (cityId) => {
  dispatch(getPincodeList(addressCountryId, addressStateId, cityId));
  }

  //SEARCH
  useEffect(async () =>{
    if(!searchUserOrder?.length) return 
    setSearching(true)
    
    let filterBody = {"role" : searchRefRole};
    if(!isNaN(searchUserOrder)) {
      filterBody={
        ...filterBody,
        searchKey : "mobile_number",
        searchValue: searchUserOrder
      }
    } else {
      filterBody={
        ...filterBody,
        "name" : searchUserOrder
      }
    }      
    await dispatch(customerSearch("q",filterBody))
    await setSearching(false)
  }, [searchUserOrder] );


  return (
    <>
    <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
      <div className="overlayer" onClick={() => {refreshComponent(true)}}></div>
      <div className="modal-dialog modal-dialog-right order-export-modal" id="hideMe" role="document">

        <div className="modal-content modal-content-demo">
          <div className="modal-header px-3 py-2 shadow-base d-flex align-items-center position-relative z-index-100">
            <h6 className="modal-title py-1"><i className='bx bxs-file-export mr-1'></i> Export Orders Receiving</h6>
          </div>
          <div className="modal-body d-flex pt-4 pb-4">
            <div className="w-100 d-flex flex-column justify-center">

              {!loader && !isExported &&
              <>
              <form onSubmit={(e) =>  loadOrderList("", e) }>
                  <div className="row">                  
                  <div className="col-md-4 mb-3">
                    <div className="form-group mb-0">
                      <label>Reference/Affiliate</label>
                      <select className="ml-2" onChange={(e) => setSearchRefRole(e.target.value)}>
                        {roles.map((role,i) => (
                          <option key={i} value={role.name} selected={role.name=="affiliate" ? true:false}>{role.label}</option>
                        ))}
                      </select>
                    </div>
                    <Select
                      name="affiliate"
                      isMulti={false}
                      isLoading={searching}
                      closeMenuOnSelect={true}
                      onInputChange={e => { setSearchUserOrder(e); }}
                      autoFocus={true}
                      options={customerRefSeachOption}
                      className="basic-multi-select react-select"
                      placeholder="Select Reference"
                    />
                  </div>

                  <div className="col-md-4 form-group"> 
                    <label>Delivery Date</label>
                    <input type="date" className="form-control" name='searchValue' onChange={e => setOrderDate(`${(e.target.value)}`) }/>
                  </div>

                  <div className='col-md-4 form-group'>
                    <label>Status</label>
                    <select className='form-control' name="status">
                      <option value="" selected>All</option>
                      <option value="order-confirmed">Order Confirmed</option>
							        <option value="order-edited">Order Edited</option>
                      <option value="order-delivered">Order Delivered</option>
                      <option value="order-received">Order Received</option>
                      <option value="order-cancelled">Order Cancelled</option>
                      <option value="order-approved">Order Approved</option>
                      <option value="order-confirmed_online_payment">Online Payment</option>
                      <option value="order-confirmed-cod-payment">COD payment</option>
                      <option value="order-estimate-invoice-rejected">Estimate Invoice Rejected</option>
                      <option value="order-out-for-delivery">Out for Delivery</option>
                    </select>
                  </div>
                  <div className='col-md-3 mt-3'>
                    <div className='font-size-13 text-secondary font-weight-400 mt-1 mb-1'>Sort By</div>
                    <div>
                      <select className='form-control' name="sort" onChange={e => setSortBy(e.target.value)}>
                        <option value="asc">Newest</option>
                        <option value="desc">Oldest</option>
                      </select>
                    </div>
                  </div>

                  <div className='col-md-3 mt-2'>
                    <div className="form-group">
                    <label className="main-content-label tx-11">State</label>
                      <select className="form-control" name="state" id="status" onChange={(e) => {setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
                        <option value="">Select State</option>
                        {stateList?.data?.map((state, i) => (
                          <option key={i} value={state?.name} name={state?.id}>{state?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-md-3 mt-2'>
                    <div className="form-group">
                    <label className="main-content-label tx-11">City</label>
                      <select className="form-control" name="city" id="status" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name')) }}>
                        <option value="">Select City</option>
                        {cityList?.data?.map((data, i) => (
                          <option key={i} value={data?.name} name={data?.id}>{data?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-md-3 mt-2'>
                    <div className="form-group">
                    <label className="main-content-label tx-11">Pincode</label>
                      <select className="form-control" name="pincode" id="status">
                        <option value="">Select Pincode</option>
                        {pincodeList?.data?.map((data, i) => (
                          <option key={i} value={data?.pincode} name={data?.id}>{data?.pincode}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  </div>              

                  <div className="card border mt-3 center px-3 py-5">
                    <button type="submit" className={`btn btn-warning`}> 
                      {loader ? <span className="spinner-border spinner-border-sm mr-1"></span> : <i className='bx bxs-download mr-1'></i>}
                      Export Orders
                    </button>
                    <p className="mt-2 text-secondary">🙂 Export Orders</p>
                  </div>
              </form>
              </>
              }

              {loader && !isExported &&
                <div className="card border mt-3 px-3 py-5 animated-1s fadeIn">
                  <div className="text-center mb-1"><b>{exportData.length} Orders Exported</b></div>
                  <div className="progress rounded-50">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${(page/ totalPageShow) * 100}%`, height:15}}></div>
                  </div>
                </div>
              } 

              {isExported &&
                
                <div className="card border mt-3 center px-3 py-5 animated-1s fadeIn">                  
                  <div className="btn-group">
                    <button onClick={() => exportToCSV('csv')} className="btn btn-outline-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download CSV
                    </button>
                    
                    <button onClick={() => exportToCSV('xls')} className="btn btn-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download XLS
                    </button>
                  </div>
                  <p className="mt-2 text-center">🙂 Now, you can download</p>
                  <button onClick={() => {setLoader(false); setIsExported(false); refreshComponent(true)}} className={`btn btn-warning btn-sm mt-3`}> X Close</button>
                </div>
              } 

            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}


function mapStateToProps(state){
  let { exportOrderReceivingList } = state.orders
  let { countryList, stateList, cityList, pincodeList } = state.shipping;
  let SearchAffiliateResult = state.users.users.customerSearch

  return {
    exportOrderReceivingList,
    stateList,
    cityList,
    pincodeList,
    SearchAffiliateResult
  }
}

export default connect(mapStateToProps)(ExportOrderReceiving)