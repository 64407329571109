import React from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { connect } from 'react-redux';

const Navigation = ({ capability, currentUser }) => {
  const history = useHistory();

  return (
    capability ?
      <div id='horizontalMain' className="horizontal-main hor-menu clearfix side-header">
        <div className="horizontal-mainwrapper container clearfix">
          {/*Nav*/}
          <nav className="horizontalMenu clearfix">
            <div className="horizontal-overlapbg" />
            <ul className="horizontalMenu-list">
              <li>
                <span className="horizontalMenu-click">
                  <i className="horizontalMenu-arrow fe fe-chevron-down" />
                </span>
                <NavLink to="/dashboard" exact>
                  <i className="bx bx-bar-chart-square menu-icon" /> Dashboard
                </NavLink>
              </li>

              {capability?.orders?.module_enable ?
                <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/orders" className="sub-icon pointer-event-none">
                    <i className="bx bx-cart menu-icon"></i> Orders
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    {capability?.orders?.module_enable ? <li><NavLink to='/orders' className="slide-item" exact>Orders</NavLink></li> : null}
                    {/* <li><NavLink to='/orders/order-chat' className="slide-item" exact>Order Chat</NavLink></li> */}
                    {capability?.orders?.invoice_generator ? <li><NavLink to='/orders/invoice-generator' className="slide-item" exact>Invoice Generater</NavLink></li> : null}
                    {capability?.orders?.module_enable ? <li><NavLink to='/orders/route-plan/list' className="slide-item" exact>Route Plan</NavLink></li> : null}
                    {/* <li><NavLink to='/orders/route-planning' className="slide-item" exact>Route Planning</NavLink></li> */}
                  </ul>
                </li> : null}

              {capability?.product?.module_enable || capability?.attribute?.module_enable || capability?.category?.module_enable ?
                <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/products" className="sub-icon pointer-event-none">
                    <i className="bx bx-store menu-icon"></i> Products
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    {capability?.product?.module_enable ? <li><NavLink to='/products' className="slide-item" exact>Products</NavLink></li> : null}
                    {capability?.attribute?.module_enable ? <li><NavLink to='/products/attributes' className="slide-item" exact>Attributes</NavLink></li> : null}
                    {capability?.category?.module_enable ? <li><NavLink to='/products/categories' className="slide-item" exact>Categories</NavLink></li> : null}
                    {/* {capability?.product?.import || capability?.product?.import_view ? <li><NavLink to='/products/import' className="slide-item" exact>Import</NavLink></li> : null} */}
                    {/* <li><NavLink to='/products/upload-image' className="slide-item" exact>Upload Image</NavLink></li> */}
                  </ul>
                </li> : null}

              {capability?.user?.module_enable || capability?.capability?.module_enable ?
                <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/users" className="sub-icon pointer-event-none">
                    <i className="bx bx-group menu-icon menu-icon"></i> Users
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    {capability?.user?.module_enable ? <li><NavLink to="/users" className="slide-item" exact>All Users</NavLink></li> : null}
                    {/* {<li><NavLink to="/users/import" className="slide-item" exact>Import Users</NavLink></li>} */}
                    {capability?.capability?.module_enable ? <li><NavLink to="/users/roles-capabilities" className="slide-item" exact>Roles &amp; Capabilities</NavLink></li> : null}
                  </ul>
                </li> : null}

              {capability?.whatsapp_template?.module_enable || capability?.email_template?.module_enable || capability?.sms_template?.module_enable || capability?.firebase_notification_template?.module_enable ?
              // {capability?.template?.module_enable || capability?.capability?.module_enable ?
                <li>
                  <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                  <NavLink to="/message-template" className="sub-icon pointer-event-none">
                    <i className="bx bx-message-square-detail menu-icon"></i> Templates
                    <i className="fe fe-chevron-down horizontal-icon"></i>
                  </NavLink>
                  <ul className="sub-menu">
                    {/* {capability?.email_template?.module_enable ? <li><NavLink to="/message-template/email/list">Email</NavLink></li> : null} */}
                    {/* {capability?.sms_template?.module_enable ? <li><NavLink to="/message-template/sms/list">SMS</NavLink></li> : null} */}
                    {/* {capability?.firebase_notification_template?.module_enable ? <li><NavLink to="/message-template/firebase-notification/list">Firebase Notification</NavLink></li> : null} */}
                    {/* <li><NavLink to="/message-template/send-notification">Send Notification</NavLink></li> */}
                    {capability?.whatsapp_template?.module_enable ?<li><NavLink to="/message-templates/whatsapp/list">Whatsapp</NavLink></li>:null}
                  </ul>
                </li> 
                 : null} 
              {capability?.shipping_setting?.module_enable || capability?.route_setting?.module_enable ?
              <li>
                <span className="horizontalMenu-click"><i className="horizontalMenu-arrow fe fe-chevron-down"></i></span>
                <NavLink to="/setting" className="sub-icon pointer-event-none">
                  <i className="bx bx-cog menu-icon"></i> Setting
                  <i className="fe fe-chevron-down horizontal-icon"></i>
                </NavLink>
                <ul className="sub-menu">
                {capability?.shipping_setting?.module_enable ? <li><NavLink className="slide-item" to="/setting/shipping">Shipping</NavLink></li> : null}
                {capability?.route_setting?.module_enable ? <li><NavLink className="slide-item" to="/setting/route">Route</NavLink></li> : null}
                 {/* <li><NavLink className="slide-item" to="/setting/shipping">Shipping</NavLink></li>
                 <li><NavLink className="slide-item" to="/setting/route">Route</NavLink></li> */}
                </ul>
              </li>:null}
            </ul>
          </nav>
          {/*Nav*/}
        </div>
      </div>
      :
      <>
        {currentUser &&
          <div>
            <div className='card p-5 center text-center container shadow-sm mt-5'>
              <div className='text-secondary'>Sorry! Buddy 🙂 you have no access</div>
            </div>
          </div>
        }
      </>
  )
};

function mapStateToProps(state) {
  const { currentUser } = state.auth
  let capability = state.auth.currentUser?.capability
  capability = capability ? JSON.parse(capability) : null
  return {
    capability,
    currentUser
  }
}

export default connect(mapStateToProps)(Navigation);