import { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { CLEAR_REPORT_ORDER_VALUE } from '../../../actions/types'
import { reportOrderValues } from "../../../actions/orders.action"

// import { getDeliveryRouteList, getDeliveryRouteOrderList } from '../../../actions/shipping.action';

function OrderValue({ handleComponent, dispatch , handlePopup, ReportOrderValue }) {
  const [btnLoader, setBtnLoader]                         = useState(false)
  const [loader, setLoader]                               = useState(false)

  useEffect(async () => {document.title = 'Routes Plan | Add New';}, [])

  useEffect(()=>{   
   dispatch(reportOrderValues())    
  },[])

  const onModelHandle = async(value) => {
    dispatch({type: CLEAR_REPORT_ORDER_VALUE})
    handlePopup(value)
  } 

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => onModelHandle(false)}></div>
      <div className="modal-dialog modal-dialog-right order-export-modal">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-clipboard font-size-22 mr-2'></i> Route Wise Order Values</h6>
          </div>
          <form onSubmit={(e) => { setBtnLoader(true)}}>
            <div className="modal-body">
              {ReportOrderValue ? 
                <>
                <div className="row mt-4">
                  <div className='col-md-12'>
                    <div className='row px-3'>
                    <div className='col-md-4'>
                      <h5>Total Route: <span className="badge badge-primary ml-2">{ReportOrderValue?.totalRoute}</span></h5>
                    </div>
                    <div className='col-md-4'>
                      <h5>Total Order: <span className="badge badge-primary ml-2">{ReportOrderValue?.totalOrder}</span></h5>
                    </div>
                    <div className='col-md-4'>
                      <h5>Total Amount: <span className="badge badge-primary ml-2">₹ {ReportOrderValue?.totalAmount}</span></h5>
                    </div>

                    </div>
                      <div className="d-md-flex">
                        <div className="tabs-style-4 add-route-plan-tabs-content">
                          <div className="panel-body pt-0">
                            <div className="tab-content">
                              <div className={`tab-pane active`}>
                                  {/* <p><strong>Total Order : </strong>{deliveryRoutePlanOrderList?.orders?.length}</p>                                                */}
                                  <table className='table table-bordered mt-3'>
                                    <thead>
                                      <tr>
                                        <th>S no.</th>
                                        <th>Route Name</th>
                                        <th>Total Orders</th>
                                        <th>Orders Value</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      { ReportOrderValue?.data?.map((orderValue, i) => (              
                                      <tr>
                                        <td>{i+1}</td>
                                        <td>{orderValue?.router_name}</td>
                                        <td>{orderValue?.orders[0]?.totalOrder ? orderValue?.orders[0]?.totalOrder : "---"}</td>
                                        <td>{orderValue?.orders[0]?.totalAmount ? `₹ ${orderValue?.orders[0]?.totalAmount}` : "---"}</td>
                                      </tr>
                                      ))}  
                                    </tbody>
                                  </table>    
                                
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>                    
                    </div> 
                </div>
                </>
                : 
                <div className="text-center"> <span className="spinner-border spinner-border-sm mx-3"></span> Please wait...</div>      
              }

              {loader &&
              <div className="d-flex mx-auto justify-content-center flex-column mt-4 mb-3 align-items-center">
                <div>Wait <b>Buddy</b>...</div>
                <div className="spinner-grow" role="status" />
              </div>
              }
              
            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" onClick={() => onModelHandle(false)} data-dismiss="modal" type="button">Close</div>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  let ReportOrderValue = state.orders.reportOrderValue;
  // console.log(ReportOrderValue?.totalRoute,"--ReportOrderValue");

  return {    
    ReportOrderValue
  };
}

export default connect(mapStateToProps)(OrderValue);