import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { convertToArray, inputFirstLetterCapital } from '../../../helpers/common-function.helper'
import { addRoute } from '../../../actions/shipping.action'
import { daysList } from '../../../helpers/shipping.helper'
// get contries
import { getStateList, getCityList, getPincodeList } from '../../../actions/shipping.action'

function RouteAdd({ handleComponent, dispatch, loadAndRefreshList, newRouteData, stateList, cityList, pincodeList }) {

  const [btnLoader, setBtnLoader]                         = useState(false)
  const [loader, setLoader]                               = useState(true)
  const [addressCountryId, setAddressCountryId]           = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               = useState([]);
  const [pincodeInArray, setPincodeInArray]               = useState([])
  const [availableDaysInArray, setAvailableDaysInArray]   = useState([])
  const [isCheckAll, setIsCheckAll]                       = useState(false);

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }

  useEffect(async () => {
    document.title = 'Routes | Add New'
    setLoader(true)
    // dispatch(getCountryList(initialPageSetting.sort, initialPageSetting.status))
    // onGetCity(addressStateId)
    onGetState(addressCountryId)
    setLoader(false)
  }, [])

  useEffect(async () => {
    setTimeout(async () => {
      if (newRouteData?.id) {
        await loadAndRefreshList()
        await handleComponent(false)
      }
    }, 2000)

  }, [newRouteData])


  const onSubmitAddRoute = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)    
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }

    const bodyData = {
      name                : data.name,
      country             : "India",
      state               : "Uttar Pradesh",
      city                : data.city,
      pincode             : pincodeInArray,
      is_available_days   : availableDaysInArray,
      calling_time : {
        start_time        : data.calling_start_time,
        end_time          : data.calling_end_time,
      } , 
      order_time : {
        start_time        : data.order_start_time,
        end_time          : data.order_end_time,
        start_day         : data.order_start_day,
        end_day           : data.order_end_day,
      },
      status              : data.status     
    }

    // console.log(bodyData,"-----------add");

    await toast.promise(
      dispatch(addRoute(bodyData)),
      {
        pending: 'Route adding...',
        success: 'Successfully! Route Added',
        error: {
          render() {
            setBtnLoader(false);
            return 'Route not added'
          }

        }
      }
    )
  }
  
  const onGetState = async (addressCountryId) => {
    // setAddressCountryId(addressCountryId)
    dispatch(getStateList(addressCountryId))
    return
  }

  const onGetCity = (stateId) => {
    // setAddressStateId(stateId)
    dispatch(getCityList(addressCountryId, stateId))
    return
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
    return
  } 

const onSelectAvailableDays = (e) => {
  var availableDays_array = [...availableDaysInArray];
  if (e.target.checked) {
    availableDays_array = [...availableDaysInArray, e.target.value];
  } else {
    availableDays_array.splice(availableDaysInArray.indexOf(e.target.value), 1);
  }
  setAvailableDaysInArray(availableDays_array);    
}

const onSelectPincode = (e) => {
  const {value,checked} = e.target;
    if (!checked) {
      setPincodeInArray(pincodeInArray.filter(item => item !== value));
    }
    else{
     setPincodeInArray([...pincodeInArray, value]);
    }
}

// CHECKED ALL CHECkEDBOX
 const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);

    setPincodeInArray(pincodeList.data.map(li => li.pincode));
    if (isCheckAll) {
      setPincodeInArray([]);
    }
  };

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right modal-dialog-for-add-user">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-plus font-size-22 mr-2'></i> Add New Route </h6>
          </div>
          <form onSubmit={(e) => { setBtnLoader(true); onSubmitAddRoute(e) }}>
            <div className="modal-body">
              {/* overflow-unset */}
              <div className="row">               

                {/* <div className='col-md-4'>
                  <div className="form-group">
                    <select className="form-control" name="country" id="status" onChange={(e) => {onGetState(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option value="" selected>Select Country</option>
                      {countryList?.data?.map((country, i) => (
                        <option key={i}  value={country.name} name={country.id}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div> */}
                <div className='col-md-4'>
                  <div className="form-group">
                    <select className="form-control" name="state" id="status" onChange={(e) => {setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option value="" selected>Select State</option>
                      {stateList?.data?.map((state, i) => (
                        <option key={i} value={state.name} name={state.id}>{state.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className="form-group">
                    <select className="form-control" name="city" id="status" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option value="" selected>Select City</option>
                      {cityList?.data?.map((data, i) => (
                        <option key={i} value={data.name} name={data.id}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className="form-group">
                    <label>Pincodes</label>
                    <div className='row'>
                      <div className='col-md-12'>
                        {pincodeList?.data?.length==0 && 
                        <div className='alert alert-warning'>Pincodes not available</div>
                        }
                      </div>

                      {pincodeList?.data && pincodeList?.data?.length!=0 &&
                      <div className='col-md-2'>
                          <label>
                            <input type='checkbox' name='allselect' id='allselect' onChange={handleSelectAll} checked={isCheckAll}/> Select All 
                          </label>
                      </div>
                      }

                      {
                        pincodeList?.data?.length!=0 && pincodeList?.data?.map((pn,i) => (
                          <>
                            <div className='col-md-2'>
                              <label>
                                <input key={i} type='checkbox' name={pn.pincode} defaultValue={pn?.pincode} onChange={(e)=>onSelectPincode(e)} checked={pincodeInArray.includes(pn?.pincode)}/> {pn?.pincode}    
                              </label>
                            </div>
                          </>   
                        ))
                      }
                      </div>                    
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className="form-group">
                    <label>Is Available Days</label>
                    <div className='row'>
                      <div className='col-md-12'>{daysList.length==0 && <div className='alert alert-warning'>Days not available</div>}</div>
                      {
                        daysList && daysList?.map((pn,i) => (
                          <>
                              <div className='col-md-3 mt-2'>
                                <label>
                                  <input key={i} type='checkbox' defaultValue={pn.value} onChange={(e) => onSelectAvailableDays(e)}/> {pn.label}    
                                </label>
                              </div>
                          </>                 
                        ))                        
                      }
                      </div>                    
                  </div>
                </div>
                
                <div className='col-md-12 row'>
                  <div className="col-md-3 mt-2">
                    <label className='mb-2'>Calling Time</label>
                    <div className="input_wrap w-100">
                      <input type='text' name="calling_start_time" className="form-control ml-2 mt-2 time_input" defaultValue="16:59:59"/>
                      <label>From Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <label>&nbsp;</label>
                    <div className="input_wrap w-100">
                      <input type='text' name="calling_end_time" className="form-control ml-2 mt-2 time_input" defaultValue="17:00:00"/>
                      <label>End Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <label>Order Time</label>
                    <div className="input_wrap w-100">
                      <input type='text' name="order_start_time" className="form-control ml-2 mt-2 time_input" defaultValue="17:00:00"/>
                      <label>From Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <label>&nbsp;</label>
                    <div className="input_wrap w-100">
                      {/* <input type='text' name="order_end_time" className="form-control ml-2 mt-2 time_input" defaultValue={moment(Date.now()).format('HH:mm')}/> */}
                      <input type='text' name="order_end_time" className="form-control ml-2 mt-2 time_input" defaultValue="17:15:00"/>
                      <label>End Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                    <div className="input_wrap w-100">
                      {/* <input type='text' name="order_start_day" className="form-control ml-2 time_input" defaultValue={moment(Date.now()).format('dddd')}/> */}
                      <input type='text' name="order_start_day" className="form-control ml-2 time_input" defaultValue="-2"/>
                      <label>Start Day</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                    <div className="input_wrap w-100">
                      <input type='text' name="order_end_day" className="form-control ml-2 time_input" defaultValue="-1"/>
                      <label>End Day</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                  <div className="form-group">
                    <select className="form-control" name="status">
                      <option value="" disabled>Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">InActive</option>                      
                    </select>
                  </div>
                </div> 
                <div className="col-md-3 mt-4">
                  <div className="input_wrap w-100">
                    <input name="name" type="text" onChange={inputFirstLetterCapital} placeholder=" " autoComplete='new-password' />
                    <label>Route Name</label>
                  </div>
                </div>   
                </div>

              </div>
            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Add Route' : <span className="spinner-border spinner-border-sm mx-3"></span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  const { countryList, stateList, cityList, pincodeList, } = state.shipping
  const newRouteData = state.shipping.newRoute

  return {
    countryList,
    pincodeList,
    stateList,
    cityList,
    newRouteData
  };
}

export default connect(mapStateToProps)(RouteAdd);