import React, {useState, useEffect} from 'react'
import axios from "axios"
import { SET_MESSAGE } from '../../actions/types'
import {category_type} from '../../helpers/category.helper'
import Select from 'react-select'
import { connect } from 'react-redux'
import {getCityList, getPincodeList ,getStateList} from '../../actions/shipping.action'


const CategoryAdd = ({ categories, dispatch, imageUrl, setParentCat, setImageUrl, btnLoader ,stateList ,cityList, pincodeList }) => {
  const [option, setOption]                                 = useState()
  const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               	= useState([]);
  const [loader, setLoader]                                 = useState(true)

// console.log(category_type,"-------category_type");

  useEffect(async () => {
    onGetState(addressCountryId)
    // onGetCity(addressStateId)
    setLoader(false)
  }, [])

  const onGetState = async () => {
    // setAddressCountryId(countryId)
    dispatch(getStateList(addressCountryId))
  }

  const onGetCity = (stateId) => {
    // setAddressStateId(stateId)
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }

  //FORMATE DROPDOWN OPTIONS
  useEffect(() => {
    if(!categories) return
    var formateOptions = [];
    categories.map((parentCategory) => {
      if(parentCategory.parent == '' || parentCategory.parent == 0){ //PARENT CATEGORY
        formateOptions.push({label:`${parentCategory.name}`, value: parentCategory.id})
        
        categories.map((subCategory,i) => { //SUB-CATEGORY
          if(subCategory.parent == parentCategory.id){
            formateOptions.push({label:` -- ${subCategory.name}`, value: subCategory.id})

            categories.map((catData) => { //LEAF
              if(catData.parent == subCategory.id){
                formateOptions.push({label:` ----- ${catData.name}`, value: catData.id})

                categories.map((other) => { //OTHER
                  if(other.parent == catData.id){
                    formateOptions.push({label:` --------- ${other.name}`, value: other.id})
                  }
                })
              }
            })
          }
        })
      }
    })
    setOption(formateOptions)
  }, [categories])

  /* UPLOAD IMAGE
   ============================= */
  const uploadF = (selectorFiles) => {
    var data = new FormData();
    data.append('file', selectorFiles[0]);
    data.append('foldername', 'category');

    axios.post("/file/upload", data)      
    .then((response) => {
      setImageUrl(response.data);
    }).catch(err => {
      dispatch({
        type:SET_MESSAGE,
        payload:err.response.data
      })
    })
  }

  return (
    <>
      <div className="input_wrap w-100 ">
        <input name="name" type="text" placeholder=" "/>
        <label>Name</label>
      </div>

      <div className="input_wrap w-100 mt-3">
        <input name="position" type="number" placeholder=" "/>
        <label>Position</label>
      </div>

      <div className='row mt-3'>
        <div className='col-md-4'>
          <div className="form-group">
          <label className="main-content-label tx-11">State</label> 
            <select className="form-control" name="state" id="status" onChange={(e) => {setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
              <option selected>Select State</option>
              {stateList?.data?.map((state, i) => (
                <option key={i} value={state?.name} name={state?.id}>{state?.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-4'>
          <div className="form-group">
          <label className="main-content-label tx-11">City</label> 
            <select className="form-control" name="city" id="status" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
              <option>Select City</option>
              {cityList?.data?.map((data, i) => (
                <option key={i} value={data.name} name={data.id}>{data.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-4'>
          <div className="form-group">
          <label className="main-content-label tx-11">Pincode</label> 
          <select className="form-control" name="pincode">
            <option selected>Select Pincode</option>
            {pincodeList?.data?.map((data, i) => (
              <option key={i} value={data.pincode}>{data.pincode}</option>
            ))}
          </select>
          </div>
        </div>
      </div>

      {/* <div className="form-group mt-3"> 
        <label className="main-content-label tx-11">Parent Category</label> 
        <Select
          options={option}
          isClearable={true}
          onChange={e => setParentCat(e?.value ? e.value : '')}
          className='react-select'
        />
      </div>   */}

      <div className="form-group mt-3"> 
        <label className="main-content-label tx-11">Parent Category</label> 
        <Select
          options={option}
          isClearable={true}
          onChange={e => setParentCat(e?.value ? e.value : '')}
          className='react-select'
        />
      </div>  

      <div className="form-group"> 
        <label className="main-content-label tx-11">Category Type</label> 
        <select name="type" className="form-control text-capitalize">
          <option value=''>Select category type ...</option>
          {category_type.map(type => (
            <option value={type}>{type}</option>
          ))}
        </select>
      </div>

      <div className="form-group"> 
        <label className="main-content-label tx-11">Status</label> 
        <select name="status" className="form-control">
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>

      <div className="form-group"> 
        <label className="main-content-label tx-11">Image</label> 
        <input name="image" defaultValue={imageUrl} type="hidden" />
        <input className="form-control" type="file" onChange={(e)=> uploadF(e.target.files)} /> 
        {imageUrl ? <><hr/> <img src={imageUrl} alt="" className="rounded wd-100p wd-sm-200" /></> : ''}
      </div>
      <button disabled={btnLoader} className="btn btn-main-primary pd-x-20 float-right" type="submit">
        {!btnLoader ? '+ Add Category' : <span className="spinner-border spinner-border-sm mx-5"></span>}
      </button>
    </>
  )
}

function mapStateToProps(state) {
  const { countryList, stateList, cityList, pincodeList } = state.shipping

  return {
    countryList,
    pincodeList,
    stateList,
    cityList
  };
}

export default connect(mapStateToProps) (CategoryAdd);
