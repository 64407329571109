import { useEffect, useState } from 'react'
import MainLayout from '../../layouts/main.component'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getCountryList } from '../../../actions/shipping.action'
import CountryAdd from './country-add.component'
import CountryEdit from './country-edit.component'
import { COUNTRY_BY_ID } from '../../../actions/types'
import { toast } from 'react-toastify'
import { countryDelete } from '../../../actions/shipping.action'
import DeleteConfirm from '../../../helpers/delete.helper'


const ShippingList = ({ countryList, dispatch }) => {

	console.log(countryList);

	const initialPageSetting = {
		sort: 'ASC',
		status: 'all',
	}
	const [pageSetting, setPageSetting] = useState(initialPageSetting)


	const history = useHistory()
	const [isMounted, setIsMounted] = useState(false)
	const [search, setSearch] = useState('')
	const [loader, setLoader] = useState(true)
	const [showAddUser, setShowAddUser] = useState(false)
	const [showEditUser, setShowEditUser] = useState(false)
	const [actionId, setActionId] = useState('')


	useEffect(async () => {
		document.title = 'Shipping | Country List'
		dispatch(getCountryList(initialPageSetting.sort, initialPageSetting.status))
		setLoader(false)
	}, [])

	async function refreshList() {
		toast.loading('Refreshing Country List...', { toastId: 'refreshingCountry' })
		await dispatch(getCountryList(pageSetting.sort, pageSetting.status))
		toast.dismiss('refreshingCountry')
	}

	//DELETE
	const [showDelete, setShowDelete] = useState(false)
	const [deleteId, setDeleteId] = useState()
	const [deleteName, setDeleteName] = useState()
	const [isDeleted, setIsDeleted] = useState(false)

	useEffect(() => {
		if (!isMounted) return
		refreshList()
	}, [isDeleted])

	useEffect(() => {
		if (!isMounted) return
		refreshList()
	}, [pageSetting])

	useEffect(() => setIsMounted(true), [])

	return (
		<MainLayout>

			{/* BREADCRUMB */}
			<nav className="breadcrumb mt-3 mb-0">
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
				<div className="mr-auto page_title">Country List</div>
				<div className="breadcrumb-item" onClick={() => history.push('/')}>Dashboard</div>
				<div className="breadcrumb-item active">Country</div>
			</nav>


			{/* HEADER */}
			<div className="d-flex justify-content-between align-items-center z-index-10">
				<div className="h4 mt-4 mb-3 d-flex align-items-center">
					<div>Country <span className="badge badge-primary-transparent ml-2">{countryList?.total}</span></div>

					{/* FILTER */}
					<div className='filter_box'>
						<div className="dropdown">
							<button type="button" className="btn btn-outline-primary ml-3 py-1 px-2 dropdown-toggle" data-toggle="dropdown">
								<i className='bx bx-filter-alt mr-1'></i> Filter
							</button>
							<Filter pageSetting={pageSetting} setPageSetting={setPageSetting} />
						</div>
					</div>
				</div>

				<div className="d-flex align-items-center">
					<button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={() => window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
					<div className="form-group w-100 mb-0">
						<div className="input-icon">
							<span className="input-icon-addon"> <i className="fe fe-search" /> </span>
							<input type="search" className="form-control ht-40" placeholder="Search" />
						</div>
					</div>
					<button className="btn btn-primary ml-2" onClick={() => setShowAddUser(true)}> +&nbsp;Add</button>
				</div>
			</div>


			{/* LIST */}
			<div className="card">

				<div className="card-body">
					<div className="userlist-table">
						{search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}

						<table className="table card-table table-hover category_table table-bordered stable-vcenter text-nowrap mb-0">
							<thead>
								<tr>
									<th className='text-center'>#</th>
									<th>Country</th>
									<th>Short Name</th>
									<th>Phone Code</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>

								{/* LIST */}
								{!loader && countryList?.data?.length && <>
									{countryList?.data?.length <= 0 ?
										<tr name=' '><td className="text-center count_ignore" colSpan="7">No Data Available</td></tr>
										:
										countryList?.data?.map((data, index) => (
											<ProductRow
												data={data}
												dispatch={dispatch}
												key={index}
												index={index}
												setShowEditUser={setShowEditUser}
												setActionId={setActionId}
												setShowDelete={setShowDelete}
												setDeleteId={setDeleteId}
												setDeleteName={setDeleteName}
												history={history}
											/>
										))
									}
								</>}
							</tbody>
						</table>

						{/* LOADER */}
						{!countryList || loader ?
							<div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
								<div>Wait <b>Buddy</b>...</div>
								<div className="spinner-grow" role="status" />
							</div> : ''}

					</div>
				</div>
			</div>

			{showAddUser && <CountryAdd refreshList={refreshList} handleComponent={setShowAddUser} />}
			{showEditUser && <CountryEdit refreshList={refreshList} handleComponent={setShowEditUser} id={actionId} />}
			{showDelete && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={countryDelete} />}

		</MainLayout>
	)
}



/* -------------------------------
							FILTER
----------------------------------*/
const Filter = ({ pageSetting, setPageSetting }) => {

	const [status, setStatus] = useState(pageSetting.status)
	const [sort, setSort] = useState(pageSetting.sort)

	function onSubmit(e) {
		e.preventDefault()
		setPageSetting({
			sort: sort,
			status: status,
		})
		document.querySelector('.dropdown-menu.show').classList.remove('show')
	}

	return (
		<form className="dropdown-menu pt-3 min-w-200 animated zoomIn" onClick={e => e.stopPropagation()} onSubmit={e => onSubmit(e)}>
			<div className='px-3'>
				<div className='font-size-13 text-secondary font-weight-400 mb-1'>Status</div>
				<div>
					<label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setStatus('all')} defaultChecked={pageSetting.status == 'all' ? true : false} name="status" className='mr-1' /> All</label>
					<label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setStatus('active')} defaultChecked={pageSetting.status == 'active' ? true : false} name="status" className='mr-1' /> Active</label>
					<label className='m-0 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setStatus('inactive')} defaultChecked={pageSetting.status == 'inactive' ? true : false} name="status" className='mr-1' /> Inactive</label>
				</div>
			</div>
			<div className='px-3 mt-3'>
				<div className='font-size-13 text-secondary font-weight-400 mb-1'>Sort By</div>
				<div>
					<label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setSort('ASC')} defaultChecked={pageSetting.sort == 'ASC' ? true : false} name="sort" className='mr-1' /> Asc</label>
					<label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setSort('DESC')} defaultChecked={pageSetting.sort == 'DESC' ? true : false} name="sort" className='mr-1' /> Desc</label>
				</div>
			</div>
			<div className='p-2 mt-2 d-flex justify-content-end'>
				<button type='button' className='btn btn-outline-danger py-1' onClick={() => document.querySelector('.dropdown-menu.show').classList.remove('show')}>Close</button>
				<button type='submit' className='btn btn-primary ml-2 py-1'>Apply</button>
			</div>
		</form>
	)
}



/* -------------------------------
					PRODUCT ROW
----------------------------------*/
const ProductRow = ({ data, dispatch, index, history, setShowEditUser, setActionId, setShowDelete, setDeleteId, setDeleteName, setShowState, showState }) => {

	return (
		<>
			<tr name={data.name.toLowerCase()} className='cursor-pointer' onClick={() => history.push(`/setting/shipping/state/?country=${data.id}&b=1`)}>
				<td className="text-center"></td>
				<td>{data.name}</td>
				<td>{data.short_name}</td>
				<td>+{data.phone_code}</td>
				<td><span className={`badge text-capitalize ${data.status == 'active' ? 'badge-teal' : 'badge-secondary'}`}>{data.status}</span></td>
				<td onClick={e => e.stopPropagation()}>
					<button onClick={() => { dispatch({ type: COUNTRY_BY_ID, payload: null }); setActionId(data.id); setShowEditUser(true); }} className="btn btn-sm btn-white mr-2" title="Edit Product"><i className='bx bx-pencil h4 m-0 text-warning'></i></button>
					<button onClick={() => { setShowDelete(true); setDeleteId(data.id); setDeleteName(data.name) }} className="btn btn-sm btn-white mr-2" title="Delete Product"><i className='bx bx-trash h4 m-0 text-danger'></i></button>
				</td>
			</tr>
		</>
	)
}



function mapStateToProps(state) {
	const { countryList } = state.shipping

	console.log(countryList, "countryList==");

	return {
		countryList
	}
}

export default connect(mapStateToProps)(ShippingList)