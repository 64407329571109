import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { convertToArray, inputFirstLetterCapital } from '../../../helpers/common-function.helper'
import { editRoute, getStateById } from '../../../actions/shipping.action'
import { daysList } from '../../../helpers/shipping.helper'
// get contries
import { getCountryList, getStateList, getCityList, getPincodeList,getRouteById } from '../../../actions/shipping.action'

function RouteEdit({ handleComponent,id, dispatch, loadAndRefreshList, newRouteData, countryList, stateList, cityList, pincodeList ,routeById}) {

  const [btnLoader, setBtnLoader]                         = useState(false)
  const [loader, setLoader]                               = useState(true)
  const [addressCountryId, setAddressCountryId]           = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               = useState([]);
  const [pincodeInArray, setPincodeInArray]               = useState([])
  const [availableDaysInArray, setAvailableDaysInArray]   = useState([])
  const [isCheckAll, setIsCheckAll]                       = useState(false);
                      
// console.log(pincodeInArray,"pincodeInArray");

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }

  useEffect(async () => {
    document.title = 'Routes | Edit New'
    setLoader(true)
    onGetState(addressCountryId)
    // dispatch(getStateList(addressCountryId))
    // dispatch(getCityList(addressCountryId, addressStateId))
    setLoader(false)
  }, [])

  useEffect(()=>{dispatch(getRouteById(id))},[id])

  useEffect(()=>{
    setAvailableDaysInArray(routeById?.is_available_days)
    setPincodeInArray(routeById?.pincode)
  },[routeById])


  useEffect(()=>{
    const getStateId = stateList?.data?.filter(i => i.name == routeById?.state);

    if(getStateId && getStateId?.length!=0) {onGetCity(getStateId[0]?.id); setAddressStateId(getStateId[0]?.id)}
  },[stateList])

  useEffect(()=>{
    const getCityId = cityList?.data?.filter(i => i.name == routeById?.city);

    if(getCityId && getCityId?.length!=0){
      onGetPincode(getCityId[0]?.id)
    }
  },[cityList])

  // DAY UPDATE
  const onIsAvailableDayChecked = (value, arr = routeById?.is_available_days) => {
    var status = false;
    for (var i = 0; i < arr.length; i++) {
        var name = arr[i];
        if (name == value) {
            status = true;
            break;
        }
    }
    return status;
  } 

  const onSelectAvailableDays = (e) => {
    var availableDays_array = [...availableDaysInArray];
    if (e.target.checked) {
      availableDays_array = [...availableDaysInArray, e.target.value];
    } else {
      availableDays_array.splice(availableDaysInArray.indexOf(e.target.value), 1);
    }
    setAvailableDaysInArray(availableDays_array);    
  }

  const onGetState = async (addressCountryId) => {
    // setAddressCountryId(addressCountryId)
    dispatch(getStateList(addressCountryId))
    return
  }

  const onGetCity = (stateId) => {
    dispatch(getCityList(addressCountryId, stateId ))
    return
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
    return
  } 



 //PINCODE UPDATE
  const onIsAvailablePincodeChecked = (value, arr = routeById?.pincode) => {
    var status = false;
    for (var i = 0; i < arr.length; i++) {
        var name = arr[i];
        if (name == value) {
            status = true;
            break;
        }
    }
    return status;
  }

  const onSelectPincode = (e) => {
    var pincode_array = [...pincodeInArray];
    if (e.target.checked) {
      pincode_array = [...pincodeInArray, e.target.value];
    } else {
      pincode_array.splice(pincodeInArray.indexOf(e.target.value), 1);
    }
    setPincodeInArray(pincode_array);    
  }

  // CHECKED ALL CHECkEDBOX
const handleSelectAll = e => {
  setIsCheckAll(!isCheckAll);

  setPincodeInArray(pincodeList.data.map(li => li.pincode));
  if (isCheckAll) {
    setPincodeInArray([]);
  }
};

  //UPDATE
  const onSubmitEditRoute = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)    
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }

    const bodyData = {
      name                : data.name,
      country             : routeById.country,
      state               : routeById.state,
      city                : data.city,
      pincode             : pincodeInArray,
      is_available_days   : availableDaysInArray,
      calling_time : {
        start_time        : data.calling_start_time,
        end_time          : data.calling_end_time,
      } , 
      order_time : {
        start_time        : data.order_start_time,
        end_time          : data.order_end_time,
        start_day         : data.order_start_day,
        end_day           : data.order_end_day
      },
      status              : data.status     
    }

    var checkfield = convertToArray(bodyData).filter(data => data == '')

    await toast.promise(
      dispatch(editRoute(bodyData, id)),
      {
        pending: 'Route Updating...',
        success: 'Successfully! Route update',
        error: {
          render() {
            setBtnLoader(false);
            return 'Route not update'
          }
        }
      }
    )
    await loadAndRefreshList()
    await handleComponent(false)
  }

  return (
    <>
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right modal-dialog-for-add-user">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-edit font-size-22 mr-2'></i> Edit Route </h6>
          </div>
          {routeById?.id &&
          <form onSubmit={(e) => { setBtnLoader(true); onSubmitEditRoute(e) }}>
            <div className="modal-body">
              {/* overflow-unset */}
              <div className="row">               

                {/* <div className='col-md-4'>
                  <div className="form-group">
                    <select className="form-control" name="country" id="status" onChange={(e) => {onGetState(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option value="" selected>Select Country</option>
                      {countryList?.data?.map((country, i) => (
                        <option key={i}  value={country.name} name={country.id}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div> */}
                <div className='col-md-4'>
                  <div className="form-group">
                    <select className="form-control" name="state" id="status" onChange={(e) => {setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option>Select State</option>
                      {stateList?.data?.map((state, i) => (
                        <option key={i} value={state.name} name={state.id} selected={state.name == routeById?.state ? true : false }>{state.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className="form-group">
                    <select className="form-control" name="city" id="status" onClick={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option>Select City</option>
                      {cityList?.data?.map((data, i) => (
                        <option key={i} value={data.name} name={data.id}  selected={data.name == routeById?.city ? true : false }>{data.name}</option>
                        // <option selected={data.name==="Lucknow"?true:false} key={i} value={data.name} name={data.id}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className="form-group">
                    <label>Pincodes</label>
                    <div className='row'>
                      <div className='col-md-12'>{pincodeList?.data?.length==0 && <div className='alert alert-warning'>Pincodes not available</div>}</div>
                      {pincodeList?.data && pincodeList?.data?.length!=0 &&
                      <div className='col-md-2'>
                        <label>
                          <input type='checkbox' name='allselect' id='allselect' onChange={handleSelectAll} checked={isCheckAll}/> Select All 
                        </label>
                      </div>
                      }
                      {
                        pincodeList?.data?.length!=0 && pincodeList?.data?.map((pn) => (
                          <>
                              <div className='col-md-2'>
                                <label>
                                  <input type='checkbox' defaultValue={pn?.pincode} defaultChecked={onIsAvailablePincodeChecked(pn?.pincode,routeById?.pincode)} checked={pincodeInArray?.includes(pn?.pincode)} onChange={(e) => onSelectPincode(e)}/> {pn?.pincode}    
                                  {/* <input type='checkbox' defaultValue={pn?.pincode} defaultChecked={onIsAvailablePincodeChecked(pn?.pincode,routeById?.pincode)} onChange={(e) => onSelectPincode(e)}/> {pn?.pincode}     */}
                                </label>
                              </div>
                          </>                 
                        ))
                      }
                    </div>                    
                  </div>
                </div>

                <div className='col-md-12'>
                  <div className="form-group">
                    <label>Is Available Days</label>
                    <div className='row'>
                      <div className='col-md-12'>{daysList.length==0 && <div className='alert alert-warning'>Days not available</div>}</div>
                      {
                        daysList && daysList?.map((pn,i) => (
                          <>
                              <div className='col-md-3 mt-2'>
                                <input type='checkbox' defaultValue={pn.value} 
                                  defaultChecked={onIsAvailableDayChecked(pn.value,routeById?.is_available_days)}
                                  onChange={(e) => onSelectAvailableDays(e)}
                                />
                                  {pn.label}    
                              </div>
                          </>                 
                        ))   
                      }
                      </div>                    
                  </div>
                </div>
                
                <div className='col-md-12 row'>
                  <div className="col-md-3 mt-2">
                    <label>Calling Time</label>
                    <div className="input_wrap w-100">
                      {/* <input type='text' name="calling_start_time" className="form-control ml-2 time_input" defaultValue={moment(Date.now()).format('HH:mm')}/> */}
                      <input type='text' name="calling_start_time" className="form-control ml-2 mt-2 time_input" defaultValue={routeById?.calling_time?.start_time} />
                      <label>From Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <label>&nbsp;</label>
                    <div className="input_wrap w-100">
                      <input type='text' name="calling_end_time" className="form-control ml-2 mt-2 time_input" defaultValue={routeById?.calling_time?.end_time}/>
                      <label>End Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <label>Order Time</label>
                    <div className="input_wrap w-100">
                      {/* <input type='text' name="order_start_time" className="form-control ml-2 time_input" defaultValue={moment(Date.now()).format('HH:mm')}/> */}
                      <input type='text' name="order_start_time" className="form-control ml-2 mt-2 time_input" defaultValue={routeById?.order_time?.start_time}/>
                      <label>From Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <label>&nbsp;</label>
                    <div className="input_wrap w-100">
                      <input type='text' name="order_end_time" className="form-control ml-2 mt-2 time_input" defaultValue={routeById?.order_time?.end_time}/>
                      <label>End Time</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                    {/* <label>&nbsp;</label> */}
                    <div className="input_wrap w-100">
                      {/* <input type='text' name="order_start_day" className="form-control ml-2 time_input" defaultValue={moment(Date.now()).format('dddd')}/> */}
                      <input type='text' name="order_start_day" className="form-control ml-2 time_input" defaultValue={routeById?.order_time?.start_day}/>
                      <label>Start Day</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                    {/* <label>&nbsp;</label> */}
                    <div className="input_wrap w-100">
                      <input type='text' name="order_end_day" className="form-control ml-2 time_input" defaultValue={routeById?.order_time?.end_day}/>
                      <label>End Day</label>
                    </div>
                  </div>
                  <div className="col-md-3 mt-4">
                  <div className="form-group">
                    {/* <label className="main-content-label tx-11">Status</label> */}
                    <select defaultValue={routeById?.status} className="form-control" name="status" >
                      <option value="" disabled>Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">InActive</option>                      
                    </select>
                  </div>
                </div> 
                 
                <div className="col-md-3 mt-4">
                  <div className="input_wrap w-100">
                    <input name="name" type="text" defaultValue={routeById?.name} onChange={inputFirstLetterCapital} placeholder=" " autoComplete='new-password' />
                    <label>Route Name</label>
                  </div>
                </div>  
                </div>

              </div>
            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Update Route' : <span className="spinner-border spinner-border-sm mx-3"></span>}
              </button>
            </div>
          </form>
           } 
        </div>
      </div>
    </div>
    </>
  )
}


function mapStateToProps(state) {
  const { countryList, stateList, cityList, pincodeList, routeById } = state.shipping
  const newRouteData = state.shipping.newRoute

// console.log(pincodeList,"----------pincodeList");

  return {
    countryList,
    pincodeList,
    stateList,
    cityList,
    newRouteData,
    routeById,
  };
}

export default connect(mapStateToProps)(RouteEdit);