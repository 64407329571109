//--------AUTH--------
export const LOGIN_SUCCESS       = "LOGIN_SUCCESS"
export const LOGIN_FAIL          = "LOGIN_FAIL"
export const LOGOUT              = "LOGOUT"

export const CURRENT_USER        = "CURRENT_USER"
export const NOT_AUTHORISED      = "NOT_AUTHORISED"


//--------ERROR & MESSAGE--------
export const SET_MESSAGE          = "SET_MESSAGE"
export const CLEAR_MESSAGE        = "CLEAR_MESSAGE"


//--------CATEGORIES--------
export const CATEGORY_LIST  = "CATEGORY_LIST"
export const CATEGORY_EDIT  = "CATEGORY_EDIT"
export const CATEGORY_BY_ID = "CATEGORY_BY_ID"
export const CATEGORY_IMAGE = "CATEGORY_IMAGE"
export const CLEAR_CATEGORY = "CLEAR_CATEGORY"


export const CLUBBED_BUYING_ORDERS = "CLUBBED_BUYING_ORDERS"


//--------MESSAGE TEMPLATES--------
export const EMAIL_LIST     = "EMAIL_LIST"
export const EMAIL_ADD      = "EMAIL_ADD"
export const EMAIL_EDIT     = "EMAIL_EDIT"
export const EMAIL_BY_NAME  = "EMAIL_BY_NAME"

export const SMS_LIST       = "SMS_LIST"
export const SMS_ADD        = "SMS_ADD"
export const SMS_EDIT       = "SMS_EDIT"
export const SMS_BY_NAME    = "SMS_BY_NAME"

export const FIREBASE_NOTIFICATION_LIST     = "FIREBASE_NOTIFICATION_LIST"
export const FIREBASE_NOTIFICATION_ADD      = "FIREBASE_NOTIFICATION_ADD"
export const FIREBASE_NOTIFICATION_EDIT     = "FIREBASE_NOTIFICATION_EDIT"
export const FIREBASE_NOTIFICATION_BY_NAME  = "FIREBASE_NOTIFICATION_BY_NAME"

export const SEND_NOTIFICATION  = "SEND_NOTIFICATION"

export const WHATSAPP_LIST      = "WHATSAPP_LIST"
export const WHATSAPP_ADD       = "WHATSAPP_ADD"
export const WHATSAPP_EDIT      = "WHATSAPP_EDIT"
export const WHATSAPP_BY_NAME   = "WHATSAPP_BY_NAME"

//--------CUSTOMER USERS--------
export const CUSTOMER_LIST          = "CUSTOMER_LIST"
export const CUSTOMER_ADD           = "CUSTOMER_ADD"
export const NEW_CUSTOMER           = "NEW_CUSTOMER"
export const EDIT_CUSTOMER          = "EDIT_CUSTOMER"
export const CUSTOMER_SEARCH        = "CUSTOMER_SEARCH"
export const AFFILIATE_SEARCH_LIST  = "AFFILIATE_SEARCH_LIST"
export const CUSTOMER_EDIT          = "CUSTOMER_EDIT"
export const USER_BY_ID             = "USER_BY_ID"
export const EXPORT_CUSTOMER_LIST   = "EXPORT_CUSTOMER_LIST"

export const CUSTOMER_DOC_LIST      = "CUSTOMER_DOC_LIST"
export const CUSTOMER_DOC_ADD       = "CUSTOMER_DOC_ADD"

export const CUSTOMER_ADDRESS        = "CUSTOMER_ADDRESS"
export const CUSTOMER_ADDRESS_ADD    = "CUSTOMER_ADDRESS_ADD"
export const CUSTOMER_ADDRESS_UPDATE = "CUSTOMER_ADDRESS_UPDATE"
export const CUSTOMER_ADDRESS_LIST   = "CUSTOMER_ADDRESS_LIST"

export const CUSTOMER_BANK_DETAIL_LIST  = "CUSTOMER_BANK_DETAIL_LIST"
export const CUSTOMER_BANK_DETAIL_ADD   = "CUSTOMER_BANK_DETAIL_ADD"
export const CUSTOMER_BANK_DETAIL_EDIT  = "CUSTOMER_BANK_DETAIL_EDIT"

//--------SELLER USERS--------
export const SELLER_LIST        = "SELLER_LIST"
export const SELLER_ADD         = "SELLER_ADD"
export const SELLER_EDIT        = "SELLER_EDIT"


//--------PRODUCTS--------
export const PRODUCT_LIST       = "PRODUCT_LIST"
export const PRODUCT_ADD        = "PRODUCT_ADD"
export const PRODUCT_BY_ID      = "PRODUCT_BY_ID"
export const PRODUCT_DELETE     = "PRODUCT_DELETE"
export const PRODUCT_SEARCH     = "PRODUCT_SEARCH"
export const CLEAR_PRODUCT      = "CLEAR_PRODUCT"
export const PRODUCT_NEW_LOG    = "PRODUCT_NEW_LOG"
export const GET_PRODUCT_LOG    = "GET_PRODUCT_LOG"
export const EXPORT_PRODUCT     = "EXPORT_PRODUCT"


//--------ATTRIBUTES--------
export const ATTRIBUTES_LIST    = 'ATTRIBUTES_LIST'
export const ATTRIBUTES_ADD     = 'ATTRIBUTES_ADD'
export const ATTRIBUTES_DELETE  = 'ATTRIBUTES_DELETE'
export const ATTRIBUTE_BY_ID    = 'ATTRIBUTE_BY_ID'
export const ATTRIBUTE_EDIT     = 'ATTRIBUTE_EDIT'

//--------DPL USERS--------
export const DPL_LIST           = "DPL_LIST"
export const DPL_BY_ID          = "DPL_BY_ID"
export const DPL_UPDATE         = "DPL_UPDATE"

//--------ORDERS--------
export const ORDER_LIST                     = "ORDER_LIST"
export const CLUBBED_BUYING_ORDER_BY_ID     = "CLUBBED_BUYING_ORDER_BY_ID"
export const CLUBBED_BUYING_ORDER_EDIT      = "CLUBBED_BUYING_ORDER_EDIT"
export const LOGS_LIST                      = "LOGS_LIST"
export const NEW_LOG                        = "NEW_LOG"
export const CLEAR_ORDER_BY_ID              = "CLEAR_ORDER_BY_ID"
export const NEW_ORDER                      = "NEW_ORDER"
export const SEARCH_ORDER                   = "SEARCH_ORDER"
export const ORDER_CHAT                     = "ORDER_CHAT"
export const EXPORT_ORDER_RECEIVING_LIST    = "EXPORT_ORDER_RECEIVING_LIST"
export const EXPORT_ORDER_INVOICING_LIST    = "EXPORT_ORDER_INVOICING_LIST"
export const REPORT_ORDER_VALUE             = "REPORT_ORDER_VALUE"
export const CLEAR_REPORT_ORDER_VALUE       = "CLEAR_REPORT_ORDER_VALUE"


//-------- SHIPPING --------
export const COUNTRY_LIST        = "COUNTRY_LIST"
export const COUNTRY_BY_ID       = "COUNTRY_BY_ID"
export const COUNTRY_ADD         = "COUNTRY_ADD"
export const COUNTRY_EDIT        = "COUNTRY_EDIT"
export const COUNTRY_DELETE      = "COUNTRY_DELETE"

export const STATE_LIST          = "STATE_LIST"
export const STATE_BY_ID         = "STATE_BY_ID"
export const STATE_ADD           = "STATE_ADD"
export const STATE_EDIT          = "STATE_EDIT"
export const STATE_DELETE        = "STATE_DELETE"

export const CITY_LIST           = "CITY_LIST"
export const CITY_BY_ID          = "CITY_BY_ID"
export const CITY_ADD            = "CITY_ADD"
export const CITY_EDIT           = "CITY_EDIT"
export const CITY_DELETE         = "CITY_DELETE"

export const PINCODE_LIST        = "PINCODE_LIST"
export const PINCODE_BY_ID       = "PINCODE_BY_ID"
export const PINCODE_ADD         = "PINCODE_ADD"
export const PINCODE_EDIT        = "PINCODE_EDIT"
export const PINCODE_DELETE          = "PINCODE_DELETE"

export const ROUTE_LIST         = "ROUTE_LIST"
export const ROUTE_BY_ID        = "ROUTE_BY_ID"
export const ROUTE_ADD          = "ROUTE_ADD"
export const ROUTE_EDIT         = "ROUTE_EDIT"
export const ROUTE_DELETE       = "ROUTE_DELETE"

export const ROUTE_PLAN_DELIVERY_LIST           = "ROUTE_PLAN_DELIVERY_LIST"
export const ROUTE_PLAN_DELIVERY_LIST_CLEAR     = "ROUTE_PLAN_DELIVERY_LIST_CLEAR"
export const ROUTE_PLAN_DELIVERY_ORDER__LIST    = "ROUTE_PLAN_DELIVERY_ORDER__LIST"
