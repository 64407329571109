import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../layouts/main.component'
import { userList, deleteUser } from '../../actions/users.action'
import { convertToArray, inputFirstLetterCapital } from '../../helpers/common-function.helper'
import UserAdd from './user-add.component'
import UserEdit from './user-edit.component'
import DeleteConfirm from '../../helpers/delete.helper'
import { roles, paginationLimit } from '../../helpers/user.helper'
import Select from 'react-select'
import { customerSearch } from '../../actions/users.action'
import { USER_BY_ID, CUSTOMER_LIST } from '../../actions/types'
import ExportUser from './export-user.component'
import { orderStatusOptions, orderPagination } from '../../helpers/order.helper'
import moment from 'moment'
import UserView from './user-view.component'
import { toast } from 'react-toastify'

const CustomerList = ({ dispatch, users, capability }) => {

  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [editUserId, setEditUserId] = useState();
  // const [showUser, setShowUser] = useState(false);
  const history                           = useHistory();
  const [search, setSearch]               = useState();
  const [loader, setLoader]               = useState(true);
  const [customerList, setCustomerList]   = useState([]);
  const [showExport, setShowExport]       = useState(false)
  const [showUserDetail, setUserDetail]   = useState(false);
  const [showUserId, setShowUserId]       = useState();
  const [searching, setSearching]         = useState(false)

  useEffect(() => {
    setCustomerList(users.customerList)
    // console.log(users.customerList," customerList");
  }, [users?.customerList])

  //LIST FILTER
  const [roleShow, setRoleShow]     = useState("customer")
  const [page, setPage]             = useState(1)
  const [limit, setLimit]           = useState(25)
  const [sortBy, setSortBy]         = useState('desc')
	const [status, setStatus]         = useState('all')
  const [pagination, setPagination] = useState([])

  useEffect(async () => setPage(1), [limit, sortBy, roleShow])
  useEffect(async () => {
    loadAndRefreshList()
  }, [page, limit, sortBy, roleShow])


  useEffect(() => {
    setPagination([])
    var paginationLength = Math.ceil(customerList?.total / limit)
    if (!paginationLength > 0) return
    Array(paginationLength).fill().map((data, i) => setPagination(page => ([
      ...page, { label: i + 1, value: i + 1 }
    ])))
  }, [customerList, users])


  //LOAD & REFRESH LIST 
  const loadAndRefreshList = async () => {
    setLoader(true)
    setSearch()
    await dispatch(userList(page, limit, sortBy, roleShow))
    setLoader(false)
    document.querySelector('#search_box').value = ''
    return
  }

  //SEARCH USER
  // useEffect(async () => {
  //   if (search == null) return
  //   setLoader(true)

	// 	const searchData = search ? JSON.parse(search):"";

  //   if(searchData?.keywords) {
	// 		await dispatch(customerSearch("q", searchData))
	// 	}	
  //   if (search.length) await dispatch(customerSearch(search, roleShow))

  //   else await loadAndRefreshList()
  //   setLoader(false)
  // }, [search])

  useEffect(async () => {
    if (!search?.length) return
    setSearching(true)

  let filterUser = {"role" : roleShow, "sort" : "desc", status: "all"};

  if (!isNaN(search)) {
    filterUser={
      ...filterUser,
      searchKey : "mobile_number",
      searchValue: search
    }
  }
  else {
    filterUser={
      ...filterUser,
      "name": search
    }
  }

    await dispatch(customerSearch("q",filterUser))
    setSearching(false)
  }, [search])

  //DELETE
  const [showDelete, setShowDelete] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [deleteName, setDeleteName] = useState()
  const [isDeleted, setIsDeleted] = useState(false)
  const [allowUpdateDelete, setAllowUpdateDelete] = useState(false)

  useEffect(() => {
    if (!allowUpdateDelete) setAllowUpdateDelete(true)
    else loadAndRefreshList()
  }, [isDeleted])

  useEffect(async () => {
    document.title = 'User List'
    document.querySelector('.table_role').classList.remove('flash')
    setTimeout(async () => document.querySelector('.table_role').classList.add('flash'), 100)
    document.querySelector('#search_box').value = ''
  }, [roleShow])

  return (
    <MainLayout>

      {/* BREADCRUMB */}
      <nav className="breadcrumb mt-3 mb-0">
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Users List</div>
        <div className="breadcrumb-item" onClick={() => history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item active">Users</div>
      </nav>

      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">
          Users <span className="badge badge-primary ml-2">{users?.customerSearch? users?.customerSearch?.length : customerList?.total}</span>
          <div className="ml-3">
            <select onChange={element => setRoleShow(element.target.value)} className='form-control' style={{ width: '150px' }}>
              {roles.map((role, i) => !capability.user.view ? capability.user.view_customer && role.name == 'customer' && <option key={i} value={role.name} selected={role.name===roleShow?role.name:""}>{role.label}</option> : <option key={i} value={role.name} selected={role.name===roleShow?role.name:""}>{role.label}</option>)}
            </select>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0 ml-2 mr-2">
            <div className="input-icon">
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span>
              <input type="search" id='search_box' onChange={e => setSearch(e.target.value)} onBeforeInput={inputFirstLetterCapital} className="form-control ht-40" placeholder="Name/Mob No." />
            </div>
          </div>

          <div className='mr-2'>
            <div className="dropdown">
						  <button type="button" className="btn btn-indigo btn-icon" data-toggle="dropdown"><i className="fe fe-filter"></i></button>
              <Filter dispatch={dispatch} setSearch={setSearch} search={search} sortBy={sortBy} setRoleShow={setRoleShow} roleShow= {roleShow} setStatus={setStatus} status={status} roles={roles} customerList={customerList}/>
            </div>
          </div>

          <button className='btn btn-outline-warning ml-2 center' onClick={() => setShowExport(true)}><i className='bx bxs-file-export mr-1'></i> Export</button>
          {capability?.user.add ? <button className="btn btn-primary text-nowrap ml-2" onClick={() => setShowAddUser(true)}> + Add User</button> : null}
          <div onClick={() => setPage(customerList.prevPage)} className={`${customerList?.prevPage == 0 || search?.length ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-2"><i className=" bx bx-chevron-left font-size-19"></i></button></div>
          <div onClick={() => setPage(customerList.nextPage)} className={`${customerList?.nextPage == 0 || search?.length ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-1"><i className=" bx bx-chevron-right font-size-19"></i></button></div>
        </div>
      </div>

      {/* USER - LIST */}
      <div className="card">

        <div className="card-body">
          <div className="userlist-table">
            {search && <div className="mb-2">Search <b className="text-warning">"{search}" <button type="button" className='btn btn-danger btn-xs btn-sm ml-3' onClick={() => window.location.reload()}>X</button></b></div>}

            <table className="table card-table table-hover category_table table-bordered stable-vcenter text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center p-0">User <span className='ml-2 badge badge-dark font-size-12 animated table_role'>{roleShow}</span></th>
                  <th className="text-center">Mobile No.</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">{roleShow=="customer" ? "Reference/Affiliate":"Manager"}</th>
                  {/* <th className="text-center">Reference / Affiliate </th> */}
                  <th className="text-center">Date</th>
                  {capability?.user.view || capability?.user.edit || capability?.user.delete ? <th className="text-center">Action</th> : null}
                </tr>
              </thead>
              <tbody>

                {customerList?.users?.length <= 0 ?
                  <>{!loader && <tr name=""><td className="text-center count_ignore" colSpan="7">No Data Available</td></tr>}</>
                  :
                  <>
                    {!loader && customerList?.users?.map((uData, index) => (
                      // console.log(uData,"udata"),
                      <tr key={index} name={uData?.first_name?.toLowerCase() + ' ' + uData?.last_name?.toLowerCase()}>
                        <td className="text-center count_ignore">{(limit * page) - (limit - 1) + index}</td>

                        <td className="text-center">
                          <div className="d-flex align-items-center">
                          
                          { uData?.profile_photo ? <img alt={uData?.first_name + ' ' + uData?.last_name} className="rounded-circle avatar-md mr-2" src={uData?.profile_photo} /> :                          
                            <img alt={uData?.first_name + ' ' + uData?.last_name} className="rounded-circle avatar-md mr-2" src="/img/1.jpg" />
                          }


                            <div className="text-left">
                              <div className="font-weight-bold text-capitalize text-wrap">{uData?.first_name + ' ' + uData?.last_name}</div>
                              <div className="mr-3 d-flex align-items-center">
                                {uData.status === 'pending' ? <span className="badge badge-warning">Pending</span> : ''}
                                {uData.status === 'in-process' ? <span className="badge badge-info">In Process</span> : ''}
                                {uData.status === 'active' ? <span className="badge badge-success">Active</span> : ''}
                                {uData.status === 'inactive' ? <span className="badge badge-secondary">In Active</span> : ''}
                                {uData.status === 'blacklist' ? <span className="badge badge-danger">Blacklist</span> : ''}
                                {uData?.invite_link && <a target='_blank' href={uData.invite_link} data-title={uData.invite_link} className="badge border title-top text-teal ml-1" >Invite Link</a>}
                              </div>
                              <span className="text-secondary" data-title='Customer Number'>CN: {uData.user_number}</span>
                              <p>latitude : {uData?.address[0]?.latitude}, longitude : {uData?.address[0]?.longitude}</p>
                            </div>
                          </div>
                        </td>

                        <td>
                          {uData.mobile_verified_at === 'true' ? <i className='bx bx-check badge d-inline-block badge-teal-transparent font-size-14 mr-2'></i> : <i className='badge d-inline-block badge-warning-transparent font-size-14 mr-2 bx bx-x'></i>}
                          {uData.mobile_number}
                        </td>

                        <td>
                          {uData.email_verified_at === 'true' ? <i className='bx bx-check badge d-inline-block badge-teal-transparent font-size-14 mr-2'></i> : <i className='badge d-inline-block badge-warning-transparent font-size-14 mr-2 bx bx-x'></i>}
                          <span className='word-break-all'>{uData.email}</span>
                        </td>

                        <td>
                          {uData?.reference &&
                            <>
                              <div>{uData?.reference?.first_name} {uData?.reference?.last_name}</div>
                              <div className='border badge title-top' data-title='User Number'>{uData?.reference?.user_number}</div>
                            </>
                          }
                        </td>

                        <td className="text-center">
                          <div className="text-secondary">{moment(uData?.createdAt).format('ll')}</div>
                          <div className="text-secondary">{moment(uData?.createdAt).utcOffset('+00:00').format('LT')}</div>
                          {/* <div className="text-secondary">{moment(uData?.createdAt).format('LT')}</div> */}
                        </td>

                        {capability?.user.view || capability?.user.edit || capability?.user.delete ?
                          <td className='text-center'>
                            <div className='btn-group'>
                              <button onClick={() => { setUserDetail(true); dispatch({ type: USER_BY_ID, payload: null }); setShowUserId(uData.id || uData._id) }} className="btn btn-sm btn-white" data-title="Show User"><i className='bx bx-show h4 m-0 text-primary'></i></button>

                              {capability?.user.edit ? <button onClick={() => { setShowEditUser(true); dispatch({ type: USER_BY_ID, payload: null }); setEditUserId(uData.id || uData._id) }} className="btn btn-sm btn-white" data-title="Edit User"><i className='bx bx-pencil h4 m-0 text-warning'></i></button> : null}
                              {capability?.user.delete ? <button onClick={() => { setShowDelete(true); setDeleteId(uData.id || uData._id); setDeleteName(uData.first_name + ' ' + uData.last_name); }} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h4 m-0 text-danger'></i></button> : null}
                            </div>
                          </td> : null}
                      </tr>
                    ))}
                  </>
                }
              </tbody>
            </table>

            {/* LOADER */}
            {loader &&
              <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
                <div>Wait <b>Buddy</b>...</div>
                <div className="spinner-grow" role="status" />
              </div>}

            {!search?.length && <Pagination addClassNameclassName="mt-3 position-relative z-index-200" page={page} setPage={setPage} sortBy={sortBy} setSortBy={setSortBy} limit={limit} setLimit={setLimit} customerList={customerList} pagination={pagination} />}

          </div>
        </div>
      </div>

      {/* USER - ADD, Show & EDIT */}
      {showAddUser && <UserAdd role={roleShow} handleComponent={setShowAddUser} loadAndRefreshList={loadAndRefreshList} />}
      {showUserDetail && <UserView role={roleShow} handleComponent={setUserDetail} id={showUserId} />}
      {showEditUser && <UserEdit roleUpdate={roleShow} handleComponent={setShowEditUser} loadAndRefreshList={loadAndRefreshList} id={editUserId} />}

      {/* DELETE */}
      {showDelete && <DeleteConfirm response={setIsDeleted} other={roleShow} role={roleShow} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={deleteUser} />}

      {/* EXPORT PRODUCT */}
      {showExport && <ExportUser handlePopup={setShowExport} />}

    </MainLayout >
  )
}

/* -------------------------------
              FILTER
----------------------------------*/
const Filter = ({dispatch, setSearch,search, roleShow, customerList, roles, status, setStatus, sortBy, setRoleShow}) => {

  const onUserFilterSubmit = async (e) => {

    e.preventDefault()
   const formData = new FormData(e.target)
   const data = {}
   for (let entry of formData.entries()) {
     data[entry[0]] = entry[1]
   } 

   setRoleShow(data?.role)

   const searchHistoryData = search ? JSON.parse(search) : "";

   await toast.promise(
     dispatch(customerSearch("q", {...searchHistoryData, ...data})),
     {
       pending: 'Searching user...',
       success: 'Search user list updated.',
       error:   {
       render(){
           return 'Searching Error!!'
       }
       }
     })
     document.querySelector('.dropdown-menu.show').classList.remove('show')
 }

  return (
    <form className="filterForm dropdown-menu pt-3 min-w-500 animated zoomIn filder-card-user" onClick={e => e.stopPropagation()} onSubmit={e => onUserFilterSubmit(e)}>
      <div className='px-3 mb-3'>

      <div className='d-flex justify-content-end'>
				<button type='button' className='btn btn-outline-danger btn-sm' onClick={() => document.querySelector('.dropdown-menu.show').classList.remove('show')}>Close</button>
				<button type='submit' className='btn btn-primary ml-2 btn-sm'>Apply</button>
			</div>

        <div className="input-group mb-3 mt-3">
				<div className="input-group-prepend">
				<select className='form-control' name="searchKey">
					<option value="user_number">User Number</option>
					<option value="mobile_number">Mobile Number</option>
					<option value="email">Email</option>
				</select>
				</div>
				<input type="text" className="form-control" name='searchValue' autoComplete='off' placeholder="Search..." />
		  	</div>

        <div className="row">
          <div className='col-md-6'>
            <div className='font-size-13 text-secondary font-weight-400 mt-1 mb-1'>Sort By</div>
            <div>
              <select className='form-control' name="sort">
                <option value="asc">Newest</option>
                <option value="desc">Oldest</option>
              </select>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='font-size-13 text-secondary font-weight-400 mb-1 mt-1'>Role</div>
            <div>
              <select className='form-control' style={{ width: '150px' }} name="role">
                  <option value="">---</option>
                  {roles.map((role,i) => (
                    <option key={i} value={role.name} selected={role.name == roleShow ? true : false}>{role.label}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className='col-md-6 mt-2'>
            <div className='font-size-13 text-secondary font-weight-400 mb-1 mt-1'>Status</div>
            <div>
            <div className="form-group">
              <select className="form-control" name="status">
                <option value="all" selected>All</option>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="inactive">InActive</option>
                <option value="blacklist">blacklist</option>
                <option value="reject">Reject</option>
                <option value="on-hold">On-hold</option>
                <option value="in-process">In-process</option>
              </select>
            </div>
            </div>
          </div>
          {/* <div className="col-md-6 mt-2">
              <div className="form-group">
              <label>{roles == "customer" ? "Reference/Affiliate" :"Manager"}</label>
                <select defaultValue={searchRefRole} name="reference" className="ml-2" onChange={(e) => setSearchRefRole(e.target.value)}>
                  {roles.map((role,i) => (
                    <option key={i} value={role.name} selected={role.name=="affiliate" ? true:false}>{role.label}</option>
                  ))}
                </select>
                </div>
              <label>{userInfo?.reference?.first_name +" "+ userInfo?.reference?.last_name}</label>
              <Select
                isMulti={false}
                // isLoading={searching}
                closeMenuOnSelect={true}
                onInputChange={e => { setSearchCustomer(e); }}
                onChange={e => { setUserInfoData(e?.value); }}
                autoFocus={true}
                options={customerSeachOption}
                className="basic-multi-select react-select"
                placeholder="Select Reference"
              />
          </div> */}
        </div>
        
      </div>
    </form>
  )
}

/* -------------------------------
          PAGINATION
----------------------------------*/
const Pagination = ({ page, setPage, sortBy, setSortBy, limit, setLimit, customerList, pagination, addClass }) => {

  return (
    <>
      <style>{`.pagination:not(.p-2) li:nth-child(${page + 1}) > *{background:#0068ff;color:white}`}</style>
      <div className={`d-flex justify-content-between align-items-center position-relative z-index-100 ${addClass}`}>
        <div className='d-flex align-items-center'>
          <select defaultValue={sortBy} onChange={e => setSortBy(e.target.value)} className='form-control mr-2'>
            <option value={'desc'}>New</option>
            <option value={'asc'}>Old</option>
          </select>
          <select defaultValue={limit} onChange={e => setLimit(e.target.value)} className='form-control'>
            {paginationLimit.map((limit, i) => <option key={i} value={limit}>{limit}</option>)}
          </select>
          <div className='font-weight-500 mr-3 text-secondary text-nowrap ml-2'>{(limit * page) - (limit - 1)}-{(limit * page) > customerList?.total ? customerList?.total : (limit * page)} of {customerList?.total}</div>
        </div>

        <div className='d-flex'>
          <button onClick={() => setPage(customerList.prevPage)} className={`btn btn-outline-primary border-light rounded-5 ${customerList?.prevPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-left'></i></button>
          <Select
            value={pagination[customerList?.currentPage - 1]}
            onChange={e => setPage(e.label)}
            options={pagination}
            menuPlacement='top'
            placeholder='Page'
            className='mx-2 react-select pagination-select react-select'
          />
          <button onClick={() => setPage(customerList.nextPage)} className={`btn btn-outline-primary border-light rounded-5 ${customerList?.nextPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-right'></i></button>
        </div>
      </div>
    </>
  )
}


function mapStateToProps(state) {
  const { users }                 = state.users

  let capability                  = state.auth.currentUser?.capability
  capability                      = capability ? JSON.parse(capability) : null

  return {
    users,
    capability,
  }
}

export default connect(mapStateToProps)(CustomerList);