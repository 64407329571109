import React,{useState, useEffect} from "react";
import axios from "axios";
import {updateCategory} from '../../actions/category.action'
import { toast } from 'react-toastify';
import { SET_MESSAGE } from "../../actions/types"
import {category_type} from '../../helpers/category.helper'
import Select from 'react-select'
import {getCityList, getPincodeList ,getStateList} from '../../actions/shipping.action'
import { connect } from "react-redux";

const CategoryEdit = ({category,formHandle,categories, dispatch, btnLoader, setBtnLoader,stateList ,cityList, pincodeList}) =>{

  const [imageUrl, setImageUrl]                           = useState(category?.image);// IMAGE
  const [option, setOption]                               = useState()
  const [defaultParentCategory, setDefaultParentCategory] = useState()
  const [parentCatEdit, setParentCatEdit]                 = useState('')
  const [loader, setLoader]                               = useState(true)

  useEffect(() => {
    if(category)setParentCatEdit(category.parent)
    if(!categories || !category || !option) return
    var defaultOption = categories.filter(data => data.id == category.parent)
    if(defaultOption.length){
      setDefaultParentCategory({label: option.filter(data => data.value == defaultOption[0].id)[0].label, value:defaultOption[0].id})
    }else{
      setDefaultParentCategory(true)
    }
  },[categories, category, option])

// COUNTRY
const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
const [addressStateId, setAddressStateId]               	= useState([]);

  useEffect(async () => {
    onGetState(addressCountryId)
    // onGetCity(addressStateId)
    setLoader(false)
  }, [])

  const onGetState = async () => {
    // setAddressCountryId(countryId)
    dispatch(getStateList(addressCountryId))
  }

  const onGetCity = (stateId) => {
    // setAddressStateId(stateId)
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }

  //FORMATE DROPDOWN OPTIONS
  useEffect(() => {
    if(!categories) return
    var formateOptions = [];
    categories.map((parentCategory) => {
      if(parentCategory.parent == '' || parentCategory.parent == 0){ //PARENT CATEGORY
        formateOptions.push({label:`${parentCategory.name}`, value: parentCategory.id})
        
        categories.map((subCategory,i) => { //SUB-CATEGORY
          if(subCategory.parent == parentCategory.id){
            formateOptions.push({label:` -- ${subCategory.name}`, value: subCategory.id})

            categories.map((catData) => { //LEAF
              if(catData.parent == subCategory.id){
                formateOptions.push({label:` ----- ${catData.name}`, value: catData.id})

                categories.map((other) => { //OTHER
                  if(other.parent == catData.id){
                    formateOptions.push({label:` --------- ${other.name}`, value: other.id})
                  }
                })
              }
            })
          }
        })
      }
    })
    setOption(formateOptions)
  }, [categories])
  

  // IMAGE UPLOAD
  const uploadF = (selectorFiles) => {
    var data = new FormData();
    data.append('file', selectorFiles[0]);
    data.append('foldername', 'category');
    axios.post("/file/upload", data)      
      .then((response) => {
        setImageUrl(response.data);
      }).catch(err => {
        dispatch({
          type:SET_MESSAGE,
          payload:err.response.data
        })
      })
  }

  // UPDATE CATEGORY
  const onSubmitEditCategory = async (e) => { 
    e.preventDefault()
    const formData = new FormData(e.target)
    formData.append('parent', parentCatEdit)
    const data = {}
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 
    // data['for_available'] = ["226024", "226010"]

    const bodyData = {
      name     : data.name,
      status   : data.status,
      position : data.position,
      image    : data.image,
      type     : data.type,
      parent   : parentCatEdit,

      for_available :{
        country  : ["India",],
        state    : [data.state],
        city     : [data.city],
        pincode  : [data.pincode]        
      }
    }

    await toast.promise(
			dispatch(updateCategory(category.id,bodyData)),
			{
				pending: 'Category updating...',
				success: 'Successfully! Category Updated',
				error: {
          render(){
            setBtnLoader(false)
            return 'Category not updated'
          }
        }
			}
		)
    
    setImageUrl(null)
    setBtnLoader(false)
    await formHandle(false);
  }

  return(
    <>
    {category &&
      <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
        <div className="modal-dialog modal-dialog-right" role="document">
          <form onSubmit={ (e) => {setBtnLoader(true); onSubmitEditCategory(e)}} className="modal-content modal-content-demo">
            <div className="modal-header">
              <h6 className="modal-title">Edit Category</h6>
              <button aria-label="Close" onClick={() => formHandle(false)} className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              {/* CATEGORY NAME */}
              <div className="input_wrap w-100 ">
                <input name="name" type="text" defaultValue={category.name} placeholder=" "/>
                <label>Name</label>
              </div>

              <div className="input_wrap w-100 mt-3">
                <input name="position" type="number" defaultValue={category.position} placeholder=" "/>
                <label>Position</label>
              </div>

              {/* State */}
                <div className='row mt-3'>
                  <div className='col-md-4'>
                    <div className="form-group">
                    <label className="main-content-label tx-11">State</label> 
                      <select className="form-control" name="state" id="status" onChange={(e) => {setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
                        <option selected>Select State</option>
                        {stateList?.data?.map((state, i) => (
                          <option key={i} value={state?.name} name={state?.id}>{state?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                    <label className="main-content-label tx-11">City</label> 
                      <select className="form-control" name="city" id="status" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                        <option>Select City</option>
                        {cityList?.data?.map((data, i) => (
                          <option key={i} value={data.name} name={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className="form-group">
                    <label className="main-content-label tx-11">Pincode</label> 
                    <select className="form-control" name="pincode">
                      <option selected>Select Pincode</option>
                      {pincodeList?.data?.map((data, i) => (
                        <option key={i} value={data.pincode}>{data.pincode}</option>
                      ))}
                    </select>
                    </div>
                  </div>
                </div>
              {/* PARENT CATEGORY */}
              <div className="form-group mt-3"> 
                <label className="main-content-label tx-11">Parent Category</label>
                  {category && option && defaultParentCategory &&
                  <Select
                    defaultValue={defaultParentCategory}
                    options={option}
                    isClearable={true}
                    onChange={e => setParentCatEdit(e?.value ? e.value : '')}
                  />}
              </div> 

              <div className="form-group"> 
                <label className="main-content-label tx-11">Category Type</label> 
                <select defaultValue={category?.type} name="type" className="form-control text-capitalize">
                  <option value=''>Select category type ...</option>
                  {category_type.map(type => (
                    <option value={type}>{type}</option>
                  ))}
                </select>
              </div>

              <div className="form-group"> 
                <label className="main-content-label tx-11">Status</label> 
                <select defaultValue={category?.status} name="status" className="form-control">
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>

               {/* IMAGE */}
               <div className="form-group"> 
                <label className="main-content-label tx-11">Image</label> 
                <input name="image" defaultValue={imageUrl} type="hidden" />
                <input className="form-control" type="file" onChange={(e)=> uploadF(e.target.files)} /> 
                {imageUrl ? <><hr/> <img src={imageUrl} alt="hashtag bazaar" className="rounded wd-100p wd-sm-200" /></> : ''}
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => formHandle(false)}>Close</div>
              <button disabled={btnLoader} className="btn ripple btn-primary" type="submit">
                {!btnLoader ? 'Save changes' : <span className="spinner-border spinner-border-sm mx-5"></span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    }
    </>
  )
}

function mapStateToProps(state) {
  const { countryList, stateList, cityList, pincodeList } = state.shipping

  return {
    countryList,
    pincodeList,
    stateList,
    cityList
  };
}

export default connect(mapStateToProps) (CategoryEdit);