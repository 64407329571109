import axios from "axios";


class shippingService {

  //Country 
  async countryList(sort = 'DESC', status = 'all', start = 1, limit = 10) {
    return axios.get(`/shipping/country?sort=${sort}&status=${status}&start=${start}&limit=${limit}`).then(response => response.data)
  }

  async addCountry(data) {
    return axios.post(`/shipping/country`, data).then(response => response.data)
  }

  async editCountry(data, id) {
    return axios.put(`/shipping/country/${id}`, data).then(response => response.data)
  }

  async countryById(id) {
    return axios.get(`/shipping/country/${id}`).then(response => response.data)
  }

  async countryDelete(id) {
    return axios.delete(`/shipping/country/${id}`).then(response => response.data)
  }

  //State
  async stateList(country, sort = 'DESC', status = 'all', start = 1, limit = 10) {
    return axios.get(`/shipping/state/?country=${country}&sort=${sort}&status=${status}&start=${start}&limit=${limit}`).then(response => response.data)
  }

  async addState(data) {
    return axios.post(`/shipping/state`, data).then(response => response.data)
  }

  async editState(data, id) {
    return axios.put(`/shipping/state/${id}`, data).then(response => response.data)
  }

  async stateById(id) {
    return axios.get(`/shipping/state/${id}`).then(response => response.data)
  }

  async stateDelete(id) {
    return axios.delete(`/shipping/state/${id}`).then(response => response.data)
  }

  //City
  async cityList(country, state, sort = 'DESC', status = 'all', start = 1, limit = 10) {
    return axios.get(`/shipping/city?country=${country}&state=${state}&sort=${sort}&status=${status}&start=${start}&limit=${limit}`).then(response => response.data)
  }

  async addCity(data) {
    return axios.post(`/shipping/city`, data).then(response => response.data)
  }

  async editCity(data, id) {
    return axios.put(`/shipping/city/${id}`, data).then(response => response.data)
  }

  async cityById(id) {
    return axios.get(`/shipping/city/${id}`).then(response => response.data)
  }

  async cityDelete(id) {
    return axios.delete(`/shipping/city/${id}`).then(response => response.data)
  }

    //PINCODE
    async pincodeList(country, state, city, sort = 'ASC', status = 'all', start = 1, limit = 50) {
      return axios.get(`/shipping/pincode?country=${country}&state=${state}&city=${city}&sort=${sort}&status=${status}&start=${start}&limit=${limit}`).then(response => response.data)
    }

    async addPincode(data) {
      return axios.post(`/shipping/pincode`, data).then(response => response.data)
    }

    async editPincode(data, id) {
      return axios.put(`/shipping/pincode/${id}`, data).then(response => response.data)
    }

    async pincodeById(id) {
      return axios.get(`/shipping/pincode/${id}`).then(response => response.data)
    }

    async pincodeDelete(id) {
      return axios.delete(`/shipping/pincode/${id}`).then(response => response.data)
    }

    //ROUTE
    async routeList( start = 1, limit = 50, sort = 'DESC', status = 'all',) {
      return axios.get(`/shipping/route?sort=${sort}&status=${status}&start=${start}&limit=${limit}`).then(response => response.data)
    }
  
    async addRoute(data) {
      return axios.post(`/shipping/route`, data).then(response => response.data)
    }
  
    async editRoute(data, id) {
      return axios.put(`/shipping/route/${id}`, data).then(response => response.data)
    }
  
    async routeById(id) {
      return axios.get(`/shipping/route/${id}`).then(response => response.data)
    }
  
    async routeDelete(id) {
      return axios.delete(`/shipping/route/${id}`).then(response => response.data)
    }

    // ROUTE PLAN
    async getDeliveryRoutePlan(country, state, city) {
      return axios.get(`/shipping/get-delivery-route-list?country=${country}&state=${state}&city=${city}`).then(response => response.data)
    }

    async getDeliveryRoutePlanOrder(routeDeliveryId) {
      return axios.get(`/shipping/get-delivery-order/${routeDeliveryId}`).then(response => response.data)
    }


}


export default new shippingService()