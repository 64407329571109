import axios from "axios"

class usersService {

  //------------- USER
  async userList(start = 0, limit = 25, sortBy = 'desc', roleShow = 'customer'){       
    return axios.get(`/users/list/${roleShow}?limit=${limit}&sort=${sortBy}&start=${start}`).then(r => r.data) 
  }

  async userById(id){         return axios.get("/users/"+id).then(r => r.data)        }
  async addUser(data){        return axios.post("/users", data).then(r => r.data)     }
  async updateUser(id, data){ return axios.put("/users/"+id, data).then(r => r.data)  }
  async deleteUser(Id){       return axios.delete("/users/"+Id).then(r => r.data)     }

  async searchCustomer(q, filterUser){ 
    return axios.get(`/users/search/${q}?sort=${filterUser?.sort ? filterUser?.sort : ""}&start=1&limit=10&UserSearch[role]=${filterUser?.role ? filterUser?.role : ""}&UserSearch[name]=${filterUser?.name ? filterUser?.name : ""}&UserSearch[status]=${filterUser?.status ? filterUser?.status : ""}&UserSearch[${filterUser?.searchKey ? filterUser?.searchKey : ""}]=${filterUser?.searchValue ? filterUser?.searchValue : ""}`).then(r => r.data.users) 
  }

  async customerAddress(id){          return axios.get("/users/address/"+id).then(r => r.data)}
  async customerAddressAdd(data){     return axios.post("/users/address", data).then(r => r.data) }
  async customerAddressUpdate(id, data){ return axios.put("/users/address/"+id, data).then(r => r.data) }
  async customerAddressList(userId){  return axios.get(`/users/address/list/${userId}`).then(r => r.data) }
  async customerAddressDelete(Id){    return axios.delete("/users/address/"+Id).then(r => r.data)     }

  //------------- DOCUMENTS
  async userDocList(userId){       
    return axios.get(`/users/document/list/${userId}`).then(r => r.data) 
  }

  async userDocAdd(data){     return axios.post("/users/document", data).then(r => r.data)     }
  async userDocDelete(Id){    return axios.delete("/users/document/"+Id).then(r => r.data)     }


  async userBankDetailList(userId){     return axios.get("/users/bank-details/list/"+userId).then(r => r.data) }
  async userBankDetailAdd(data)  {      return axios.post("/users/bank-details", data).then(r => r.data)        }
  async userBankDetailUpdate(id,data)  {return axios.put("/users/bank-details"+id, data).then(r => r.data)     }
  async userBankDetailDelete(id){       return axios.delete("/users/bank-details/"+id).then(r => r.data)     }
  
  //---------------- FOR SELLER
  async sellerList() {         return axios.get("/users/seller").then(r => r.data)           }
  async addSeller(data){       return axios.post("/users", data).then(r => r.data)           }
  async updateSeller(id,data){ return axios.put("/users/seller/"+id, data).then(r => r.data) }
  async deleteSeller(id){      return axios.delete("/users/seller/"+id).then(r => r.data)    }

}
export default new usersService()
