import { ORDER_LIST, CLUBBED_BUYING_ORDER_BY_ID, EXPORT_ORDER_RECEIVING_LIST, EXPORT_ORDER_INVOICING_LIST,SEARCH_ORDER, LOGS_LIST, ORDER_CHAT,REPORT_ORDER_VALUE} from "./types"
import ordersService from "../services/orders.service"
import { handleErrors } from "../helpers/common-function.helper"


export const clubbedBuyOrderList = (page, limit, sortBy, status) => async (dispatch) => { //ORDER LIST
  return ordersService.orderList(page, limit, sortBy, status).then(
    (data) => { dispatch({ type: ORDER_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const exportOrderReceiving = (query, filterOrder) => async (dispatch) => { //ORDER LIST
  return ordersService.searchOrder(query, filterOrder).then(
    (data)  => { dispatch({ type: EXPORT_ORDER_RECEIVING_LIST, payload: data })},
    (error) => handleErrors(error, dispatch)
  )
}

//ORDER LIST
export const exportOrderInvoicing = (query, filterOrder) => async (dispatch) => { 
  return ordersService.searchOrder(query, filterOrder).then(
    (data)  => { dispatch({ type: EXPORT_ORDER_INVOICING_LIST, payload: data })},
    (error) => handleErrors(error, dispatch)
  )
}


export const clubbedBuyEditOrder = (id, data) => async (dispatch) => { //ORDER EDIT
  return ordersService.editOrder(id, data).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const getOrderById = (id) => async (dispatch) => { //ORDER BY ID
  return ordersService.getOrderById(id).then(
    (data)  => { dispatch({ type: CLUBBED_BUYING_ORDER_BY_ID, payload: data, }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const clubbedBuyDeleteSingleOrderProduct = (orderId,itemId) => async (dispatch) => { //ORDER SINGLE PRODUCT DELETE
  return ordersService.clubbedBuyDeleteSingleOrderProduct(orderId,itemId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const deleteOrder = (orderId,itemId) => async (dispatch) => { //ORDER SINGLE PRODUCT DELETE
  return ordersService.deleteOrder(orderId,itemId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const logsList = (orderId) => async (dispatch) => { //LOG LIST
  return ordersService.logsList(orderId).then(
    (data)  => { dispatch({ type: LOGS_LIST, payload: data }) },
    (error) => handleErrors(error, dispatch)
  )
}

export const newLog = (data) => async (dispatch) => { //NEW LOG
  return ordersService.newLog(data).then(
    (res)   => {dispatch(logsList(data.orderId))},
    (error) => handleErrors(error, dispatch)
  )
}

export const newOrder = (data) => async (dispatch) => { //NEW ORDER
  return ordersService.newOrder(data).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

//SEARCH ORDER
export const searchOrder = (query, filterOrder) => async (dispatch) => { 
  return ordersService.searchOrder(query,filterOrder).then(
    (data)  => {dispatch({type: SEARCH_ORDER,payload: data.orders})},
    (error) => handleErrors(error, dispatch)
  )
}

export const regenerateInvoice = (orderId) => async (dispatch) => { //REGENERATE INVOICE
  return ordersService.regenerateInvoice(orderId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const resendNotification = (orderId) => async (dispatch) => { //REGENERATE INVOICE
  return ordersService.resendNotification(orderId).then(
    (data)  => {},
    (error) => handleErrors(error, dispatch)
  )
}

export const reportOrderValues = (orderValue) => async (dispatch) => { //ORDER VALUE
  return ordersService.reportOrderValue(orderValue).then(
    (data)  => {dispatch({type: REPORT_ORDER_VALUE, payload: data})},
    (error) => handleErrors(error, dispatch)
  )
}
