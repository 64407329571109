import { ORDER_LIST, EXPORT_ORDER_RECEIVING_LIST,EXPORT_ORDER_INVOICING_LIST, CLEAR_ORDER_BY_ID, SEARCH_ORDER, CLUBBED_BUYING_ORDER_EDIT, CLUBBED_BUYING_ORDER_DELETE, SET_MESSAGE, CLUBBED_BUYING_ORDER_BY_ID, LOGS_LIST, REPORT_ORDER_VALUE,CLEAR_REPORT_ORDER_VALUE } from "../actions/types"
 
const initialValue = {
  clubbedBuyOrderList         : null,
  clubbedBuyOrderById         : null,
  clubbedBuyOrderSearch       : null,
  clubbedBuyLogsList          : null,
  exportOrderReceivingList    : null,
  exportOrderInvoicingList    : null,
  reportOrderValue            : null,
}

export default function foo(state = initialValue, action){
  const {type, payload} = action
  
  switch(type){
    case ORDER_LIST:
      return{
        ... state,
        clubbedBuyOrderList: payload
      }
    case EXPORT_ORDER_RECEIVING_LIST:
      return{
        ... state,
        exportOrderReceivingList: payload
      }
      case EXPORT_ORDER_INVOICING_LIST:
      return{
        ... state,
        exportOrderInvoicingList: payload
      }
    case CLUBBED_BUYING_ORDER_BY_ID:
      return{
        ...state,
        clubbedBuyOrderById: payload
      }
    case LOGS_LIST:
      return{
        ...state,
        clubbedBuyLogsList: payload
      }
    case CLEAR_ORDER_BY_ID:
      return{
        ... state,
        clubbedBuyOrderById: null
      }
    case SEARCH_ORDER:
      return{
        ... state,
        clubbedBuyOrderSearch: payload
      }
      case REPORT_ORDER_VALUE:
      return{
        ... state,
        reportOrderValue: payload
      }
      case CLEAR_REPORT_ORDER_VALUE:
      return{
        ... state,
        reportOrderValue: null
      }
    default:
      return state
  }
}