import { useEffect, useState } from 'react'
import MainLayout from '../../layouts/main.component'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import CountryAdd from './country-add.component'
import CountryEdit from './country-edit.component'
import { COUNTRY_BY_ID, STATE_BY_ID, STATE_LIST, CITY_BY_ID, PINCODE_BY_ID } from '../../../actions/types'
import DeleteConfirm from '../../../helpers/delete.helper'
import { getStateList, stateDelete, getCountryList, countryDelete, getCityList, cityDelete, getPincodeList, pincodeDelete } from '../../../actions/shipping.action'
import StateEdit from './state-edit.component'
import StateAdd from './state-add.component'
import CityAdd from './city-add.component'
import CityEdit from './city-edit.component'
import PincodeAdd from './pincode-add.component'
import PincodeEdit from './pincode-edit.component'


function ShippingIndex({ dispatch, countryList, stateList, cityList, pincodeList, capability }) {

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }
  const history = useHistory()
  const [isMounted, setIsMounted] = useState(false)
  const [pageSetting, setPageSetting] = useState(initialPageSetting)
  const [loader, setLoader] = useState(true)
  const [showAddCountry, setShowAddCountry] = useState(false)
  const [showEditCountry, setShowEditCountry] = useState(false)
  const [editCountryId, setEditCountryId] = useState('')
  const [selectCountry, setSelectCountry] = useState('')
  const [selectState, setSelectState] = useState('')
  const [selectCity, setSelectCity] = useState('')
  const [selectPincode, setSelectPincode] = useState('')

  useEffect(async () => {
    document.title = 'Shipping | Country List'
    await dispatch(getCountryList(initialPageSetting.sort, initialPageSetting.status))
    setLoader(false)
  }, [])

  async function refreshList() {
    toast.loading('Refreshing Country List...', { toastId: 'refreshingCountry' })
    await dispatch(getCountryList(pageSetting.sort, pageSetting.status))
    toast.dismiss('refreshingCountry')
  }

  useEffect(() => {
    if (selectCountry) document.querySelector('.state-tab-heading').click()
  }, [selectCountry])

  useEffect(() => {
    if (!isMounted) return
    setSelectCity('')
  }, [selectState])


  //DELETE
  const [showDelete, setShowDelete] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [deleteName, setDeleteName] = useState()
  const [isDeleted, setIsDeleted] = useState(false)

  useEffect(() => {
    if (!isMounted) return
    refreshList()
  }, [isDeleted, pageSetting])



  useEffect(() => setIsMounted(true), [])
  return (
    <MainLayout>

      {/* BREADCRUMB */}
      <nav className="breadcrumb mt-3 mb-0">
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Country List</div>
        <div className="breadcrumb-item" onClick={() => history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item active">Country</div>
      </nav>

      <div className="row row-sm mt-4 ">
        <div className={`col-md-4`}>
          <div className="custom-card">
            <div className="">
              <div className="main-content-app main-content-contacts pt-0">
                <div className="card main-content-left main-content-left-contacts">

                  {/* TAB HEADING */}
                  <div className="tab-menu-heading">
                    <div className="tabs-menu1 ">
                      <ul className="nav panel-tabs main-nav-line main-nav-line-chat  pl-3 d-flex flex-nowrap">
                        <li>
                          <a href="#all-country" className="nav-link active d-flex" data-toggle="tab" onClick={() => { setSelectCountry(''); setSelectState(''); setSelectCity(''); dispatch({ type: STATE_LIST, payload: null }) }}>
                            Country <span className={`badge badge-primary-transparent ml-1 ${selectCountry && 'visibility-hidden'}`}>{countryList?.total}</span>
                          </a>
                        </li>
                        {selectCountry &&
                          <li className='slideInRight animated'>
                            <a href="#states" className="nav-link state-tab-heading d-flex" data-toggle="tab">
                              States <span className={`badge badge-primary-transparent ml-1`}>{stateList?.total}</span>
                            </a>
                          </li>}
                      </ul>
                    </div>
                  </div>

                  {/* COUNTRY & CITY */}
                  <div className="panel-body tabs-menu-body p-0">
                    <div className="tab-content overflow-unset">

                      {!selectCountry &&
                        <div className='center filter_btn'>
                          {capability?.shipping_setting.filter ? 
                          <div className="dropdown">
                            <button type="button" className="btn btn-outline-primary filter_button py-1 px-2 dropdown-toggle dropdown-toggle-hide" data-toggle="dropdown">
                              <i className="fe fe-filter"></i> Filter
                            </button>
                            <Filter pageSetting={pageSetting} setPageSetting={setPageSetting} />
                          </div>
                           : null}
                          {capability?.shipping_setting.add ? <button className='btn btn-primary py-1 px-2 ml-2' onClick={() => setShowAddCountry(true)}><i className="fe fe-plus"></i> Add</button> :null}
                        </div>
                      }

                      {/* COUNTRY TAB */}
                      <div className="tab-pane active" id="all-country">
                        <div className="main-contacts-list">
                          {loader ?
                            <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
                              <div>Country <b>Loading</b>...</div>
                              <div className="spinner-grow" role="status" />
                            </div>
                            :
                            countryList?.data?.length ? countryList?.data?.map((data, index) => (
                              <ContryListItem
                                data={data}
                                dispatch={dispatch}
                                key={index}
                                index={index}
                                setShowEditCountry={setShowEditCountry}
                                setEditCountryId={setEditCountryId}
                                setShowDelete={setShowDelete}
                                setDeleteId={setDeleteId}
                                setDeleteName={setDeleteName}
                                setSelectCountry={setSelectCountry}
                                selectCountry={selectCountry}
                                capability={capability}
                              />
                            ))
                              :
                              <div className='p-4 center'>No Country Available</div>
                          }

                        </div>
                      </div>

                      {/* STATE TAB */}
                      <div className="tab-pane" id="states">
                        {selectCountry &&
                          <StateTab
                            id={selectCountry}
                            dispatch={dispatch}
                            stateList={stateList}
                            setSelectState={setSelectState}
                            selectState={selectState} 
                            capability={capability}
                            />}
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CITY */}
        {selectState &&
          <div className={`col-md-4`}>
            <div className="custom-card">
              <div className="">
                <div className="main-content-app main-content-contacts pt-0">
                  <div className="card main-content-left main-content-left-contacts">

                    {/* TAB HEADING */}
                    <div className="tab-menu-heading">
                      <div className="tabs-menu1 ">
                        <ul className="nav panel-tabs main-nav-line main-nav-line-chat  pl-3 d-flex flex-nowrap">
                          <li>
                            <a href="#city" className="nav-link active d-flex" data-toggle="tab">
                              City <span className={`badge badge-primary-transparent ml-1`}>{cityList?.total}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* TAB CONTENT */}
                    <div className="panel-body tabs-menu-body p-0">
                      <div className="tab-content overflow-unset">

                        <div className="tab-pane active" id="city">
                          <CityTab
                            state={selectState}
                            country={selectCountry}
                            dispatch={dispatch}
                            cityList={cityList}
                            setSelectCity={setSelectCity}
                            selectCity={selectCity}
                            selectState={selectState}
                            capability={capability}
                          />
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>}

        {/* PINCODE */}
        {selectCity &&
          <div className={`col-md-4`}>
            <div className="custom-card">
              <div className="">
                <div className="main-content-app main-content-contacts pt-0">
                  <div className="card main-content-left main-content-left-contacts">

                    {/* TAB HEADING */}
                    <div className="tab-menu-heading">
                      <div className="tabs-menu1 ">
                        <ul className="nav panel-tabs main-nav-line main-nav-line-chat  pl-3 d-flex flex-nowrap">
                          <li>
                            <a href="#city" className="nav-link active d-flex" data-toggle="tab">
                              Pincode <span className={`badge badge-primary-transparent ml-1`}>{pincodeList?.total}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* TAB CONTENT */}
                    <div className="panel-body tabs-menu-body p-0">
                      <div className="tab-content overflow-unset">

                        <div className="tab-pane active" id="city">
                          <PincodeTab
                            state={selectState}
                            country={selectCountry}
                            city={selectCity}
                            dispatch={dispatch}
                            pincodeList={pincodeList}
                            setSelectPincode={setSelectPincode}
                            selectPincode={selectPincode}
                            selectCity={selectCity}
                            capability={capability}
                          />
                        </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>}
      </div>
      
      {showAddCountry && <CountryAdd refreshList={refreshList} handleComponent={setShowAddCountry} />}
      {showEditCountry && <CountryEdit refreshList={refreshList} handleComponent={setShowEditCountry} id={editCountryId} />}
      {showDelete && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={countryDelete} />}
      
      </MainLayout>
  )
}

/* -------------------------------
          COUNTRY LIST ITEM
----------------------------------*/
const ContryListItem = ({ data, dispatch, index, setShowEditCountry, setSelectCountry, selectCountry, setEditCountryId, setShowDelete, setDeleteId, setDeleteName, capability }) => {
  console.log(capability )
  return (
    <div className={`main-contact-item ${selectCountry == data.id && 'selected'}`} onClick={() => setSelectCountry(data.id)}>
      <div className="main-contact-body ml-0">
        <h6 title='Country Name'>{data.name} - <span className='text-secondary'>{data.short_name}</span></h6>
        <span className={`badge text-capitalize mr-2 ${data.status == 'active' ? 'badge-teal-transparent text-teal' : 'badge-light text-secondary'}`}>{data.status}</span>
        <span className="phone" title='Phone Code'>+{data.phone_code}</span>
      </div>
      <div className="main-contact-star center" onClick={e => e.stopPropagation()}>
        {capability?.shipping_setting?.edit ? <i className="fe fe-edit-2 px-2" onClick={() => { dispatch({ type: COUNTRY_BY_ID, payload: null }); setEditCountryId(data.id); setShowEditCountry(true); }}></i> : null}
        {capability?.shipping_setting?.delete ?  <i className="fe fe-trash border-left pl-2" onClick={() => { setShowDelete(true); setDeleteId(data.id); setDeleteName(data.name) }}></i>: null }
      </div>
    </div>
  )
}


/* -------------------------------
          STATE TAB
----------------------------------*/
const StateTab = ({ id, dispatch, stateList, setSelectState, selectState ,capability}) => {

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }
  const [pageSetting, setPageSetting] = useState(initialPageSetting)
  const [loader, setLoader] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [actionId, setActionId] = useState('')

  useEffect(async () => {
    await dispatch(getStateList(id, initialPageSetting.sort, initialPageSetting.status))
    setLoader(false)
  }, [])

  async function refreshList() {
    toast.loading('Refreshing State List...', { toastId: 'refreshingState' })
    await dispatch(getStateList(id, pageSetting.sort, pageSetting.status))
    toast.dismiss('refreshingState')
  }

  //DELETE
  const [showDelete, setShowDelete] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [deleteName, setDeleteName] = useState()
  const [isDeleted, setIsDeleted] = useState(false)

  useEffect(() => {
    if (!isMounted) return
    refreshList()
  }, [isDeleted, pageSetting])


  useEffect(() => setIsMounted(true), [])
  return (
    <>
      <div className="main-contacts-list">
        <div className='center filter_btn'>
        {capability?.shipping_setting.filter ? 
          <div className="dropdown">
            <button type="button" className="btn btn-outline-primary filter_button py-1 px-2 dropdown-toggle dropdown-toggle-hide" data-toggle="dropdown">
              <i className="fe fe-filter"></i> Filter
            </button>
            <Filter pageSetting={pageSetting} setPageSetting={setPageSetting} />
          </div>
          : null}
          {capability?.shipping_setting.add ? <button className='btn btn-primary py-1 px-2 ml-2' onClick={() => setShowAdd(true)}><i className="fe fe-plus"></i> Add</button> : null}
        </div>

        {loader ?
          <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
            <div>State <b>Loading</b>...</div>
            <div className="spinner-grow" role="status" />
          </div>
          :
          stateList?.data?.length ? stateList?.data?.map((data, index) => (
            <StateListItem
              data={data}
              dispatch={dispatch}
              key={index}
              index={index}
              setShowEdit={setShowEdit}
              setActionId={setActionId}
              setShowDelete={setShowDelete}
              setDeleteId={setDeleteId}
              setDeleteName={setDeleteName}
              setSelectState={setSelectState}
              selectState={selectState}
              capability={capability}
            />
          ))
            :
            <div className='p-4 center'>No State Available</div>
        }
      </div>

      {showAdd && <StateAdd refreshList={refreshList} country={id} handleComponent={setShowAdd} />}
      {showEdit && <StateEdit refreshList={refreshList} country={id} handleComponent={setShowEdit} id={actionId} />}
      {showDelete && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={stateDelete} />}
    </>
  )
}



/* -------------------------------
          STATE LIST ITEM
----------------------------------*/
const StateListItem = ({ data, dispatch, index, setShowEdit, setActionId, setShowDelete, setDeleteId, setDeleteName, setSelectState, selectState,capability }) => {
  return (
    <div className={`main-contact-item ${selectState == data.id && 'selected'}`} onClick={() => { setSelectState(data.id); }}>
      <div className="main-contact-body ml-0">
        <h6 title='Country Name'>{data.name} - <span className='text-secondary'>{data.short_name}</span></h6>
        <span className={`badge text-capitalize ${data.status == 'active' ? 'badge-teal-transparent text-teal' : 'badge-light text-secondary'}`}>{data.status}</span>
      </div>
      
      <div className="main-contact-star center" onClick={e => e.stopPropagation()}>
        {capability?.shipping_setting?.edit ?<i className="fe fe-edit-2 px-2" onClick={() => { dispatch({ type: STATE_BY_ID, payload: null }); setActionId(data.id); setShowEdit(true) }}></i> : null }
        {capability?.shipping_setting?.delete ?<i className="fe fe-trash border-left pl-2" onClick={() => { setShowDelete(true); setDeleteId(data.id); setDeleteName(data.name) }}></i> : null }
      </div>
    </div>
  )
}



/* -------------------------------
              CITY TAB
----------------------------------*/
const CityTab = ({ state, country, dispatch, cityList, setSelectCity, selectCity, selectState,capability }) => {

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }
  const [pageSetting, setPageSetting] = useState(initialPageSetting)
  const [loader, setLoader] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [actionId, setActionId] = useState('')

  useEffect(async () => {
    setLoader(true)
    await dispatch(getCityList(country, state, initialPageSetting.sort, initialPageSetting.status))
    setLoader(false)
  }, [selectState])

  async function refreshList() {
    toast.loading('Refreshing City List...', { toastId: 'refreshingCity' })
    await dispatch(getCityList(country, state, pageSetting.sort, pageSetting.status))
    toast.dismiss('refreshingCity')
  }

  //DELETE
  const [showDelete, setShowDelete] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [deleteName, setDeleteName] = useState()
  const [isDeleted, setIsDeleted] = useState(false)

  useEffect(() => {
    if (!isMounted) return
    refreshList()
  }, [isDeleted, pageSetting])


  useEffect(() => setIsMounted(true), [])
  return (
    <>
      <div className="main-contacts-list">
        <div className='center filter_btn'>
        {capability?.shipping_setting.filter ? 
          <div className="dropdown">
            <button type="button" className="btn btn-outline-info filter_button py-1 px-2 dropdown-toggle dropdown-toggle-hide" data-toggle="dropdown">
              <i className="fe fe-filter"></i> Filter
            </button>
            <Filter pageSetting={pageSetting} setPageSetting={setPageSetting} />
          </div>
        : null}
        {capability?.shipping_setting?.add ? <button className='btn btn-primary py-1 px-2 ml-2' onClick={() => setShowAdd(true)}><i className="fe fe-plus"></i> Add</button>:null}
        </div>

        {loader ?
          <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
            <div>State <b>Loading</b>...</div>
            <div className="spinner-grow" role="status" />
          </div>
          :
          cityList?.data?.length ? cityList?.data?.map((data, index) => (
            <CityListItem
              data={data}
              dispatch={dispatch}
              key={index}
              index={index}
              setShowEdit={setShowEdit}
              setActionId={setActionId}
              setShowDelete={setShowDelete}
              setDeleteId={setDeleteId}
              setDeleteName={setDeleteName}
              setSelectCity={setSelectCity}
              selectCity={selectCity}
              capability={capability}
            />
          ))
            :
            <div className='p-4 center'>No City Available</div>
        }
      </div>

      {showAdd && <CityAdd refreshList={refreshList} country={country} state={state} handleComponent={setShowAdd} />}
      {showEdit && <CityEdit refreshList={refreshList} country={country} state={state} handleComponent={setShowEdit} id={actionId} />}
      {showDelete && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={cityDelete} />}
    </>
  )
}



/* -------------------------------
          CITY LIST ITEM
----------------------------------*/
const CityListItem = ({ data, dispatch, index, setShowEdit, setActionId, setShowDelete, setDeleteId, setDeleteName, setSelectCity, selectCity ,capability}) => {
  return (
    <div className={`main-contact-item ${selectCity == data.id && 'selected'}`} onClick={() => setSelectCity(data.id)}>
      <div className="main-contact-body ml-0">
        <h6 title='Country Name'>{data.name} - <span className='text-secondary'>{data.short_name}</span></h6>
        <span className={`badge text-capitalize ${data.status == 'active' ? 'badge-teal-transparent text-teal' : 'badge-light text-secondary'}`}>{data.status}</span>
      </div>
      
      <div className="main-contact-star center" onClick={e => e.stopPropagation()}>
        {capability?.shipping_setting.edit ?<i className="fe fe-edit-2 px-2" onClick={() => { dispatch({ type: CITY_BY_ID, payload: null }); setActionId(data.id); setShowEdit(true) }}></i>: null}
        {capability?.shipping_setting.delete ?<i className="fe fe-trash border-left pl-2" onClick={() => { setShowDelete(true); setDeleteId(data.id); setDeleteName(data.name) }}></i>: null}
      </div>
    </div>
  )
}



/* -------------------------------
          PINCODE LIST ITEM
----------------------------------*/
const PincodeTab = ({ state, country, city, dispatch, pincodeList, setSelectPincode, selectPincode, selectCity,capability }) => {

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }

  const [pageSetting, setPageSetting] = useState(initialPageSetting)
  const [loader, setLoader] = useState(true)
  const [isMounted, setIsMounted] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [actionId, setActionId] = useState('')

  useEffect(async () => {
    setLoader(true)
    await dispatch(getPincodeList(country, state, city, initialPageSetting.sort, initialPageSetting.status))
    setLoader(false)
  }, [selectCity])

  async function refreshList() {
    toast.loading('Refreshing Pincode List...', { toastId: 'refreshingState' })
    await dispatch(getPincodeList(country, state, city, pageSetting.sort, pageSetting.status))
    toast.dismiss('refreshingState')
  }

  //DELETE
  const [showDelete, setShowDelete] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [deleteName, setDeleteName] = useState()
  const [isDeleted, setIsDeleted] = useState(false)

  useEffect(() => {
    if (!isMounted) return
    refreshList()
  }, [isDeleted, pageSetting])


  useEffect(() => setIsMounted(true), [])
  return (
    <>
      <div className="main-contacts-list">
        <div className='center filter_btn'>
        {capability?.shipping_setting.filter ? 
          <div className="dropdown">
            <button type="button" className="btn btn-outline-info filter_button py-1 px-2 dropdown-toggle dropdown-toggle-hide" data-toggle="dropdown">
              <i className="fe fe-filter"></i> Filter
            </button>
            <Filter pageSetting={pageSetting} setPageSetting={setPageSetting} />
          </div>
        : null}
        {capability?.shipping_setting.add ?<button className='btn btn-primary py-1 px-2 ml-2' onClick={() => setShowAdd(true)}><i className="fe fe-plus"></i> Add</button>: null}
        </div>

        {loader ?
          <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
            <div>Pincode <b>Loading</b>...</div>
            <div className="spinner-grow" role="status" />
          </div>
          :
          pincodeList?.data?.length ? pincodeList?.data?.map((data, index) => (
            <PincodeListItem
              data={data}
              dispatch={dispatch}
              key={index}
              index={index}
              setShowEdit={setShowEdit}
              setActionId={setActionId}
              setShowDelete={setShowDelete}
              setDeleteId={setDeleteId}
              setDeleteName={setDeleteName}
              setSelectPincode={setSelectPincode}
              selectPincode={selectPincode}
              capability={capability}
            />
          ))
            :
            <div className='p-4 center'>No Pincode Available</div>
        }
      </div>

      {showAdd && <PincodeAdd refreshList={refreshList} country={country} state={state} city={city} handleComponent={setShowAdd} />}
      {showEdit && <PincodeEdit refreshList={refreshList} country={country} state={state} city={city} handleComponent={setShowEdit} id={actionId} />}
      {showDelete && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={pincodeDelete} />}
    </>
  )
}



/* -------------------------------
          PINCODE LIST ITEM
----------------------------------*/
const PincodeListItem = ({ data, dispatch, index, setShowEdit, setActionId, setShowDelete, setDeleteId, setDeleteName, setSelectPincode, selectPincode,capability }) => {
  return (
    <div className={`main-contact-item`} onClick={() => setSelectPincode(data.id)}>
      <div className="main-contact-body ml-0">
        <h6 title='Country Name'>{data.pincode}</h6>
        <span className={`badge text-capitalize ${data.status == 'active' ? 'badge-teal-transparent text-teal' : 'badge-light text-secondary'}`}>{data.status}</span>
      </div>
      
      <div className="main-contact-star center" onClick={e => e.stopPropagation()}>
        {capability?.shipping_setting.edit ? <i className="fe fe-edit-2 px-2" onClick={() => { dispatch({ type: PINCODE_BY_ID, payload: null }); setActionId(data.id); setShowEdit(true) }}></i>: null}
        {capability?.shipping_setting.delete ?<i className="fe fe-trash border-left pl-2" onClick={() => { setShowDelete(true); setDeleteId(data.id); setDeleteName(data.pincode) }}></i>: null}
      </div>
    </div>   
  )
}



/* -------------------------------
              FILTER
----------------------------------*/
const Filter = ({ pageSetting, setPageSetting }) => {

  const [status, setStatus] = useState(pageSetting.status)
  const [sort, setSort] = useState(pageSetting.sort)

  function onSubmit(e) {
    e.preventDefault()
    setPageSetting({
      sort: sort,
      status: status,
    })
    document.querySelector('.dropdown-menu.show').classList.remove('show')
  }

  return (
    <form className="dropdown-menu pt-3 min-w-200 animated zoomIn" onClick={e => e.stopPropagation()} onSubmit={e => onSubmit(e)}>
      <div className='px-3'>
        <div className='font-size-13 text-secondary font-weight-400 mb-1'>Status</div>
        <div>
          <label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setStatus('all')} defaultChecked={pageSetting.status == 'all' ? true : false} name="status" className='mr-1' /> All</label>
          <label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setStatus('active')} defaultChecked={pageSetting.status == 'active' ? true : false} name="status" className='mr-1' /> Active</label>
          <label className='m-0 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setStatus('inactive')} defaultChecked={pageSetting.status == 'inactive' ? true : false} name="status" className='mr-1' /> Inactive</label>
        </div>
      </div>
      <div className='px-3 mt-3'>
        <div className='font-size-13 text-secondary font-weight-400 mb-1'>Sort By</div>
        <div>
          <label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setSort('ASC')} defaultChecked={pageSetting.sort == 'ASC' ? true : false} name="sort" className='mr-1' /> Asc</label>
          <label className='m-0 mr-3 mb-1 d-inline-flex align-items-center cursor-pointer'><input type='radio' onClick={() => setSort('DESC')} defaultChecked={pageSetting.sort == 'DESC' ? true : false} name="sort" className='mr-1' /> Desc</label>
        </div>
      </div>
      <div className='p-2 mt-2 d-flex justify-content-end'>
        <button type='button' className='btn btn-outline-danger py-1' onClick={() => document.querySelector('.dropdown-menu.show').classList.remove('show')}>Close</button>
        <button type='submit' className='btn btn-primary ml-2 py-1'>Apply</button>
      </div>
    </form>
  )
}



function mapStateToProps(state) {
  const { countryList } = state.shipping
  const { stateList } = state.shipping
  const { cityList } = state.shipping
  const { pincodeList } = state.shipping
  let capability     = state?.auth?.currentUser?.capability
  capability       = capability ? JSON.parse(capability) : null

  return {
    countryList,
    stateList,
    pincodeList,
    cityList,
    capability
  }
}

export default connect(mapStateToProps)(ShippingIndex)