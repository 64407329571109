import { useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { addCity } from '../../../actions/shipping.action'


function CityAdd({ dispatch, country, state, handleComponent, refreshList }) {

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }
    await toast.promise(dispatch(addCity(data)), {
      pending: 'Adding ...',
      success: 'Successfully! Addded',
      error: 'Sorry! not added'
    })
    handleComponent(false)
    refreshList()
  }

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-layer-plus font-size-22 mr-2'></i> Add City</h6>
          </div>
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <input type='hidden' name='country' value={country} />
                  <input type='hidden' name='state' value={state} />
                  <div className="input_wrap w-100">
                    <input name="name" type="text" placeholder=" " required />
                    <label>City Name</label>
                  </div>
                  <small className='text-secondary'>Example: Lucknow, Bhopal</small>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="input_wrap w-100">
                    <input name="short_name" type="text" placeholder=" " required />
                    <label>Short Name</label>
                  </div>
                  <small className='text-secondary'>Example: LKO, BPL</small>
                </div>
                <div className='col-md-12 mb-3'>
                  <select className='form-control' name='status' required>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" onClick={() => handleComponent(false)} data-dismiss="modal" type="button">Close</div>
              <button type='submit' className="btn ripple btn-primary">+ Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(CityAdd)