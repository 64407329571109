import axios from "axios";

class productService{
    
    //PRODUCT LIST
    async productList(page = 1, limit = 10, sortBy = 'desc', status = 'pending'){
        return axios
        .get(`/products`,{
            params:{
                status : status,
                start  : page,
                limit  : limit,
                sort   : sortBy
            }
        })
        .then(response => {
            return response.data
        })
    }
    
    //VIEW PRODUCT
    async viewProduct(id){
        return axios
        .get("/products/" + id)
        .then(response => {
            return response.data
        })
    }

    //VIEW PRODUCT
    async onUpdatePriceFromExcel(id){
        return axios
        .get("/admin/product/update-price-from-excel")
        .then(response => {
            return response.data
        })
    }    

    //DELETE PRODUCT
    async deleteProduct(id){
        return axios
        .delete("/products/"+id)
        .then(response => {
            return response
        })
    }


    //DELETE VARIATION
    async deleteVariation(productId,VariationId){
        return axios.delete(`/products/${productId}?isVariation=yes&variationId=${VariationId}`).then(res => res.data)
    }

    //ADD PRODUCT
    async addNewProduct(data){
        return axios
        .post("/products",data)
        .then(response => {
            return response
        })
    }

    //UPDATE PRODUCT
    async updateProduct(pId,vId,data){
        return axios
        .put(`/products/${pId}/${vId}`,data)
        .then(response => {
            return response
        })
    }

    //SEARCH PRODUCT
    // async searchProduct(query, status='published', byType = ''){
    //     return axios.get(`/products/search/${query}?status=${status}&byType=${byType}`).then(res => res.data)
    // }
    
    async searchProduct(query, FilterData,){
        // async searchProduct(query, page = 1, limit = 10, sortBy = 'desc', FilterData){
        return axios.get(`/products/search/${query}?sort=${FilterData?.sort ? FilterData?.sort :""}&start=1&limit=10&ProductSearch[${FilterData?.searchKey ? FilterData?.searchKey : ""}]=${FilterData?.searchValue ? FilterData?.searchValue : ""}&ProductSearch[status]=${FilterData?.status ? FilterData?.status : ""}&ProductSearch[categories.slug]=${FilterData?.categories ? FilterData?.categories : ""}&ProductSearch[keywords]=${FilterData?.keywords ? FilterData?.keywords : ""}`).then(res => res.data.products)
    }

    //ADD VARIATION
    async addNewVariation(productId, vData){
        return axios.post(`/products/variation/new/${productId}`, vData).then(res => res.data)
    }

    //ADD PRODUCT LOG
    async addProductLog(data){
        return axios.post(`products/logs`, data).then(res => res.data)
    }

    //GET PRODUCT LOG
    async getProductLog(productId){
        return axios.get(`products/logs/${productId}`).then(res => res.data)
    }
}

export default new productService();