import React, {useEffect, useState}               from 'react'
import { connect }                                from "react-redux"
import { useHistory }                             from "react-router-dom"
import { WHATSAPP_BY_NAME }                       from '../../../actions/types'
import { getWhatsappList ,deleteWhatsappTemplate} from '../../../actions/message-templates.action'
import { paginationLimit }                        from '../../../helpers/user.helper'
import MainLayout                                 from '../../layouts/main.component'
import Select                                     from 'react-select'
import DeleteConfirm                              from '../../../helpers/delete.helper'
import AddWhatsappComponent                       from './add-whatsapp.component'
import EditWhatsappComponent                      from './edit-whatsapp.component'

const WhatsappList = ({capability,dispatch, whatsappList}) => {

  const history                                 = useHistory()
  const [search, setSearch]                     = useState('')
  const [showAddWhatsapp, setShowAddWhatsapp]   = useState(false);
  const [editWhatsapp, setEditWhatsapp]         = useState(false); 
  const [editWhatsappName, setEditWhatsappName] = useState();
  const [editWhatsappId, setEditWhatsappId]     = useState();
  const [loader, setLoader]                     = useState(true);
  const [whatsappLists, setWhatsappList]        = useState([]);

  //LIST FILTER
  const [limit, setLimit]             = useState(25)
  const [page, setPage]               = useState(1)
  const [sortBy, setSortBy]           = useState('desc')
  const [pagination, setPagination]   = useState([])

  useEffect (() => {
    document.title = 'Whatsapp Templates List'
    setWhatsappList(whatsappList)
  }, [whatsappList] )
  
  useEffect(async () => setPage(1), [limit, sortBy])
  useEffect(async () => {
    loadAndRefreshList()
  }, [page, limit, sortBy])

  useEffect(() => {
    setPagination([])
    var paginationLength = Math.ceil(whatsappLists?.total / limit)
    if (!paginationLength > 0) return
    Array(paginationLength).fill().map((data, i) => setPagination(page => ([
      ...page, { label: i + 1, value: i + 1 }
    ])))
  }, [whatsappLists,whatsappList])
  

  //DELETE
  const [showDelete, setShowDelete]               = useState(false)
  const [deleteId, setDeleteId]                   = useState()
  const [deleteName, setDeleteName]               = useState()

  //LOAD & REFRESH LIST 
  const loadAndRefreshList = async () => {
    setLoader(true)
    await dispatch(getWhatsappList())
    setLoader(false)
    return
  }

  return (
    <MainLayout>
      {/* BREADCRUMB */}             
      <nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Whatsapp Template</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Whatsapp Template</div>
			</nav>
    
      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 center">Whatsapp Template <span className="badge badge-primary ml-2">{whatsappLists ? whatsappLists?.length : 0}</span></div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search" /> 
            </div> 
          </div>   
          {/* {capability?.email_template.add    && <Link to={'/message-template/email/addnew'} className="text-nowrap ml-2"><button className="btn btn-primary"> + Add Template</button></Link> } */}
          {capability?.whatsapp_template.add ? <button onClick={() => setShowAddWhatsapp(true)} className="btn btn-primary text-nowrap ml-2"> + Add Whatsapp</button>: null}
        </div>
      </div>

      {/* LIST TABLE */}
      <div className='card'>
        <div className='card-body'>
          <div className='userlist-table'>

            {/* SEARCH */}
            {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}
            <style>
              {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                  visibility:hidden;
                  position:absolute
              }`}
            </style>

            <table className="table card-table category_table table-bordered stable-vcenter text-wrap mb-0 table-hover">
              <thead>
                <tr>
                  <th className='text-center'>#</th>
                  <th>Name</th>
                  <th>Content</th>
                  {/* <th>Action</th> */}
                  { capability?.whatsapp_template.edit || capability?.whatsapp_template.delete ? <th>Action</th> :null}
                </tr>
              </thead>
              <tbody>
                { whatsappLists ? whatsappLists?.map((whatsappData, index) => (
                    // <tr key={index} className='cursor-pointer' name={whatsappData.name.toLowerCase() + whatsappData.content.toLowerCase()} onClick={() => history.push('/message-template/email/view/'+whatsappData.name)}>
                    <tr key={index} name={whatsappData?.name?.toLowerCase() + whatsappData?.content?.toLowerCase()} >
                      <td className='text-center'></td>
                      <td>{whatsappData?.name}</td>
                      <td>{whatsappData?.content}</td>
                      <td className='text-center'>
                          <div className='btn-group'>
                            {/* <button onClick={() => { setUserDetail(true); dispatch({ type: USER_BY_ID, payload: null }); setShowUserId(rData.id || rData._id) }} className="btn btn-sm btn-white" data-title="Show User"><i className='bx bx-show h4 m-0 text-primary'></i></button> */}
                            {capability?.whatsapp_template.edit ? <button onClick={() => { setEditWhatsapp(true); dispatch({ type: WHATSAPP_BY_NAME, payload: null }); setEditWhatsappName(whatsappData.name || whatsappData._name); setEditWhatsappId(whatsappData.id || whatsappData.id) }} className="btn btn-sm btn-white" data-title="Edit Whatsapp"><i className='bx bx-pencil h4 m-0 text-warning'></i></button> : null}
                            {capability?.whatsapp_template.delete ? <button onClick={() => { setShowDelete(true); setDeleteId(whatsappData.id || whatsappData._id); setDeleteName(whatsappData.name); }} className="btn btn-sm btn-white" data-title="Delete Whatsapp"><i className='bx bx-trash h4 m-0 text-danger'></i></button> :null}
                          </div>
                        </td>
                    </tr>
                )): <tr name=' ' className='pointer-event-none'>
                  <td colSpan="5" className="text-center count_ignore">
                    {loader &&
                    <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
                      <div>Wait <b>Buddy</b>...</div>
                      <div className="spinner-grow" role="status"/>
                    </div>}
                    </td>
                  </tr>
                }
              </tbody>
            </table> 
            {!search?.length && <Pagination addClass="mt-3 position-relative z-index-200" page={page} setPage={setPage} sortBy={sortBy} setSortBy={setSortBy} limit={limit} setLimit={setLimit} whatsappLists={whatsappLists} pagination={pagination} />}
          </div>
        </div>
      </div>

    {/* ADD */}
     {showAddWhatsapp && <AddWhatsappComponent handleComponent={setShowAddWhatsapp} loadAndRefreshList={loadAndRefreshList}/>}
     {/* EdIT */}
     {editWhatsapp && <EditWhatsappComponent handleComponent={setEditWhatsapp} name={editWhatsappName} id={editWhatsappId} loadAndRefreshList={loadAndRefreshList}/>}
    {/* DELETE */}
    {showDelete && <DeleteConfirm  id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={deleteWhatsappTemplate}/>}

    </MainLayout>  
  )
}

/* -------------------------------
          PAGINATION
----------------------------------*/
const Pagination = ({ page, setPage, sortBy, setSortBy, limit, setLimit, whatsappLists, pagination, addClass }) => {

  return (
    <>
      <style>{`.pagination:not(.p-2) li:nth-child(${page + 1}) > *{background:#0068ff;color:white}`}</style>
      <div className={`d-flex justify-content-between align-items-center position-relative z-index-100 ${addClass}`}>
        <div className='d-flex align-items-center'>
          <select defaultValue={sortBy} onChange={e => setSortBy(e.target.value)} className='form-control mr-2'>
            <option value={'desc'}>New</option>
            <option value={'asc'}>Old</option>
          </select>
          <select defaultValue={limit} onChange={e => setLimit(e.target.value)} className='form-control'>
            {paginationLimit.map((limit, i) => <option key={i} value={limit}>{limit}</option>)}
          </select>
          <div className='font-weight-500 mr-3 text-secondary text-nowrap ml-2'>{(limit * page) - (limit - 1)}-{(limit * page) > whatsappLists?.total ? whatsappLists?.total : (limit * page)} of {whatsappLists?.total}</div>
        </div>

        <div className='d-flex'>
          <button onClick={() => setPage(whatsappLists.prevPage)} className={`btn btn-outline-primary border-light rounded-5 ${whatsappLists?.prevPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-left'></i></button>
          <Select
            value={pagination[whatsappLists?.currentPage - 1]}
            onChange={e => setPage(e.label)}
            options={pagination}
            menuPlacement='top'
            placeholder='Page'
            className='mx-2 react-select pagination-select react-select'
          />
          <button onClick={() => setPage(whatsappLists.nextPage)} className={`btn btn-outline-primary border-light rounded-5 ${whatsappLists?.nextPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-right'></i></button>
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  const { message }           = state.message
  const { whatsappList }         = state.messageTemplate.messageTemplate
  let capability              = state.auth.currentUser?.capability
  capability                  = capability ? JSON.parse(capability) : null

  return {
    message,
    whatsappList,
    capability
  };
}

export default connect(mapStateToProps)(WhatsappList);