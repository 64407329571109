import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { addWhatsappTemplate } from "../../../actions/message-templates.action";
import SunEditor from 'suneditor-react';
// get contries

const AddWhatsapp = ({ handleComponent, dispatch, loadAndRefreshList }) =>{

  const [btnLoader, setBtnLoader]                         = useState(false)
  const [loader, setLoader]                     = useState(true);

  useEffect(async () => {
    document.title = 'Whatsapp Templates | Add New'
    setLoader(true)
  }, [])

  const onSubmitAddWhatsapp = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)    
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }

    const bodyData = { 
      name: data.name,
      content: data.content,
      peid:"",
      dlttemplateid:""
     }

    await toast.promise(
      dispatch(addWhatsappTemplate(bodyData)),
      {
        pending: 'Whatsapp Template adding...',
        success: 'Successfully! Whatsapp Template Added',
        error: {
          render() {
            setBtnLoader(false);
            return 'Whatsapp Template not added'
          }

        }
      }
    )
    setLoader(false)
    await loadAndRefreshList();
    await handleComponent(false);
  }

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right modal-dialog-for-add-user">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-plus font-size-22 mr-2'></i> Add Whatsapp Template </h6>
          </div>
            <form onSubmit={ (e) => {setBtnLoader(true); onSubmitAddWhatsapp(e) }}>  
            <div className="modal-body">
              <div className="row">
                  <div className="col-md-12">
                    <div className="input_wrap w-100">
                      <input name='name' type="text"  placeholder=" "/>
                      <label>Name</label>
                    </div>
                  </div>

                  <div className="col-md-12 mt-5">
                    <div className="form-group mb-0"> 
                      <label className="main-content-label tx-11 m-0">Content</label> 
									    <textarea rows={6} name="content" className="form-control" placeholder="Please type here..." />
                    </div>
                    {/* <SunEditor name="content" lang="en" height="200" /> */}
                  </div>

              </div>
            </div>
            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Add Whatsapp' : <span className="spinner-border spinner-border-sm mx-3"></span>}
              </button>
            </div>
            </form> 
        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {

  return {}
}

export default connect(mapStateToProps)(AddWhatsapp);