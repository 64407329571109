import React, { useEffect, useState, useRef } from 'react'
import Layout from '../../layouts/main.component'
import { useHistory } from 'react-router-dom'
import { customerSearch } from '../../../actions/users.action'
import {connect} from 'react-redux'
import Select from 'react-select'
import { roles, modulesList } from '../../../helpers/user.helper'
import { updateUser } from '../../../actions/users.action'
import { toast } from 'react-toastify'
import { productStatus } from '../../../helpers/product.helper'

function RolesCapabilities({customerSearchResult, dispatch, capability, currentUser}) {
  const history                                = useHistory()
  const [searchQuery, setSearchQuery]          = useState('')
  const [options, setOptions]                  = useState([])
  const [userInfo, setUserInfo]                = useState()
  const [userCapabilities, setUserCapabilites] = useState()
  const [searching, setSearching]              = useState(false)
  const roleRef                                = useRef()

  // useEffect(() => { //SEARCH USER 
  //   if(searchQuery.length) dispatch(customerSearch(searchQuery, roleRef.current.value))
  // },[searchQuery])

  useEffect(async() => {
    if (!searchQuery.length) {
      setSearching(true)
    }
    let filterData = {"role": roleRef?.current?.value, "sort" : "desc"}

    if (!isNaN(searchQuery)) {
      filterData={
        ...filterData,
        searchKey : "mobile_number",
        searchValue: searchQuery
      }
      
    }
    else{
      filterData={
        ...filterData,
        "name": searchQuery
      }
    }
    await dispatch(customerSearch("q", filterData))
    setSearching(false)
  },[searchQuery])


  useEffect(() => { //ADD MISSING MODULE IN "USER CAPABILITES"
    document.title = 'Roles & Capabilites'
    if(userInfo){
      var formateCapabilities = {}
      modulesList.map(module => {
        formateCapabilities[module.module_name] = {}
        module.components.map(component => {
          formateCapabilities[module.module_name][component.name] = false
          formateCapabilities[module.module_name]['module_enable'] = false
        })
      })

      var userCapabilitiesCheck       = userInfo?.capability ? JSON.parse(userInfo?.capability) : {}
      var capabilitesModule           = Object.keys(userCapabilitiesCheck)

      capabilitesModule.map((data,i) => {
        if(formateCapabilities[data]){
          var capabilitesComponentKey   = Object.keys(userCapabilitiesCheck[data])
          var capabilitesComponentValue = Object.values(userCapabilitiesCheck[data])
          capabilitesComponentKey.map((cData,index) => {
            if(formateCapabilities[data][cData] != undefined){
              formateCapabilities[data][cData] = userCapabilitiesCheck[data][cData]
            }else{
              toast.warn(<div><b className='font-weight-600'>Component n/a:</b> {data} -&gt; {cData}</div>, {autoClose: false})
            }
          })
        }else{
          toast.warn(<div><b className='font-weight-600'>Module n/a:</b> {data}</div>, {autoClose: false})
        }
      })
      setUserCapabilites(formateCapabilities)
    }
  },[userInfo])

  useEffect(() => { //FORMATE USER SEARCH "OPTIONS" DROPDOWN
    var formateOptions = customerSearchResult?.map((option,index) => ({
      value: option,
      label: `${option.first_name} ${option.last_name}  #${option.user_number}`
    }))
    setOptions(formateOptions)
  },[customerSearchResult])


  async function submitFormData(e) { //FORM HANDLE
    e.preventDefault()
    const formData = new FormData(e.target)
    const data     = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }
    
    if(!userInfo) return alert('Buddy! 😇 Add User');  
    if(userInfo.status !== 'active') return alert('Buddy! 😇 User is not "Active"');  

    var finalDataArray = {}
    modulesList.map(module => {
      finalDataArray[module.module_name] = {}
      module.components.map(component => {
        finalDataArray[module.module_name][component.name] = false
        finalDataArray[module.module_name]['module_enable'] = false
      })
    })
    
    var fieldKeys = Object.keys(data)
    var fieldValues = Object.values(data)
    Array(fieldKeys.length).fill('').map((d,i) => {
      var fieldData = JSON.parse(fieldKeys[i])
      var module = fieldData.module
      var component = fieldData.component
      finalDataArray[module][component] = true
      finalDataArray[module]['module_enable'] = true
    })

    var viewStatus = []
    productStatus.map(status => {
      if(finalDataArray.product[status.name] == true) viewStatus.push(true)
      else viewStatus.push(false)
    })
    if(viewStatus.filter(status => status == true).length) finalDataArray.product.view = true
    else finalDataArray.product.view = false
  

    var userData = null
    setUserInfo(data =>{userData = data; return data})
    
    const submitData = {
      "first_name"   : userData?.first_name,
      "last_name"    : userData?.last_name,
      "email"        : userData?.email,
      "mobile_number": userData?.mobile_number,
      "role"         : userData?.role,
      "status"       : userData?.status,
      "capability"   : JSON.stringify(finalDataArray)
    }
    
    await toast.promise(
      dispatch(updateUser(userData._id || userData.id,submitData,userData?.role)),
      {
        pending: 'User Updating...',
        success: 'Successfully! User Updated',
        error: 'User not Updated'
      }
    )
    setTimeout(async () => currentUser.id == userInfo._id ? await window.location.reload() : null, 1000)
  }


  return (
    <Layout>
      
      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Users List</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Roles &amp; Capabilities</div>
			</nav>
      
      {/* FILTER */}
      <div className='row mt-3'>
    
        <div className='col-md-6 d-flex justify-content-end pr-0'>
          {userInfo &&
            <div className='position-relative user-box'>
              <button className='mr-2 popup-contol border outline-unset bg-white d-flex align-items-stretch  overflow-hidden rounded-5 p-0 cursor-pointer animated bounceIn'>
                <i className='bx bxs-user-circle bg-gray-100 p-2 mr-2 font-size-20 center'></i> 
                <div className='center pr-2'>
                  <span className='text-secondary font-weight-500'>{userInfo.first_name}&nbsp;{userInfo.last_name}</span>
                  <i className='bx bxs-chevron-down ml-1 text-secondary'></i>
                </div>
              </button>
              <div className="popover shadow-2 animated show bs-popover-bottom t-40 r--0" role="tooltip"  x-placement="top">
                <div className="arrow"></div>
                <h3 className="popover-header d-flex font-size-13 d-flex align-items-center">User&nbsp;Info <span className='badge ml-auto font-weight-300 font-size-11 badge-primary'>{userInfo?.role}</span></h3>
                <div className="popover-body">
                  <table className='w-100'>
                    <tbody>
                      <tr>
                        <td className='font-weight-500'>Name</td>
                        <td className='text-right'>{userInfo?.first_name} {userInfo?.last_name}</td>
                      </tr>
                      <tr>
                        <td className='font-weight-500'>Email</td>
                        <td className='text-right'>{userInfo?.email}</td>
                      </tr>
                      <tr>
                        <td className='font-weight-500'>ID</td>
                        <td className='text-right'>{userInfo?.user_number}</td>
                      </tr>
                      <tr>
                        <td className='font-weight-500'>Status</td>
                        <td className='text-right'><span className='badge badge-primary-transparent font-weight-400'>{userInfo?.status}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          }
        </div>
        <div className='col-md-6'>
          <div className='d-flex align-items-center'>
            <div>
              <select className='form-control employee_filter' ref={roleRef} style={{minWidth:125}}>
                {roles.map((role,i) => role.name != 'customer' ? <option key={i} value={role.name}>{role.label}</option> : null)}
              </select>
            </div>
            <Select
              className='w-100 search_employee_input Select react-select'
              onChange={e => setUserInfo(e.value)}
              onInputChange={e => setSearchQuery(e)}
              placeholder='Search Employee...'
              options={options}
              autoFocus={true}
              />
            <button className="btn border bg-white border-5 ml-3 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
            <button disabled={!userInfo} className='btn btn-primary ml-2 center' onClick={() => document.querySelector('#submitForm').click()}>Submit</button>
          </div>
        </div>
      </div>

      {/* CONTENT */}
      {userInfo && userCapabilities ? 
        <form className='mt-4' onSubmit={submitFormData}>
          <button type='submit' className='d-none' id='submitForm'></button>
          <div className='row'>
            {modulesList.map((module,index) => (
              <Module data={module} key={index} capability={capability} userCapabilities={userCapabilities} modulesList={modulesList}/>
            ))}
          </div>
        </form>
      : 
        <div className='card px-3 py-5 mt-3 center'>
          <div className="mt-3 h6 text-secondary d-flex py-5">Hello, Buddy 🙂 <b className='center ml-2'>Search&nbsp;<i className='bx bx-search'></i>&nbsp;User</b></div>
        </div>
      }

    </Layout>
  )
}


/* ------------------------
          Module
------------------------ */
const Module = ({data, capability, modulesList, userCapabilities}) => {

  return(
    <div className='col-md-3 mb-4'>
      <div className='card m-0 px-3 py-4 rounded-10 h-100'>
        <h2 className='font-size-15 font-weight-500 pb-1 mb-2 d-flex align-items-center role_heading'>{data.module_label}</h2>

        <div className='select_box_container'>
          {data.components.map((moduleComponent,i) => (
            !moduleComponent?.disabled == true && <CapabilityComponent key={i} i={i} userCapabilities={userCapabilities} moduleComponent={moduleComponent} data={data}/>
          ))}
        </div>
      </div>
    </div>
  )
}


/* ------------------------
        COMPONENT
------------------------ */
const CapabilityComponent = ({userCapabilities, moduleComponent, i, data}) => {

  const [isChecked, setIsChecked] = useState()
  useEffect(() => setIsChecked(userCapabilities[data?.module_name][moduleComponent?.name] == true ? true : false), [userCapabilities])

  return(
    <label key={i} className='cursor-pointer mb-0'>
      <div className='bg-white select_box'>
        <input type='checkbox' defaultChecked={isChecked} name={`{"module": "${data?.module_name}", "component": "${moduleComponent?.name}"}`} className='mr-1'/>
        <span>{moduleComponent?.label}</span>
      </div>
    </label>
  )
}


function mapStateToProps(state) {
  const {currentUser}        = state.auth
  const customerSearchResult = state.users.users.customerSearch
  let capability             = state?.auth?.currentUser?.capability
  capability                 = capability ? JSON.parse(capability) : null

  return{
    customerSearchResult,
    capability,
    currentUser
  }
}

export default connect(mapStateToProps)(RolesCapabilities);
