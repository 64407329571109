export const paginationLimit = [25, 50, 75, 100]

export const daysList = [
  {value:'monday'     , label:'Monday'},
  {value:'tuesday'    , label:'Tuesday'},
  {value:'wednesday'  , label:'Wednesday'},
  {value:'thursday'   , label:'Thursday'},
  {value:'friday'     , label:'Friday'},
  {value:'saturday'   , label:'Saturday'},
  {value:'sunday'     , label:'Sunday'}
]
