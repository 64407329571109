import { 
  CITY_LIST, 
  COUNTRY_LIST, 
  STATE_LIST, 
  COUNTRY_BY_ID, 
  STATE_BY_ID, 
  CITY_BY_ID, 
  PINCODE_LIST, 
  PINCODE_BY_ID,
	ROUTE_BY_ID, 
	ROUTE_LIST ,
  ROUTE_ADD,

  ROUTE_PLAN_DELIVERY_LIST,
  ROUTE_PLAN_DELIVERY_LIST_CLEAR,
  ROUTE_PLAN_DELIVERY_ORDER__LIST
} from "../actions/types"

const initialState = {
  countryList: null,
  countryById: null,

  stateList: null,
  stateById: null,

  cityList: null,
  cityById: null,

  pincodeList: null,
  pincodeById: null,

  routeList: null,
  routeById: null,
  newRoute: null,

  deliveryRoutePlanList:null,
  deliveryRoutePlanOrderList:null
}

export default function reducer(state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case COUNTRY_LIST: return { ...state, countryList: payload }
    case COUNTRY_BY_ID: return { ...state, countryById: payload }

    case STATE_LIST: return { ...state, stateList: payload }
    case STATE_BY_ID: return { ...state, stateById: payload }

    case CITY_LIST: return { ...state, cityList: payload }
    case CITY_BY_ID: return { ...state, cityById: payload }

    case PINCODE_LIST: return { ...state, pincodeList: payload }
    case PINCODE_BY_ID: return { ...state, pincodeById: payload }

    case ROUTE_LIST: return { ...state, routeList: payload }
    case ROUTE_BY_ID: return { ...state, routeById: payload }
    case ROUTE_ADD: return { ...state, newRoute: payload }

    case ROUTE_PLAN_DELIVERY_LIST_CLEAR: return { ...state, deliveryRoutePlanList: null }
    case ROUTE_PLAN_DELIVERY_LIST: return { ...state, deliveryRoutePlanList: payload }
    case ROUTE_PLAN_DELIVERY_ORDER__LIST: return { ...state, deliveryRoutePlanOrderList: payload }

    default: return state
  }
}