export const paginationLimit = [25, 50, 75, 100]
export const roles = [
  {name:'admin', label:'Admin'},
  {name:'affiliate', label:'Affiliate'},
  {name:'customer', label:'Customer'},
  {name:'logistics', label:'Logistics'},
  {name:'marketing', label:'Marketing'},
  {name:'operation', label:'Operation'},
  {name:'supplier', label:'Supplier'},    
  {name:'technology', label:'Technology'},
  
]

export const KYCDocuments = [
  {name:'Business Registration Certificate', label:'Business Registration Certificate'},
  {name:'Shop and Established Certificate', label:'Shop and Established Certificate'},
  {name:'Sales & Income Tax Returns', label:'Sales & Income Tax Returns'},
  {name:'GST Certificate', label:'GST Certificate'},
  {name:'Sales Tax / Service Tax Certificate', label:'Sales Tax / Service Tax Certificate'},
  {name:'Electricity /Water or Landline Bill', label:'Electricity /Water or Landline Bill'},
  {name:'Trade License', label:'Trade License'},
  {name:'PAN Card', label:'PAN Card'},
  {name:'Aadhaar Card', label:'Aadhaar Card'},
]


export const modulesList = [
  {
    module_label: <><i className='bx bx-cart'></i> <span> Orders</span></>,
    module_name:  'orders',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology', 'operation'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology', 'operation'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology', 'operation'], label:<>View</>},
        {name:'invoice_generator',   default:['admin', 'technology', 'operation'], label:<>Invoice Generator</>},
        {name:'route_plan',   default:['admin', 'technology'], label:<>Route Plan</>},
      ]
  },

  {
    module_label: <><i className='bx bx-store'></i> <span>Product</span></>,
    module_name:  'product',
    moduel_enable: false,
    components: [
        {name:'add',       default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',      default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete',    default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',      default:['admin', 'technology', 'operation'], label:<>View</>, disabled: true},
        {name:'import',    default:['admin', 'technology'], label:<>Import</>},
        {name:'all',       default:['admin', 'technology'], label:<>View All</>},
        {name:'pending',   default:['admin', 'technology'], label:<>View Pending</>},
        {name:'published', default:['admin', 'technology'], label:<>View Published</>},
        {name:'review',    default:['admin', 'technology'], label:<>View Review</>},
        {name:'drafts',    default:['admin', 'technology'], label:<>View Drafts</>},
        // {name:'product_attribute',    default:['admin', 'technology'], label:<>Attribute</>},
        // {name:'product_categories',    default:['admin', 'technology'], label:<>Categories</>},
      ]
  },
   {
    module_label: <><i className='bx bxs-network-chart' ></i> <span>Product -- Attribute</span></>,
    module_name:  'attribute',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology', 'operation'], label:<>View</>},
      ]
  },
  {
    module_label: <><i className="fab fa-buffer"></i> <span>Product -- Category</span></>,
    module_name:  'category',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology', 'operation'], label:<>View</>},
      ]
  },
  
  {
    module_label: <><i className='bx bx-user'></i> <span>User</span></>,
    module_name:  'user',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology'], label:<>View All</>},
        {name:'view_customer',   default:['admin', 'technology', 'operation'], label:<>View Customer</>},
        {name:'roles_capabilites',   default:['admin', 'technology', 'operation'], label:<>Roles &amp; Capabilites</>},
      ]
  },
  // {
  //   module_label: <><i className='bx bx-user'></i> <span>Template</span></>,
  //   module_name:  'template',
  //   moduel_enable: false,
  //   components: [
  //       // {name:'email',   default:['admin', 'technology', 'operation'], label:<>Email</>},
  //       // {name:'sms',   default:['admin', 'technology', 'operation'], label:<>SMS</>},
  //       // {name:'firebase_notification',   default:['admin', 'technology', 'operation'], label:<>Firebase Notification</>},
  //       {name:'whatsapp',   default:['admin', 'technology', 'operation'], label:<>Whatsapp</>},
  //     ]
  // },
  // {
  //   module_label: <><i className='bx bx-user'></i> <span>Setting</span></>,
  //   module_name:  'setting',
  //   moduel_enable: false,
  //   components: [
  //       // {name:'add',    default:['admin', 'technology'], label:<>Add</>},
  //       // {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
  //       // {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
  //       {name:'view',   default:['admin', 'technology'], label:<>View All</>},
  //       {name:'shipping',   default:['admin', 'technology', 'operation'], label:<>Shipping</>},
  //       {name:'route',   default:['admin', 'technology', 'operation'], label:<>Route</>},
  //     ]
  // },
  {
    module_label: <><i className='bx bx-message-square-detail'></i> <span>Template -- Whatsapp</span></>,
    module_name:  'whatsapp_template',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology'], label:<>View</>},
      ]
  },
  {
    module_label: <><i class='bx bx-package'></i> <span>Setting -- Shipping</span></>,
    module_name:  'shipping_setting',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology'], label:<>View</>},
        {name:'filter',   default:['admin', 'technology'], label:<>Filter</>},
      ]
  },
  {
    module_label: <><i class='bx bx-current-location'></i> <span>Setting -- Route</span></>,
    // module_label: <><i className='bx bx-layout'></i> <span>Setting -- Route</span></>,
    module_name:  'route_setting',
    moduel_enable: false,
    components: [
        {name:'add',    default:['admin', 'technology'], label:<>Add</>},
        {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
        {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
        {name:'view',   default:['admin', 'technology'], label:<>View</>},
      ]
  },
 
 
  // {
  //   module_label: <><i className='bx bx-layout'></i> <span>Email Template</span></>,
  //   module_name:  'email_template',
  //   moduel_enable: false,
  //   components: [
  //       {name:'add',    default:['admin', 'technology'], label:<>Add</>},
  //       {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
  //       {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
  //       {name:'view',   default:['admin', 'technology'], label:<>View</>},
  //     ]
  // },
  // {
  //   module_label: <><i className='bx bx-message-square-detail'></i> <span>SMS Template</span></>,
  //   module_name:  'sms_template',
  //   moduel_enable: false,
  //   components: [
  //       {name:'add',    default:['admin', 'technology'], label:<>Add</>},
  //       {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
  //       {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
  //       {name:'view',   default:['admin', 'technology'], label:<>View</>},
  //     ]
  // },
  // {
  //   module_label: <><i className='bx bx-bell'></i> <span>Firebase Notification Template</span></>,
  //   module_name:  'firebase_notification_template',
  //   moduel_enable: false,
  //   components: [
  //       {name:'add',    default:['admin', 'technology'], label:<>Add</>},
  //       {name:'edit',   default:['admin', 'technology'], label:<>Edit</>},
  //       {name:'delete', default:['admin', 'technology'], label:<>Delete</>},
  //       {name:'view',   default:['admin', 'technology'], label:<>View</>},
  //     ]
  // },  

  {
    module_label: <><i className='bx bx-check-double'></i> <span>Roles &amp; Capabilites</span></>,
    module_name:  'capability',
    moduel_enable: false,
    components: [
        {name:'module_enable', default:['admin'], label:<span onClick={() => alert(`Alert! 😲 are you sure?`)}>Enable Module</span>},
      ]
  }
]