import axios from "axios";

class MessageTemplatesService {


  // ----------------------------- EMAIL NOTIFICATION  
  async EmailList() {
    return axios.get("/message-template/email").then(r => r.data)
  }

  async addEmailTemp(data) {
    return axios.post("/message-template/email", data).then(r => r.data)
  }

  async editEmailTemp(id, data) {
    return axios.put("/message-template/email/"+id, data).then(r => r.data)
  }

  async emailByName(name) {
    return axios.get("/message-template/email/"+name).then(r => r.data)
  }

  async deleteEmailTemp(eId) {
    return axios.delete("/message-template/email/"+eId).then(r => r.data)
  }


  // ----------------------------- SMS NOTIFICATION
  async SmsList() {
    return axios.get("/message-template/sms").then(r => r.data)
  }

  async addSmsTemp(data) {
    return axios.post("/message-template/sms", data).then(r => r.data)
  }

  async editSmsTemp(id, data) {
    return axios.put("/message-template/sms/"+id, data).then(r => r.data)
  }

  async smsByName(name) {
    return axios.get("/message-template/sms/"+name).then(r => r.data)
  }

  async deleteSmsTemp(eId) {
    return axios.delete("/message-template/sms/"+eId).then(r => r.data)
  }


  // ----------------------------- FIREBASE NOTIFICATION
  async firebaseNotificationList() {
    return axios.get("/message-template/firebase-notification").then(r => r.data)
  }

  async addFirebaseNotificationTemp(data) {
    return axios.post("/message-template/firebase-notification", data).then(r => r.data)
  }

  async editFirebaseNotificationTemp(id, data) {
    return axios.put("/message-template/firebase-notification/"+id, data).then(r => r.data)
  }

  async firebaseNotificationByName(name) {
    return axios.get("/message-template/firebase-notification/"+name).then(r => r.data)
  }

  async deleteFirebaseNotificationTemp(eId) {
    return axios.delete("/message-template/firebase-notification/"+eId).then(r => r.data)
  }


  // ----------------------------- SEND NOTIFICATION
  async sendNotification(data) {
    return axios.post("/notification", data).then(r => r.data)
  }

   // ----------------------------- WHATSAPP NOTIFICATION
   async whatsappList() {
    return axios.get("/message-template/whatsapp/").then(r => r.data)
  }

  async whatsappByName(name) {
    return axios.get("/message-template/whatsapp/"+name).then(r => r.data)
  }

  async addWhatsapp(data) {
    return axios.post("/message-template/whatsapp/", data).then(r => r.data)
  }

  async editWhatsapp(id, data) {
    return axios.put("/message-template/whatsapp/"+id, data).then(r => r.data)
  }

  async deleteWhatsapp(id) {
    return axios.delete("/message-template/whatsapp/"+id).then(r => r.data)
  }

}

export default new MessageTemplatesService();
