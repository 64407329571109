import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import MainLayout from '../layouts/main.component'
import { productListShow, deleteProduct, clearProduct, searchProduct, updatePriceFromExcel } from '../../actions/product.action'
import DeleteConfirm from '../../helpers/delete.helper'
import { paginationLimit, productStatus } from '../../helpers/product.helper'
import moment from 'moment'
import Select from 'react-select'
import { PRODUCT_ADD } from '../../actions/types'
import { categoryList } from '../../actions/category.action'
import ExportProduct from './export-product.component'
import ProductLogs from './product-logs.component'
import { toast } from 'react-toastify'
import { updateProductInBulk } from './product.operation'
import { inputFirstLetterCapital } from '../../helpers/common-function.helper'


const ProductList = ({ dispatch, productList, productSearch, categories, capability, userId, ... props}) => {
	const history                             		= useHistory()
	const [search, setSearch]                 		= useState("")	
	const [page, setPage]                   		= useState(1)
	const [limit, setLimit]                   		= useState(25)
	const [loader, setLoader]                 		= useState(false)
	const [sortBy, setSortBy]                 		= useState('desc')
	const [status, setStatus]         		  		= useState('all')
	const [pagination, setPagination]         		= useState([])
	const [filterByStatus, setFilterByStatus] 		= useState()
	const [stopUseEffect, setStopUseEffect]   		= useState(true)
	const [countError, setCountError]         		= useState(0)
	const [showExport, setShowExport]         		= useState(false)
	const [showLog, setShowLog]               		= useState(false)
	const [productId, setProductId]           		= useState()

	//PAGINATION FILTER CONTROL
	var defaultStatus; 
	useEffect(() => {
		defaultStatus = 
		productStatus.filter(status => {
			if(capability.product[status.name] == true) return status
		})[0]?.name || 'none';
		setFilterByStatus(defaultStatus)
	},[])

	useEffect(async ()=> !stopUseEffect && setPage(1), [limit, sortBy, filterByStatus])
	useEffect(async ()=>{
		if(stopUseEffect) return
		setCountError(0)
		document.title = 'Product List'
		setLoader(true)
		await dispatch(productListShow(page,limit,sortBy, filterByStatus || defaultStatus))
		await dispatch(categoryList())
		await setLoader(false)
	}, [page, limit, sortBy, filterByStatus])


	//DELETE
	const [showDelete, setShowDelete]  = useState(false)
	const [deleteId, setDeleteId]      = useState()
	const [deleteName, setDeleteName]  = useState()
	const [isDeleted, setIsDeleted]    = useState(false)

	useEffect(async() => {
			if(stopUseEffect) return
		toast.loading('Updating list...', {toastId: 'updating'})
		await dispatch(productListShow(page,limit,sortBy, filterByStatus))
			await setTimeout(() => toast.dismiss('updating'), 500)
	},[isDeleted])


	useEffect(() => {
		setPagination([])
		var paginationLength = Math.ceil(productList?.total / limit)
		if(!paginationLength > 0) return
		Array(paginationLength).fill().map( (data,i) => setPagination(page => ([
		...page, { label:i+1, value:i+1 }
		])))
	},[productList])

	//SEARCH PRODUCT
	useEffect(async ()=>{	

		if(stopUseEffect) return
		
		const searchData = search ? JSON.parse(search):"";
		if(searchData?.keywords) {
			await dispatch(searchProduct("q", searchData))
		}		
	},[search])


	useEffect(() => setStopUseEffect(false),[])

	const onUpdatePriceFromExcel = async () => {
		await toast.promise(
		dispatch(updatePriceFromExcel()),
		{
			pending: 'Updating product price...',
			success: 'Successfully! Product price updated',
			error:   {
			render(){
				console.log("123")
			}
			}
		})
	}
	

	return (
		<MainLayout>

			{/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
				<div className="page_title">Products List</div>
				{ countError > 0 && <span className='badge badge-danger-transparent center ml-2 center  animated-1s flash'>Error <b className='ml-1'>"{countError}"</b></span> }
				<div className="ml-auto breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Products</div>
			</nav>

			{/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
				<div className="h4 mt-4 mb-3 d-flex align-items-center">Products 
					<span className="badge badge-primary ml-2">{productSearch ? productSearch?.length : productList?.total}</span>
					{/* <div className="ml-3">
							<select defaultValue={filterByStatus} 
								onChange={e => {
									productStatus.map((status,i) => {
										if(status?.showItems.filter(item => capability?.product[item] == true && status.name == e.target.value).length){
											setFilterByStatus(e.target.value)
										}
									})
								}} 
								className='form-control text-capitalize' 
								style={{width:'115px'}}>
								{productStatus.map((status,i) => {
									if(status?.showItems.filter(item => capability?.product[item] == true).length){
										return <option key={i} value={status.name}>{status.label}</option>
									}
								})}
							</select>
					</div> */}
				</div>

				<div className="d-flex align-items-center">
					<button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
					
					<div className="form-group w-50 mb-0 mr-2"> 
						<div className={`input-icon capitalize-before ${filterByStatus != 'all' ? 'title-top' : 'title-none'}`} data-title={`Search from - "${filterByStatus} Product"`}> 
						<span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
						<input type="search" onChange={e => setSearch(JSON.stringify({"keywords" : e.target.value}))} onBeforeInput={inputFirstLetterCapital} className="form-control ht-40" placeholder="Search product" /> 
						</div> 
					</div>
					<div className='mr-2'>
						<div className="dropdown">
						<button type="button" className="btn btn-indigo btn-icon" data-toggle="dropdown"><i className="fe fe-filter"></i></button>
						{/* <button type="button" className="btn btn-info btn-lg py-1 px-2 dropdown-toggle dropdown-toggle-hide" data-toggle="dropdown"><i className="fe fe-filter"></i></button> */}
						<Filter categories={categories} search={search} setSearch={setSearch} dispatch={dispatch} searchProduct={searchProduct}/>
						</div>
					</div>
					{/* {capability?.product.import && <Link to={'/products/import'} className='text-nowrap ml-2'><button className="btn btn-warning"><i className='bx bx-import'></i> Import</button></Link> } */}
					{<button className='btn btn-outline-info center w-50' onClick={() => onUpdatePriceFromExcel()}><i className='bx bxs-file-export mr-1'></i> Update Price</button>}
					{capability?.product.import && <button className='btn btn-outline-warning ml-2 center' onClick={() => setShowExport(true)}><i className='bx bxs-file-export mr-1'></i> Export</button>}
					{capability?.product.add    && <Link to={'/products/addnew'} onClick={() => dispatch({type: PRODUCT_ADD, payload:null})} className="text-nowrap ml-2"><button className="btn btn-primary"> + Add Product</button></Link> }
					<div onClick={() => setPage(productList.prevPage)} className={`${productList?.prevPage == 0 ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-2"><i className=" bx bx-chevron-left font-size-19"></i></button></div>
					<div onClick={() => setPage(productList.nextPage)} className={`${productList?.nextPage == 0 ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-1"><i className=" bx bx-chevron-right font-size-19"></i></button></div>
				</div>
			</div>

			{/* PRODUCT LIST */}
			<div className="card">
				
				<div className="card-body"> 
					<div className="userlist-table"> 
						{/* {search && <div className="mb-2">Search: <b className="text-warning"><span>
							[{productSearchKey}]={productSearchValue}</span>, <span>[Status]= {searchStatus}</span>, <span>[Category]= {searchCategory}
							{search}
						</span></b></div>} */}

						<table className="table card-table table-hover category_table table-bordered stable-vcenter text-nowrap mb-0">
							<thead>
								<tr>
									<th className='text-center'>#</th>
									<th>Product</th>
									{/* <th className='text-center'>Price</th> */}
									<th>Category</th>   
									<th className='text-center'>Date</th>   
									{capability?.product.view || capability?.product.edit || capability?.product.delete ? <th className='text-center'>Action</th> : null}
								</tr>
							</thead>
							<tbody>

								{/* PRODUCT LIST */}
								{!loader && <>
									{ productList?.products?.length  <= 0  ?
										<tr name=' '><td className="text-center count_ignore" colSpan="7">No Data Available</td></tr>
									:
										productList?.products?.map((pData, index) => (
											<ProductRow 
												userId={userId}
												categories={categories}
												limit={limit} 
												capability={capability} 
												page={page} 
												setShowDelete={setShowDelete}  
												setDeleteId={setDeleteId} 
												setDeleteName={setDeleteName} 
												pData={pData} 
												dispatch={dispatch} 
												key={pData.id ? pData.id : pData._id}
												index={index}
												setCountError={setCountError}
												setProductId={setProductId}
												setShowLog={setShowLog}
											/>
										))
									}
								</>}
							</tbody>
						</table>    

						{/* LOADER */}
						{!productList || loader ? 
						<div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
							<div>Wait <b>Buddy</b>...</div>
							<div className="spinner-grow" role="status"/>
						</div>: ''}

						{!search?.length && 
							<Pagination 
								addClass="mt-3 position-relative z-index-200" 
								page={page} 
								setPage={setPage} 
								sortBy={sortBy} 
								setSortBy={setSortBy} 
								limit={limit} 
								setLimit={setLimit} 
								productList={productList} 
								pagination={pagination}
							/>
						}

					</div>
				</div>
			</div>


			{/* DELETE */}
      		{showDelete && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={deleteProduct}/>}

			{/* EXPORT PRODUCT */}
			{showExport && <ExportProduct handlePopup={setShowExport}/>}

			{/* PRODUCT LOGS */}
			{showLog && <ProductLogs id={productId} handleComponent={setShowLog}/>}

		</MainLayout>
	)
}

/* -------------------------------
					PRODUCT ROW
----------------------------------*/
const ProductRow = ({pData, userId, setProductId, setShowLog, index, setCountError, dispatch, limit, page, setShowDelete,  setDeleteId, setDeleteName, capability, categories}) => {
	const history                                     = useHistory()
	const [productSuggestions, setProductSuggestions] = useState([])
	const [variation, setVariation]                   = useState(false)

	// useEffect(() => updateProductInBulk(pData, userId, dispatch), [])

	//CHECK CATEGORY
	useEffect(() => {
		pData.categories.map(data => {
			var isCategoryExist         = categories.filter(cData => cData.id == data.category_id) 
			var checkCategoryNameUpdate = categories.filter(cData => cData.name == data.name) 
			
			if(!productSuggestions.length){
				!isCategoryExist.length && setProductSuggestions(list => [... list, {type: 'danger', message:'Category Removed'}])
				if(isCategoryExist.length) !checkCategoryNameUpdate.length && setProductSuggestions(list => [... list, {type: 'warning', message: 'Category name changed	'}])
				!isCategoryExist.length ? setCountError(error => error + 1) : !checkCategoryNameUpdate.length && setCountError(error => error + 1)
			}
		})
	},[])
	

	return (
		<>
			<tr name={pData.variation[0]?.name.toLowerCase()}>
				{/* COLUMN S.NO */}
				<td className="text-center count_ignore">{(limit * page) - (limit - 1) + index}</td>

				{/* COLUMN PRODUCT */}
				<td>
					<div className="d-flex align-items-center mb-2 last-mb-0">
						<img 
							className="rounded avatar-md mr-2 border" 
							src={
								pData.variation[0]?.images?.filter(data => data.featured == 'yes')[0]?.link ||
								pData.variation[0]?.images[0]?.link || 
								'/no-image.png'
							}
						/> 
						<div className="text-left">
							{capability?.product.view && <Link to={`/products/view/${pData.id ? pData?.id : pData?._id}`} onClick={()=> dispatch(clearProduct())}><div className="font-weight-500 white-space-normal line-2" title={pData.variation[0]?.name}>{pData.variation[0]?.name}</div> </Link> }
							
							{!capability?.product.view && <div className="font-weight-500 white-space-normal">{pData.variation[0]?.name}</div>}
							
							<div className="mr-3 d-flex justify-content-start align-items-center">
								{pData.status === 'pending' ? <span className="badge badge-warning-transparent text-capitalize">{pData.status}</span>:''}
								{pData.status === 'published' ? <span className="badge badge-success-transparent text-capitalize">{pData.status}</span>:''} 
								{pData.status === 'drafts' ? <span className="badge badge-light text-secondary text-capitalize">{pData.status}</span>:''}
								{pData.status === 'review' ? <span className="badge badge-primary-transparent text-capitalize">{pData.status}</span>:''}
								{productSuggestions.length > 0 && <div className="badge badge-danger error-badge danger-animation text-capitalize ml-1">Error</div>}
								<span className="text-secondary ml-3">SKU: {pData.variation[0]?.sku_id}</span> 
							</div>
							{ pData.variation.length > 1 &&
								<span onClick={() => setVariation(variation? false : true)} className="btn btn-outline-indigo pt-0 pb-0 pl-2 pr-2 mt-2 font-size-13">
									{pData.variation.length - 1} Variations Available
								</span>
							}
						</div>
					</div>
				</td>

				{/* COLUMN PRICE */}
				{/* <td className='text-center'>
					<div>&#8377; {pData.variation[0]?.price?.listing_price}</div>
					<del className="text-secondary">&#8377; {pData.variation[0]?.price?.mrp}</del>
				</td> */}

				{/* COLUMN CATEGORY */}
				<td>
					<div className="d-flex flex-wrap">
						{pData?.categories.map((category, index) => (
							<span key={index} className="tag mb-1 mr-1">{category.name}</span>
						))}
					</div>
				</td>

				{/* COLUMN DATE */}
				<td>
					{pData.createdAt == pData.updatedAt? "Published" : "Last Modified"}
					<div className="text-secondary">{moment(pData.updatedAt).utcOffset('+00:00').format('lll')}</div>
				</td>

				{/* COLUMN ACTIONS */}
				{capability?.product.view || capability?.product.edit || capability?.product.delete ?
				<td>
					<div className="tool_box">
						{capability?.product.view && <Link to={'/products/view/'+pData.id || pData._id }><button onClick={()=> dispatch(clearProduct())} className="btn btn-sm btn-white" title="View Product"><i className='las la-eye h4 m-0 text-primary'></i></button></Link> }
						{capability?.product.view && <button onClick={()=> {setShowLog(true); setProductId(pData.id || pData._id)}} className="btn btn-sm btn-white" title="View Log"><i className='lar la-sticky-note h4 m-0 text-secondary'></i></button>}
						
						{capability?.product.edit && <Link to={'/products/edit/'+pData.id || pData._id}><button onClick={()=> dispatch(clearProduct())} className="btn btn-sm btn-white" title="Edit Product"><i className='bx bx-pencil h4 m-0 text-warning'></i></button></Link> }

						{capability?.product.delete && <button onClick={() => {setShowDelete(true); setDeleteId(pData.id || pData._id); setDeleteName(pData.variation.length == 1 && pData.variation[0]?.name || pData.variation.length > 1 && "All Vaiations");}} className="btn btn-sm btn-white" title="Delete Product"><i className='bx bx-trash h4 m-0 text-danger'></i></button> }
					</div>
				</td> : null}
			</tr>

			{/* VARIATIONS */}
			{variation && 
				<tr className="variation_data animated fadeIn" name={pData.variation[0]?.name.toLowerCase()}>
					<td colSpan={10} className="count_ignore">
						{pData.variation.filter((data,index) => index !== 0).map((vData,i) => (
							<div className="d-flex align-items-center mb-2 last-mb-0">
								<img 
									className="rounded avatar-md mr-2 border" 
									src={
										vData.images?.filter(data => data.featured == 'yes').length ?
										vData.images?.filter(data => data.featured == 'yes')[0].link:
										vData.images[0]?.link
									}
								/> 
								<div className="text-left">
									<div className="font-weight-bold text-overflow-hidden">{vData.name}</div>
									<div className="mr-3 d-flex justify-content-start">
										<del>₹{vData.price.listing_price}</del> 
										<span className="ml-2">₹{vData.price.purchase_price}</span>, 
										<span className="text-secondary ml-3">SKU: {vData.sku_id}</span> 
									</div>
								</div>
							</div>
						))}
					</td>
				</tr>
			}
		</>
	)
}

/* -------------------------------
              FILTER
----------------------------------*/
const Filter = ({ search, setSearch, categories, dispatch, searchProduct }) => {
  
	const onFilterSubmit = async (e) => {

	 	e.preventDefault()
		const formData = new FormData(e.target)
		const data = {}
		for (let entry of formData.entries()) {
			data[entry[0]] = entry[1]
		} 

		const searchHistoryData = search ? JSON.parse(search) : "";
		
		await toast.promise(
			dispatch(searchProduct("q", {...searchHistoryData, ...data})),
			{
				pending: 'Searching products...',
				success: 'Search products list updated.',
				error:   {
				render(){
            		return 'Searching Error!!'
				}
				}
			})
			document.querySelector('.dropdown-menu.show').classList.remove('show')
	}
  
	return (
	  <form className="filterForm dropdown-menu pt-3 min-w-500 animated zoomIn filder-card-product" onClick={e => e.stopPropagation()} onSubmit={e => onFilterSubmit(e)}>
		<div className='px-3'>
			
			<div className='d-flex justify-content-end'>
				<button type='button' className='btn btn-outline-danger btn-sm' onClick={() => document.querySelector('.dropdown-menu.show').classList.remove('show')}>Close</button>
				<button type='submit' className='btn btn-primary ml-2 btn-sm'>Apply</button>
			</div>
		  	
		  	<div className="input-group mb-3 mt-3">
				<div className="input-group-prepend">
				<select className='form-control' name="searchKey">
					{/* <option value="sku_id">SKU ID</option> */}
					<option value="hsn_code">HSN Code</option>
				</select>
				</div>
				<input type="text" className="form-control" name='searchValue' autoComplete='off' class="form-control" placeholder="Enter Id" />
		  	</div>

			<div className='row'>
				<div className='col-md-6'>
					<div className='font-size-13 text-secondary font-weight-400 mt-1 mb-1'>Sort By</div>
					<div>
						<select className='form-control' name="sort">
							<option value="asc">Newest</option>
							<option value="desc">Oldest</option>
						</select>
					</div>
				</div>
				<div className='col-md-6'>
					<div className='font-size-13 text-secondary font-weight-400 mb-1 mt-1'>Status</div>
					<div>
						<select className='form-control' name="status">
							<option value="">All</option>
							<option value="pending">Pending</option>
							<option value="published">Published</option>
							<option value="review">Review</option>
							<option value="drafts">Drafts</option>
						</select>
					</div>
				</div>
			</div>
			
		  <div className='font-size-13 text-secondary font-weight-400 mb-1 mt-2'>Category</div>

		  {/* CATEGORY */}
		  <div className='mt-2 mb-4'>
			<select className='form-control' name="categories">
			<option value="">---</option>

			  {categories && categories?.map((catData, i) => (
                <option key={i} value={catData?.slug}>{catData?.name}</option>
              ))}
			  
			</select>
		  </div>
  
		</div>
	  </form>
	)
  }

/* -------------------------------
					PAGINATION
----------------------------------*/
const Pagination = ({page, setPage, sortBy, setSortBy, limit, setLimit, productList, pagination, addClass}) => {
	return (
		<>
			<style>{`.pagination:not(.p-2) li:nth-child(${page+1}) > *{background:#0068ff;color:white}`}</style>
			<div className={`d-flex justify-content-between align-items-center position-relative z-index-100 ${addClass}`}>
				<div className='d-flex align-items-center'>
					<select defaultValue={sortBy} onChange={e => setSortBy(e.target.value)} className='form-control mr-2'>
						<option value={'asc'}>Old</option>
						<option value={'desc'}>New</option>
					</select>
					<select defaultValue={limit} onChange={e => setLimit(e.target.value)} className='form-control'>
						{paginationLimit.map((limit,i) => <option key={i} value={limit}>{limit}</option>)}
					</select>
					<div className='font-weight-500 mr-3 text-secondary text-nowrap ml-2'>{(limit * page) - (limit - 1)}-{(limit * page) > productList?.total ? productList?.total : (limit * page)} of {productList?.total}</div>
				</div>

				<div className='d-flex'>
          <button onClick={() => setPage(productList.prevPage)} className={`btn btn-outline-primary border-light rounded-5 ${productList?.prevPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-left'></i></button>
          <Select 
            value={pagination[productList?.currentPage - 1]}
            onChange={e => setPage(e.label)}
            options={pagination}
            menuPlacement='top'
            placeholder='Page'
            className='mx-2 react-select pagination-select react-select'
          />
          <button onClick={() => setPage(productList.nextPage)} className={`btn btn-outline-primary border-light rounded-5 ${productList?.nextPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-right'></i></button>
        </div>
			</div>
		</>
	)
}


function mapStateToProps(state){
	const { message }       = state.message
	const { productList }   = state.products.products
	const { productSearch } = state.products.products
	const { categories }    = state.categories;
	const userId            = state?.auth?.currentUser?.id;
	let capability          = state?.auth?.currentUser?.capability
  	capability              = capability ? JSON.parse(capability) : null

//   console.log(productSearch,"=------productSearch")

	return {
		message,
		productList,
		productSearch,
		capability,
		categories,
		userId
	};
}

export default connect(mapStateToProps)(ProductList);