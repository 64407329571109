import React, {useEffect, useState} from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../layouts/main.component'
import { categoryList, addCategory, deleteCategory, getCategoryById } from '../../actions/category.action'
import CategoryAdd from './category-add.component'
import CategoryEdit from './category-edit.component'
import { toast } from 'react-toastify'
import DeleteConfirm from '../../helpers/delete.helper'
import { updateCategory } from '../../actions/category.action'

const CategoryList = ({dispatch, categories, categoryById, capability,stateList ,cityList,pincodeList,category_type}) => {
  const history                   = useHistory() //ROUTER
  const [imageUrl, setImageUrl]   = useState(null) //IMAGE
  const [search, setSearch]       = useState(''); //SEARCH CATEGORIES
  const [parentCat, setParentCat] = useState('')
  const [btnLoader, setBtnLoader] = useState()
  const [editCategoryForm, setEditCategoryForm] = useState(false) //CATEGORY EDIT 'show/hide'

  useEffect(()=>{
    dispatch(categoryList());
    document.title = 'Categories List';
  }, [dispatch])

  //--- ADD CATEGORY ---
  const onSubmitAddCategory = async (e) => { 
    e.preventDefault()
    const formData = new FormData(e.target)
    formData.append('parent', parentCat)
    const data = {}
    for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
    } 
console.log(parentCat,"-parentCat");
    
    // data['for_available'] = ["226024", "226010"] 
    const bodyData = {
      name     : data.name,
      status   : data.status,
      position : data.position,
      image    : data.image,
      type     : data.type,
      parent    : parentCat,

      for_available :{
        country  : ["India",],
        state    : [data.state],
        city     : [data.city],
        pincode  : [data.pincode]        
      }
    }

    await toast.promise(
			dispatch(addCategory(bodyData)),
			{
				pending: 'Category adding...',
				success: 'Successfully! Category Added',
				error: {
          render(){
            setBtnLoader(false)
            return 'Category not added'
          }
        }
			}
		)

    setBtnLoader(false)
    setImageUrl(null)
    e.target.reset()
  }

  //DELETE
  const [showDelete, setShowDelete]  = useState(false)
  const [deleteId, setDeleteId]      = useState()
  const [deleteName, setDeleteName]  = useState()

  //--- EDIT CATEGORY ---
  const editCategory = async (catId) => {
    await dispatch(getCategoryById(catId));
    await setEditCategoryForm(true);
  }

  return (
    <MainLayout>

      {/* EDIT CATEGORY */}
      {editCategoryForm && <CategoryEdit setBtnLoader={setBtnLoader} btnLoader={btnLoader} category={categoryById} dispatch={dispatch} categories={categories} formHandle={setEditCategoryForm}/>}

      {/* DELETE */}
      {showDelete && <DeleteConfirm id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={deleteCategory}/>}

      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Categories List</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Categories</div>
			</nav>


      {/* Header */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Categories <span className="badge badge-primary ml-2">{categories?.length}</span></div>
        <div className='d-flex align-items-center'>
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>  
          <div className="form-group w-100 mb-0"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search categories" /> 
            </div> 
          </div> 
        </div>
      </div>


      <div className="row">
        
        {/* ADD CATEGORY */}
        {capability?.category.add ?
				<div className="col-md-5">
					<div className="card card-body stick-top">
						<h4 className="font-weight-500 font-size-16 mb-4 text-secondary">Add New Category</h4>
						<form onSubmit={ (e) => {setBtnLoader(true); onSubmitAddCategory(e)}}>   
              <CategoryAdd btnLoader={btnLoader} setParentCat={setParentCat} dispatch={dispatch} setImageUrl={setImageUrl} imageUrl={imageUrl} categories={categories} stateList={stateList} cityList={cityList} pincodeList={pincodeList} category_type={category_type}/>                              
            </form>  
					</div>
				</div> :null}


        {/* CATEGORY LIST */}
        <div className={`${capability?.category.add ? 'col-md-7': 'col-md-12'}`}>
          
          <style>{/* CATEGORY SEARCH */}
            {`.category_table tbody tr:not([name${search.length ? `*="${search}"`: ''}]){
                visibility:hidden;
                position:absolute
            }`}
          </style>

					<div className="card card-body userlist-table">
            {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}
            
						<table className="table table-bordered table-hover text-center category_table border-0 text-nowrap mb-0">
							<thead>
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>IMAGE</th>
									<th>Position</th>
                  {capability?.category.edit || capability?.category.delete ? <th>Action</th> : null}
								</tr>
							</thead>
							<tbody>
                
								{categories?.length == 0 ?
									<tr><td colSpan="100">No Data Available</td></tr> 
                :
									<>
										{/* ----- CATEGORY */}
										{categories?.filter(res => res.parent=='' || res.parent==0).map((catData,index) => (
											<Category capability={capability} dispatch={dispatch} setShowDelete={setShowDelete}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} key={index}  number={index} catData={catData} categories={categories} editCategory={editCategory}/>
										))}
									</>
								}
							</tbody>
						</table>

            {/* LOADER */}
            {!categories && 
            <div className="d-flex justify-content-center flex-column mt-4 mb-3 align-items-center">
              <div>Wait <b>Buddy</b>...</div>
              <div className="spinner-grow" role="status"/>
            </div>}
            
					</div>
				</div>
			</div>
    </MainLayout>
  )
}



/* -------------------------------
					  CATEGORY
----------------------------------*/
const Category = ({ catData, categories, editCategory, setShowDelete,  setDeleteId, setDeleteName, capability, dispatch}) => {

  {/* AUTOMATIC UPDATE CATEGORY TYPE */}
  // useEffect(() => {
  //   var updateCategoryData = {
  //     name: catData.name,
  //     image: catData.image,
  //     status: catData.status,
  //     type: 'category',
  //     position:catData.position || 99,
  //     parent: catData.parent
  //   }
  //   dispatch(updateCategory(catData.id, updateCategoryData))
  // },[])

  // console.log(editCategory,"---editCategory");

  return (
    <React.Fragment key={catData.id}>
      <tr name={catData.name.toLowerCase()} className='category_list cursor-pointer' >
        <td></td>
        <td className="left t_1 text-left title-left" data-title={"Buddy! No Parent"}>
          { catData.name }<br/>
          {catData.status == "true"  && <span className="badge badge-success text-capitalize">Active</span>}
          {catData.status == "false" && <span className="badge badge-secondary text-capitalize">InActive</span>}
          <span className='ml-2 text-capitalize'>Type: {catData.type ? catData.type : <span className='text-danger'>😲 not added</span>}</span>
        </td>
        <td>
          {catData.image ? 
            <img src={ catData.image } alt={catData.name} className="rounded wd-50p wd-sm-70" />
          : 
            <div className="d-flex justify-content-center">
              <div className="avatar avatar-sm bg-danger d-none d-sm-flex"> 404 </div>
            </div>
          }
        </td>

        <td>{catData?.position}</td>

        {capability?.category.edit || capability?.category.delete ? 
        <td>
          <div className="btn-group align-top" onClick={e => e.stopPropagation()}>
            {/* EDIT & DELETE */}
            {capability?.category.edit ? 
            <button className="btn btn-sm btn-white" data-title="Edit Category" onClick={() => editCategory(catData.id)}>
              <i className='bx bx-pencil h4 m-0 text-primary'></i>
            </button> :null}

            {capability?.category.delete ? 
            <button className="btn btn-sm btn-white" data-title="Delete Category" onClick={() => {setShowDelete(true); setDeleteId(catData.id); setDeleteName(catData.name);}}>
              <i className='bx bx-trash h4 m-0 text-danger'></i>
            </button> :null}
          </div>
        </td> : null}
      </tr>
      <SubCategory setShowDelete={setShowDelete} dispatch={dispatch} capability={capability}  setDeleteId={setDeleteId} setDeleteName={setDeleteName} categories={categories} parent={catData.name} id={catData.id} ar={1} editCategory={editCategory}/>
    </React.Fragment>
  )
}


/* -------------------------------
					  SUB-CATEGORY
----------------------------------*/
const SubCategory = ({setShowDelete,  setDeleteId, setDeleteName, dispatch, categories, id , ar, editCategory, parent, capability}) => {
  var arrowRows = [];
  const [categoryUpdated, setCategoryUpdated] = useState(false)

  for (var i = 1; i <= ar; i++) {
      arrowRows.push(<i className="ion-md-remove font-size-13"> </i>);
  }
  

  {/* AUTOMATIC UPDATE CATEGORY TYPE */}
  // function updateCategoryType (catData2){
  //   if(categoryUpdated) return
  //   var updateCategoryData = {
  //     name: catData2.name,
  //     image: catData2.image,
  //     status: catData2.status,
  //     type: 'subcategory',
  //     position:9,
  //     parent: catData2.parent
  //   }
  //   console.log(updateCategoryData)
  //   // dispatch(updateCategory(catData2.id, updateCategoryData))
  //   setCategoryUpdated(true)
  // }

  return (
    <React.Fragment key={id}>
      {categories.filter(res=> res.parent===id).map((catData2, index) => (
        <React.Fragment key={catData2.id}>
          {/* {updateCategoryType(catData2)} */}
          <tr name={catData2.name.toLowerCase()}>
            <td></td>
            <td className="left t_1 text-left title-left" data-title={parent}>
              {arrowRows}  {catData2.name }<br/>
              {catData2.status == "true"  && <span className="badge badge-success text-capitalize">Active</span>}
              {catData2.status == "false" && <span className="badge badge-secondary text-capitalize">InActive</span>}
              <span className='ml-2 text-capitalize'>Type: {catData2.type ? catData2.type : <span className='text-danger'>😲 not added</span>}</span>
            </td>
            <td>
              {catData2.image ? 
                <img src={ catData2.image } alt={catData2.name} className="rounded wd-50p wd-sm-70" />
              : 
                <div className="d-flex justify-content-center">
                  <div className="avatar avatar-sm bg-danger d-none d-sm-flex"> 404 </div>
                </div>
              }
            </td>

            <td>{catData2?.position}</td>

            {capability?.category.edit || capability?.category.delete ? 
            <td>
              <div className="btn-group align-top">
                {capability?.category.edit ? 
                <button className="btn btn-sm btn-white" data-title="Edit Category" onClick={() => editCategory(catData2.id)}>
                  <i className='bx bx-pencil h4 m-0 text-primary'></i>
                </button> :null} 

                {capability?.category.delete ? 
                <button className="btn btn-sm btn-white" data-title="Delete Category" onClick={() => {setShowDelete(true); setDeleteId(catData2.id); setDeleteName(catData2.name);}}>
                  <i className='bx bx-trash h4 m-0 text-danger'></i>
                </button> :null}
              </div>
            </td> :null}
          </tr>
          <SubCategory setShowDelete={setShowDelete} dispatch={dispatch} capability={capability} setDeleteId={setDeleteId} setDeleteName={setDeleteName} categories={categories}  parent={`${parent} -> ${catData2.name}`} id={catData2.id} ar={ar+1} editCategory={editCategory}/>
        </React.Fragment>
    ))
      }
    </React.Fragment>
  )
}



function mapStateToProps(state) {
  const { message }       = state.message
  const { categories }    = state.categories
  const { categoryById }  = state.categories
  let capability          = state?.auth?.currentUser?.capability
  capability              = capability ? JSON.parse(capability) : null

  return {
    message,
    categories,
    categoryById,
    capability
  };
}

export default connect(mapStateToProps)(CategoryList);