import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import MainLayout from '../../layouts/main.component'
import { getRouteList, routeDelete } from '../../../actions/shipping.action'
import DeleteConfirm from '../../../helpers/delete.helper'
import { paginationLimit } from '../../../helpers/user.helper'
import Select from 'react-select'
import {ROUTE_BY_ID} from '../../../actions/types'
import moment from 'moment'
import RouteAddComponent from './route-add.component'
import RouteEditComponent from './route-edit.component'

const RouteListComponent = ({ dispatch, routeList, capability }) => {

  const history = useHistory();
  const [search, setSearch]               = useState(null);
  const [loader, setLoader]               = useState(true);
  const [routesList, setRoutesList]       = useState([]);
  const [showAddRoute, setShowAddRoute]   = useState(false);
  const [editRoute, setEditRoute]         = useState(false);
  const [editRouteId, setEditRouteId]     = useState();

  useEffect(() => {
    setRoutesList(routeList)
  }, [routeList])

  //LIST FILTER
  const [limit, setLimit]             = useState(25)
  const [page, setPage]               = useState(1)
  const [sortBy, setSortBy]           = useState('desc')
  const [pagination, setPagination]   = useState([])


  useEffect(async () => setPage(1), [limit, sortBy])
  useEffect(async () => {
    loadAndRefreshList()
  }, [page, limit, sortBy])


  useEffect(() => {
    setPagination([])
    var paginationLength = Math.ceil(routesList?.total / limit)
    if (!paginationLength > 0) return
    Array(paginationLength).fill().map((data, i) => setPagination(page => ([
      ...page, { label: i + 1, value: i + 1 }
    ])))
  }, [routesList, routeList])


  //LOAD & REFRESH LIST 
  const loadAndRefreshList = async () => {
    setLoader(true)
    setSearch(null)
    await dispatch(getRouteList(page, limit, sortBy))
    setLoader(false)
    document.querySelector('#search_box').value = ''
    return
  }

  //SEARCH
  // useEffect(async () => {
  //   if (search == null) return
  //   setLoader(true)
  //   if (search.length) await dispatch(customerSearch(search))
  //   else await loadAndRefreshList()
  //   setLoader(false)
  // }, [search])


  //DELETE
  const [showDelete, setShowDelete]               = useState(false)
  const [deleteId, setDeleteId]                   = useState()
  const [deleteName, setDeleteName]               = useState()
  const [isDeleted, setIsDeleted]                 = useState(false)
  const [allowUpdateDelete, setAllowUpdateDelete] = useState(false)

  useEffect(() => {
    if (!allowUpdateDelete) setAllowUpdateDelete(true)
    else loadAndRefreshList()
  }, [isDeleted])


  useEffect(async () => {
    document.title = 'Routes List'
    // document.querySelector('.table_role').classList.remove('flash')
    // setTimeout(async () => document.querySelector('.table_role').classList.add('flash'), 100)
    // document.querySelector('#search_box').value = ''
  }, [])


  return (
    <MainLayout>

      {/* BREADCRUMB */}
      <nav className="breadcrumb mt-3 mb-0">
        <div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Route List</div>
        <div className="breadcrumb-item" onClick={() => history.push('/')}>Dashboard</div>
        <div className="breadcrumb-item active">Route</div>
      </nav>

      {/* HEADER */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3 d-flex align-items-center">
          Route <span className="badge badge-primary ml-2">{routesList?.total}</span>          
        </div>

        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 title-top" data-title='Refresh' onClick={() => window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0 ml-2">
            <div className="input-icon">
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span>
              <input type="search" id='search_box' onChange={e => setSearch(e.target.value.toLowerCase())} className="form-control ht-40" placeholder="Search Route" />
            </div>
          </div>
          {capability?.route_setting?.add ?<button className="btn btn-primary text-nowrap ml-2" onClick={() => setShowAddRoute(true)}> + Add Route</button>:null}
          {/* <div onClick={() => setPage(routesList.prevPage)} className={`${routesList?.prevPage == 0 || search?.length ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-2"><i className=" bx bx-chevron-left font-size-19"></i></button></div> */}
          {/* <div onClick={() => setPage(routesList.nextPage)} className={`${routesList?.nextPage == 0 || search?.length ? 'disabled' : ''}`}><button className="btn btn-white center p-9 ml-1"><i className=" bx bx-chevron-right font-size-19"></i></button></div> */}
        </div>
      </div>


      {/* USER - LIST */}
      <div className="card">

        <div className="card-body">
          <div className="userlist-table">
            {search && <div className="mb-2">Search <b className="text-warning">"{search}"</b></div>}

            <table className="table card-table table-hover category_table table-bordered stable-vcenter text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center p-0">City Name</th>
                  <th className="text-center p-0">Route Name</th>
                  <th className="text-center">Available Days</th>
                  <th className="text-center">Time</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Date</th>
                  {capability?.route_setting?.edit || capability?.route_setting?.delete ?<th className="text-center">Action</th> : null}
                  {/* <th className="text-center">Action</th> */}
                </tr>
              </thead>
              <tbody>
                {routesList?.data?.length <= 0 ?
                  <>{!loader && <tr name=""><td className="text-center count_ignore" colSpan="7">No Data Available</td></tr>}</>
                  :
                  <>
                    {!loader && routesList?.data?.map((rData, index) => (
                      // console.log(JSON.parse(rData.is_available_days),"--rData"),
                      <tr key={index} name={rData?.first_name?.toLowerCase() + ' ' + rData?.last_name?.toLowerCase()}>
                        <td className="text-center count_ignore">{(limit * page) - (limit - 1) + index}</td>
                        <td className="text-center w-50">{rData.city}</td>
                        <td className="text-center">{rData.name}</td>
                        <td>{rData.is_available_days.map(data => <span>{data},</span>)}</td>
                        <td>
                          <p>Calling Time : {rData.calling_time.start_time} - {rData.calling_time.end_time}</p>
                          <p>Order Time : {rData.order_time.start_time} - {rData.order_time.end_time}</p>
                        </td>
                        <td>{rData.status}</td>

                        <td className="text-center"><div className="text-secondary">{moment().format('ll')}</div><div className="text-secondary">{moment().format('LT')}</div> </td>
                       {capability?.route_setting?.edit || capability?.route_setting?.delete ?
                        <td className='text-center'>
                          <div className='btn-group'>
                            {capability?.route_setting?.edit ? <button onClick={() => { setEditRoute(true); dispatch({ type: ROUTE_BY_ID, payload: null }); setEditRouteId(rData.id || rData._id) }} className="btn btn-sm btn-white" data-title="Edit User"><i className='bx bx-pencil h4 m-0 text-warning'></i></button>:null}
                            {capability?.route_setting?.delete ?<button onClick={() => { setShowDelete(true); setDeleteId(rData.id || rData._id); setDeleteName(rData.name); }} className="btn btn-sm btn-white" data-title="Delete Route"><i className='bx bx-trash h4 m-0 text-danger'></i></button>:null}
                          </div>
                        </td> 
                        :null}
                      </tr>
                    ))}
                  </>
                }
              </tbody>
            </table>

            {/* LOADER */}
            {loader &&
              <div className="d-flex justify-content-center flex-column mt-5 mb-3 align-items-center">
                <div>Wait <b>Buddy</b>...</div>
                <div className="spinner-grow" role="status" />
              </div>}

            {!search?.length && <Pagination addClass="mt-3 position-relative z-index-200" page={page} setPage={setPage} sortBy={sortBy} setSortBy={setSortBy} limit={limit} setLimit={setLimit} routesList={routesList} pagination={pagination} />}

          </div>
        </div>
      </div>

      {/* Route - ADD, Show & EDIT */}
      {showAddRoute && <RouteAddComponent handleComponent={setShowAddRoute} loadAndRefreshList={loadAndRefreshList} />}
      {editRoute && <RouteEditComponent handleComponent={setEditRoute} loadAndRefreshList={loadAndRefreshList} id={editRouteId} />}
      {/* {showEditUser && <UserEdit role={roleShow} handleComponent={setShowEditUser} loadAndRefreshList={loadAndRefreshList} id={editUserId} />} */}

      {/* DELETE */}
      {showDelete && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={routeDelete} />}

      {/* EXPORT PRODUCT */}
      {/* {showExport && <ExportUser handlePopup={setShowExport} />} */}

    </MainLayout >
  )
}


/* -------------------------------
          PAGINATION
----------------------------------*/
const Pagination = ({ page, setPage, sortBy, setSortBy, limit, setLimit, routesList, pagination, addClass }) => {

  return (
    <>
      <style>{`.pagination:not(.p-2) li:nth-child(${page + 1}) > *{background:#0068ff;color:white}`}</style>
      <div className={`d-flex justify-content-between align-items-center position-relative z-index-100 ${addClass}`}>
        <div className='d-flex align-items-center'>
          <select defaultValue={sortBy} onChange={e => setSortBy(e.target.value)} className='form-control mr-2'>
            <option value={'desc'}>New</option>
            <option value={'asc'}>Old</option>
          </select>
          <select defaultValue={limit} onChange={e => setLimit(e.target.value)} className='form-control'>
            {paginationLimit.map((limit, i) => <option key={i} value={limit}>{limit}</option>)}
          </select>
          <div className='font-weight-500 mr-3 text-secondary text-nowrap ml-2'>{(limit * page) - (limit - 1)}-{(limit * page) > routesList?.total ? routesList?.total : (limit * page)} of {routesList?.total}</div>
        </div>

        <div className='d-flex'>
          <button onClick={() => setPage(routesList.prevPage)} className={`btn btn-outline-primary border-light rounded-5 ${routesList?.prevPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-left'></i></button>
          <Select
            value={pagination[routesList?.currentPage - 1]}
            onChange={e => setPage(e.label)}
            options={pagination}
            menuPlacement='top'
            placeholder='Page'
            className='mx-2 react-select pagination-select react-select'
          />
          <button onClick={() => setPage(routesList.nextPage)} className={`btn btn-outline-primary border-light rounded-5 ${routesList?.nextPage == 0 ? 'disabled' : ''}`}><i className='bx bxs-chevron-right'></i></button>
        </div>
      </div>
    </>
  )
}


function mapStateToProps(state) {
  const { routeList } = state.shipping
  let capability  = state.auth.currentUser?.capability
  capability = capability ? JSON.parse(capability) : null
  return {
    routeList,
    capability
  }
}

export default connect(mapStateToProps)(RouteListComponent);