export const paymentModeOptions   = ['active', 'Mixed (CASH+ONLINE)','cod','Wallet', 'CREDIT_CARD', 'DEBIT_CARD','NET_BANKING','UPI', 'PREPAID_CARD', 'PhonePe']
// ['COD','UPI', 'BANK TRANSFER', 'CASHFREE','NET_BANKING']
export const paymentStatusOptions = ['unpaid','paid']
export const paidPaymentMode      = ['Mixed (CASH+ONLINE)', 'Wallet', 'CREDIT_CARD', 'DEBIT_CARD','NET_BANKING','UPI', 'PhonePe']
export const forChangingPaymentStatusPaymentModeRequired =  ['Mixed (CASH+ONLINE)', 'cod','Wallet', 'CREDIT_CARD', 'DEBIT_CARD','NET_BANKING','UPI', 'PhonePe']
export const orderPagination     = [25, 50, 75, 100]
export const route = {
  'Route 1': [226003,226020,226021,226022,226024,226026,226013,226006,226018,226031],
  'Route 2': [226016,226010,226028,226019],
  'Route 3': [226001,226002,226014,226006,226025,226029,226030,227405,226301,226027],
  'Route 4': [226004,226005,226011,226012,226017,226023,226008],
}

export const orderStatusOptions   = [
  {
    "name"           : "Order Received",
    "value"          : "order-received",
    "new_order"      : true,
    "order_status"   : ['order-received', 'order-approved'],
    "payment_status" : ['unpaid'],
    "payment_mode"   : [],
    
  },
  {
    "name"           : "Order Approved",
    "value"          : "order-approved",
    "new_order"      : true,
    "order_status"   : ["order-approved",'order-received', 'order-confirmed-cod-payment', 'order-confirmed-online-payment', 'order-delivered', 'order-cancelled'],
    "payment_status" : ['unpaid'],
    "payment_mode"   : [],
    
  },
  {
    "name"  : "Order confirmed",
    "value" : "order-confirmed",
    "new_order"      : false,
    "order_status"   : ['order-confirmed', 'order-confirmed-online-payment', 'order-out-for-delivery', 'order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order confirmed (Online  Payment)",
    "value" : "order-confirmed-online-payment",
    "new_order"      : false,
    "order_status"   : ['order-confirmed', 'order-confirmed-online-payment', 'order-out-for-delivery', 'order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order confirmed (COD)",
    "value" : "order-confirmed-cod-payment",
    "new_order"      : false,
    "order_status"   : ['order-confirmed', 'order-confirmed-online-payment', 'order-out-for-delivery', 'order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order Out For Delivery",
    "value" : "order-out-for-delivery",
    "new_order"      : false,
    "order_status"   : ['order-confirmed', 'order-confirmed-online-payment', 'order-out-for-delivery', 'order-cancelled'],
    "payment_status" : ['unpaid','paid'],
    "payment_mode"   : forChangingPaymentStatusPaymentModeRequired,
  },
  {
    "name"  : "Order Delivered",
    "value" : "order-delivered",
    "new_order"      : false,
    "order_status"   : ['order-confirmed', 'order-confirmed-online-payment', 'order-out-for-delivery', 'order-cancelled'],
    "payment_status" : [],
    "payment_mode"   : [],
  },
  {
    "name"  : "Order Cancelled",
    "value" : "order-cancelled",
    "new_order"      : false,
    "order_status"   : ['order-cancelled'],
    "payment_status" : [],
    "payment_mode"   : [],
  },
  {
    "name"  : "Order Edited",
    "value" : "order-edited",
    "new_order"      : false,
    "order_status"   : ['order-edited'],
    "payment_status" : [],
    "payment_mode"   : [],
  },
  {
    "name"  : "Estimate Invoice Rejected",
    "value" : "order-estimate-invoice-rejected",
    "new_order"      : false,
    "order_status"   : ['order-estimate-invoice-rejected'],
    "payment_status" : [],
    "payment_mode"   : [],
  }
]
