import React, { useState, useEffect } from 'react'
import MainLayout from '../../layouts/main.component'
import {useHistory} from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import RoutePlanNewComponent from './route-plan-new.component'

function RoutePlanning({ dispatch, routeList, capability }) {
  const history                                   = useHistory()
  const [loader, setLoader]                       = useState()
  const [showAddRoutePlan, setShowAddRoutePlan]   = useState(false);

  useEffect(() => {
    document.title = 'Route Planning'    
  },[])

  return (
    <MainLayout>

      {/* BREADCRUMB */} 
			<nav className="breadcrumb mt-3 mb-0"> 
				<div className="cursor-pointer mr-4 text-primary" onClick={history.goBack}><i className='bx bxs-chevron-left'></i> Back</div>
        <div className="mr-auto page_title">Route Planning</div>
				<div className="breadcrumb-item" onClick={()=> history.push('/') }>Dashboard</div>
				<div className="breadcrumb-item active">Route Planning</div>
			</nav>

      {/* HEADER */}
			<div className="d-flex justify-content-between align-items-center">
        <div className="h4 mt-4 mb-3">Total <span className="badge badge-primary ml-2">1</span> </div>
        <div className="d-flex align-items-center">
          <button className="btn btn-white border-5 mr-2 title-top" data-title='Refresh' onClick={()=> window.location.reload()}><i className="bx bx-refresh pt-1 font-size-16"></i></button>
          <div className="form-group w-100 mb-0 d-flex"> 
            <div className="input-icon"> 
              <span className="input-icon-addon"> <i className="fe fe-search" /> </span> 
              <input type="search" className="form-control ht-40" placeholder="Search Product" /> 
            </div> 
          </div>  
          <button className="btn btn-primary text-nowrap ml-2" onClick={() => setShowAddRoutePlan(true)}> + Add Route Plan</button> 
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-body userlist-table">

          </div>
        </div>
      </div>

      { showAddRoutePlan && <RoutePlanNewComponent handleComponent={setShowAddRoutePlan} dispatch={dispatch} /> }
    </MainLayout>
  )
}



function mapStateToProps(state) {
  const { routeList } = state.shipping
  return {
    routeList,
    // capability
  }
}

export default connect(mapStateToProps)(RoutePlanning);
