import { useState, useEffect } from "react"
import { connect } from "react-redux"
import exportFromJSON from 'export-from-json'
import { EXPORT_CUSTOMER_LIST } from "../../actions/types"
import { exportClubbedBuyOrderList } from "../../actions/orders.action"
import moment from 'moment'
import { exportUserListF  } from '../../actions/users.action'


const ExportUser = ({handlePopup, dispatch, exportUserList}) => {
	const [loader, setLoader]               = useState(false)
  const [page, setPage]                   = useState(1)
	const [limit, setLimit]                 = useState(50)
	const [sortBy, setSortBy]               = useState('desc')
  const [exportData, setExportData]       = useState([])
  const [isExported, setIsExported]       = useState(false)
  const [totalPageShow, setTotalPageShow] = useState(0)
  // console.log(exportUserList)

  function loadOrderList(nextPage){
    setLoader(true); 
    dispatch(exportUserListF(nextPage || page,limit,sortBy))
  }

  useEffect(async () => {
    if(!exportUserList) return

    let formateList = []
    let totalPage   = Math.ceil(exportUserList?.total / limit)
    setTotalPageShow(totalPage)
    
    exportUserList?.users?.map((uData, index) => {
      let finalData = {
        'S. No.'                    : exportData.length + formateList.length + 1,
        'CUSTOMER NAME'             : uData?.first_name +' '+ uData?.last_name,
        'CUSTOMER ID'               : uData?.user_number,
        'MOBILE NUMBER'             : uData?.mobile_number,
        'E-MAIL ID'                 : uData?.email,
        'AFFILIATE/REFRENCE NAME'   : uData.reference?.first_name +' '+ uData.reference?.last_name,

      }
      let addressList = ''
      uData?.address?.map((address, i) => {
        if(i > 0) addressList += ' |\n'
        addressList += `${address.address}, ${address.locality}, ${address.landmark}, ${address.city}, ${address.state} - ${address.pincode}`
      })
      finalData['ADDRESS LIST'] = addressList
      formateList.push(finalData)
    })

    setExportData(data => data.concat(formateList))

    if(page + 1 <= totalPage){
      setPage(page => page + 1)
      await loadOrderList(page + 1)
    }else{
      setLoader(false);
      setIsExported(true)
    }
  },[exportUserList])


  function exportToCSV(downloadType){
    const fileName   = `User List - ${moment(Date.now()).utcOffset('+05:30').format('lll')}`
    const exportType = exportFromJSON.types[downloadType]
    let data         = exportData
    exportFromJSON({ data, fileName, exportType })
  }


  async function refreshComponent(close){
    loadOrderList = null
    setIsExported(false)
    setPage(1)
    setLoader(false)
    setExportData([])
    await dispatch({
      type: EXPORT_CUSTOMER_LIST,
      payload: null
    })
    loader && !isExported && window.location.reload()
    close && await handlePopup(false)
  }


  return (
    <>
    <div className="modal effect-flip-vertical show" id="modaldemo8" aria-modal="true" style={{display:"block"}}>
      <div className="overlayer" onClick={() => {refreshComponent(true)}}></div>
      <div className="modal-dialog modal-dialog-right" id="hideMe" role="document">

        <div className="modal-content modal-content-demo">
          <div className="modal-header px-3 py-2 shadow-base d-flex align-items-center position-relative z-index-100">
            <h6 className="modal-title py-1"><i className='bx bxs-file-export mr-1'></i> Export User</h6>
          </div>
          <div className="modal-body d-flex pt-4 pb-4">
            <div className="w-100 d-flex flex-column justify-center">

              {!loader && !isExported &&
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <div>Sort By:</div> 
                  <select className="form-control ml-auto w-auto" onChange={e => setSortBy(e.target.value)}>
                    <option value='desc'>New User First</option>
                    <option value='asc'>Old User First</option>
                  </select>
                </div>
                <div className="card border mt-3 center px-3 py-5">
                  <button onClick={() => loadOrderList()} className={`btn btn-warning`}> 
                    {loader ? <span className="spinner-border spinner-border-sm mr-1"></span> : <i className='bx bxs-download mr-1'></i>}
                    Export User
                  </button>
                  <p className="mt-2 text-secondary">🙂 Export Users</p>
                </div>
              </>}

              {loader && !isExported &&
                <div className="card border mt-3 px-3 py-5 animated-1s fadeIn">
                  <div className="text-center mb-1"><b>{exportData.length} User Exported</b></div>
                  <div className="progress rounded-50">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${(page/ totalPageShow) * 100}%`, height:15}}></div>
                  </div>
                </div>
              } 

              {isExported &&
                <div className="card border mt-3 center px-3 py-5 animated-1s fadeIn">
                  <div className="btn-group">
                    <button onClick={() => exportToCSV('csv')} className="btn btn-outline-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download CSV
                    </button>
                    
                    <button onClick={() => exportToCSV('xls')} className="btn btn-primary"> 
                      <i className='bx bxs-download mr-1'></i>
                      Download XLS
                    </button>
                  </div>
                  <p className="mt-2 text-center">🙂 Now, you can download</p>
                </div>
              } 

            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}


function mapStateToProps(state){
  const {exportUserList} = state.users.users
  return {
    exportUserList
  }
}

export default connect(mapStateToProps)(ExportUser)