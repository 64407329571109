import { handleErrors } from "../helpers/common-function.helper";
import shippingService from "../services/shipping.service";
import { 
	CITY_LIST, 
	CITY_ADD, 
	CITY_EDIT, 
	CITY_DELETE, 
	COUNTRY_LIST, 
	STATE_LIST, 
	COUNTRY_ADD, 
	COUNTRY_EDIT, 
	COUNTRY_BY_ID, 
	COUNTRY_DELETE, 
	STATE_EDIT, 
	STATE_BY_ID,
	STATE_DELETE, 
	STATE_ADD, 
	CITY_BY_ID,
	PINCODE_DELETE, 
	PINCODE_BY_ID, 
	PINCODE_EDIT, 
	PINCODE_ADD, 
	PINCODE_LIST ,
	ROUTE_DELETE, 
	ROUTE_BY_ID, 
	ROUTE_EDIT, 
	ROUTE_ADD, 
	ROUTE_LIST,
	ROUTE_PLAN_DELIVERY_LIST,
	ROUTE_PLAN_DELIVERY_ORDER__LIST
} from "../actions/types"

//Country
export const getCountryList = (sort, status) => async (dispatch) => {
	return shippingService.countryList(sort, status).then(
		(data) => dispatch({ type: COUNTRY_LIST, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const addCountry = (data) => async (dispatch) => {
	return shippingService.addCountry(data).then(
		(data) => dispatch({ type: COUNTRY_ADD, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const editCountry = (data, id) => async (dispatch) => {
	return shippingService.editCountry(data, id).then(
		(data) => dispatch({ type: COUNTRY_EDIT, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const getCountryById = (id) => async (dispatch) => {
	return shippingService.countryById(id).then(
		(data) => dispatch({ type: COUNTRY_BY_ID, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const countryDelete = (id) => async (dispatch) => {
	return shippingService.countryDelete(id).then(
		(data) => dispatch({ type: COUNTRY_DELETE, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}


//STATE
export const getStateList = (country, sort, status) => (dispatch) => {
	return shippingService.stateList(country, sort, status).then(
		(data) => dispatch({ type: STATE_LIST, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const addState = (data) => async (dispatch) => {
	return shippingService.addState(data).then(
		(data) => dispatch({ type: STATE_ADD, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const editState = (data, id) => async (dispatch) => {
	return shippingService.editState(data, id).then(
		(data) => dispatch({ type: STATE_EDIT, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const getStateById = (id) => async (dispatch) => {
	return shippingService.stateById(id).then(
		(data) => dispatch({ type: STATE_BY_ID, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const stateDelete = (id) => async (dispatch) => {
	return shippingService.stateDelete(id).then(
		(data) => dispatch({ type: STATE_DELETE, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}


//CITY
export const getCityList = (country, state, sort, status) => (dispatch) => {
	return shippingService.cityList(country, state, sort, status).then(
		(data) => dispatch({ type: CITY_LIST, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const addCity = (data) => async (dispatch) => {
	return shippingService.addCity(data).then(
		(data) => dispatch({ type: CITY_ADD, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const editCity = (data, id) => async (dispatch) => {
	return shippingService.editCity(data, id).then(
		(data) => dispatch({ type: CITY_EDIT, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const getCityById = (id) => async (dispatch) => {
	return shippingService.cityById(id).then(
		(data) => dispatch({ type: CITY_BY_ID, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const cityDelete = (id) => async (dispatch) => {
	return shippingService.cityDelete(id).then(
		(data) => dispatch({ type: CITY_DELETE, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}


//PINCODE
export const getPincodeList = (country, state, city, sort, status) => (dispatch) => {
	return shippingService.pincodeList(country, state, city, sort, status).then(
		(data) => dispatch({ type: PINCODE_LIST, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const addPincode = (data) => async (dispatch) => {
	return shippingService.addPincode(data).then(
		(data) => dispatch({ type: PINCODE_ADD, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const editPincode = (data, id) => async (dispatch) => {
	return shippingService.editPincode(data, id).then(
		(data) => dispatch({ type: PINCODE_EDIT, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const getPincodeById = (id) => async (dispatch) => {
	return shippingService.pincodeById(id).then(
		(data) => dispatch({ type: PINCODE_BY_ID, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const pincodeDelete = (id) => async (dispatch) => {
	return shippingService.pincodeDelete(id).then(
		(data) => dispatch({ type: PINCODE_DELETE, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}


//ROUTE
export const getRouteList = (page, limit, sortBy, status) => (dispatch) => {
	return shippingService.routeList(page, limit, sortBy, status).then(
		(data) => dispatch({ type: ROUTE_LIST, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const addRoute = (data) => async (dispatch) => {
	return shippingService.addRoute(data).then(
		(data) => dispatch({ type: ROUTE_ADD, payload: data.success[0].data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const editRoute = (data, id) => async (dispatch) => {
	return shippingService.editRoute(data, id).then(
		(data) => dispatch({ type: ROUTE_EDIT, payload: data.success[0].data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const getRouteById = (id) => async (dispatch) => {
	return shippingService.routeById(id).then(
		(data) => dispatch({ type: ROUTE_BY_ID, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const routeDelete = (id) => async (dispatch) => {
	return shippingService.routeDelete(id).then(
		(data) => dispatch({ type: ROUTE_DELETE, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}


//ROUTE PLAN 
export const getDeliveryRouteList = (country, state, city) => (dispatch) => {
	return shippingService.getDeliveryRoutePlan(country, state, city).then(
		(data) => dispatch({ type: ROUTE_PLAN_DELIVERY_LIST, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}

export const getDeliveryRouteOrderList = (routeDeliveryId) => (dispatch) => {
	return shippingService.getDeliveryRoutePlanOrder(routeDeliveryId).then(
		(data) => dispatch({ type: ROUTE_PLAN_DELIVERY_ORDER__LIST, payload: data }),
		(error) => handleErrors(error, dispatch)
	)
}
