import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import { convertToArray, inputFirstLetterCapital } from '../../../helpers/common-function.helper'
import { updateWhatsappTemplate,getWhatsappByName } from "../../../actions/message-templates.action";
import SunEditor from 'suneditor-react';
// get contries

const EditWhatsapp = ({ handleComponent, dispatch,whatsappByName, id,loadAndRefreshList, name }) =>{

  const [btnLoader, setBtnLoader]               = useState(false)
  const [loader, setLoader]                     = useState(true);

  useEffect(async () => {
    document.title = `Edit Whatsapp Templates | ${name}`
    setLoader(true)
  }, [])

  useEffect(() => {
    dispatch(getWhatsappByName(name))
  },[])


  const onSubmitUpdateWhatsapp = async (e) => {
// console.log(id,"-----------id");

    e.preventDefault()
    const formData = new FormData(e.target)    
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }

    const bodyData = { 
      name: data.name,
      content: data.content,
      peid:"",
      dlttemplateid:""
     }
     var checkfield = convertToArray(bodyData).filter(data => data == '')

     await toast.promise(
        dispatch(updateWhatsappTemplate(id, bodyData)),
        {
          pending: 'Whatsapp Template Updating...',
          success: 'Successfully! Whatsapp Template update',
          error: {
            render() {
              setBtnLoader(false);
              return 'Whatsapp Template not update'
            }
          }
        }
      )
  await loadAndRefreshList();
  await handleComponent(false);
  }

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right modal-dialog-for-add-user">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-plus font-size-22 mr-2'></i> Edit Whatsapp Template </h6>
          </div>
            <form onSubmit={ (e) => {setBtnLoader(true); onSubmitUpdateWhatsapp(e) }}>  
            <div className="modal-body">
              <div className="row">
                  <div className="col-md-12">
                    <div className="input_wrap w-100">
                      <input name='name' type="text"  placeholder=" " defaultValue={whatsappByName?.name}/>
                      <label>Name</label>
                    </div>
                  </div>

                  <div className="col-md-12 mt-5">
                    <div className="form-group mb-0"> 
                      <label className="main-content-label tx-11 m-0">Content</label> 
									    <textarea rows={6} name="content" className="form-control" defaultValue={whatsappByName?.content}/>
                    </div>
                    {/* <SunEditor name="content" lang="en" height="200" placeholder="Please type here..." setContents={whatsappByName?.content}/> */}
                  </div>

              </div>
            </div>
            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Update Whatsapp' : <span className="spinner-border spinner-border-sm mx-3"></span>}
              </button>
            </div>
            </form> 
        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
    const { message }             = state.message;
    const { whatsappByName }      = state.messageTemplate.messageTemplate;
  
    // console.log(whatsappByName,"===========whatsappByName");

    return {
      message,
      whatsappByName
    };
  }

export default connect(mapStateToProps)(EditWhatsapp);