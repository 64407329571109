import { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { ROUTE_PLAN_DELIVERY_LIST_CLEAR } from '../../../actions/types'
import { getDeliveryRouteList, getDeliveryRouteOrderList } from '../../../actions/shipping.action';
import { getCountryList, getStateList, getCityList} from '../../../actions/shipping.action';
import RouteVehiclePlanRoute from './route-vehicle-plan-.component';

function RoutePlanAdd({ handleComponent, dispatch, loadAndRefreshList, countryList, stateList, cityList, deliveryRoutePlanList, deliveryRoutePlanOrderList }) {

  const [btnLoader, setBtnLoader]                         = useState(false)
  const [loader, setLoader]                               = useState(false)
  const [routePlanTabs, setRoutePlanTabs]                 = useState()
  const [addressCountryId, setAddressCountryId]           = useState("6321679bc2fe1900193f9184");
  const [addressStateId, setAddressStateId]               = useState("632167dcc2fe1900193f918f");
  const [showVehiclePlan, setShowVehiclePlan]             = useState(false);

  useEffect(async () => {document.title = 'Routes Plan | Add New';}, [])
  useEffect(() => {dispatch(getCountryList())},[])
  useEffect(() => {onGetState("6321679bc2fe1900193f9184")},[countryList])
  useEffect(() => {onGetCity(addressStateId)},[stateList])


  const onSubmitAddRoute = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)    
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }

    const bodyData = {
      name                : data.name,
      country             : data.country,
      state               : data.state,
      city                : data.city,
      // pincode             : pincodeInArray,
      // is_available_days   : availableDaysInArray,
      start_time           : data.start_time,
      end_time             : data.end_time,     
      status              : data.status     
    }

    // await toast.promise(
    //   dispatch(addRoute(bodyData)),
    //   {
    //     pending: 'Route adding...',
    //     success: 'Successfully! Route Added',
    //     error: {
    //       render() {
    //         setBtnLoader(false);
    //         return 'Route not added'
    //       }

    //     }
    //   }
    // )
  }

  const onGetState = (countryId) => {
    setAddressCountryId(countryId)
    dispatch(getStateList(countryId))
  }

  const onGetCity = (stateId) => {
    setAddressStateId(stateId)
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetRoute = async (city) => {
    setLoader(true) 
    await toast.promise(
      dispatch(getDeliveryRouteList("India", "Uttar Pradesh", city)),
      {
        pending: 'Fetching delivery route...',
        success: 'Successfully! fetch',
        error: {
          render() {
            setLoader(false);
            return 'Route not fetch'
          }
        }
      }
    )
    setLoader(false);
  }

  const onGetRouteWiseOrder = async (routeDeliveryId) => {
    setLoader(true) 
    await toast.promise(
      dispatch(getDeliveryRouteOrderList(routeDeliveryId)),
      {
        pending: 'Fetching delivery route order...',
        success: 'Successfully! order fetch',
        error: {
          render() {
            setLoader(false);
            return 'Route order not fetch'
          }
        }
      }
    )
    setLoader(false);
  }
   
  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right modal-dialog-for-add-route-plan">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-plus font-size-22 mr-2'></i> Add New Route  Plan</h6>
          </div>
          <form onSubmit={(e) => { setBtnLoader(true); onSubmitAddRoute(e) }}>
            <div className="modal-body">

              {countryList &&               
              <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label>Country</label>
                    <select className='form-control' onChange={(e) => {onGetState(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option value="">---</option>
                      {countryList?.data?.map((country, i) => (
                        <option key={i} value={country.name} name={country.id} selected={country.name=="India" ? true : false}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='form-group'>
                    <label>State</label>
                    <select className='form-control' onChange={(e) => {onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
                      <option value="">---</option>
                      {stateList?.data?.map((state, i) => (
                        <option key={i} value={state.name} name={state.id} selected={state.name=="Uttar Pradesh" ? true : false}>{state.name}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='col-md-3'>
                  <div className='form-group'>
                    <label>City</label>
                    <select className='form-control' onChange={(e) => onGetRoute(e.target.value)}>
                      <option value="">---</option>
                      {cityList?.data?.map((data, i) => (
                        <option key={i} value={data.name} name={data.id}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              }
              <hr/>
              {deliveryRoutePlanList ? 
                <>
                <div className="row mt-4">
                  <div className='col-md-12'>
                      <div className="d-md-flex">
                        <div className>
                          <div className="panel panel-primary tabs-style-4">
                            <div className="tab-menu-heading">
                              <div className="tabs-menu">
                                <ul className="nav panel-tabs mr-3">
                                  { deliveryRoutePlanList && deliveryRoutePlanList?.map((rPlan, index) => (
                                    <li className><a href={`#tab-${rPlan.id}`} onClick={() => onGetRouteWiseOrder(rPlan.id) } className={routePlanTabs==rPlan.id ? "active" : ""} data-toggle="tab"><i className="fe fe-map-pin mr-1" /> {rPlan.name}</a></li>     
                                  ))}                         
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {deliveryRoutePlanOrderList?.orders && !loader &&
                        <div className="tabs-style-4 add-route-plan-tabs-content">
                          <div className="panel-body pt-0">
                            <div className="tab-content">
                              <div className={`tab-pane active`}>
                                  <div className='row'>
                                    <div className='col-md-12'>
                                      <div className="btn-group mr-5 float-right">
                                        <button className={`btn btn-primary`}><i className='bx bxs-check-square'></i> Check Supplier</button>
                                        <button className={`btn btn-warning ml-2`} onClick={() => setShowVehiclePlan(true)}><i className='bx bxs-truck'></i> Vehicle Plan</button>
                                      </div>                                
                                    </div>  
                                  </div>  
                                  <p><strong>Total Order : </strong>{deliveryRoutePlanOrderList?.orders?.length}</p>                                               
                                  <table className='table table-bordered mt-3'>
                                    <thead>
                                      <tr>
                                        <th>Order No.</th>
                                        <th>Customer</th>
                                        <th>Payment</th>
                                        <th>Affiliate</th>
                                        <th>Affiliate Manager</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      { deliveryRoutePlanOrderList?.orders?.map((routeOrder, index) => (              
                                      <tr>
                                        <td>{routeOrder.order_number}</td>
                                        <td>{routeOrder.user.first_name + " "+ routeOrder.user.last_name}</td>
                                        <td>
                                          <strong>Mode : </strong> {routeOrder.payment_mode}<br/>
                                          <strong>Status : </strong> {routeOrder.payment_status}
                                        </td>
                                        <td>{routeOrder.affiliate.first_name + " "+ routeOrder.affiliate.last_name}</td>
                                        <td>---</td>
                                      </tr>
                                      ))}  
                                    </tbody>
                                  </table>    
                                
                                </div>
                            </div>
                          </div>
                        </div>
                        }
                      </div>                    
                    </div> 
                </div>
                </>
                : 
                <div className='alert alert-warning offset-1'>Route not available</div>      
              }

              {loader &&
              <div className="d-flex mx-auto justify-content-center flex-column mt-4 mb-3 align-items-center">
                <div>Wait <b>Buddy</b>...</div>
                <div className="spinner-grow" role="status" />
              </div>
              }
              
            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => {dispatch({type: ROUTE_PLAN_DELIVERY_LIST_CLEAR}); handleComponent(false); }}>Close</div>
              <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Add Route' : <span className="spinner-border spinner-border-sm mx-3"></span>}
              </button>
            </div>

            {/* VEHICLE PLAN */}
            {showVehiclePlan && <RouteVehiclePlanRoute handleComponent={setShowVehiclePlan} deliveryRoutePlanOrderList={deliveryRoutePlanOrderList}/> }
          </form>
        </div>
      </div>
    </div>
  )
}


function mapStateToProps(state) {
  const { countryList, stateList, cityList, deliveryRoutePlanList, deliveryRoutePlanOrderList } = state.shipping

  return {    
    countryList,
    stateList,
    cityList,
    deliveryRoutePlanList,
    deliveryRoutePlanOrderList
  };
}

export default connect(mapStateToProps)(RoutePlanAdd);