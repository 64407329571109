import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { logout } from "../../actions/auth.action";
import { clearCache } from '../../helpers/common-function.helper';

const $ = window.$;

const Header = (props) => {
  // const history = useHistory();

  const { currentUser } = props;

  useEffect(() => {
    // dispatch({type: "CURRENT_USER", payload:''});
    
    $('.main-header .dropdown > a').on('click', function (e) {
      e.preventDefault();
      $(this).parent().toggleClass('show');
      $(this).parent().siblings().removeClass('show');
      $(this).find('.drop-flag').removeClass('show');
    });


    $(document).on('click touchstart', function (e) {
      e.stopPropagation();
      // closing of dropdown menu in header when clicking outside of it
      var dropTarg = $(e.target).closest('.main-header .dropdown').length;
      if (!dropTarg) {
        $('.main-header .dropdown').removeClass('show');
      }
      // closing nav sub menu of header when clicking outside of it
      if (window.matchMedia('(min-width: 992px)').matches) {
        // Navbar
        var navTarg = $(e.target).closest('.main-navbar .nav-item').length;
        if (!navTarg) {
          $('.main-navbar .show').removeClass('show');
        }
        // Header Menu
        var menuTarg = $(e.target).closest('.main-header-menu .nav-item').length;
        if (!menuTarg) {
          $('.main-header-menu .show').removeClass('show');
        }
        if ($(e.target).hasClass('main-menu-sub-mega')) {
          $('.main-header-menu .show').removeClass('show');
        }
      } else {
        if (!$(e.target).closest('#mainMenuShow').length) {
          var hm = $(e.target).closest('.main-header-menu').length;
          if (!hm) {
            $('body').removeClass('main-header-menu-show');
          }
        }
      }
    });
  }, [])


  const [open, setOpen] = useState("none");

  const onHandleProfile = () =>{
    setOpen("block")

    if (open === "block") {
    setOpen("none")
      
    }
  }

  return (
    <React.Fragment>
      <div id='mainHeader' className="main-header2 main-header-fixed">
        <div className="container">
          <div className="main-header-left">
            <a href="#!" className="animated-arrow hor-toggle horizontal-navtoggle"><span /></a>
            <a className="header-brand" href="/">
              <img src="/logo.png" alt="true" className="desktop-logo" id="change-image" />
            </a> <h4 className='bg-gray-200 border mb-0 mt-2 pl-2 pr-2 py-1 rounded text-muted'>B2B</h4>
          </div>
          <div className="main-header-center">
            <div className="responsive-logo">
              <a href="/"><img src="/logo.png" className="mobile-logo" alt="logo" /></a>
            </div>
          </div>
          <div className="main-header-right">

            {/* CLEAR CACHE */}
            <div className='btn btn-primary position-relative border font-size-14 mr-2 rounded-5 text-dark text-white-hover-active px-2' onClick={clearCache}><i className='bx bx-upvote' ></i> Update App</div>

            {/* FULL SCREEN */}
            <div className="dropdown main-header-message right-toggle">
              <div className="nav-item full-screen fullscreen-button">
                <div className="new nav-link p-0 full-screen-link menu-icons fullscreen pt-2">
                  <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
                  </svg>
                </div>
                <div className="new nav-link  full-screen-link exit-fullscreen">
                  <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                    <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
                  </svg>
                </div>
              </div>
            </div>
            {/* Full-screen closed

            {/* Notification start */}
            {/* <div className="dropdown nav-item main-header-notification">
              <a className="new nav-link menu-icons" href="#!">
                <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                  <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                  <path d="M13.73 21a2 2 0 0 1-3.46 0" />
                </svg>
                <span className="pulse" />
              </a>
              <div className="dropdown-menu animated fadeInUp">
                <div className="menu-header-content text-left d-flex">
                  <p className="main-notification-text mb-0">You have 1 unread notification<a className="badge badge-pill badge-success ml-2 float-right" href="#!">Mark All Read</a></p>
                </div>
                <div className="main-notification-list Notification-scroll ps">
                  <a className="d-flex pl-3 pr-3 border-bottom" href="#!">
                    <div className="media new">
                      <div className="main-img-user online"><img alt="avatar" src="/img/1.jpg" /></div>
                      <div className="media-body">
                        <p>Congratulate <strong> Olivia James </strong> for New template start</p>
                        <span>Oct 15 12:32pm</span>
                      </div>
                    </div>
                  </a> 
                </div>
                <div className="dropdown-footer"><a href="#!">VIEW ALL</a></div>
              </div>
            </div> */}
            {/* Notification closed */}

            {/* Main-header-message closed */}
            <div className="dropdown main-profile-menu nav nav-item nav-link">
              <a className="profile-user" onClick={() => onHandleProfile()}><img alt="Hemant Vishwakarma" src="/img/1.jpg" /></a>
              <div className={`dropdown-menu animated fadeInUp d-${open}`}>
                <div className="main-header-profile text-nowrap header-img align-items-start pl-3 pr-3">
                  {currentUser ?
                    <div>
                      <h6>{currentUser.first_name + ' ' + currentUser.last_name}</h6><span>{currentUser ? currentUser.designation : ''}</span>
                    </div>
                    : ''}
                </div>
                <a className="dropdown-item" href="#!"><i className="bx bx-user-circle" /> My Profile</a> <a className="dropdown-item" href="/"><i className="bx bxs-edit" /> Edit Profile</a>
                <a className="dropdown-item" href="#!"><i className="bx bx-envelope" /> Chat</a>
                <a className="dropdown-item" href="#!"><i className="bx bx-cog" /> Account Settings</a>
                <a className="dropdown-item" href="#!" onClick={() => {
                  props.dispatch(logout());
                  // setTimeout(() =>{  window.location.reload() },10)
                }}><i className="bx bx-log-out-circle" /> Sign Out</a>
              </div>
            </div>
            {/* Main-profile-menu closed */}

            <button className="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon bx bx-dots-vertical-rounded" />
            </button>
            {/* Navresponsive closed */}

          </div>
        </div>
      </div>

      {/* Mobile Header */}
      <div className="responsive main-header">
        <div className="mb-1 navbar navbar-expand-lg nav nav-item navbar-nav-right responsive-navbar navbar-dark">
          <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
            <div className="d-flex order-lg-2 ml-auto">
              <div className="dropdown main-header-message right-toggle">
                <div className="nav-item full-screen fullscreen-button">
                  <a className="new nav-link full-screen-link menu-icons fullscreen" href="/">
                    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                      <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
                    </svg>
                  </a>
                  <a className="new nav-link full-screen-link exit-fullscreen" href="/">
                    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                      <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
                    </svg>
                  </a>
                </div>
              </div>
              {/* Fullscreen closed */}

              <div className="dropdown nav-item main-header-notification">
                <a className="new nav-link menu-icons" href="/">
                  <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
                    <path d="M13.73 21a2 2 0 0 1-3.46 0" />
                  </svg>
                  <span className="pulse" />
                </a>
                <div className="dropdown-menu animated fadeInUp">
                  <div className="menu-header-content text-left d-flex">
                    <p className="main-notification-text mb-0">You have 1 unread notification<a className="badge badge-pill badge-success ml-2 float-right" href="/">Mark All Read</a></p>
                  </div>
                  <div className="main-notification-list Notification-scroll1 ps">
                    <a className="d-flex pl-3 pr-3 border-bottom" href="/">
                      <div className="media new">
                        <div className="main-img-user online"><img alt="avatar" src="/img/1.jpg" /></div>
                        <div className="media-body">
                          <p>Congratulate <strong> Olivia James </strong> for New template start</p>
                          <span>Oct 15 12:32pm</span>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="dropdown-footer"><a href="/">VIEW ALL</a></div>
                </div>
              </div>
              {/* Notification closed */}

              <div className="dropdown main-profile-menu nav nav-item nav-link">
                <a className="profile-user" href="/"><img alt="fds" src="/img/1.jpg" /></a>
                <div className="dropdown-menu animated fadeInUp">
                  <div className="main-header-profile header-img">
                    <h6>Hemant Vishwakarma</h6>
                    <span>Technology Lead</span>
                  </div>
                  <a className="dropdown-item" href="profile.html"><i className="bx bx-user-circle" /> My Profile</a> <a className="dropdown-item" href="editprofile.html"><i className="bx bxs-edit" /> Edit Profile</a>
                  <a className="dropdown-item" href="chat.html"><i className="bx bx-envelope" /> Chat</a> <a className="dropdown-item" href="account-setting.html"><i className="bx bx-cog" /> Account Settings</a>
                  <a className="dropdown-item" href="signin.html"><i className="bx bx-log-out-circle" /> Sign Out</a>
                </div>
              </div>
              {/* Main-profile-menu closed */}
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Header */}
    </React.Fragment>
  );
}


export default connect()(Header);
  // export default Header;