import React,{useEffect, useState, useRef, useMemo} from "react"
import { toast } from 'react-toastify'
import axios from "axios"
import { connect } from "react-redux"
import { paymentModeOptions, paymentStatusOptions, orderStatusOptions, forChangingPaymentStatusPaymentModeRequired } from "../../helpers/order.helper"
import { Link } from "react-router-dom"
import { CLEAR_ORDER_BY_ID, NEW_CUSTOMER, CUSTOMER_ADDRESS } from "../../actions/types"
import { convertToArray } from "../../helpers/common-function.helper"
import {addUser, customerSearch, userById} from '../../actions/users.action'
import Select from 'react-select'
import { newOrder } from "../../actions/orders.action"
import { customerAddress } from "../../actions/users.action"
import moment from "moment"
import { roles, KYCDocuments } from '../../helpers/user.helper'
import { getStateList, getCityList, getPincodeList } from '../../actions/shipping.action'


const ClubbedBuyingAddOrder = ({dispatch, formHandle, customerSearchResult, refreshList, customerAddressById, userByIdData, newCustomer, countryList, stateList, cityList, pincodeList}) =>{
  
  const [addOrderItem, setAddOrderItem]                 = useState(false)
  const [editOrderItem, setEditOrderItem]               = useState(false)
  const [editOrderItemDetails, setEditOrderItemDetails] = useState()
  const [editItemNumber, setEditItemNumber]             = useState()
  const [showAddUser, setShowAddUser]                   = useState(false)
  const [searchCustomer, setSearchCustomer]             = useState()
  const [searchRefCustomer, setSearchRefCustomer]       = useState('')
  const [searchRefRole, setSearchRefRole]               = useState('affiliate')
  const [shippingAddress, setShippingAddress]           = useState(false)
  const [orderProductList, setOrderProductList]         = useState([])
  const [customerSeachOption, setCustomerSearchOption]  = useState([])
  const [customerRefSeachOption, setCustomerRefSearchOption]  = useState([])
  const [shippingAddressData, setShippingAddressData]   = useState()
  const [billingAddressData, setBillingAddressData]     = useState()
  const [userId, setUserId]                             = useState()
  const [userInfo, setUserInfo]                         = useState()
  const [userRefId, setUserRefId]                       = useState()
  const [userRefInfo, setUserRefInfo]                   = useState()
  const [addingOrder, setAddingOrder]                   = useState(false)
  const [advanceOptionAllow, setAdvanceOptionAllow]     = useState(false)
  const [orderDate, setOrderDate]                       = useState(moment(Date.now()).format('yyyy-MM-DD'))
  const [orderTime, setOrderTime]                       = useState(moment(Date.now()).format('HH:mm'))
  const selectInputRef                                  = useRef()
  const [deliveryCharge, setDeliveryCharge]             = useState(false) 
  const [addressCountryId, setAddressCountryId]         = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]             = useState("");
  const [searchUserResult, setSearchUserResult]         = useState()

  useEffect(() => {
    if(!newCustomer) return
    selectInputRef?.current?.clearValue()
    setUserInfo(newCustomer)
    setCustomerName(customerNameRef.current.value = `${newCustomer?.first_name} ${newCustomer?.last_name}`)
    setMobileNumber(mobileNumberRef.current.value = newCustomer?.mobile_number)
    setAltMobileNumber(altMobileNumberRef.current.value = '')
    setLocality(localityRef.current.value = '')
    setAddress(addressRef.current.value = '')
    setLandmark(landmarkRef.current.value = '')
    setPincode(pincodeRef.current.value = '')
  },[newCustomer])


  useEffect(() => {
    var formateCustomerOptions = customerSearchResult?.map((option,index) => ({
      value: option,
      label: `${option.first_name} ${option.last_name} "${option.mobile_number}" -  #${option.user_number}`
    }))    
    setCustomerSearchOption(formateCustomerOptions)
  },[customerSearchResult])
 
  // useEffect(() => {
  //   var formateCustomerOptions = customerRefSeachOption?.map((option,index) => ({
  //     value: option,
  //     label: `${option.first_name} ${option.last_name} "${option.mobile_number}" -  #${option.user_number}`
  //   }))
  //   setCustomerRefSearchOption(formateCustomerOptions)
  // },[customerRefSeachOption])


  useEffect(async () =>{
    if(!searchCustomer?.length) return 
    setSearching(true)

    let filterBody = {"role" : "customer"};
    if(!isNaN(searchCustomer)) {
      filterBody={
        ...filterBody,
        searchKey : "mobile_number",
        searchValue: searchCustomer
      }
    } else {
      filterBody={
        ...filterBody,
        "name" : searchCustomer
      }
    }  
    await dispatch(customerSearch('q', filterBody))
    await setSearching(false)
  }, [searchCustomer] )

  useEffect(async () =>{
    if(!searchRefCustomer.length) return 
    setSearching(true)
    let filterBody = {"role" : searchRefRole};
    if(!isNaN(searchRefCustomer)) {
      filterBody={
        ...filterBody,
        searchKey : "mobile_number",
        searchValue: searchRefCustomer
      }
    } else {
      filterBody={
        ...filterBody,
        "name" : searchRefCustomer
      }
    }  

    await dispatch(customerSearch("q",filterBody))
    await setSearching(false)
  }, [searchRefCustomer] )

  function closePopup(){
    document.querySelector('.modal-dialog')?.classList?.add('fadeInDown')
    setTimeout(() =>  formHandle(false),500)
    dispatch({type: CLEAR_ORDER_BY_ID})
    dispatch({type: NEW_CUSTOMER, payload:null})
    dispatch({type:CUSTOMER_ADDRESS, payload:null})
  }


  //FORM FIELDS
  const notify_customer                       = useRef()
  const notify_affiliate                      = useRef()
  const orderStatusRef                        = useRef()
  const paymentStatusRef                      = useRef()
  const paymentModeRef                        = useRef()
  const businessNameRef                       = useRef()
  const gstNoRef                              = useRef()
  const customerNameRef                       = useRef()
  const mobileNumberRef                       = useRef()
  const altMobileNumberRef                    = useRef()
  const localityRef                           = useRef()
  const addressRef                            = useRef()
  const landmarkRef                           = useRef()
  const cityRef                               = useRef()
  const stateRef                              = useRef()
  const pincodeRef                            = useRef()
  const [businessName, setBusinessName]       = useState('')
  const [gstNo, setGstNo]                     = useState('')
  const [customerName, setCustomerName]       = useState('')
  const [mobileNumber, setMobileNumber]       = useState('')
  const [altMobileNumber, setAltMobileNumber] = useState('')
  const [locality, setLocality]               = useState('')
  const [address, setAddress]                 = useState('')
  const [landmark, setLandmark]               = useState('')
  const [city, setCity]                       = useState()
  const [state, setState]                     = useState('')
  const [pincode, setPincode]                 = useState('')
  const [searching, setSearching]             = useState(false)

  useEffect(() => {
    if(userId) {
      dispatch(customerAddress(userId))
      dispatch(userById(userId))
    }
  }, [userId])

  useEffect(() => {onGetState(addressCountryId)},[])
  
  useEffect(() => {
    if(customerAddressById){
      var getAddress = customerAddressById.filter(address => address.default_address == 'yes').length ? customerAddressById.filter(address => address.default_address == 'yes')[0] : ""
      setCustomerName(customerNameRef.current.value = getAddress?.name || '')
      setMobileNumber(mobileNumberRef.current.value = getAddress?.mobile_number || '')
      setAltMobileNumber(altMobileNumberRef.current.value = getAddress?.alternate_mobile_number || '')
      setLocality(localityRef.current.value = getAddress?.locality || '')
      setAddress(addressRef.current.value = getAddress?.address || '')
      setLandmark(landmarkRef.current.value = getAddress?.landmark || '')
      setCity(cityRef.current.value = getAddress?.city || '')
      setState(stateRef.current.value = getAddress?.state || '')
      setPincode(pincodeRef.current.value = getAddress?.pincode || '')

      // FIND & GET ID SELECTED CITY BY STATE
      const getStateIdByAddress = stateList?.data.filter(item => item.name==getAddress?.state );
      if(getStateIdByAddress != undefined && getStateIdByAddress?.length!=0) onGetCity(getStateIdByAddress[0]?.id)   
      
    }
  }, [customerAddressById])  

  useEffect(() => {
    if(userByIdData){
      setBusinessName(businessNameRef.current.value = userByIdData?.business?.name || '')
      const findGST = userByIdData?.documents?.filter(item => item.name == "GST Certificate");
      setGstNo(gstNoRef.current.value = findGST[0]?.number || '')
    }
  }, [userByIdData])

  useEffect(() => {
    if(cityList){
      const getCityIdByAddress = cityList?.data?.filter(item => item.name==city )
      if(getCityIdByAddress?.length!=0) onGetPincode(getCityIdByAddress[0]?.id)
    }
  },[cityList])
 
  //SHIPPING ADDRESS FORM
  function shippingAddressForm(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data     = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }
    setShippingAddressData(data)
  }

  //BILLING ADDRESS FORM
  function billingAddressForm(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data     = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }
    setBillingAddressData(data)
  }

  function readyToAddProduct() {
    setAddingOrder(true)
    document.querySelector('#billingAddressFormSubmit').click()
    document.querySelector('#shippingAddressFormSubmit').click()
    
    var finalShippingAddress = null
    setShippingAddressData(data => {finalShippingAddress = data; return data})

    var finalBillingAddress = null
    setBillingAddressData(data => {finalBillingAddress = data; return data})

    var finalItemList = orderProductList.map(item => ({
      product     : item.product_id,
      variation   : item.variation_id,
      quantity    : item.quantity,
      // is_need     :'yes'
    }))

    setTimeout(() => setAddingOrder(false), 100)
    if(!userId) return alert('Good Morning! Buddy 🙂 "Add User"');
    if(!orderStatusRef.current.value) return alert('Good Morning! Buddy 🙂 "Add Order Status"');
    if(!paymentModeRef.current.value) return alert('Good Morning! Buddy 🙂 "Add Payment Mode"');
    if(!paymentStatusRef.current.value) return alert('Good Morning! Buddy 🙂 "Add Payment Status"');
    if(!finalItemList.length) return alert('Good Morning! Buddy 🙂 "Add product"');
    setTimeout(() => setAddingOrder(true), 100)

    setTimeout(async() => {
      var finalDataSubmit = {
        // "createdAt"         : `${orderDate}T${orderTime}:00.000Z`,
        "user"              : userId,
        "affiliate"         : userRefId,
        // "society"           : '625d49275df297001a431bc1',    
        "payment_mode"      : paymentModeRef.current.value,
        "payment_status"    : paymentStatusRef.current.value,
        "status"            : orderStatusRef.current.value,
        "notify"            : notify_customer.current.checked ? 'yes': 'no',
        // "notify_affiliate"  : notify_affiliate.current.checked ? 'yes': 'no',
        "notes"             : "Order Created By - Admin Panel",
        "billing_address"   : finalBillingAddress, 
        "shipping_address"  : !shippingAddress ? finalBillingAddress : finalShippingAddress,  
        "isDeliveryCharge"  : deliveryCharge ? 'no' : 'yes', 
        "items"             : finalItemList
      }     
      
      await toast.promise(
        dispatch(newOrder(finalDataSubmit)),
        {
          pending: 'Order adding...',
          success: 'Successfully! Order Added',
          error:   {
            render(){
              setAddingOrder(false)
              return `👆👆👆👆👆👆👆👆👆👆👆`
            }
          }
        }
      )
      await refreshList()
      await closePopup()
    },100)
  }

  const onGetState = async (countryId) => {
    // setAddressCountryId(countryId)
    dispatch(getStateList(countryId))
  }

  const onGetCity = (stateId) => { 
    setAddressStateId(stateId)
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }

  return(
    <>
      <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
        <div className="overlayer" onClick={() => closePopup()}></div>
        <div className="modal-dialog modal-dialog-bottom" id="hideMe" role="document">

          <div className="modal-content modal-content-demo">
            <div className="modal-header px-3 py-2 shadow-base d-flex align-items-center position-relative z-index-100">
              <div className="d-flex align-items-center">
                <h6 className="modal-title">+ Add Order</h6>
                <div className="ml-3 d-flex align-items-center">
                  <input type="date" onChange={e => setOrderDate(e.target.value)} className="form-control" defaultValue={moment(Date.now()).format('yyyy-MM-DD')}/>
                  <input type="time" onChange={e => setOrderTime(e.target.value)} className="form-control ml-2 time_input" defaultValue={moment(Date.now()).format('HH:mm')}/>
                </div>
                {/* <label className="badge mb-0 ml-2 badge-success-transparent center cursor-pointer user-select-none">
                  <input type="checkbox" onChange={e => setDeliveryCharge(e.target.checked ? true : false)} className="mr-1"/> Free Delivery
                </label> */}
              </div>

              <div className="d-flex align-items-center">
                <label className="cursor-pointer d-flex align-items-center m-0">Notify Customer <input type="checkbox" disabled ref={notify_customer} className="ml-1"/></label>
                {/* <label className="cursor-pointer d-flex align-items-center m-0 ml-/2">Notify Reference/Affiliate <input type="checkbox" ref={notify_affiliate} className="ml-1"/></label> */}
                <button className="mr-5 btn btn-primary ml-4" disabled={addingOrder} onClick={readyToAddProduct}>
                  {!addingOrder ? 'Add Order' : <span className="spinner-border spinner-border-sm mx-4"></span>}
                </button>
                
              </div>
              <button aria-label="Close" onClick={() => closePopup()} className="close shadow-sm" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body d-flex pt-0 pb-0">
              <div className="mt-3 mb-3 w-100 position-relative z-index-10">
                <div className="d-flex">

                  <div className="col-md-4 pl-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group"> 
                          <label className="main-content-label tx-11">Order Status</label> 
                          <label className="float-right badge badge-warning-transparent center cursor-pointer user-select-none title-left" data-title="🚫 Stop: Only for Emergency 🚨">
                            <input type="checkbox" className="mr-1" checked={advanceOptionAllow} onChange={e => setAdvanceOptionAllow(!advanceOptionAllow)}/> Advance Edit
                          </label>
                          <select ref={orderStatusRef} className="form-control">
                            {orderStatusOptions?.map((option,index) => (
                              <option key={index} disabled={advanceOptionAllow || option.new_order ? false : true} value={option.value}>{option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group"> 
                          <label className="main-content-label tx-11">Payment Status</label> 
                          <select ref={paymentStatusRef} id="payment_status"  className="form-control text-capitalize">
                            {/* <option value=''>Select...</option> */}
                            {paymentStatusOptions?.map((option,index) => (
                              <option key={index} disabled={advanceOptionAllow || option == 'unpaid' ? false : true} value={option}>{option}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group"> 
                          <label className="main-content-label tx-11">Payment Mode</label> 
                          <select ref={paymentModeRef} name="parent" className="form-control text-capitalize">
                            {/* <option value=''>Select...</option> */}
                            {paymentModeOptions?.map((option,index) => (
                              <option key={index} disabled={advanceOptionAllow || option == 'active' ? false : true} value={option}>{option}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="card pl-3 mb-2 pr-3 pt-2 pr-2  pb-3 border position-relative z-index-10">
                          <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                            <h3 className="font-size-14 text-muted font-weight-400 mb-1 mt-2"><i className='bx bx-user'></i> Customer:</h3>
                            <div className="d-flex">
                              {userInfo &&
                                <div className='position-relative user-box'>
                                  <button className='mr-2 popup-contol border outline-unset bg-white d-flex align-items-stretch  overflow-hidden rounded-5 p-0 cursor-pointer animated bounceIn'>
                                    <i className='bx bxs-user-circle bg-gray-100 p-2 mr-2 font-size-20 center'></i> 
                                    <div className='center pr-2'>
                                      <span className='text-secondary font-weight-500'>{userInfo.first_name}&nbsp;{userInfo.last_name}</span>
                                      <i className='bx bxs-chevron-down ml-1 text-secondary'></i>
                                    </div>
                                  </button>
                                  <div className="popover shadow-2 animated show bs-popover-bottom t-40 r--0" role="tooltip"  x-placement="top">
                                    <div className="arrow"></div>
                                    <h3 className="popover-header d-flex font-size-13 d-flex align-items-center">User&nbsp;Info <span className='badge ml-auto font-weight-300 font-size-11 badge-primary'>{userInfo?.role}</span></h3>
                                    <div className="popover-body">
                                      <table className='w-100'>
                                        <tbody>
                                          <tr>
                                            <td className='font-weight-500'>Name</td>
                                            <td className='text-right'>{userInfo?.first_name} {userInfo?.last_name}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>Email</td>
                                            <td className='text-right'>{userInfo?.email}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>Mobile</td>
                                            <td className='text-right'>{userInfo?.mobile_number}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>ID</td>
                                            <td className='text-right'>{userInfo?.user_number}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>Status</td>
                                            <td className='text-right'><span className='badge badge-primary-transparent font-weight-400'>{userInfo?.status}</span></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              }
                              {/* <button className="btn btn-dark font-size-13 py-1 px-2 center title-top" data-title="Add Customer" onClick={() => setShowAddUser(true)}>+ Add</button> */}
                            </div>
                          </div>
                          <Select
                            isMulti={false}  
                            isLoading={searching}
                            ref={selectInputRef}
                            closeMenuOnSelect={true}
                            onInputChange={e => {setSearchCustomer(e);}}
                            onChange={e => {setUserId(e?.value?.id); setUserInfo(e?.value);}}
                            autoFocus={true}
                            options={customerSeachOption}
                            className="basic-multi-select react-select"
                            placeholder="Search User..."
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="card pl-3 mb-2 pr-3 pt-2 pr-2  pb-3 border position-relative">
                          <div className="d-flex justify-content-between align-items-center mb-2 mt-1">
                            <h3 className="font-size-14 text-muted font-weight-400 mb-1 mt-2"><i className='bx bx-user'></i> Reference/Affiliate:
                            <span className="float-right text-right ml-3">
                              <select style={{width:130,float:"right"}} onChange={(e) => setSearchRefRole(e.target.value)}>
                                {roles.map((role,i) => (
                                  <option key={i} value={role.name} selected={role.name=="affiliate" ? true:false}>{role.label}</option>
                                ))}
                              </select>
                            </span>
                            </h3>
                            <div className="d-flex">
                              {userRefInfo &&
                                <div className='position-relative user-box'>
                                  <button className='mr-2 popup-contol border outline-unset bg-white d-flex align-items-stretch  overflow-hidden rounded-5 p-0 cursor-pointer animated bounceIn'>
                                    <i className='bx bxs-user-circle bg-gray-100 p-2 mr-2 font-size-20 center'></i> 
                                    <div className='center pr-2'>
                                      <span className='text-secondary font-weight-500'>{userRefInfo.first_name}&nbsp;{userRefInfo.last_name}</span>
                                      <i className='bx bxs-chevron-down ml-1 text-secondary'></i>
                                    </div>
                                  </button>
                                  <div className="popover shadow-2 animated show bs-popover-bottom t-40 r--0" role="tooltip"  x-placement="top">
                                    <div className="arrow"></div>
                                    <h3 className="popover-header d-flex font-size-13 d-flex align-items-center">User&nbsp;Info <span className='badge ml-auto font-weight-300 font-size-11 badge-primary'>{userRefInfo?.role}</span></h3>
                                    <div className="popover-body">
                                      <table className='w-100'>
                                        <tbody>
                                          <tr>
                                            <td className='font-weight-500'>Name</td>
                                            <td className='text-right'>{userRefInfo?.first_name} {userRefInfo?.last_name}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>Email</td>
                                            <td className='text-right'>{userRefInfo?.email}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>Mobile</td>
                                            <td className='text-right'>{userRefInfo?.mobile_number}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>ID</td>
                                            <td className='text-right'>{userRefInfo?.user_number}</td>
                                          </tr>
                                          <tr>
                                            <td className='font-weight-500'>Status</td>
                                            <td className='text-right'><span className='badge badge-primary-transparent font-weight-400'>{userRefInfo?.status}</span></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              }
                              {/* <button className="btn btn-dark font-size-13 py-1 px-2 center title-top" data-title="Add Customer" onClick={() => setShowAddUser(true)}>+ Add</button> */}
                            </div>
                          </div>
                          
                          <Select
                            isMulti={false}  
                            isLoading={searching}
                            ref={selectInputRef}
                            closeMenuOnSelect={true}
                            onInputChange={e => {setSearchRefCustomer(e);}}
                            onChange={e => {setUserRefId(e?.value?.id); setUserRefInfo(e?.value);}}
                            autoFocus={false}
                            options={customerSeachOption}
                            className="basic-multi-select react-select  z-index-10"
                            placeholder="Search User..."
                          />
                        </div>
                      </div>


                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mt-2 mb-3 ml-0 mr-0 pt-3 pb-2 pl-3 pr-3 custom-legend">
                      <div className="row">
                        <div className="col-12 d-flex align-items-end">
                          <div className="font-size-a14 text-secondary custom-legend-heading p-2 px-3"><i className='bx bx-package'></i> Billing Address <span className={`animated ${!shippingAddress ? 'flash' : 'fadeOut'}`}>+ Shipping Address</span></div>
                        </div>
                        <form onSubmit={billingAddressForm} className="row mb-2 overflow-auto pt-2 billingAddressForm" onScroll={e => document.querySelector('.shippingAddressForm').scrollTop = e.target.scrollTop} style={{maxHeight:216}}>
                          <button type="submit" id="billingAddressFormSubmit" className="d-none"></button>
                          <div className="col-md-12 px-4">
                            <div className="input_wrap w-100">
                              <input ref={businessNameRef} name="business_name" readOnly onChange={e => setBusinessName(e.target.value)} type="text" placeholder=" "/>
                              <label>Business Name</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4 mt-3">
                            <div className="input_wrap w-100">
                              <input ref={gstNoRef} name="business_gst_no" readOnly onChange={e => setGstNo(e.target.value)} type="text" placeholder=" "/>
                              <label>GST No.</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4 mt-3">
                            <div className="input_wrap w-100">
                              <input ref={customerNameRef} name="name" onChange={e => setCustomerName(e.target.value)} type="text" placeholder=" "/>
                              <label>Customer Name</label>
                            </div>
                          </div>
                          <div className="col-md-6 pl-4">
                            <div className="input_wrap w-100 mt-3">
                              <input ref={mobileNumberRef} name="mobile_number" onChange={e => setMobileNumber(e.target.value)} type="number" placeholder=" "/>
                              <label>Mobile Number</label>
                            </div>
                          </div>
                          <div className="col-md-6 pr-4">
                            <div className="input_wrap w-100 mt-3">
                              <input ref={altMobileNumberRef} name="alternate_mobile_number" onChange={e => setAltMobileNumber(e.target.value)} type="number" placeholder=" "/>
                              <label>Alternate Mobile No.</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4">
                            <div className="input_wrap w-100 mt-3">
                              <input ref={localityRef} name="locality" onChange={e => setLocality(e.target.value)} type="text" placeholder=" "/>
                              <label>Locality</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4">
                            <div className="input_wrap w-100 mt-3">
                              <input ref={addressRef} name="address" onChange={e => setAddress(e.target.value)} type="text" placeholder=" "/>
                              <label>Address</label>
                            </div>
                          </div>
                          <div className="col-md-6 pl-4">
                            <div className="input_wrap w-100 mt-3">
                              <input ref={landmarkRef} name="landmark" onChange={e => setLandmark(e.target.value)} type="text" placeholder=" "/>
                              <label>Landmark</label>
                            </div>
                          </div>
                          <div className="col-md-6 pr-4">
                            <div className="input_wrap w-100 mt-3">
                              {/* <input ref={cityRef} defaultValue={'Lucknow'} name="city" onChange={e => setCity(e.target.value)}  type="text" placeholder=" "/> */}
                              {/* <label>City</label> */}
                              <select className="form-control" name="city" ref={cityRef}  onChange={(e) => {setCity(e.target.value);onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                                <option value="" selected>Select City</option>
                                {cityList?.data?.map((data, i) => (
                                  <option key={i} value={data.name} name={data.id} selected={city==data.name ? true : false}>{data.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 pl-4">
                            <div className="input_wrap w-100 mt-3">
                              {/* <input ref={pincodeRef} name="pincode" onChange={e => setPincode(e.target.value)} type="number" placeholder=" "/> */}
                              {/* <label>Pincode</label> */}

                              <select className="form-control" ref={pincodeRef} name="pincode" onChange={e => setPincode(e.target.value)}>
                                <option value="" selected>Select Pincode</option>
                                {pincodeList?.data?.map((data, i) => (
                                  <option key={i} value={data.pincode} selected={pincode==data.pincode ? true : false}>{data.pincode}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 pr-4">
                            <div className="input_wrap w-100 mt-3">
                              {/* <input ref={stateRef} readOnly={true} name="state" onChange={e => setState(e.target.value)}  type="text" placeholder=" "/> */}
                              {/* <label>State</label> */}
                              <select ref={stateRef}  className="form-control" name="state" onChange={(e) =>  {setState(e.target.value);onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
                                <option value="">Select State</option>
                                {stateList && stateList?.data?.map((data, i) => (
                                  <option key={i} value={data.name} name={data.id} selected={state==data.name ? true : false}>{data.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mt-2 mb-3 ml-0 mr-0 pt-3 pb-2 pl-3 pr-3 custom-legend">
                      <div className="row">
                        <div className="col-12 d-flex align-items-end">
                          <div className="font-size-a14 text-secondary position-relative custom-legend-heading  p-2 pl-3 pr-5">
                            <i className='bx bx-receipt'></i> Shipping Address
                            <label onChange={() => setShippingAddress(!shippingAddress)} className="custom-switch billing_switch cursor-pointer position-absolute">
                              <input type="checkbox" className="custom-switch-input"/>
                              <span className="custom-switch-indicator"></span> 
                            </label>
                          </div>
                        </div>
                        <form onSubmit={shippingAddressForm} className="row mb-2 overflow-auto pt-2 shippingAddressForm"  style={{maxHeight:216}}>
                          <button type="submit" id="shippingAddressFormSubmit" className="d-none"></button>
                          <div className="col-md-12 px-4">
                            <div className="input_wrap w-100">
                              <input name="business_name" defaultValue={businessName} readOnly type="text" disabled={shippingAddress ? false : true } placeholder=" "/>
                              <label>Business Name</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4 mt-3">
                            <div className="input_wrap w-100">
                              <input name="business_gst_no" defaultValue={gstNo} readOnly type="text" disabled={shippingAddress ? false : true } placeholder=" "/>
                              <label>GST No.</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4 mt-3">
                            <div className="input_wrap w-100">
                              {shippingAddress ? 
                                <input name="name" defaultValue={customerName} type="text" disabled={shippingAddress ? false : true } placeholder=" "/> :
                                <input name="name" value={customerName} type="text" disabled={shippingAddress ? false : true } placeholder=" "/> 
                              }
                              <label>Customer Name</label>
                            </div>
                          </div>
                          <div className="col-md-6 pl-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                <input name="mobile_number" defaultValue={mobileNumber} type="number" disabled={shippingAddress ? false : true } placeholder=" "/> :
                                <input name="mobile_number" value={mobileNumber} type="number" disabled={shippingAddress ? false : true } placeholder=" "/>
                              }
                              <label>Mobile Number</label>
                            </div>
                          </div>
                          <div className="col-md-6 pr-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                <input name="alternate_mobile_number" defaultValue={altMobileNumber} type="number" disabled={shippingAddress ? false : true } placeholder=" "/> :
                                <input name="alternate_mobile_number" value={altMobileNumber} type="number" disabled={shippingAddress ? false : true } placeholder=" "/>
                              }
                              <label>Alternate Mobile No.</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                <input name="locality" defaultValue={locality} type="text" disabled={shippingAddress ? false : true } placeholder=" "/> :
                                <input name="locality" value={locality} type="text" disabled={shippingAddress ? false : true } placeholder=" "/>
                              }
                              <label>Locality</label>
                            </div>
                          </div>
                          <div className="col-md-12 px-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                <input name="address" defaultValue={address} type="text" disabled={shippingAddress ? false : true } placeholder=" "/> :
                                <input name="address" value={address} type="text" disabled={shippingAddress ? false : true } placeholder=" "/>
                              }
                              <label>Address</label>
                            </div>
                          </div>
                          <div className="col-md-6 pl-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                <input name="landmark" defaultValue={landmark} type="text" disabled={shippingAddress ? false : true } placeholder=" "/> :
                                <input name="landmark" value={landmark} type="text" disabled={shippingAddress ? false : true } placeholder=" "/>
                              }
                              <label>Landmark</label>
                            </div>
                          </div>
                          <div className="col-md-6 pr-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                // <input name="city11" defaultValue={city} type="text" disabled={shippingAddress ? false : true } placeholder=" "/> 
                                <select className="form-control" name="city" ref={cityRef} onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                                  <option>---</option>
                                  {cityList && cityList?.data?.map((data, i) => (
                                    <option key={i} value={data.name} name={data.id} disabled={shippingAddress || data == 'active' ? false : true}>{data.name}</option>
                                  ))}
                                </select>
                                :
                                // <input name="city22" value={city} type="text" disabled={shippingAddress ? false : true } placeholder=" "/>
                                <select className="form-control" name="city" ref={cityRef} onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                                  <option value="" selected>Select City</option>
                                  {cityList?.data?.map((data, i) => (
                                    <option key={i} value={data.name} name={data.id} selected={city==data.name ? true : false}>{data.name}</option>
                                  ))}
                                </select>
                              }
                              {/* <label>City</label> */}

                              
                            </div>
                          </div>
                          <div className="col-md-6 pl-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                // <input name="pincode" defaultValue={pincode} type="number" disabled={shippingAddress ? false : true } placeholder=" "/> 
                                <select className="form-control" name="pincode">
                                  <option>---</option>
                                  {pincodeList?.data?.map((data, i) => (
                                    <option key={i} value={data.pincode} disabled={shippingAddress || data == 'active' ? false : true}>{data.pincode}</option>
                                  ))}
                                </select>
                                :
                                // <input name="pincode" value={pincode} type="number" disabled={shippingAddress ? false : true } placeholder=" "/>
                                <select className="form-control" ref={pincodeRef} name="pincode">
                                  <option value="" selected>Select Pincode</option>
                                  {pincodeList?.data?.map((data, i) => (
                                    <option key={i} value={data.pincode} selected={pincode==data.pincode ? true : false}>{data.pincode}</option>
                                  ))}
                                </select>
                              }
                              {/* <label>Pincode</label> */}
                            </div>
                          </div>
                          {/* <div className="col-md-6 pr-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                <input name="state" readOnly={true} defaultValue={state} type="text" disabled={shippingAddress ? false : true } placeholder=" "/> :
                                <input name="state" readOnly={true} value={state} type="text" disabled={shippingAddress ? false : true } placeholder=" "/>
                              }
                              <label>State</label>
                            </div>
                          </div> */}

                           <div className="col-md-6 pr-4">
                            <div className="input_wrap w-100 mt-3">
                              {shippingAddress ? 
                                <select ref={stateRef}  className="form-control" name="state" onChange={(e) =>  {setState(e.target.value);onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
                                  <option>---</option>
                                  {stateList && stateList?.data?.map((stateData, i) => (
                                    <option key={i} value={stateData.name} name={stateData.id} disabled={shippingAddress || stateData == 'active' ? false : true}>{stateData.name}</option>
                                  ))}
                                </select>
                                :
                                <select ref={stateRef}  className="form-control" name="state" onChange={(e) =>  {setState(e.target.value);onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
                                  <option selected>Select State</option>
                                  {stateList && stateList?.data?.map((stateData, i) => (
                                    <option key={i} value={stateData.name} name={stateData.id} selected={state==stateData.name ? true : false}>{stateData.name}</option>
                                  ))}
                              </select>
                              }
                              {/* <label>State</label> */}
                            </div>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="userlist-table order-table">
                  <div className="order-table-helper"></div>
                  <table className="table card-table table-hover mt-2 category_table table-bordered stable-vcenter text-nowrap mb-0 item_table">
                    <thead>
                      <tr>
                        <th className='text-center'>#</th>
                        <th>Product</th>
                        <th className='text-center'>Listing Price</th>
                        <th className='text-center'>Current Price</th>
                        <th className='text-center'>HSN Code</th>
                        <th className='text-center'>Qty</th>   
                        <th className='text-center'>MRP</th>   
                        <th className='text-center'>Tax Type</th>
                        <th className='text-center'>Tax Rate %</th>
                        <th className='text-center'>Action</th>  
                      </tr>
                    </thead>
                    <tbody>
                      {orderProductList?.map((item,index) => (
                        <Item number={index} setEditOrderItemDetails={setEditOrderItemDetails} setEditItemNumber={setEditItemNumber} setEditOrderItem={setEditOrderItem} orderProductList={orderProductList} setOrderProductList={setOrderProductList}  key={index} item={item}/>
                      ))}
                      <tr name=" " className="no-hover">
                        <td className="count_ignore bg-white" colSpan={100}>
                          <div className="d-flex flex-column align-items-center my-2">
                            <button className="btn btn-light" type="button" onClick={() => setAddOrderItem(true)}>+ Add Product</button>
                            {!orderProductList.length ? <div className="mt-2">Hello, Buddy 🙂 <b>Ready To Add Product</b></div> : null}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="pb-4"/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* ADD USER */}
      {showAddUser && <AddUser dispatch={dispatch} handleComponent={setShowAddUser}/>}

      {/* ADD ORDER ITEM */}
      {addOrderItem && <AddOrderItem orderProductList={orderProductList} setOrderProductList={setOrderProductList} handleComponent={setAddOrderItem} pincode={pincode}/>}
      
      {/* EDIT ORDER ITEM */}
      {editOrderItem && <EditOrderItem setOrderProductList={setOrderProductList} orderProductList={orderProductList} editItemNumber={editItemNumber} editOrderItemDetails={editOrderItemDetails} handleComponent={setEditOrderItem}/>}
    </>
  )
}


/* -------------------------
        ADD USER   
------------------------- */
const AddUser = ({handleComponent, dispatch}) => {

  let onSubmitAddUser;
  useMemo(() => {
    onSubmitAddUser = async (e) => {
      e.preventDefault()
      const formData = new FormData(e.target)
      formData.append('password', Math.random())
      const data     = {}
      data['role']   = 'customer'
      data['status'] = 'active'
      for (let entry of formData.entries()) {
        data[entry[0]] = entry[1]
      } 
      await toast.promise(
        dispatch(addUser(data)),
        {
          pending: 'User adding...',
          success: 'Successfully! User Added',
          error: 'User not added'
        }
      )
      handleComponent(false)
    }
  },[])

  return (
    <div className="modal" aria-hidden="true" style={{display:'block'}}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div> 
      <div className="modal-dialog modal-dialog-right" role="document"> 
        <div className="modal-content modal-content-demo"> 
          <div className="modal-header"> 
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-user-plus font-size-22 mr-2'></i> Add Customer</h6>
          </div> 
          <form onSubmit={onSubmitAddUser}>
            <div className="modal-body">                                                   
                <div className="row">
                  <div className="col-md-12">
                    <div className="input_wrap w-100">
                      <input name="first_name" type="text" placeholder=" " autoFocus={true}/>
                      <label>First Name</label>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="input_wrap w-100">
                      <input name="last_name" type="text" placeholder=" "/>
                      <label>Last Name</label>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="input_wrap w-100">
                      <input name="email" type="email" placeholder=" "/>
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <div className="input_wrap w-100">
                      <input name="mobile_number" type="number" placeholder=" "/>
                      <label>Mobile Number</label>
                    </div>
                  </div>
                </div>
            </div> 
            <div className="modal-footer">
                <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
                <button type='submit' className="btn ripple btn-primary">Add User</button>
            </div>
          </form>   
        </div> 
      </div> 
    </div>
  )
}


/* -----------------
        ITEM
----------------- */
const Item = ({item, number, orderProductList, setOrderProductList, setEditOrderItem, setEditOrderItemDetails, setEditItemNumber}) =>{
  const [deleteThis, setDeleteThis] = useState(false)
  function deleteItem(){
    setDeleteThis(true)
    setTimeout(() => {
      setOrderProductList(orderProductList.filter((item,i) => i != number))
      setDeleteThis(false)
    },1000)
  }

  return(
    <tr name=" "  className={`animated position-relative ${deleteThis ? 'fadeOutDown' : ''}`}>
      <td className="text-center"></td>
      <td className="text-wrap">
        <Link to={`/products/view/${item?.product_id}`} target="_blank">
          <div className={`align-items-center ${!item?.name ? 'bg-warning-transparent-2':''} mb-2 last-mb-0 d-flex`}>
            {/* <img 
              className="rounded avatar-md mr-2 border" 
              src={item?.image}
            />  */}
            <div className="text-left">
              <div className="font-weight-500 white-space-normal rounded">{item?.name} 
              {/* <span className="badge badge-success-transparent mt-1"><i className='bx bxs-check-circle'></i> Is need</span> */}
              </div>
            </div>
          </div>
        </Link>
      </td>
      <td className="text-right">₹{item.listing_price}</td>
      <td className="text-right">₹{item.current_price}</td>
      <td className="text-center">{item.hsn_code}</td>
      <td className="text-center">{item.quantity}</td>
      <td className="text-right">₹{item.mrp}</td>
      <td className="text-center">{item?.tax_type}</td>
      <td className="text-center">{item?.tax_rate}</td>
      <td className="text-center">
        <button className="btn p-0 pr-2 border-right" data-title="Edit" onClick={ () => { setEditOrderItemDetails(item); setEditItemNumber(number); setEditOrderItem(true)} }><i className='bx bx-pencil m-0 text-warning font-size-17'></i></button>
        <button className="btn p-0 pl-2" data-title="Delete" onClick={() => deleteItem()}><i className='bx bx-trash m-0 text-danger font-size-17'></i></button>
      </td>
    </tr>
  )
}


/* -------------------------
        ADD ORDER ITEM
------------------------- */
const AddOrderItem = ({handleComponent, setOrderProductList, orderProductList, pincode}) => {
  const [quantity, setQuantity]         = useState(parseInt(1))
  const [searchQuery, setSearchQuery]   = useState()
  const [searchResult, setSearchResult] = useState()
  const [productData, setProductData]   = useState()
  const [options, setOptions]           = useState([])
  const [isExistingProduct, setIsExistingProduct] = useState(null)
  const [searching, setSearching]       = useState(false)
  
  function chooseProduct(e){
    setProductData(e.value)
    if(orderProductList.length){
      orderProductList.filter((item,i) => {
        if(item?.variation_id == e.value?.variation.id){
          setIsExistingProduct(null)
          setIsExistingProduct({
            name      : item.name,
            quantity  : item.quantity,
            number    : i
          })
          setQuantity(item.quantity)
        }
      })
    }
  }


  useEffect(async ()=>{    
    setSearching(true)
    if(searchQuery?.length) {
      if(!pincode) return alert('Kindly add the customer before adding the product');
      setSearchResult(await axios.get(`/products/search/q?sort=desc&start=1&limit=10&ProductSearch[keywords]=${searchQuery}&ProductSearch[pincode]=${pincode}`).then(res => res.data))
    }
    await setSearching(false)
  },[searchQuery])


  useEffect(() => {
    if(searchResult?.products.length){
      var optionList = [];
      var optionData = searchResult?.products?.map(option => (
        option.variation.map((resultData,i) => optionList.push({value: {id: option.id, hsn_code:option.hsn_code, variation: resultData}, label: resultData.name + `${resultData?.stock_status == 'available' ? '' : ` | Sold Out`}`, isDisabled: resultData?.stock_status == 'available' ? false : true}))
      ))
      setOptions(optionList)
      setOptions(optionList => optionList)
    }
  },[searchResult])


  function filterPriceByPincode(prices, pincode) {
    var result;
    for(var i=0; i<prices?.length; i++) {
      var t = prices[i].pincode.filter((i) => i == pincode);
      if(t!=0) {
        result = prices[i]
      }
    }
    return result;
    // return prices && prices?.map((p, ) => (
    //   p.pincode.filter((i) => i == pincode)
    // ))
  }

  function addNewOrderItem(e) {
    e.preventDefault();
    var findPrice = filterPriceByPincode(productData?.variation?.prices, pincode)
    // if(productData?.variation?.prices.length==0 || productData?.variation.prices[0]?.price.listing_price == 0) return alert(`This product's (${productData?.variation.name}) price is not available for this pincode.`);
    if(productData?.variation?.prices.length==0 || findPrice == undefined || findPrice?.length == 0) return alert(`This product's (${productData?.variation.name}) price is not available for this pincode.`);
    if(productData && !isExistingProduct){
      setOrderProductList(item => [ 
        ...item,
        {
          'name'            : productData?.variation.name,
          'mrp'             : findPrice?.price.mrp,
          'quantity'        : quantity,
          'hsn_code'        : productData?.hsn_code, 
          'listing_price'   : findPrice?.price.listing_price,
          'current_price'   : findPrice?.clubbed_buying?.current_value?.price,
          'tax_type'        : productData?.variation.tax.type,
          'tax_rate'        : productData?.variation.tax.percentage,
          'image'           : productData?.variation?.images[0]?.link,
          'variation_id'    : productData?.variation.id,
          'product_id'      : productData?.id
        }
      ])

      document.querySelector('#hideAdd')?.classList?.add('fadeOutDown')
      setTimeout(() => {
        setTimeout(() => document.querySelector(`.item_table tbody tr:nth-last-child(2)`)?.classList?.add('flash'), 200)
        handleComponent(false)
      }, 1000)

    } else if(isExistingProduct){
      var updateOrderProductList = orderProductList;
      updateOrderProductList[isExistingProduct.number].quantity = quantity
      setOrderProductList(updateOrderProductList)
      document.querySelector('#hideAdd')?.classList?.add('fadeOutDown')

      setTimeout(() => {
        document.querySelector(`.item_table tbody tr:nth-child(${isExistingProduct.number + 1})`)?.classList?.remove('flash')
        setTimeout(() => document.querySelector(`.item_table tbody tr:nth-child(${isExistingProduct.number + 1})`)?.classList?.add('flash'), 200)
        handleComponent(false)
      }, 1000)

    }else{
      alert('Good Morning! Buddy 🙂 Add Product')
    }
  }
  
  
  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
      <form className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideAdd" role="document" onSubmit={addNewOrderItem}>

        <div className="modal-content modal-content-demo">
          <div className="modal-header d-flex align-items-center">
            <h6 className="modal-title">Add Product</h6>
          </div>
          
          <div className="modal-body overflow-unset">
            <div className="position-relative">
              <div className={`address_box animated`}>
                <Select
                  isMulti           ={false}
                  isLoading         ={searching}
                  closeMenuOnSelect = {true}
                  onChange          = {e => chooseProduct(e)}
                  onInputChange     = {e => setSearchQuery(e)}
                  autoFocus         = {true}
                  options           = {options}
                  className         = "basic-multi-select mb-3 react-select"
                  placeholder       = "Search Product..."
                />
              </div>
            </div>

            {isExistingProduct && <div className="mt-3 alert-warning alert">Product Already Added In List <br/><b>Product: </b>{isExistingProduct.name}<br/><b>Quantity: </b>{isExistingProduct.quantity}</div>}

            <div className="input-group mb-3 d-flex mt-3">
              <button type="button" className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity > 1 ? quantity - 1: 1)}>-</button>
              <div className="input_wrap text-center" style={{width:130}}>
                <input name="quantity" type="number" min={1}  value={quantity} onChange={(e) => setQuantity(e.target.value)} className="rounded-0 text-center border-right-0"/>
                <label>Quantity</label>
              </div>
              <button type="button" className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity + 1)}>+</button>
            </div>
          </div>

          <div className="modal-footer">
            <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
            <button className="btn ripple btn-primary" style={{minWidth:83}} type="submit" >Add</button>
          </div>
        </div>
      </form>
    </div>
  )
}


/* -------------------------
        EDIT ORDER ITEM
------------------------- */
const EditOrderItem = ({handleComponent, setOrderProductList, editOrderItemDetails, editItemNumber, orderProductList}) => {
  const [quantity, setQuantity] = useState(parseInt(editOrderItemDetails.quantity))
  
  function updateOrderProductList(e) {
    e.preventDefault()
    var updateOrderProductList = orderProductList;
    updateOrderProductList[editItemNumber].quantity = quantity
    setOrderProductList(updateOrderProductList)
    document.querySelector('#hideAdd')?.classList?.add('fadeOutDown')
    setTimeout(() => {
      document.querySelector(`.item_table tbody tr:nth-child(${editItemNumber + 1})`)?.classList?.remove('flash')
      setTimeout(() => document.querySelector(`.item_table tbody tr:nth-child(${editItemNumber + 1})`)?.classList?.add('flash'), 200)
      handleComponent(false)
    }, 1000)
  } 

  return (
    <div className="modal effect-flip-vertical show align-items-end" id="modaldemo8" aria-modal="true" style={{display:"flex"}}>
      <form className="modal-dialog modal-dialog-centered animated fadeInUpBig" id="hideAdd" role="document" onSubmit={updateOrderProductList}>

        <div className="modal-content modal-content-demo">
          <div className="modal-header d-flex align-items-center">
            <h6 className="modal-title overflow-hidden text-overflow-hidden" title={editOrderItemDetails.name}>Edit <span className="text-secondary font-weight-500">"{editOrderItemDetails.name}"</span></h6>
          </div>
          
          <div className="modal-body overflow-unset">
            <div className="input-group mb-3 d-flex mt-3">
              <button type="button" className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity > 1 ? quantity - 1: 1)}>-</button>
              <div className="input_wrap text-center" style={{width:130}}>
                <input name="quantity" type="number" min={1} value={quantity} onChange={(e) => setQuantity(e.target.value)}  placeholder=" " className="rounded-0 text-center border-right-0"/>
                <label>Quantity</label>
              </div>
              <button type="button" className="input-group-prepend cursor-pointer btn btn-white" onClick={() => setQuantity(quantity + 1)}>+</button>
            </div>
          </div>

          <div className="modal-footer">
            <div className="btn ripple btn-outline-primary" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
            <button className="btn ripple btn-primary" type='submit' style={{minWidth:83}} >Add</button>
          </div>
        </div>
      </form>
    </div>
  )
}


function mapStateToProps(state) {
  let { customerAddressById, userById }     = state.users.users
  let { newCustomer }                       = state.users.users
  let orderDetails                          = state.orders.clubbedBuyOrderById
  let customerSearchResult                  = state.users.users.customerSearch
  const { stateList, cityList, pincodeList, }          = state.shipping

  return {
    orderDetails,
    customerSearchResult,
    customerAddressById,
    userByIdData:userById,
    newCustomer,
    stateList,
    cityList,
    pincodeList
  };
}

export default connect(mapStateToProps)(ClubbedBuyingAddOrder);