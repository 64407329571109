import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { convertToArray, inputFirstLetterCapital } from '../../helpers/common-function.helper'
import { updateUser, userById, userDocumentList, userDocumentAdd,userBankDetailAdd, userDocumentDelete, customerAddressList,customerAddressAdd,customerAddressUpdate, customerAddressDelete ,userBankDetailList,userBankDetailUpdate,userBankDetailDelete} from '../../actions/users.action'
import { roles, KYCDocuments } from '../../helpers/user.helper'
import { modulesList } from '../../helpers/user.helper'
import Select from 'react-select'
import { customerSearch, AffiliateSearch } from '../../actions/users.action'
import { categoryList } from '../../actions/category.action'
import { SET_MESSAGE } from '../../actions/types'
import axios from "axios"
import DeleteConfirm from '../../helpers/delete.helper'
import { USER_BY_ID } from '../../actions/types'

//COUNTRY
import { getCountryList, getStateList, getCityList, getPincodeList } from '../../actions/shipping.action'

function UserEdit({ handleComponent, dispatch, id, userInfo, roleUpdate, customerDocList, addressList, loadAndRefreshList,bankDetailList,
  customerSearchResult, countryList, stateList, cityList, pincodeList, categories }) {

  const [currentTabStatus, setCurrentTabStatus]                             = useState('basic_details');
  const [updateUserRole, setUpdateUserRole]                                 = useState()
  const [changePsd, setChangePsd]                                           = useState(false)
  const [btnLoader, setBtnLoader]                                           = useState(false)
  const [userInfoData, setUserInfoData]                                     = useState()
  const [searching, setSearching]                                           = useState(false)
  const [searchCustomer, setSearchCustomer]                                 = useState('');
  const [customerSeachOption, setCustomerSearchOption]                      = useState()
  const [imageUrl, setImageUrl]                                             = useState(false)
  const [docStructureList, setDocStructureList]                             = useState([{ doc_name: "", doc_number: "", doc_file: "" }]);
  const [addressStructureList, setAddressStructureList]                     = useState([]);
  const [searchRefUserRole, setSearchRefUserRole]                           = useState("affiliate");
  const [bankDetails, setBankDetails]                                       = useState([{ bank_name: "", account_number: "", ifsc_code: "" , branch_name: "",is_default: "yes"}])
  const [loader, setLoader]                                                 = useState(true)
	const [affiliateCapability, setAffiliateCapability]		                    = useState([]);
	const [affiliateCapabilityDeleteId, setAffiliateCapabilityDeleteId]		    = useState([]);
	const [addressDeleteId, setAddressDeleteId]		                            = useState([]);
	const [handleCapabilities, setHandleCapabilities]						              = useState(false)
	const [handleAddress, setHandleAddress]						                        = useState(false)
  const [userRefInfoId, setUserRefInfoId]                                   = useState()

  // useEffect(async () => {
  //   if (!searchCustomer.length) return
  //   setSearching(true)
  //   await dispatch(customerSearch(searchCustomer, searchRefUserRole))
  //   await setSearching(false)
  // }, [searchCustomer])

  useEffect(async () =>{
    if(!searchCustomer?.length) return 
    setSearching(true)
    let filterBody = {"role" : updateUserRole};
    if(!isNaN(searchCustomer)) {
      filterBody={
        ...filterBody,
        searchKey : "mobile_number",
        searchValue: searchCustomer
      }
    } else {
      filterBody={
        ...filterBody,
        "name" : searchCustomer
      }
    }  

    await dispatch(customerSearch("q",filterBody))
    await setSearching(false)
  }, [searchCustomer] )

  useEffect(async () =>{
    if(!searchRefUserRole?.length) return 
    setSearching(true)

    let filterBody = {"role" : searchRefUserRole};
    if(!isNaN(searchCustomer)) {
 
      filterBody={
        ...filterBody,
        searchKey : "mobile_number",
        searchValue: searchCustomer
      }
    } else {
      filterBody={
        ...filterBody,
        "name" : searchCustomer
      }
    } 
    await dispatch(AffiliateSearch('q', filterBody))
    await setSearching(false)
  }, [searchCustomer] )


  useEffect(() => {
    var formateCustomerOptions = customerSearchResult?.map((RefOption, index) => ({
      value: RefOption,
      label: `${RefOption.first_name} ${RefOption.last_name} "${RefOption.mobile_number}" -  #${RefOption.user_number}`
    }))
    setCustomerSearchOption(formateCustomerOptions)
  }, [customerSearchResult])

  useEffect(() => dispatch(userById(id)), [])
  // useEffect(() => setAffiliatAaddressStructureList(userInfo?.address), [userInfo])
  useEffect(() => setAddressStructureList(addressList), [addressList])
  useEffect(() => setUpdateUserRole(userInfo?.role), [userInfo])
  useEffect(() => setAffiliateCapability(userInfo?.affiliate_capability), [userInfo])
  useEffect(() => dispatch(userDocumentList(id)), [])
  useEffect(() => dispatch(customerAddressList(id)), [])
  useEffect(() => dispatch(userBankDetailList(id)), [])
  useEffect(() => dispatch(categoryList()), [])

  //DELETE
  const [deleteAddress, setDeleteAddress]         = useState(false)
  const [bankAddress, setDeleteBank]              = useState(false)
  const [deleteDoc, setDeleteDoc]                 = useState(false)
  const [showDelete, setShowDelete]               = useState(false)
  const [deleteId, setDeleteId]                   = useState()
  const [deleteName, setDeleteName]               = useState()
  const [isDeleted, setIsDeleted]                 = useState(false)
  const [allowUpdateDelete, setAllowUpdateDelete] = useState(false)

  //UPDATE
  const [showUpdate, setShowUpdate]               = useState(false)
  const [updateId, setUpdateId]                   = useState()

  useEffect(() => {
    if (!allowUpdateDelete) setAllowUpdateDelete(true)
    else {
      loadAndRefreshDocList();
      loadAndRefreshAddresscList();
      loadAndRefreshBankList();
    }
  }, [isDeleted])

  useEffect(() => {
    if (!allowUpdateDelete) setAllowUpdateDelete(true)
    else loadAndRefreshList()
  }, [isDeleted])

  //LOAD & REFRESH LIST 
  const loadAndRefreshDocList = async () => {
    await dispatch(userDocumentList(id))
    return
  }

  const loadAndRefreshAddresscList = async () => {
    await dispatch(customerAddressList(id))
    return
  }

  const loadAndRefreshBankList = async () => {
    await dispatch(userBankDetailList(id))
    return
  }


  const uploadF = (selectorFiles, index) => {
    var data = new FormData();
    data.append('file', selectorFiles[0]);
    data.append('foldername', 'user-documents');

    axios.post("/file/upload", data)
      .then((response) => {
        setImageUrl(response.data)
      }).catch(err => {
        dispatch({
          type: SET_MESSAGE,
          payload: err.response.data
        })
      })
  }

  const onAddDocuments = async (userId) => {
    docStructureList && docStructureList.map((docAdd) => {
      if(docAdd.doc_name) {
        const body = {
          user      : userId,
          name      : docAdd.doc_name,
          number    : docAdd.doc_number,
          file      : imageUrl
        }
        if (docAdd.doc_name && docAdd.doc_number && imageUrl) dispatch(userDocumentAdd(body))
      }
    })
  }

  const onAddAddress = async (userId, customerData) => {
    addressStructureList && addressStructureList.map((add) => {
      if (add.locality && add.tempId) {
        const body = {
          user                      : userId,
          name                      : customerData.first_name + ' ' + customerData.last_name,
          mobile_number             : customerData.mobile_number,
          alternate_mobile_number   : add.alternate_mobile_number,
          locality                  : add.locality,
          address                   : add.address,
          landmark                  : add.landmark,
          pincode                   : add.pincode,
          city                      : add.city,
          state                     : add.state,
          country                   : "India",
          address_type              : add.address_type,
          default_address           : add.default_address,
          latitude                  : add.latitude ? add.latitude:"",
          longitude                 : add.longitude ? add.longitude:""
        }
        dispatch(customerAddressAdd(body));
      }
    })
  }

  const onAddBankDetails = async (userId) => {
    bankDetails?.map((bankDetails) => {
      if(bankDetails.bank_name) {
        const body = {
          user           : userId,
          bank_name      : bankDetails.bank_name,
          account_number : bankDetails.account_number,
          ifsc_code      : bankDetails.ifsc_code,
          branch_name    : bankDetails.branch_name,
          is_default     : bankDetails.is_default
        }
        dispatch(userBankDetailAdd(body))
      }
    })
  }

  const onUpdateBankDetails = async (userId) => {
    bankDetails?.map((bankDetails) => {
      if(bankDetails.bank_name) {
        const body = {
          user           : userId,
          bank_name      : bankDetails.bank_name,
          account_number : bankDetails.account_number,
          ifsc_code      : bankDetails.ifsc_code,
          branch_name    : bankDetails.branch_name,
          is_default     : bankDetails.is_default
        }
        dispatch(userBankDetailUpdate(body))
        // console.log(body,userId,"----onUpdateBankDetails---body");
      }
    })
  }

  const onSubmitEditUser = async (e) => {
    e.preventDefault()

    var formateCapabilities = {}
    modulesList.map(module => {
      formateCapabilities[module.module_name] = {}
      module.components.map(component => {
        formateCapabilities[module.module_name][component.name] = component.default.filter(r => r == updateUserRole).length ? true : false
        formateCapabilities[module.module_name]['module_enable'] = formateCapabilities[module.module_name]['module_enable'] !== true ? component.default.filter(r => r == updateUserRole).length ? true : false : true
      })
    })

    const formData = new FormData(e.target)
    if (updateUserRole != 'customer') formData.append('capability', JSON.stringify(formateCapabilities))
    else formData.append('password', Math.random())
    if (updateUserRole == 'customer') userInfoData ? formData.append('reference', userInfoData?._id || userRefInfoId) : formData.append('reference', "")
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }  

    const bodyData = {
      user_number       : data.user_number,
      first_name        : data.first_name,
      last_name         : data.last_name,
      email             : data.email,
      mobile_number     : data.mobile_number,
      reference         : data.reference,

      role              : data.role,
      password          : data.password,
      isNotify          : data.isNotify,
      remark            : data.remark,
      state             : data.state,
      device: {
        name           : data.device_name,
        id             : data.device_id,
      },
      business            : {
        id                : userInfo?.business?.id,
        owner_first_name  : data.business_owner_first_name,
        owner_last_name   : data.business_owner_last_name,
        name              : data.business_name,
        address             : {
          locality          : data.business_address_locality,
          address           : data.business_address_address,
          city              : data.business_address_city,
          state             : data.business_address_state,
          pincode           : data.business_address_pincode,
          country           : "India",
          landmark          : data.business_address_landmark,
          latitude          : data.business_address_latitude,
          longitude         : data.business_address_longitude,
        }
      },
      affiliateCapabilityDeleteId : affiliateCapabilityDeleteId,
      affiliate_capability        : affiliateCapability,
      status                      : data.status,

    }

    // var checkfield = convertToArray(bodyData).filter(data => data == '')

    await toast.promise(
      dispatch(updateUser(id, bodyData, roleUpdate)),
      {
        pending: 'User Updating...',
        success: 'Successfully! User Updated',
        error: {
          render() {
            setBtnLoader(false)
            return 'User not Updated'
          }
        }
      }

    )

    await onAddDocuments(userInfo?.id)
    // setTimeout(async () => { await onUpdateBankDetails(userInfo?.id) }, 1000)
    setTimeout(async () => { await onAddBankDetails(userInfo?.id) }, 1000)
    setTimeout(async () => { await onAddAddress(userInfo?.id, userInfo) }, 1500)
    await loadAndRefreshList()
    await handleComponent(false)
  }

  // BANK DETAIL
  const handleBankDetailsEdit = (e, index) => {
    const { name, value } = e.target;
    const list = [...bankDetails];
    list[index][name] = value;
    setBankDetails(list);
  };

  const handleBankDetailsADD = () => {
    setBankDetails([...bankDetails, { bank_name: "", account_number: "", ifsc_code: "" , branch_name: "",is_default: "yes" }]);
  };

  const handleBankDetailsRemove = (index) => {
    const list = [...bankDetails];
    list.splice(index, 1);
    setBankDetails(list);
  };

  
  const handleDocStructureChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...docStructureList];
    list[index][name] = value;
    setDocStructureList(list);
  };

  const handleDocStructureRemove = (index) => {
    const list = [...docStructureList];
    list.splice(index, 1);
    setDocStructureList(list);
  };

  const handleDocStructureAdd = () => {
    setDocStructureList([...docStructureList, { doc_name: "", doc_number: "", doc_file: "" }]);
  };

  // ADDRESS
  const handleAddressStructureChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addressStructureList];
    list[index][name] = value;
    setAddressStructureList(list);
  };

  const handleAddressStructureRemove = (index) => {
    const list = [...addressStructureList];
    list.splice(index, 1);
    setAddressStructureList(list);
  };

  const handleAddressStructureAdd = () => {
    setAddressStructureList([...addressStructureList, { country: "", state: "", city: "", pincode: "", locality: "", address: "", landmark: "", latitude: "", longitude: "", address_type: "work", default_address: "yes" }]);
  };

  // COUNTRY
  const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               	= useState("632167dcc2fe1900193f918f");

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }

  useEffect(async () => {
    document.title = 'User | Country List'
    setLoader(true);
    dispatch(getStateList(addressCountryId));
    // dispatch(getCityList(addressCountryId, addressStateId));
    setLoader(false);
  }, []);

  // const onGetState = (CountryId) => {
  //   dispatch(getStateList(CountryId));
  // }

  const onGetCity = (stateId) => {
    // setAddressStateId(stateId)
    dispatch(getCityList(addressCountryId, stateId));
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId));
  }

  // ---------------DELETE AFFILIATE CAPABILITY 
  const onAffiliateDelete = (index, id="") => {
		if ( window.confirm("Are you sure?")) {

    if(id) {
		  var filteredArray = affiliateCapability.filter(function(e) { return e.id != index })
      setAffiliateCapabilityDeleteId([...affiliateCapabilityDeleteId, id])
		  setAffiliateCapability(filteredArray); 

    } else {
      var filteredArray = affiliateCapability.filter(function(e) { return e.tempId != index })
      setAffiliateCapability(filteredArray); 
    }
  }
		return
	}

  // ---------------DELETE ADDRESS
const onAddressDelete = (index, id="") => {
  if ( window.confirm("Are you sure?")) {

  if(id) {
    var filteredArray = addressStructureList.filter(function(e) { return e.id != index })
    setAddressDeleteId([...addressDeleteId, id])
    setAddressStructureList(filteredArray); 

  } else {
    var filteredArray = addressStructureList.filter(function(e) { return e.tempId != index })
    setAddressStructureList(filteredArray); 
  }
}
  return
}

  return (
    <>
      {id == userInfo?.id &&
        <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
          <div className="overlayer" onClick={() => handleComponent(false)}></div>
          <div className="modal-dialog modal-dialog-right modal-dialog-for-add-user" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h6 className="modal-title d-flex align-items-center"><i className='bx bx-edit font-size-22 mr-2'></i> Edit User</h6>
              </div>
              {/* DELETE */}
              {showDelete && deleteDoc && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={ userDocumentDelete} />}

              {showDelete && deleteAddress && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={customerAddressDelete } />}

              {showDelete && bankAddress && <DeleteConfirm response={setIsDeleted} id={deleteId} title={deleteName} componentHandle={setShowDelete} dispatch={dispatch} deleteItem={ userBankDetailDelete } />}
              
              {/* EDIT */}
              {showUpdate && <EditConfirmAddress  componentHandle={setShowUpdate} id={updateId} dispatch={dispatch} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList} userInfo={userInfo} loadAndRefreshAddresscList={loadAndRefreshAddresscList} setAddressStructureList={setAddressStructureList}/>}

              <form onSubmit={(e) => { setBtnLoader(true); onSubmitEditUser(e) }}>
              <div className="modal-body">
              <div className="row addNew">
                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-lg-4 col-xl-3 col-md-4 col-sm-12 col-xs-12 account-setting-tab-menu">
                          <div className="box-widget widget-user border border-bottom-0">
                            <div className="widget-user-header testbgpattern1" />
                            <div className="widget-user-image">
                              {
                                  userInfo?.profile_photo ? 
                                  <img alt="Profile Photo" className="rounded-circle" src={userInfo?.profile_photo} /> :
                                  <img alt="Profile Photo" className="rounded-circle" src="/img/1.jpg" />
                              }
                            </div>
                            <div className="card-body text-center mt-5">
                              <div className="item-user pro-user">
                                <h4 className="pro-user-username tx-15 pt-2 mt-4 mb-1">{userInfo?.first_name+" "+ userInfo?.last_name}</h4>
                                <p className="pro-user-username tx-12 pt-2 mt-2 mb-1">{userInfo?.mobile_number}</p>
                                <h6 className="pro-user-desc tx-12 mb-2 font-weight-normal text-muted"></h6>
                                <p className={`text-center tx-16 list-group-item card-title`}>
                                  <i className="bx bxs-user"></i> Role : {updateUserRole}
                                  <div className="float-right" style={{width:'25%'}} />
                                </p> 
                                
                              </div>
                            </div>
                          </div>
                          <div className="list-group mt-0">
                            <button type='button' onClick={() => setCurrentTabStatus("basic_details") } className={`text-left list-group-item ${currentTabStatus=="basic_details" && 'active'}`}>
                              <i className="bx bxs-user-detail"></i> Basic Details 
                              <div className="float-right" style={{width:'25%'}} />
                            </button> 
                            <button type='button' onClick={() => setCurrentTabStatus("address") } className={`text-left list-group-item ${currentTabStatus=="address" && 'active'}`}>
                             <i className='bx bxs-map' ></i> Address
                               <div className="float-right" style={{width:'25%'}} />
                            </button> 
                            <button type='button' onClick={() => setCurrentTabStatus("documents") } className={`text-left list-group-item ${currentTabStatus=="documents" && 'active'}`}>
                              <i className='bx bxs-file-doc'></i> Documents 
                              <div className="float-right" style={{width:'25%'}} />
                            </button> 
                          
                            {updateUserRole == "customer" || updateUserRole === "supplier" ? <>
                              <button type='button' onClick={() => setCurrentTabStatus("business_details") } className={`text-left list-group-item ${currentTabStatus=="business_details" && 'active'}`}>
                              <i className='bx bx-buildings' ></i> Business Details 
                                <div className="float-right" style={{width:'25%'}} />
                              </button> 
                             
                             </>
                            :""}
                            
                            {updateUserRole === "affiliate" || updateUserRole === "operation" ? <>
                              <button type='button' onClick={() => setCurrentTabStatus("bank_details") } className={`text-left list-group-item ${currentTabStatus=="bank_details" && 'active'}`}>
                              <i className='bx bxs-bank'></i> Bank Details
                                <div className="float-right" style={{width:'25%'}} />
                              </button>
                              <button type='button' onClick={() => setCurrentTabStatus("affiliate_capability") } className={`text-left list-group-item ${currentTabStatus=="affiliate_capability" && 'active'}`}>
                              <i className='bx bx-sitemap'></i> Affiliate Capability
                                <div className="float-right" style={{width:'25%'}} />
                              </button>
                              <button type='button' onClick={() => setCurrentTabStatus("device_details") } className={`text-left list-group-item ${currentTabStatus=="device_details" && 'active'}`}>
                              <i className='bx bx-mobile-alt'></i> Device Details
                                <div className="float-right" style={{width:'25%'}} />
                              </button>
                              </>
                            :""}
                          </div>                          
                        </div>
                        <div className="col-lg-8 col-xl-9 col-md-8 col-sm-12 col-xs-12 mt-4 mt-md-0">
                          <div className="border account-setting-tab br-7">
                            {/* ---------------------------basic details section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="basic_details" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Basic Details </h4></div>
                              <div className="p-4">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>First Name</label>
                                        <input defaultValue={userInfo.first_name} type="text" className="form-control" onBeforeInput={inputFirstLetterCapital} name="first_name" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Last Name</label>
                                        <input defaultValue={userInfo.last_name} type="text" className="form-control" onBeforeInput={inputFirstLetterCapital} name="last_name" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Email</label>
                                        <input defaultValue={userInfo.email} type="text" className="form-control" name="email" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input defaultValue={userInfo.mobile_number} type="number" className="form-control" name="mobile_number" />
                                      </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                      <div className="form-group">
                                        <label className="main-content-label tx-11">Role</label>
                                        <select onChange={e => setUpdateUserRole(e.target.value)} defaultValue={userInfo.role} className="form-control" name="role">
                                          <option value="" selected>Select Role...</option>
                                          {roles.map((role, i) => (
                                            <option key={i} value={role.name}>{role.label}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6 mt-2">
                                      <div className="form-group">
                                        <label className="main-content-label tx-11">Status</label>
                                        <select defaultValue={userInfo.status} className="form-control" name="status">
                                          <option selected>Select Status</option>
                                          <option value="pending">Pending</option>
                                          <option value="active">Active</option>
                                          <option value="inactive">InActive</option>
                                          <option value="blacklist">blacklist</option>
                                          <option value="reject">Reject</option>
                                          <option value="on-hold">On-hold</option>
                                          <option value="in-process">In-process</option>
                                        </select>
                                      </div>
                                    </div>
                                    {/* {updateUserRole != "customer" && <>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" className="form-control" name="password" autoComplete="new-password"/>
                                      </div>
                                    </div>
                                    </>} */}
                                    <div className="col-md-6 mt-2">
                                      <div className="form-group">
                                      <label>{updateUserRole == "customer" ? "Reference/Affiliate" :"Manager"}</label>
                                        <select className="ml-2" onChange={(e) => setSearchRefUserRole(e.target.value)}>
                                          {roles.map((referenceRole,i) => (
                                            <option key={i} value={referenceRole.name} selected={ searchRefUserRole==referenceRole.name ? true : false}>{referenceRole.label}</option>
                                          ))}
                                        </select>
                                       </div>
                                       {/* <div className='col-md-6 mt-3 mb-3'> */}
                                      <label>{userInfo?.reference?.first_name +" "+ userInfo?.reference?.last_name}</label>
                                      <Select
                                        isMulti={false}
                                        isLoading={searching}
                                        closeMenuOnSelect={true}
                                        onInputChange={e => { setSearchCustomer(e); }}
                                        onChange={e => { setUserInfoData(e?.value);setUserRefInfoId(e?.value?._id ||e?.value?.id ) }}
                                        autoFocus={false}
                                        options={customerSeachOption}
                                        className="basic-multi-select react-select"
                                        placeholder="Select Reference"
                                      />
                                    {/* </div> */}
                                    </div>
                                    <div className='col-md-12 mt-2'>
                                      <div className='bg-gray-100 mb-2 p-3 card border'>
                                        <div className='text-primary font-weight-500 cursor-pointer' onClick={() => setChangePsd(!changePsd)}>Change Password</div>
                                        {changePsd ?
                                          <div className="input_wrap w-100 mt-3">
                                            <input name="password" type="password" placeholder=" " autoComplete='new-password' />
                                            <label>Password</label>
                                          </div> : null}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <hr />
                                  <div className="card-footer pb-0 pr-0 border-top-0 text-right">
                                    <button type="submit" className="btn btn-primary btn-lg">Save</button>
                                  </div> */}
                              </div>
                            </div> 
                            {/*---------------------------- basic details */}

                            {/* ---------------------------- Business Details */}
                            <div className={`account-setting-tab-content ${currentTabStatus=="business_details" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Business Details </h4></div>
                              <BusinessDetails dispatch={dispatch} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList} userInfo={userInfo} />
                            </div> 
                            {/* ---------------------------- Business Details */}

                            {/*---------------------------- customer Documents section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="documents" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Documents </h4></div>
                                <div className="p-4">
                                  <table className='table table-bordered'>
                                    <thead>
                                      <th>Name</th>
                                      <th>Number</th>
                                      <th>File</th>
                                      <th>Action</th>
                                    </thead>
                                    <tbody>
                                    {
                                      customerDocList && customerDocList?.map((doc) => (
                                        <tr>
                                          <td>{doc.name}</td>
                                          <td>{doc?.number}</td>
                                          <td><a href={doc?.file} target="_blank" className='btn btn-primary btn-sm btn-flat'>preview</a></td>
                                          <td>
                                            <button type='button' onClick={() => { setShowDelete(true); setDeleteDoc(true); setDeleteId(doc.id || doc._id); setDeleteName(doc.name); loadAndRefreshList={loadAndRefreshDocList}}} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h4 m-0 text-danger'></i></button>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                    {customerDocList?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
                                    </tbody>
                                  </table>
                                  <h5 className='mb-3'>Add New Document</h5>
                                  {docStructureList?.map((singleDoc, index) => (
                                    <div className={`row mt-4`}>
                                      <div className='col-md-4'>
                                        <div className="form-group">
                                          <select className="form-control" name="doc_name" onChange={(e) => handleDocStructureChange(e, index)}>
                                            <option value="" selected disabled>Select Document</option>
                                            {KYCDocuments.map((docl) => (
                                              <option value={docl.name}>{docl.name}</option>
                                              // <option value={docl.name} selected={singleDoc.name == docl.name ? true : false}>{docl.name}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="doc_number" type="text" placeholder=" " onChange={(e) => handleDocStructureChange(e, index)} />
                                          <label>Number</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          {/* {docStructureList.length !== 1 && <button type="button" className='btn btn-danger btn-xs float-right btn-flat btn-sm remove-doc' onClick={() => handleDocStructureRemove(index)}>X</button>}                    */}
                                          {/*   onChange={(e) => handleDocStructureChange(e, index)}    */}
                                          {imageUrl ? <a href={imageUrl} target="_blank" className='btn btn-primary btn-sm float-right btn-flat mt-2 remove-doc' >preview</a> : ""}
                                          <input type="file" placeholder="" onChange={(e) => { uploadF(e.target.files, index) }} />
                                          <label>File</label>
                                        </div>
                                        {/* {docStructureList.length - 1 === index && docStructureList.length < 5 && <button type="button" className='btn btn-primary btn-sm float-right btn-flat mt-2' onClick={handleDocStructureAdd}>+ add</button>}  */}
                                      </div>
                                    </div>

                                  ))}
                                </div>
                            </div> 
                            {/*----------------------------  Documents section end */}

                            {/*---------------------------- Bank Details section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="bank_details" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Bank Details </h4></div>
                                <div className="p-4">
                                {/* {bankDetailList?.length === 0 && <div className='alert alert-warning text-center'>data not available</div>} */}
                                <table className='table table-bordered'>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>Bank Name</th>
                                          <th>Account Number</th>
                                          <th>IFSC Code</th>
                                          <th>Branch Name</th>
                                          <th>Default Bank</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                    <tbody>
                                    {
                                      bankDetailList?.length!=0 && bankDetailList?.map((pdata, index) => (
                                        <tr key={index}>
                                          <td>{index+1}</td>
                                          <td> {pdata?.bank_name}</td>
                                          <td> {pdata?.account_number}</td>
                                          <td> {pdata?.ifsc_code}</td>
                                          <td> {pdata?.bank_name}</td>
                                          <td> {pdata?.is_default}</td>
                                          <td><button type='button' onClick={() => { setShowDelete(true); setDeleteBank(true); setDeleteId(pdata.id || pdata._id); loadAndRefreshList={loadAndRefreshBankList} }} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td>
                                          {/* <td><button type='button' onClick={() => onAffiliateDelete(pdata?.id ? pdata?.id : pdata.tempId, pdata.id)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td> */}
                                        </tr>
                                      )) 
                                    }
                                    {bankDetailList?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
                                    </tbody>
                                    </table>

                                    {bankDetails?.map((bankData, index) => (
                                    <>
                                    <div className='row mt-5'>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="bank_name" type="text" value={bankData?.bank_name} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsEdit(e, index)} />
                                          <label>Bank Name</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="account_number" type="text" value={bankData?.account_number} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsEdit(e, index)}/>
                                          <label>Account Number</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="ifsc_code" type="text" value={bankData?.ifsc_code} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsEdit(e, index)}/>
                                          <label>IFSC Code</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="branch_name" type="text" value={bankData?.branch_name} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsEdit(e, index)}/>
                                          <label>Branch Name</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3 mt-3'>
                                        <div className="form-group">
                                          <select className="form-control" name="is_default" onChange={(e) => handleBankDetailsEdit(e, index)}>
                                            <option selected>Default Bank</option>
                                            <option value="no">No</option>
                                            <option value="yes" selected={true}>Yes</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className='col-md-2 mt-3'>
                                        {bankDetails.length - 1 === index && bankDetails.length < 5 && <button type="button" className='btn btn-primary btn-sm float-left btn-flat mt-2' onClick={handleBankDetailsADD}>+ Add More</button>}
                                      </div>
                                      <div className='col-md-1 mt-4'>
                                        {bankDetails.length !== 1 && <button type="button" className='btn btn-danger btn-xs btn-sm' onClick={() => handleBankDetailsRemove(index)}>X</button>}
                                      </div>
                                    </div>
                                    {bankDetails.length !== 1 && <hr />}
                                    </>
                                  ))}
                                </div>
                            </div> 
                            {/*----------------------------  Bank Details section end */}

                             {/*---------------------------- Affiliate Capability section */}
                             <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="affiliate_capability" && 'active'}`} >
                              {/* <div className="card-header border-bottom"><h4 className="card-title mb-0"> Affiliate Capability</h4></div> */}
                                <div className="card-header border-bottom">
                                  <h6>Affiliate Capability <button type='button' className='btn btn-primary btn-sm float-right btn-flat card-title' onClick={() => setHandleCapabilities(true)}>+ Add Capability</button></h6>
                                </div>
                                <div className="p-4">
                                {/* {bankDetailList.length === 0 && <div className='alert alert-warning text-center'>data not available</div>} */}
                                  <div className="row">
                                    <div className='col-md-12'>
                                    <table className='table table-bordered'>
                                      <thead>
                                        <tr>
                                          <th>#</th>
                                          <th>State</th>
                                          <th>City</th>
                                          <th style={{width:"30%"}}>Pincode</th>
                                          <th style={{width:"30%"}}>Categories</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                    <tbody>
                                    {
                                      affiliateCapability?.length!=0 && affiliateCapability?.map((pdata, index) => (
                                        <tr key={index}>
                                          <td>{index+1}</td>
                                          <td> {pdata?.state}</td>
                                          <td> {pdata?.city}</td>
                                         
                                          <td>{
                                              pdata?.pincode?.map((pin, index) =>(
                                                <span key={index}>{pin}, </span>
                                              ))
                                            }
                                          </td> 
                                           <td>{
                                                pdata?.category.map((categories, index) =>(
                                                <p key={index}>{categories}, </p>
                                                ))
                                            }</td>
                                          <td><button type='button' onClick={() => onAffiliateDelete(pdata?.id ? pdata?.id : pdata.tempId, pdata.id)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td>
                                        </tr>
                                      )) 
                                    }
                                    {affiliateCapability?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
                                    </tbody>
                                    </table>
                                    </div>
                                      {/* <AffiliateCapability dispatch={dispatch} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList} categories={categories} affiliateCapability={affiliateCapability} setAffiliateCapability={setAffiliateCapability} userInfo={userInfo} affiliateCapabilityDeleteId={affiliateCapabilityDeleteId} setAffiliateCapabilityDeleteId={setAffiliateCapabilityDeleteId}/> */}
                                  </div>
                                </div>
                            </div> 
                            {/*----------------------------  Affiliate Capability section end */}

                            {/*---------------------------- Address section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="address" && 'active'}`} >
                                <div className="card-header border-bottom">
                                  <h6>Address <button type='button' className='btn btn-primary btn-sm float-right btn-flat card-title' onClick={() => setHandleAddress(true)}>+ Add Address</button></h6>
                                </div>
                              {/* <div className="card-header border-bottom"><h4 className="card-title mb-0"> Affiliate Address</h4></div> */}
                              <div className="p-4">
                              <table className='table table-bordered'>
                                <thead>
                                  <th style={{width:"10%"}}>#</th>
                                  <th>Address</th>
                                  <th style={{width:"10%"}}>Action</th>
                                </thead>
                                <tbody>
                                  {
                                    addressStructureList?.length!=0  && addressStructureList?.map((ad,i) => (
                                      <tr>
                                        <td>{i+1}</td>
                                        <td className='' >
                                          {ad.locality}, {ad.address}, {ad.landmark}, {ad.pincode}, {ad.city}, {ad.state}, {ad.country}
                                          <p><strong>Type</strong> - {ad.address_type} | <strong>Default</strong> - {ad.default_address}</p>
                                        </td>
                                        <td className='text-center'>
                                          <div className='btn-group'>
                                            {/* <td><button type='button' onClick={() => onAddressDelete(ad?.id ? ad?.id : ad?.tempId ,ad.id)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td> */}

                                            <button type='button' onClick={() => {setShowUpdate(true); setUpdateId(ad.id || ad._id);}} className="btn btn-sm btn-white" data-title="Edit Address"><i className='bx bx-pencil h5 m-0 text-warning'></i></button>
                                            <button type='button' onClick={() => { setShowDelete(true); setDeleteAddress(true); setDeleteId(ad.id || ad._id); loadAndRefreshList={loadAndRefreshAddresscList}}} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h5 m-0 text-danger'></i></button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                    {addressStructureList?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	

                                </tbody>
                              </table>
                              
                              </div>
                            </div> 
                            {/*----------------------------  Affiliate Address section end */}

                            {/*----------------------------  Device section start */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="device_details" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Device Details </h4></div>
                              <div className="p-4">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Device Name</label>
                                        <input defaultValue={userInfo?.device?.name} type="text" className="form-control" name="device_name" autoComplete='new-password' readOnly/>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Device Id</label>
                                        <input defaultValue={userInfo?.device?.id} type="text" className="form-control" name="device_id" autoComplete='new-password' readOnly/>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div> 
                            {/*----------------------------  Address section end */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                <div className="modal-footer">
                  <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
                  <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                    {!btnLoader ? 'Update User' : <span className="spinner-border spinner-border-sm mx-4"></span>}
                  </button>

                </div>
              </form>
            </div>
          </div>
          {/* ------ADD more affiliate capabilities */}
          {handleCapabilities && <AffiliateCapability dispatch={dispatch} handleComponent={setHandleCapabilities} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList} userInfo={userInfo} categories={categories} affiliateCapability={affiliateCapability} setAffiliateCapability={setAffiliateCapability} setHandleCapabilities={setHandleCapabilities} affiliateCapabilityDeleteId={affiliateCapabilityDeleteId} setAffiliateCapabilityDeleteId={setAffiliateCapabilityDeleteId}/>}
          
          {handleAddress && <AddMoreAddress dispatch={dispatch} stateList={stateList} cityList={cityList} pincodeList={pincodeList} userInfo={userInfo} addressList={addressList} handleComponent={setHandleAddress} handleAddress={handleAddress} setHandleAddress={setHandleAddress} setDeleteAddress={setDeleteAddress} setDeleteId={setDeleteId} setShowDelete={setShowDelete} addressStructureList={addressStructureList} setAddressStructureList={setAddressStructureList} setAddressDeleteId={setAddressDeleteId} addressDeleteId={addressDeleteId}/>}
        </div >
      }
    </>
  )
}

{/* BUSINESS */ }
const BusinessDetails = ({ dispatch, countryList, userInfo }) => {

  const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]                 = useState([]);
  const [stateList, setStateList]								            = useState([]);
	const [cityList, setCityList]								              = useState([]);
	const [pincodeList, setPincodeList]							          = useState([]);

  useEffect(async () => {
      // FIND STATE ID
      const stateListRes = await axios.get(`/shipping/state?country=${addressCountryId}&sort=ASC&start=1&limit=50`);
      setStateList(stateListRes?.data?.data)
      const stateFilter = stateListRes?.data?.data.filter(i => i.name == userInfo?.business?.address?.state)
      setAddressStateId(stateFilter[0]?.id)
      // GET CITY 
      await onGetCity(stateFilter[0]?.id)
	}, [])

  useEffect( async () => {
    if(cityList)  {
      const cityFilter = cityList?.filter(i => i.name == userInfo?.business?.address?.city)
      await onGetPincode(cityFilter[0]?.id)
    }
  },[userInfo, cityList])


  // useEffect(async () => {
	// 	const stateListRes = await axios.get(`/shipping/state?country=${addressCountryId}&sort=ASC&start=1&limit=50`);
	// 	setStateList(stateListRes?.data?.data)
	// }, [])

  const onGetCity = async (statetId) => {
		const cityListRes = await axios.get(`/shipping/city?country=${addressCountryId}&state=${statetId}&sort=ASC&start=1&limit=50`)
		setCityList(cityListRes?.data?.data)
	}

	const onGetPincode = async (cityId) => {
		const pincodeListRes = await axios.get(`/shipping/pincode?country=${addressCountryId}&state=${addressStateId}&city=${cityId}&sort=ASC&start=1&limit=50`)
		setPincodeList(pincodeListRes?.data?.data)
	}

  return (
    <>
      <div className='row p-4'>
        <div className='col-md-3'>
          <div className="input_wrap w-100">
            <input name="business_owner_first_name" defaultValue={userInfo?.business?.owner_first_name} type="text" onChange={inputFirstLetterCapital} placeholder=" " />
            <label>Owner First Name</label>
          </div>
        </div>
        <div className='col-md-3'>
          <div className="input_wrap w-100">
            <input name="business_owner_last_name" defaultValue={userInfo?.business?.owner_last_name} type="text" onChange={inputFirstLetterCapital} placeholder=" " />
            <label>Owner Last Name</label>
          </div>
        </div>
        <div className='col-md-6'>
          <div className="input_wrap w-100">
            <input name="business_name" defaultValue={userInfo?.business?.name} type="text" onChange={inputFirstLetterCapital} placeholder=" " />
            <label>Business Name</label>
          </div>
        </div>

        <div className='col-md-12 mt-2'><h5 className='mt-4 mb-3'>Address</h5></div>
        {/* <div className='col-md-3 mt-1'>
          <div className="form-group">
            <select defaultValue={userInfo?.business?.address?.country} className="form-control" name="business_address_country" onChange={(e) => { onGetState(e.target.selectedOptions[0].getAttribute('name')) }}>
              <option value="" selected disabled>Select Country</option>
              {countryList?.data?.map((country, i) => (
                <option key={i} value={country?.name} name={country?.id}>{country?.name}</option>
              ))}
            </select>
          </div>
        </div> */}
        <div className='col-md-2 mt-1'>
          <div className="form-group">
            <select className="form-control" name="business_address_state" onChange={(e) => { setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
              <option>Select State</option>
              {stateList && stateList?.map((state, i) => (
                <option key={i} value={state?.name} name={state?.id} selected={state?.name == userInfo?.business?.address?.state ? true : false}>{state?.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-2 mt-1'>
          <div className="form-group">
            <select className="form-control" name="business_address_city" onChange={(e) => { onGetPincode(e.target.selectedOptions[0].getAttribute('name')) }}>
              <option>Select City</option>
              {cityList && cityList?.map((data, i) => (
                <option key={i} value={data?.name} name={data?.id} selected={data?.name == userInfo?.business?.address?.city ? true : false}>{data?.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-2 mt-1'>
          <div className="form-group">
            <select defaultValue={userInfo?.business?.address?.pincode} className="form-control" name="business_address_pincode">
              <option>Select Pincode</option>
              {pincodeList && pincodeList?.map((data, i) => (
                <option key={i} value={data?.pincode} selected={data?.pincode == userInfo?.business?.address?.pincode ? true : false}>{data?.pincode}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-6 mt-1'>
          <div className="input_wrap w-100">
            <input name="business_address_locality" defaultValue={userInfo?.business?.address?.locality} type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
            <label>House No., Building Name</label>
          </div>
        </div>
        <div className='col-md-6 mt-2'>
          <div className="input_wrap w-100">
            <input name="business_address_address" defaultValue={userInfo?.business?.address?.address} type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
            <label>Road Name, Area, Colony</label>
          </div>
        </div>
        <div className='col-md-6 mt-2'>
          <div className="input_wrap w-100">
            <input name="business_address_landmark" defaultValue={userInfo?.business?.address?.landmark} type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
            <label>Landmark</label>
          </div>
        </div>
        <div className='col-md-3 mt-3'>
          <div className="input_wrap w-100">
            <input name="business_address_latitude" defaultValue={userInfo?.business?.address?.latitude} type="text" placeholder=" " autoComplete='off' />
            <label>latitude</label>
          </div>
        </div>
        <div className='col-md-3 mt-3'>
          <div className="input_wrap w-100">
            <input name="business_address_longitude" defaultValue={userInfo?.business?.address?.longitude} type="text" placeholder=" " autoComplete='off' />
            <label>longitude</label>
          </div>
        </div>
      </div>
    </>
  )
}


// UPDATE ADDRESS
const EditConfirmAddress = ({userInfo, id, response, componentHandle, dispatch, countryLists, loadAndRefreshAddresscList})=>{

	const [loader, setLoader]                                 = useState(false)
  const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               	= useState();
	const [addresslistData, setAddresslistData]					      = useState()
  const [stateList, setStateList]								            = useState();
	const [cityList, setCityList]								              = useState();
	const [pincodeList, setPincodeList]							          = useState();
  // console.log(id,"--iduser");

  useEffect(async () => {
		const addressRes = await axios.get(`/users/address/${id}?source=id`);
		setAddresslistData(addressRes?.data)    

    if(addressRes?.data) {
      // FIND STATE ID
      const stateListRes = await axios.get(`/shipping/state?country=${addressCountryId}&sort=ASC&start=1&limit=50`);
      setStateList(stateListRes?.data?.data)
      const stateFilter = stateListRes?.data?.data?.filter(i => i.name == addressRes?.data?.state)
      setAddressStateId(stateFilter[0]?.id)
      // GET CITY 
      await onGetCity(stateFilter[0]?.id)
    }
	}, [])

  useEffect( async () => {
    if(cityList)  {
      const cityFilter = cityList?.filter(i => i.name == addresslistData?.city)
      await onGetPincode(cityFilter[0]?.id)
    }
  },[addresslistData, cityList])


  // Submit
	const updateProductHandle = async (e) =>{
    e.preventDefault()

    const formData = new FormData(e.target)
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    } 

    const body ={
      user                      : userInfo.id,
      name                      : addresslistData.name,
      mobile_number             : addresslistData.mobile_number,
      alternate_mobile_number   : addresslistData.alternate_mobile_number,
      locality                  : data.locality,
      address                   : data.address,
      landmark                  : data.landmark,
      pincode                   : data.pincode,
      city                      : data.city,
      state                     : data.state,
      country                   : "India",
      address_type              : data.address_type,
      default_address           : data.default_address,
      latitude                  : data.latitude ,
      longitude                 : data.longitude
    }
    
    // dispatch(customerAddressUpdate(id,body))

		await toast.promise(
			dispatch(customerAddressUpdate(id, body)),
			{
				pending: 'Updating...',
				success: 'Successfully! Address Updated',
				error: {
					render(){
						setLoader(false)
						return 'Not Update'
					}
				}
			}
		)
    await loadAndRefreshAddresscList()
		await componentHandle(false)
		// await response && response(data => !data)
	}


  // useEffect(async () => {
	// 	const stateListRes = await axios.get(`/shipping/state?country=${addressCountryId}&sort=ASC&start=1&limit=50`);
	// 	setStateList(stateListRes?.data?.data)

  //   const r = stateListRes?.data?.data.filter(i => i.name == addresslistData?.state)
  //   console.log(r)
	// }, [])


  const onGetCity = async (statetId) => {
		const cityListRes = await axios.get(`/shipping/city?country=${addressCountryId}&state=${statetId}&sort=ASC&start=1&limit=50`)
		setCityList(cityListRes?.data?.data)
	}

	const onGetPincode = async (cityId) => {
		const pincodeListRes = await axios.get(`/shipping/pincode?country=${addressCountryId}&state=${addressStateId}&city=${cityId}&sort=ASC&start=1&limit=50`)
		setPincodeList(pincodeListRes?.data?.data)
	}


	return(
    <>
		<div className="modal fade show bd-example-modal-lg" style={{display:'block'}}>
			<div className="overlayer" onClick={() => componentHandle(false)}></div>
			<div className="modal-dialog modal-lg modal-dialog-centered animated zoomIn w-100">
          <div className="modal-content tx-size-sm">
          <form onSubmit={(e) =>  {setLoader(true); updateProductHandle(e)}}>
					<div className="modal-body simple-modal overflow-unset tx-center pd-y-20 pd-x-20">
						<div className='row mt-4'>
              {/* <div className='col-md-3'>
                <div className="form-group">
                  <select className="form-control" name="country" id="status" onChange={(e) => {onGetState(e.target.selectedOptions[0].getAttribute('name')) }}>
                    <option value="" selected disabled>Select Country</option>
                    {countryList?.data?.map((country, i) => (
                      <option key={i} value={country?.name} name={country?.id}>{country?.name}</option>
                    ))}
                  </select>
                </div>
              </div> */}
              <div className='col-md-3'>
                <div className="form-group">
                  <select className="form-control" name="state" id="status" onChange={(e) => {setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
                    <option>Select State</option>
                    {stateList && stateList?.map((state, i) => (
                      <option key={i} value={state?.name} name={state?.id} selected={state?.name == addresslistData?.state ? true : false }>{state?.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='col-md-3'>
                <div className="form-group">
                  <select className="form-control" name="city" id="status" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name')) }}>
                    <option>Select City</option>
                    {cityList && cityList?.map((data, i) => (
                      <option key={i} value={data?.name} name={data?.id} selected={data?.name == addresslistData?.city ? true : false }>{data?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="form-group">
                  <select className="form-control" name="pincode" id="status" >
                    <option>Select Pincode</option>
                    {pincodeList && pincodeList?.map((data, i) => (
                      <option key={i} value={data?.pincode} name={data?.id} selected={data?.pincode == addresslistData?.pincode ? true : false }>{data?.pincode}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='col-md-6 mt-3'>
                <div className="input_wrap w-100">
                  <input defaultValue={addresslistData?.locality} name="locality" type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
                  <label>House No., Building Name</label>
                </div>
              </div>
              <div className='col-md-6 mt-3'>
                <div className="input_wrap w-100">
                  <input defaultValue={addresslistData?.address} name="address" type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
                  <label>Road Name, Area, Colony</label>
                </div>
              </div>
              <div className='col-md-6 mt-3'>
                <div className="input_wrap w-100">
                  <input defaultValue={addresslistData?.landmark}  name="landmark" type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
                  <label>Landmark</label>
                </div>
              </div>
              <div className='col-md-3 mt-3'>
                <div className="input_wrap w-100">
                  <input defaultValue={addresslistData?.latitude} name="latitude" type="text" placeholder=" " autoComplete='off' />
                  <label>latitude</label>
                </div>
              </div>
              <div className='col-md-3 mt-3'>
                <div className="input_wrap w-100">
                  <input defaultValue={addresslistData?.longitude} name="longitude" type="text" placeholder=" " autoComplete='off' />
                  <label>longitude</label>
                </div>
              </div>

              <div className='col-md-3 mt-4'>
                <div className="form-group">
                  <select defaultValue={addresslistData?.address_type} className="form-control" name="address_type" >
                    <option>Address Type</option>
                    <option value="home">Home</option>
                    <option value="work" selected={true}>Work</option>
                  </select>
                </div>
              </div>
              <div className='col-md-3 mt-4'>
                <div className="form-group">
                  <select defaultValue={addresslistData?.default_address} className="form-control" name="default_address" >
                    <option>Default Address</option>
                    <option value="no">No</option>
                    <option value="yes" selected={true}>Yes</option>
                  </select>
                </div>
              </div>
            </div>	
					</div>
          
          <div className="modal-footer">
              <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={() => componentHandle(false)}>Close</button>
              <button type='submit' disabled={loader} className="btn ripple btn-primary">
                {!loader ? 'Update Address' : <span className="spinner-border spinner-border-sm mx-4"></span>}
              </button>
              {/* <button type="button" className="btn btn-primary" onClick={(e) => {setLoader(true); updateProductHandle(e)}}>Update</button> */}
          </div>
          </form>
				</div>
			</div> 
		</div>
    </>
	)
}


// AFFILIATE ADDRESS
const AffiliateCapability = ({dispatch,handleComponent, userInfo, countryList, stateList, cityList, pincodeList, categories, affiliateCapability, setAffiliateCapability, affiliateCapabilityDeleteId, setAffiliateCapabilityDeleteId,setHandleCapabilities}) => {

  const [addressCountryId, setAddressCountryId]                   = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]                       = useState([]);
  const [categoriesInArray, setCategoriesInArray]                 = useState([])
  const [isCheckAll, setIsCheckAll]                               = useState(false);
  const [isCheckAllCategories, setIsCheckAllCategories]           = useState(false);
  const [pincodeInArray, setPincodeInArray]                       = useState([])
  const [stateListGet, setStateListGet]								            = useState([]);
	const [cityListGet, setCityListGet]								              = useState([]);
	const [tempId, setTempId]								                        = useState(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0));
	// const [affiliateCapability, setAffiliateCapability]						  = useState([]);


  useEffect(async () => {
    onGetState(addressCountryId)
    setAffiliateCapability(userInfo?.affiliate_capability);
  }, [])

  const onGetState = async (countryId) => {
    dispatch(getStateList(addressCountryId));
  }

  const onGetCity = async (stateId) => {
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }
  
  //PINCODE
  const onSelectPincode = (e) => {
    const {value,checked} = e.target;
      if (!checked) {
        setPincodeInArray(pincodeInArray.filter(item => item !== value));
      }
      else{
       setPincodeInArray([...pincodeInArray, value]);
      }
  }

  // CHECKED ALL CHECkEDBOX
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);

    setPincodeInArray(pincodeList.data.map(li => li.pincode));
    if (isCheckAll) {
      setPincodeInArray([]);
    }

  };

  //CATEGORY
  const onSelectCategories = (e) => {
    const {value,checked} = e.target;
      if (!checked) {
        setCategoriesInArray(categoriesInArray.filter(item => item !== value));
      }
      else{
        setCategoriesInArray([...categoriesInArray, value]);
      }
  }

 // CHECKED ALL CHECkEDBOX
 const handleSelectAllCategories = e => {
  setIsCheckAllCategories(!isCheckAllCategories);

  setCategoriesInArray(categories.map(li => li.name));
    if (isCheckAllCategories) {
      setCategoriesInArray([]);
    }
  };

  const onSubmitAffiliateCapability = async (e) => {
    e.preventDefault()

    const bodyData = {

      // affiliate_capability: [{
        tempId    : tempId,
        country   : "India",
        state     :  stateListGet,
        city      :  cityListGet,
        pincode   :  pincodeInArray,
        category  :  categoriesInArray,

      // }]
    }

    if(bodyData.pincode.length==0) {toast.error('Pincode is required'); return;}	
    if(bodyData.category.length==0) {toast.error('category is required'); return;}	
		if(affiliateCapability.find(e => e.city == cityListGet)) {toast.error(`${cityListGet} Already added`);return}
		setAffiliateCapability([...affiliateCapability,bodyData])
    setTempId(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0))

  }

// ---------------DELETE AFFILIATE CAPABILITY 
  const onAffiliateDelete = (index, id="") => {
		if ( window.confirm("Are you sure?")) {

    if(id) {
		  var filteredArray = affiliateCapability.filter(function(e) { return e.id != index })
      setAffiliateCapabilityDeleteId([...affiliateCapabilityDeleteId, id])
		  setAffiliateCapability(filteredArray); 

    } else {
      var filteredArray = affiliateCapability.filter(function(e) { return e.tempId != index })
      setAffiliateCapability(filteredArray); 
    }
  }
		return
	}

  return (
    <>
    <div id='PriceDiv' className="modal" aria-hidden="true" style={{ display: 'block' }}>
				<div className="overlayer"></div>
				<div className="modal-dialog modal-dialog-right modal-dialog-for-add-user" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h6 className="modal-title d-flex align-items-center">Affiliate Capability</h6>
						</div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-md-12'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>State</th>
                    <th>City</th>
                    <th style={{width:"30%"}}>Pincode</th>
                    <th style={{width:"30%"}}>Categories</th>
                    <th>Action</th>
                  </tr>
                </thead>
              <tbody>
              {
                affiliateCapability?.length!=0 && affiliateCapability?.map((pdata, index) => (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td> {pdata?.state}</td>
                    <td> {pdata?.city}</td>
                    <td style={{ width: '20%' }}>{
                        pdata?.pincode?.map((pin, index) =>(
                          <span key={index}>{pin}, </span>
                        ))
                      }
                    </td> 
                    <td>{
                      pdata?.category?.map((categories, index) =>(
                        <p key={index}>{categories}, </p>
                      ))
                    }</td>
                    <td><button type='button' onClick={() => onAffiliateDelete(pdata?.id ? pdata?.id : pdata.tempId, pdata.id)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td>
                  </tr>
                )) 
              }
              {affiliateCapability?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
              </tbody>
              </table>
              </div>
              <div className='col-md-3'>
                <div className="form-group">
                  <select className="form-control" name="state" id="status" onChange={(e) => {setStateListGet(e.target.value);setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
                    <option selected>Select State</option>
                    {stateList?.data?.map((state, i) => (
                      <option key={i} value={state?.name} name={state?.id}>{state?.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='col-md-3'>
                <div className="form-group">
                  <select className="form-control" name="city" id="status" onChange={(e) => {setCityListGet(e.target.value);onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                    <option selected>Select City</option>
                    {cityList?.data?.map((data, i) => (
                      <option key={i} value={data.name} name={data.id}>{data.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='col-md-12'>
                <div className="form-group">
                  <label>Pincodes</label>
                  <div className='row'>
                    <div className='col-md-12'>
                      {pincodeList?.data?.length==0 && 
                      <div className='alert alert-warning'>Pincodes not available</div>
                      }
                    </div>

                    {pincodeList?.data && pincodeList?.data?.length!=0 &&
                    <div className='col-md-2'>
                        <label>
                          <input type='checkbox' name='allselect' id="status" onChange={handleSelectAll} checked={isCheckAll}/> Select All 
                        </label>
                    </div>
                    }

                    {
                      pincodeList?.data?.length!=0 && pincodeList?.data?.map((pn,i) => (
                        <>
                          <div className='col-md-2'>
                            <label>
                              <input key={i} type='checkbox'  name={pn.pincode} defaultValue={pn?.pincode} onChange={(e) => onSelectPincode(e)} checked={pincodeInArray.includes(pn?.pincode)}/> {pn?.pincode}    
                            </label>
                          </div>
                        </>   
                      ))
                    }
                    </div>                    
                </div>
              </div>
              <div className='col-md-12'>
                <div className="form-group">
                  <label>Add Categories</label>
                  <div className='row'>
                    <div className='col-md-12'>
                      {categories?.length==0 && 
                      <div className='alert alert-warning'>Categories not available</div>
                      }
                    </div>

                    {categories && categories?.length!=0 &&
                    <div className='col-md-2'>
                        <label>
                          <input type='checkbox' name='allselect_categories' id='status' onChange={handleSelectAllCategories} checked={isCheckAllCategories}/> Select All 
                        </label>
                    </div>
                    }

                    {
                      categories?.length!=0 && categories?.map((data,i) => (
                        // console.log(data,"-------data"),
                        <>
                          <div className='col-md-2'>
                            <label>
                              <input key={i} type='checkbox' name={data.name} defaultValue={data?.name} onChange={(e)=>onSelectCategories(e)} checked={categoriesInArray.includes(data?.name)}/> {data?.name}    
                            </label>
                          </div>
                        </>   
                      ))
                    }
                    </div>                    
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer text-center">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => setHandleCapabilities(false)}>Close</div>							
              <button type="submit" className="btn ripple btn-primary" onClick={(e) => onSubmitAffiliateCapability(e)}>Add Capability</button>
            </div>
        </div>
				</div>
			</div>
        {/* <button type="submit" className="btn ripple btn-primary" onClick={(e) => onSubmitAffiliateCapability(e)}>Add Capability</button> */}
    </>
  )
}


// ADD MORE ADDRESS
const AddMoreAddress = ({dispatch, handleComponent, userInfo, countryList, addressList, stateList, cityList, pincodeList, setHandleAddress, addressStructureList,setAddressStructureList ,setAddressDeleteId,addressDeleteId}) => {

  const [addressCountryId, setAddressCountryId]                   = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]                       = useState([]);
  const [newPincode, setNewPincode]                               = useState([null])
  const [newStateList, setNewStateList]								            = useState([null]);
	const [newCityList, setNewCityList]								              = useState([null]);
	const [newLocalityList, setNewLocalityList]								      = useState();
	const [newAddressList, setNewAddressList]								        = useState();
	const [newLandmarkList, setNewLandmarkList]								      = useState();
	const [newLatitudeList, setNewLatitudeList]								      = useState();
	const [newLongitudeList, setNewLongitudeList]								    = useState();
	const [newAddrtessTypeList, setNewAddrtessTypeList]							= useState("work");
	const [newDefaultAddressList, setNewDefaultAddressList]				  = useState("yes");
	const [tempId, setTempId]								                        = useState(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0));


  useEffect(async () => {
    onGetState(addressCountryId)
    // setAddressStructureList(userInfo?.address)
  }, [])

  const onGetState = async (countryId) => {
    dispatch(getStateList(addressCountryId));
  }

  const onGetCity = async (stateId) => {
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }
  
  const onSubmitAddress = async (e) => {
    e.preventDefault()

      const bodyData = {
        tempId                    : tempId,
        user                      : addressStructureList?.id,
        name                      : addressStructureList?.name,
        mobile_number             : addressStructureList?.mobile_number,
        locality                  : newLocalityList,
        address                   : newAddressList,
        landmark                  : newLandmarkList,
        pincode                   : newPincode,
        city                      : newCityList,
        state                     : newStateList,
        country                   : "India",
        address_type              : newAddrtessTypeList,
        default_address           : newDefaultAddressList,
        latitude                  : newLatitudeList,
        longitude                 : newLongitudeList
      }
    
		if(addressStructureList.find(e => e.locality == newLocalityList)) {toast.error(`Address Already added`);return}
    setAddressStructureList([...addressStructureList, bodyData])
    setTempId(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0))

  }

// ---------------DELETE AFFILIATE CAPABILITY 
const onAddressDelete = (index, id="") => {
  if ( window.confirm("Are you sure?")) {

  if(id) {
    var filteredArray = addressStructureList.filter(function(e) { return e.id != index })
    setAddressDeleteId([...addressDeleteId, id])
    setAddressStructureList(filteredArray); 

  } else {
    var filteredArray = addressStructureList.filter(function(e) { return e.tempId != index })
    setAddressStructureList(filteredArray); 
  }
}
  return
}

  return (
    <>
    <div id='PriceDiv' className="modal" aria-hidden="true" style={{ display: 'block' }}>
				<div className="overlayer"></div>
				<div className="modal-dialog modal-dialog-right modal-dialog-for-add-user" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h6 className="modal-title d-flex align-items-center">Address</h6>
						</div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-md-12'>
                <table className='table table-bordered'>
                  <thead>
                    <th>Address</th>
                    <th style={{width:"10%"}}>Action</th>
                  </thead>
                  <tbody>
                    {
                      addressStructureList && addressStructureList?.map((ad,index) => (
                        <tr>
                          <td className='' >
                            {ad.locality}, {ad.address}, {ad.landmark}, {ad.pincode}, {ad.city}, {ad.state}, {ad.country}
                            <p><strong>Address Type</strong> - {ad.address_type} | <strong>Default Address</strong> - {ad.default_address}</p>
                          </td>
                          <td className='text-center'>
                            <div className='btn-group'>
                            <td><button type='button' onClick={() => onAddressDelete(ad?.id ? ad?.id : ad?.tempId ,ad.id)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td>

                              {/* <button type='button' onClick={() => {setShowUpdate(true); setUpdateId(ad.id || ad._id);loadAndRefreshList={loadAndRefreshList}}} className="btn btn-sm btn-white" data-title="Edit User"><i className='bx bx-pencil h5 m-0 text-warning'></i></button> */}
                              {/* <button type='button' onClick={() => { setShowDelete(true); setDeleteAddress(true); setDeleteId(ad.id || ad._id) }} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h5 m-0 text-danger'></i></button> */}
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    {addressStructureList?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	

                  </tbody>
                </table>
              </div>
              <div className="mt-2 p-4">
                  <div className='row mt-4'>
                    <div className='col-md-2'>
                      <div className="form-group">
                        <select className="form-control" name="state" id="status" onChange={(e) => {setNewStateList(e.target.value); setAddressStateId(e.target.selectedOptions[0].getAttribute('name')); onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
                          <option selected>Select State</option>
                          {stateList?.data?.map((state, i) => (
                            <option key={i} value={state?.name} name={state?.id}>{state?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-2'>
                      <div className="form-group">
                        <select className="form-control" name="city" id="status" onChange={(e) => {setNewCityList(e.target.value); onGetPincode(e.target.selectedOptions[0].getAttribute('name')) }}>
                          <option selected>Select City</option>
                          {cityList?.data?.map((data, i) => (
                            <option key={i} value={data?.name} name={data?.id}>{data?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-2'>
                      <div className="form-group">
                        <select className="form-control" name="pincode" id="status" onChange={(e)=> setNewPincode(e.target.value)} required>
                          <option selected>Select Pincode</option>
                          {pincodeList?.data?.map((data, i) => (
                            <option key={i} value={data?.pincode} name={data?.id}>{data?.pincode}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="input_wrap w-100">
                        <input name="locality" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewLocalityList(e.target.value)} required onBeforeInput={inputFirstLetterCapital}/>
                        {/* <input name="locality" type="text" placeholder=" " autoComplete='off' onChange={(e)=> handleAddressStructureChange(e,index)} /> */}
                        <label>House No., Building Name</label>
                      </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                      <div className="input_wrap w-100">
                        <input name="address" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewAddressList(e.target.value)} required onBeforeInput={inputFirstLetterCapital}/>
                        <label>Road Name, Area, Colony</label>
                      </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                      <div className="input_wrap w-100">
                        <input name="landmark" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewLandmarkList(e.target.value)} required onBeforeInput={inputFirstLetterCapital}/>
                        <label>Landmark</label>
                      </div>
                    </div>
                    <div className='col-md-3 mt-4'>
                      <div className="input_wrap w-100">
                        <input name="latitude" type="text" required={true} placeholder=" " autoComplete='off' onChange={(e)=> setNewLatitudeList(e.target.value)}/>
                        <label>latitude</label>
                      </div>
                    </div>
                    <div className='col-md-3 mt-4'>
                      <div className="input_wrap w-100">
                        <input name="longitude" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewLongitudeList(e.target.value)} required/>
                        <label>longitude</label>
                      </div>
                    </div>

                    <div className='col-md-2 mt-4'>
                      <div className="form-group">
                        <select className="form-control" name="address_type" onChange={(e)=> setNewAddrtessTypeList(e.target.value)}>
                          <option value="" selected>Address Type</option>
                          <option value="home">Home</option>
                          <option value="work" selected={true}>Work</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-2 mt-4'>
                      <div className="form-group">
                        <select className="form-control" name="default_address" onChange={(e)=> setNewDefaultAddressList(e.target.value)}>
                          <option value="" selected>Default Address</option>
                          <option value="no">No</option>
                          <option value="yes" selected={true}>Yes</option>
                        </select>
                      </div>
                      {/* {addressStructureList.length - 1 === index && addressStructureList.length < 5 && <button type="button" className='btn btn-primary btn-sm float-right btn-flat mt-2' onClick={handleAddressStructureAdd}>+ add More</button>} */}
                    </div>
                    {/* <div className='col-md-2 mt-4'>
                      {addressStructureList.length - 1 === index && addressStructureList.length < 5 && <button type="button" className='btn btn-primary btn-sm btn-flat' onClick={handleAffiliateAddressAdd}>+ add More</button>}
                      {addressStructureList.length !== 1 && <button type="button" className='btn btn-danger btn-xs float-right btn-flat btn-sm remove-button mt-4 mr-2' onClick={() => handleAffiliateAddressRemove(index)}>X</button>}
                    </div> */}
                    {/* <div className='col-md-1 mt-5'>
                      {addressStructureList.length !== 1 && <button type="button" className='btn btn-danger btn-xs float-right btn-flat btn-sm remove-doc' onClick={() => handleAddressStructureRemove(index)}>X</button>}
                    </div> */}
                  </div>
              </div>
            </div>
          </div>
          <div className="modal-footer text-center">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => setHandleAddress(false)}>Close</div>							
              <button type="submit" className="btn ripple btn-primary" onClick={(e) => onSubmitAddress(e)}>Add More Address</button>
            </div>
        </div>
				</div>
			</div>
        {/* <button type="submit" className="btn ripple btn-primary" onClick={(e) => onSubmitAffiliateCapability(e)}>Add Capability</button> */}
    </>
  )
}


function mapStateToProps(state) {
  const userInfo                                          = state.users.users.userById;
  // let { customerAddressById }                             = state.users.users;
  // let customerSearchResult                                = state.users.users.customerSearch;
  let customerSearchResult                                = state.users.users.affiliateSearchList;
  let customerDocList                                     = state.users.users.customerDocList;
  let addressList                                         = state.users.users.customerAddressList;
  let bankDetailList                                      = state.users.users.customerBankDetailList;
  let newCustomerData                                     = state.users.users.newCustomer;
  const { categories }                                    = state.categories
  let { countryList, stateList, cityList, pincodeList }   = state.shipping;


  return {
    customerSearchResult,
    addressList,
    customerDocList,
    bankDetailList,
    newCustomerData,
    userInfo,
    countryList,
    stateList,
    cityList,
    pincodeList,
    categories
  }
}

export default connect(mapStateToProps)(UserEdit);