import axios from "axios"

class OrderServices {
  url = '/admin/order';
  async editOrder(id,data){ return axios.put(`${this.url}/`+id, data).then(res => res.data) }

  async searchOrder(query, filterOrder){
    // console.log(filterOrder?.page)
    // console.log(`/admin/order/search/${query}?sort=${filterOrder?.sort ? filterOrder?.sort : "desc"}&start=${filterOrder?.page ? filterOrder?.page : 1}&limit=${filterOrder?.limit ? filterOrder?.limit : 10}&OrderSearch[${filterOrder?.searchKey? filterOrder?.searchKey : ""}]=${filterOrder?.searchValue ? filterOrder?.searchValue :""}&OrderSearch[status]=${filterOrder?.status ? filterOrder?.status :""}&OrderSearch[shipping_address.state]=${filterOrder?.state ? filterOrder?.state : ""}&OrderSearch[shipping_address.city]=${filterOrder?.city ?filterOrder?.city :""}&OrderSearch[shipping_address.pincode]=${filterOrder?.pincode ? filterOrder?.pincode : ""}&OrderSearch[affiliate]=${filterOrder?.affiliate ? filterOrder?.affiliate: ""}`)

    return axios.get(`/admin/order/search/${query}?sort=${filterOrder?.sort ? filterOrder?.sort : "desc"}&start=${filterOrder?.page ? filterOrder?.page : 1}&limit=${filterOrder?.limit ? filterOrder?.limit : 70}&OrderSearch[${filterOrder?.searchKey? filterOrder?.searchKey : ""}]=${filterOrder?.searchValue ? filterOrder?.searchValue :""}&OrderSearch[status]=${filterOrder?.status ? filterOrder?.status :""}&OrderSearch[shipping_address.state]=${filterOrder?.state ? filterOrder?.state : ""}&OrderSearch[shipping_address.city]=${filterOrder?.city ?filterOrder?.city :""}&OrderSearch[shipping_address.pincode]=${filterOrder?.pincode ? filterOrder?.pincode : ""}&OrderSearch[affiliate]=${filterOrder?.affiliate ? filterOrder?.affiliate: ""}`).then(res => res.data) 
  }

  async newOrder(data){     return axios.post(`${this.url}/`, data).then(res => res.data) }
  async getOrderById(id){   return axios.get(`${this.url}/${id}`).then(res => res.data) }
  async newLog(data){       return axios.post(`${this.url}/log`, data).then(res => res.data) }
  async logsList(orderId){ return axios.get(`${this.url}/log/${orderId}`).then(res => res.data) }
  async deleteOrder(orderId,itemId){  return axios.delete(`${this.url}/${orderId}/${itemId}?type=all`).then( res => res.data )  }


  async clubbedBuyDeleteSingleOrderProduct(orderId,itemId){ //DELETE ORDER ITEM
    return axios.delete(`${this.url}/${orderId}/${itemId}`).then( res => res.data ) 
  }

  //ORDER LIST
  async orderList(page = 1, limit = 25, sortBy = 'desc', status="all", fromDate="", toDate="", fieldKey=""){ 
    // console.log(page = 1, limit = 10, sortBy = 'desc', status="all", fromDate="", toDate="", fieldKey="")
    return axios.get(`${this.url}?start=${page}&limit=${limit}&sort=${sortBy}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&fieldKey=${fieldKey}`).then(res => res.data) 
  }

  // async orderList(page = 1, limit, sortBy, status, fromDate, fieldKey){ 
  //   // console.log(page = 1, limit = 10, sortBy = 'desc', status="all", fromDate="", toDate="", fieldKey="")
  //   return axios.get(`${this.url}?start=${page}&limit=${limit}&sort=${sortBy}&status=${status}&fromDate=${fromDate}&fieldKey=${fieldKey}`).then(res => res.data) 
  // }

  async regenerateInvoice(orderId){
    return axios.get(`admin/invoice/regenerate/${orderId}`).then(res => res.data)
  }

  async resendNotification(orderId){
    return axios.get(`admin/notification/resend/${orderId}`).then(res => res.data)
  }

  async reportOrderValue() {
    return axios.get(`admin/order/report/order-value/`).then(res => res.data)
  }

}


export default new OrderServices()