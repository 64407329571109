import { useState, useEffect, useMemo ,useRef} from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { convertToArray, inputFirstLetterCapital } from '../../helpers/common-function.helper'
import { addUser, userDocumentAdd, customerAddressAdd, userBankDetailAdd, userDocumentList} from '../../actions/users.action'
import { roles, KYCDocuments } from '../../helpers/user.helper'
import { modulesList } from '../../helpers/user.helper'
import Select from 'react-select'
import { customerSearch,AffiliateSearch } from '../../actions/users.action'
import { categoryList } from '../../actions/category.action'
import { SET_MESSAGE } from '../../actions/types'
import axios from "axios"
import { useLocation } from 'react-router-dom'
// get contries
import {getCityList, getPincodeList ,getStateList} from '../../actions/shipping.action'

function UserAdd({ handleComponent, id, dispatch, role, loadAndRefreshList, customerSearchResult, newCustomerData, countryList, bankDetailList,categories, customerDocAdd,stateList,cityList,pincodeList}) {

  const [currentTabStatus, setCurrentTabStatus]                   = useState('basic_details');
  const [userRole, setUserRole]                                   = useState(role)
  const [btnLoader, setBtnLoader]                                 = useState(false)
  const [userInfo, setUserInfo]                                   = useState()
  const [searchingUser, setSearchingUser]                         = useState(false)
  const [imageUrl, setImageUrl]                                   = useState(false)
  const [searchUserCustomer, setSearchUserCustomer]               = useState()
  const [userCustomerSeachOption, setUserCustomerSearchOption]    = useState([])
  const [docStructureList, setDocStructureList]                   = useState([{ doc_name: "", doc_number: "", doc_file: "" }]);
  const [addressStructureList, setAddressStructureList]           = useState([]);
  const [addressCountryId, setAddressCountryId]                   = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]                       = useState([]);
	// const [addressStateId, setAddressStateId]                       = useState("632167dcc2fe1900193f918f");
  const [loader, setLoader]                                       = useState(true)
  const [searchRefRole, setSearchRefRole]                         = useState('affiliate')
  const [userRefInfoId, setUserRefInfoId]                         = useState()
  const [bankDetails, setBankDetails]                             = useState([{ bank_name: "", account_number: "", ifsc_code: "" , branch_name: "",is_default: "yes"}])
  const selectInputRef                                            = useRef()
  const [parentCat, setParentCat]                                 = useState('')
  const [option, setOption]                                       = useState()
  const [pincodeInArray, setPincodeInArray]                       = useState([])
	const [affiliateCapability, setAffiliateCapability]						  = useState([])
	const [firstName, setFirstName]						                      = useState([])
	const [LastName, setLastName]						                        = useState([])
	const [customerMobile, setCustomerMobile]						            = useState([])
	const [handleCapabilities, setHandleCapabilities]						    = useState(false)
	const [handleAddress, setHandleAddress]						              = useState(false)

  const initialPageSetting = {
    sort: 'ASC',
    status: 'all',
  }

  function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery()

  useEffect(async () => {
    document.title = 'User | Country List'
    // selectInputRef?.current?.clearValue()
    dispatch(categoryList())
    setLoader(true)
    onGetState(addressCountryId)
    // onGetCity(addressStateId)
    setLoader(false)
  }, [])

  useEffect(async () =>{
    if(!searchUserCustomer?.length) return 
    setSearchingUser(true)
    let filterBody = {"role" : searchRefRole};
    if(!isNaN(searchUserCustomer)) {
      filterBody={
        ...filterBody,
        searchKey : "mobile_number",
        searchValue: searchUserCustomer
      }
    } else {
      filterBody={
        ...filterBody,
        "name" : searchUserCustomer
      }
    }  

    await dispatch(customerSearch("q",filterBody))
    await setSearchingUser(false)
  }, [searchUserCustomer] )

  useEffect(async () =>{
    if(!searchUserCustomer?.length) return 
    setSearchingUser(true)

    let filterBody = {"role" : searchRefRole};
    if(!isNaN(searchUserCustomer)) {
      filterBody={
        ...filterBody,
        searchKey : "mobile_number",
        searchValue: searchUserCustomer
      }
    } else {
      filterBody={
        ...filterBody,
        "name" : searchUserCustomer
      }
    }  

    await dispatch(AffiliateSearch('q', filterBody))
    await setSearchingUser(false)
  }, [searchUserCustomer] )
  
  // useEffect(async () => {
  //   if (!searchUserCustomer.length) return
  //   setSearchingUser(true)
  //   await dispatch(customerSearch(searchUserCustomer, searchRefRole))
  //   await setSearchingUser(false)
  // }, [searchUserCustomer])

  useEffect(async () => {
    if (newCustomerData?.id) await onAddDocuments(newCustomerData?.id)
    setTimeout(async () => {
      if (newCustomerData?.id) await onAddBankDetails(newCustomerData?.id)
    }, 1000)
    setTimeout(async () => {
      if (newCustomerData?.id) await onAddAddress(newCustomerData?.id, newCustomerData)
    }, 1500)

    setTimeout(async () => {
      if (newCustomerData?.id) {
        await loadAndRefreshList()
        await handleComponent(false)
      }
    }, 2000)

  }, [newCustomerData])


  useEffect(() => {
    var formateCustomerOptions = customerSearchResult?.map((option, index) => ({
      value: option,
      label: `${option?.first_name} ${option?.last_name} "${option.mobile_number}" -  #${option.user_number}`
    }))
    setUserCustomerSearchOption(formateCustomerOptions)
  }, [customerSearchResult])

  const uploadF = (selectorFiles, index) => {
    var data = new FormData();
    data.append('file', selectorFiles[0]);
    data.append('foldername', 'user-documents');

    axios.post("/file/upload", data)
      .then((response) => {
        setImageUrl(response.data)
      }).catch(err => {
        dispatch({
          type: SET_MESSAGE,
          payload: err.response.data
        })
      })
  }

// ADD DOCUMENTS
  const onAddDocuments = async (userId) => {
    docStructureList?.map((docAdd) => {
      if(docAdd.doc_name) {
        const body = {
          user    : userId,
          name    : docAdd.doc_name,
          number  : docAdd.doc_number,
          file    : imageUrl
        }
        dispatch(userDocumentAdd(body))
      }
    })
  }

  useEffect(() => { console.log(customerDocAdd) }, [customerDocAdd])

  // ADD ADDRESS
  const onAddAddress = async (userId, customerData) => {
    addressStructureList && addressStructureList.map((add) => {
      if(add.locality && add.tempId) {
        const body = {
          user                    : userId,
          name                    : customerData.first_name + ' ' + customerData.last_name,
          mobile_number           : customerData.mobile_number,
          alternate_mobile_number : add.alternate_mobile_number,
          locality                : add.locality,
          address                 : add.address,
          landmark                : add.landmark,
          pincode                 : add.pincode,
          city                    : add.city,
			    state                   : add.state,
          country                 : "India",
          address_type            : add.address_type,
          default_address         : add.default_address,
          latitude                : add.latitude,
          longitude               : add.longitude        
        }
        dispatch(customerAddressAdd(body))
      }
      })
    
  }

  // ADD BANK
  const onAddBankDetails = async (userId) => {
    bankDetails?.map((bankDetails) => {
      if(bankDetails.bank_name) {
        const body = {
          user           : userId,
          bank_name      : bankDetails.bank_name,
          account_number : bankDetails.account_number,
          ifsc_code      : bankDetails.ifsc_code,
          branch_name    : bankDetails.branch_name,
          is_default     : bankDetails.is_default
        }
        dispatch(userBankDetailAdd(body))
      }
    })
  }

  // SUBMIT
  const onSubmitAddUser = async (e) => {
    e.preventDefault()

    var formateCapabilities = {}
    modulesList.map(module => {
      formateCapabilities[module.module_name] = {}
      module.components.map(component => {
        formateCapabilities[module.module_name][component.name] = component.default.filter(r => r == userRole).length ? true : false
        formateCapabilities[module.module_name]['module_enable'] = formateCapabilities[module.module_name]['module_enable'] !== true ? component.default.filter(r => r == userRole).length ? true : false : true
      })
    })

    const formData = new FormData(e.target)
    if (userRole != 'customer' && userRole != 'affiliate') formData.append('capability', JSON.stringify(formateCapabilities))
    else formData.append('password', Math.random())
    if (userRole == 'customer') userRefInfoId ? formData.append('reference', userRefInfoId) : formData.append('reference', "")
    const data = {}
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }

    // if (data.user_number == defaultEmployeeNo) {
    //   setBtnLoader(false);
    //   return toast.warning(<>Buddy! 🙂 Enter Valid <b>#HTB Employee Number</b></>)
    // }

    const bodyData = {
      user_number       : data.user_number,
      first_name        : data.first_name,
      last_name         : data.last_name,
      email             : data.email,
      mobile_number     : data.mobile_number,
      reference         : userRefInfoId,
      role              : data.role,
      password          : data.password,
      isNotify          : data.isNotify,
      remark            : data.remark,
      status            : data.status,
      // device: {
      //   name           : data.device_name,
      //   id             : data.device_id,
      // },
      business: {
        owner_first_name  : data.business_owner_first_name,
        owner_last_name   : data.business_owner_last_name,
        name              : data.business_name,
        address: {
          locality    : data.business_address_locality,
          address     : data.business_address_address,
          city        : data.business_address_city,
          pincode     : data.business_address_pincode,
          country     : "India",
			    state       : data.business_address_state,
          landmark    : data.business_address_landmark,
          latitude    : data.business_address_latitude,
          longitude   : data.business_address_longitude,
        }
      },
      affiliate_capability : affiliateCapability
    }
    // console.log(bodyData,"---onSubmitAddUser");

    // if(Object.values(bodyData).filter(bodyData => bodyData == '').length > 1){
    //   setBtnLoader(false);
    //   return toast.error('Buddy! 🙂 all fields required expect "Email"', {
    //   position: "top-right",
    //   autoClose: 3000,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   })
    // }else{

    await toast.promise(
      dispatch(addUser(bodyData, role)),
      {
        pending: 'User adding...',
        success: 'Successfully! User Added',
        error: {
          render() {
            setBtnLoader(false);
            return 'User not added'
          }

        }
      }
    )
    // }
  }

  // BANK DETAIL
  const handleBankDetailsChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...bankDetails];
    list[index][name] = value;
    setBankDetails(list);
  };

  const handleBankDetailsADD = () => {
    setBankDetails([...bankDetails, { bank_name: "", account_number: "", ifsc_code: "" , branch_name: "",is_default: "yes" }]);
  };

  const handleBankDetailsRemove = (index) => {
    const list = [...bankDetails];
    list.splice(index, 1);
    setBankDetails(list);
  };
 
  //DOCUMENT
  const handleDocStructureChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...docStructureList];
    list[index][name] = value;
    setDocStructureList(list);
  };

  const handleDocStructureRemove = (index) => {
    const list = [...docStructureList];
    list.splice(index, 1);
    setDocStructureList(list);
  };

  const handleDocStructureAdd = () => {
    setDocStructureList([...docStructureList, { doc_name: "", doc_number: "", doc_file: "" }]);
  };


  // ADDRESS
  const handleAddressStructureChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addressStructureList];
    list[index][name] = value;
    setAddressStructureList(list);
  };

  const handleAddressStructureRemove = (index) => {
    const list = [...addressStructureList];
    list.splice(index, 1);
    setAddressStructureList(list);
  };

  const handleAddressStructureAdd = () => {
    setAddressStructureList([...addressStructureList, { country:"", state: "", city: "", pincode: "", locality: "", address: "", landmark: "", latitude: "", longitude:"", address_type: "work", default_address: "yes"}]);
  };

  const onGetState = async (countryId) => {
    // setAddressCountryId(countryId)
    dispatch(getStateList(addressCountryId))
  }

  const onGetCity = (stateId) => {
    // setAddressStateId(stateId)
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }



// Address

// const [stateList, setStateList]								            = useState([]);
// const [cityList, setCityList]								              = useState([]);
// const [pincodeList, setPincodeList]							          = useState([]);

// useEffect(async () => {
//   const stateListRes = await axios.get(`/shipping/state?country=${addressCountryId}&sort=ASC&statusall&start=1&limit=50`);
//   setStateList(stateListRes?.data?.data)
// }, [])

// const onGetCity = async (statetId) => {
//   const cityListRes = await axios.get(`/shipping/city?country=${addressCountryId}&state=${statetId}&sort=ASC&statusall&start=1&limit=50`)
//   setCityList(cityListRes?.data?.data)
// }

// const onGetPincode = async (cityId) => {
//   const pincodeListRes = await axios.get(`/shipping/pincode?country=${addressCountryId}&state=${addressStateId}&city=${cityId}&sort=ASC&status=all&start=1&limit=50`)
//   setPincodeList(pincodeListRes?.data?.data)
// }



 //PINCODE
 const onSelectPincode = (e) => {
  const {value,checked} = e.target;
    if (!checked) {
      setPincodeInArray(pincodeInArray.filter(item => item !== value));
    }
    else{
      setPincodeInArray([...pincodeInArray, value]);
    }
  }

  // ---------------DELETE AFFILIATE CAPABILITY 
  const onAffiliateDelete = (index) => {
		var filteredArray = affiliateCapability.filter(function(e) { return e.tempId != index })
		setAffiliateCapability(filteredArray); 
		return
	}

  return (
    <div className="modal" aria-hidden="true" style={{ display: 'block' }}>
      <div className="overlayer" onClick={() => handleComponent(false)}></div>
      <div className="modal-dialog modal-dialog-right modal-dialog-for-add-user">
        <div className="modal-content modal-content-demo">
          <div className="modal-header">
            <h6 className="modal-title d-flex align-items-center"><i className='bx bx-user-plus font-size-22 mr-2'></i> Add User - &nbsp; <span className='text-capitalize'>{userRole}</span></h6>
          </div>
          <form onSubmit={(e) => { setBtnLoader(true); onSubmitAddUser(e) }}>
            <div className="modal-body">
              <div className="row addNew">
                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-lg-4 col-xl-3 col-md-4 col-sm-12 col-xs-12 account-setting-tab-menu">
                          <div className="box-widget widget-user border border-bottom-0">
                            <div className="widget-user-header testbgpattern1" />
                            <div className="widget-user-image"><img alt="Logo" className="rounded-circle" src="/img/1.jpg" /></div>
                            <div className="card-body text-center mt-5">
                              <div className="item-user pro-user">
                                <h4 className="pro-user-username tx-15 pt-2 mt-4 mb-1">{firstName+" "+ LastName}</h4>
                                <p className="pro-user-username tx-14 pt-2 mt-2 mb-1">{customerMobile}</p>
                                <h6 className="pro-user-desc tx-12 mb-2 font-weight-normal text-muted"></h6>
                                <p className={`text-center tx-16 list-group-item card-title`}>
                                  <i className="bx bxs-user"></i> Role : {userRole}
                                  <div className="float-right" style={{width:'25%'}} />
                                </p> 
                                
                              </div>
                            </div>
                          </div>
                          <div className="list-group mt-0">
                            <button type='button' onClick={() => setCurrentTabStatus("basic_details") } className={`text-left list-group-item ${currentTabStatus=="basic_details" && 'active'}`}>
                              <i className="bx bxs-user-detail"></i> Basic Details 
                              <div className="float-right" style={{width:'25%'}} />
                            </button> 
                            <button type='button' onClick={() => setCurrentTabStatus("address") } className={`text-left list-group-item ${currentTabStatus=="address" && 'active'}`}>
                              <i class='bx bxs-map' ></i> Address
                              <div className="float-right" style={{width:'25%'}} />
                            </button>
                            <button type='button' onClick={() => setCurrentTabStatus("documents") } className={`text-left list-group-item ${currentTabStatus=="documents" && 'active'}`}>
                              <i class='bx bxs-file-doc'></i> Documents 
                              <div className="float-right" style={{width:'25%'}} />
                            </button> 
                            {userRole == "customer" || userRole === "supplier" ? <>
                              <button type='button' onClick={() => setCurrentTabStatus("business_details") } className={`text-left list-group-item ${currentTabStatus=="business_details" && 'active'}`}>
                                <i class='bx bx-buildings' ></i> Business Details 
                                <div className="float-right" style={{width:'25%'}} />
                              </button> 
                             </>
                            :""}
                            
                            {userRole == "affiliate" || userRole === "operation" ? <>
                              <button type='button' onClick={() => setCurrentTabStatus("bank_details") } className={`text-left list-group-item ${currentTabStatus=="bank_details" && 'active'}`}>
                                <i class='bx bxs-bank'></i> Bank Details
                                <div className="float-right" style={{width:'25%'}} />
                              </button>
                              <button type='button' onClick={() => setCurrentTabStatus("affiliate_capability") } className={`text-left list-group-item ${currentTabStatus=="affiliate_capability" && 'active'}`}>
                                <i class='bx bx-sitemap'></i> Affiliate Capability
                                <div className="float-right" style={{width:'25%'}} />
                              </button>

                              {/* <button type='button' onClick={() => setCurrentTabStatus("device_details") } className={`text-left list-group-item ${currentTabStatus=="device_details" && 'active'}`}>
                              <i class='bx bx-mobile-alt'></i> Device Details
                                <div className="float-right" style={{width:'25%'}} />
                              </button> */}

                              </>
                              :""}

                          </div>                          
                        </div>
                        <div className="col-lg-8 col-xl-9 col-md-8 col-sm-12 col-xs-12 mt-4 mt-md-0">
                          <div className="border account-setting-tab br-7">
                            {/* ---------------------------basic details section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="basic_details" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Basic Details </h4></div>
                              <div className="p-4">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" onChange={(e) => setFirstName(e.target.value)} onBeforeInput={inputFirstLetterCapital} name="first_name" autoComplete='new-password'/>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" onChange={(e) => setLastName(e.target.value)} onBeforeInput={inputFirstLetterCapital} name="last_name" autoComplete='new-password' />
                                        {/* <input type="text" className="form-control" onChange={inputFirstLetterCapital} name="last_name" autoComplete='new-password' /> */}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" className="form-control" name="email" autoComplete="new-password" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input type="text" className="form-control" onChange={(e) => setCustomerMobile(e.target.value)} name="mobile_number" autoComplete='new-password' />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="main-content-label tx-11">Role</label>
                                        <select defaultValue={role} onChange={e => setUserRole(e.target.value)} className="form-control" name="role">
                                          <option selected>Select Role...</option>
                                          {roles.map((role, i) => (
                                            <option key={i} value={role.name}>{role.label}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Status</label>
                                        <select className="form-control" name="status">
                                        {userRole == "affiliate" ? <>
                                        <option value="pending">Pending</option>
                                        </>
                                        :
                                        <>
                                        <option value="active">Active</option>
                                        <option value="pending">Pending</option>
                                        <option value="inactive">InActive</option>
                                        <option value="blacklist">Blacklist</option>
                                        <option value="reject">Reject</option>
                                        <option value="on-hold">On-Hold</option>
                                        <option value="in-process">In Proess</option>
                                        </>
                                        }
                                        </select>
                                      </div>
                                    </div>

                                    {userRole != "customer" && <>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" className="form-control" name="password" autoComplete="new-password"/>
                                      </div>
                                    </div>
                                    </>}

                                    {userRole != "customer" && userRole != "affiliate" ? <>
                                    <div className="col-md-6 mt-4 animated flash">
                                      <div className="input_wrap w-100">
                                        <input name="user_number" type="text" placeholder=" " autoComplete='new-password' />
                                        <label>HTB Employee Number</label>
                                      </div>
                                    </div>
                                    </> :""}
                                                      
                                    <div className="col-md-6">
                                      <div className="form-group mb-0">
                                        <label>{userRole == "customer" ? "Reference/Affiliate" :"Manager"}</label>
                                        <select className='ml-2' onChange={(e) => setSearchRefRole(e.target.value)}>
                                          {roles.map((role,i) => (
                                            <option key={i} value={role?.name} selected={role?.name=="affiliate" ? true : false}>{role.label}</option>
                                          ))}
                                        </select>
                                       </div>

                                       <Select
                                        isMulti={false}
                                        isLoading={searchingUser}
                                        closeMenuOnSelect={true}
                                        ref={selectInputRef}
                                        onInputChange={e => { setSearchUserCustomer(e); }}
                                        onChange={e => { setUserInfo(e?.value);setUserRefInfoId(e?.value?._id ||e?.value?.id ) }}
                                        autoFocus={true}
                                        options={userCustomerSeachOption}
                                        className="basic-multi-select react-select"
                                        placeholder="Add Reference"
                                      />
                                    </div>
                                   
                                  </div>
                                  {/* <hr />
                                  <div className="card-footer pb-0 pr-0 border-top-0 text-right">
                                    <button type="submit" className="btn btn-primary btn-lg">Save</button>
                                  </div> */}
                              </div>
                            </div> 
                            {/*---------------------------- basic details */}

                            {/* ---------------------------- Business Details */}
                            <div className={`account-setting-tab-content ${currentTabStatus=="business_details" && 'active'}`} >
                              <BusinessDetails dispatch={dispatch} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList}/>                
                            </div> 
                            {/* ---------------------------- Business Details */}

                            {/*---------------------------- customer Documents section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="documents" && 'active'}`} >

                              <div className="card-header border-bottom">
                                <h4 className="card-title mb-0"> Documents </h4>
                              </div>
                              <div className="p-4">
                               
                                  {docStructureList.map((singleDoc, index) => (
                                    <div className={`row mt-4`}>
                                      <div className='col-md-4'>
                                        <div className="form-group">
                                          <select className="form-control" name="doc_name" onChange={(e) => handleDocStructureChange(e, index)}>
                                            <option selected>Select Document</option>
                                            {KYCDocuments.map((docl) => (
                                              <option value={docl.name} selected={singleDoc.doc_name == docl.name ? true : false}>{docl.name}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="doc_number" type="text" placeholder=" " value={singleDoc.doc_number} onChange={(e) => handleDocStructureChange(e, index)} />
                                          <label>Number</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          {/*   onChange={(e) => handleDocStructureChange(e, index)}    */}
                                          {imageUrl ? <a href={imageUrl} target="_blank" className='btn btn-primary btn-sm float-right btn-flat mt-2 remove-doc' >preview</a> : ""}
                                          <input type="file" placeholder="" onChange={(e) => { uploadF(e.target.files, index) }} />
                                          <label>File</label>
                                        </div>
                                      <div className='col-md-2'>
                                      {docStructureList.length !== 1 && <button type="button" className='btn btn-danger btn-xs float-right btn-flat btn-sm remove-doc mt-2' onClick={() => handleDocStructureRemove(index)}>X</button>}                   
                                      </div>
                                      {docStructureList.length - 1 === index && docStructureList.length < 5 && <button type="button" className='btn btn-primary btn-sm float-right btn-flat mt-2' onClick={handleDocStructureAdd}>+ add</button>} 
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div> 
                            {/*----------------------------  Documents section end */}

                            {/*---------------------------- Bank Details section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="bank_details" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Bank Details </h4></div>
                              <div className="p-4">
                                <div className="row">
                                  {bankDetails?.map((bankData, index) => (
                                    <>
                                    <div className='row'>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="bank_name" type="text" value={bankData?.bank_name} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsChange(e, index)} />
                                          <label>Bank Name</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="account_number" type="text" value={bankData?.account_number} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsChange(e, index)}/>
                                          <label>Account Number</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="ifsc_code" type="text" value={bankData?.ifsc_code} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsChange(e, index)}/>
                                          <label>IFSC Code</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3'>
                                        <div className="input_wrap w-100">
                                          <input name="branch_name" type="text" value={bankData?.branch_name} placeholder=" " autoComplete='off' onChange={(e) => handleBankDetailsChange(e, index)}/>
                                          <label>Branch Name</label>
                                        </div>
                                      </div>
                                      <div className='col-md-3 mt-3'>
                                        <div className="form-group">
                                          <select className="form-control" name="is_default" onChange={(e) => handleBankDetailsChange(e, index)}>
                                            <option selected>Default Bank</option>
                                            <option value="no">No</option>
                                            <option value="yes" selected={true}>Yes</option>
                                          </select>
                                        </div>
                                      {/* {bankDetails.length - 1 === index && bankDetails.length < 5 && <button type="button" className='btn btn-primary btn-sm float-left btn-flat mt-2' onClick={handleBankDetailsADD}>+ Add More</button>} */}
                                    </div>
                                    {/* <div className='col-md-1 mt-5'>
                                      {bankDetails.length !== 1 && <button type="button" className='btn btn-danger btn-xs float-right btn-flat btn-sm remove-doc' onClick={() => handleBankDetailsRemove(index)}>X</button>}
                                    </div> */}
                                      <div className='col-md-2 mt-3'>
                                        {bankDetails.length - 1 === index && bankDetails.length < 5 && <button type="button" className='btn btn-primary btn-sm float-left btn-flat mt-2' onClick={handleBankDetailsADD}>+ Add More</button>}
                                      </div>
                                      <div className='col-md-1 mt-4'>
                                        {bankDetails.length !== 1 && <button type="button" className='btn btn-danger btn-xs btn-sm' onClick={() => handleBankDetailsRemove(index)}>X</button>}
                                      </div>
                                    </div>
                                    {bankDetails.length !== 1 && <hr />}
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div> 
                            {/*----------------------------  Bank Details section end */}

                             {/*---------------------------- Affiliate Capability section */}
                             <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="affiliate_capability" && 'active'}`} >
                                <div className="card-header border-bottom">
                                {/* <h4 className="card-title mb-0"> Affiliate Capability</h4> */}
                                  <h4>Affiliate Capability <button type='button' className='btn btn-primary btn-sm float-right btn-flat card-title' onClick={() => setHandleCapabilities(true)}>+ Add Capability</button></h4>
                                    <div className="p-4">
                                      <div className="row">
                                        <table className='table table-bordered'>
                                          <thead>
                                            <tr>
                                              <th>#</th>
                                              <th>State</th>
                                              <th>City</th>
                                              <th style={{width:"30%"}}>Pincode</th>
                                              <th style={{width:"30%"}}>Categories</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {
                                            affiliateCapability.length!=0 && affiliateCapability?.map((pdata, index) => (
                                              <tr key={index}>
                                                <td>{index+1}</td>
                                                <td> {pdata.state}</td>
                                                <td> {pdata.city}</td>
                                                <td style={{ width: '20%' }}>{
                                                      pdata.pincode.map((pin, index) =>(
                                                        <span key={index}>{pin}, </span>
                                                      ))
                                                    }</td> 
                                                <td>{
                                                  pdata.category.map((categories, index) =>(
                                                    <p key={index}>{categories}, </p>
                                                  ))
                                                }</td>
                                                <td><button type='button' onClick={() => onAffiliateDelete(pdata.tempId)} className="btn btn-sm btn-white" title="Delete Supplier"><i className='bx bx-trash h4 m-0 text-danger'></i></button></td>
                                              </tr>
                                            ))
                                          }
                                          {affiliateCapability.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
                                          </tbody>
                                        </table>
                                      </div>
                                  </div>
                                </div>
                              {/* <div className="p-4">
                                <div className="row">
                                    <AffiliateCapability dispatch={dispatch} handleComponent={setHandleCapabilities} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList} categories={categories} affiliateCapability={affiliateCapability} setAffiliateCapability={setAffiliateCapability}/>
                                </div>
                              </div> */}
                            </div> 
                            {/*----------------------------  Affiliate Capability section end */}

                            {/*---------------------------- Address section */}
                            <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="address" && 'active'}`} >
                              {/* <div className="card-header border-bottom"><h4 className="card-title mb-0"> Affiliate Address</h4></div> */}
                                <div className="card-header border-bottom">
                                  <h6>Address <button type='button' className='btn btn-primary btn-sm float-right btn-flat card-title' onClick={() => setHandleAddress(true)}>+ Add Address</button></h6>
                                </div>
                              <div className="p-4">
                              <table className='table table-bordered'>
                                <thead>
                                  <th style={{width:"10%"}}>#</th>
                                  <th>Address</th>
                                  <th style={{width:"10%"}}>Action</th>
                                </thead>
                                <tbody>
                                  {
                                    addressStructureList?.length!=0  && addressStructureList?.map((ad,i) => (
                                      <tr>
                                        <td>{i+1}</td>
                                        <td className='' >
                                          {ad.locality}, {ad.address}, {ad.landmark}, {ad.pincode}, {ad.city}, {ad.state}, {ad.country}
                                          <p><strong>Type</strong> - {ad.address_type} | <strong>Default</strong> - {ad.default_address}</p>
                                        </td>
                                        <td className='text-center'>
                                          <div className='btn-group'>
                                          {/* <td><button type='button' onClick={() => onAddressDelete(ad?.id ? ad?.id : ad?.tempId ,ad.id)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td> */}
                                            <td><button type='button' onClick={() => handleAddressStructureRemove(i)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td>

                                            {/* <button type='button' onClick={() => { setShowDelete(true); setDeleteAddress(true); setDeleteId(ad.id || ad._id); loadAndRefreshList={loadAndRefreshAddresscList} }} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h5 m-0 text-danger'></i></button> */}
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                    {addressStructureList?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	

                                </tbody>
                              </table>
                              </div>
                            </div> 
                            {/*----------------------------  Address section end */}



                            {/*----------------------------  Device section start */}

                            {/* <div className={`account-setting-tab-content pl-0 pt-0 ${currentTabStatus=="device_details" && 'active'}`} >
                              <div className="card-header border-bottom"><h4 className="card-title mb-0"> Device Details </h4></div>
                              <div className="p-4">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Device Name</label>
                                        <input type="text" className="form-control" name="device_name" autoComplete='new-password'/>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Device Id</label>
                                        <input type="text" className="form-control" name="device_id" autoComplete='new-password' />
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>  */}

                            {/*----------------------------  Address section end */}



                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => handleComponent(false)}>Close</div>
              <button type='submit' disabled={btnLoader} className="btn ripple btn-primary">
                {!btnLoader ? 'Add User' : <span className="spinner-border spinner-border-sm mx-3"></span>}
              </button>
            </div>

          </form>
        </div>
      </div>
      
      {/* ------ADD more affiliate capabilities */}
      {handleCapabilities && <AffiliateCapability dispatch={dispatch} handleComponent={setHandleCapabilities} countryList={countryList} stateList={stateList} cityList={cityList} pincodeList={pincodeList} categories={categories} affiliateCapability={affiliateCapability} setAffiliateCapability={setAffiliateCapability} setHandleCapabilities={setHandleCapabilities}/>}
		  
      {/* {handleCapabilities && <DocumentComponent dispatch={dispatch} handleComponent={setHandleCapabilities}/> } */}
      {handleAddress && <UpdateAddress dispatch={dispatch} stateList={stateList} cityList={cityList} pincodeList={pincodeList} userInfo={userInfo} handleComponent={setHandleAddress} handleAddress={handleAddress} setHandleAddress={setHandleAddress} addressStructureList={addressStructureList} setAddressStructureList={setAddressStructureList} />}

    </div>
  )
}

/* -------------------------------
		  Business Details 
----------------------------------*/
  const BusinessDetails = ({dispatch,countryList}) => {
  const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [businessAddressStateId, setBusinessAddressStateId] = useState([]);
  const [stateList, setStateList]								            = useState([]);
	const [cityList, setCityList]								              = useState([]);
	const [pincodeList, setPincodeList]							          = useState([]);

  useEffect(async () => {
		const stateListRes = await axios.get(`/shipping/state?country=${addressCountryId}&sort=ASC&statusall&start=1&limit=50`);
		setStateList(stateListRes?.data?.data)
	}, [])

  const onGetCity = async (StatetId) => {
		// console.log("dfds")
		const cityListRes = await axios.get(`/shipping/city?country=${addressCountryId}&state=${StatetId}&sort=ASC&statusall&start=1&limit=50`)
		setCityList(cityListRes?.data?.data)
	}

	const onGetPincode = async (cityId) => {
		// dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
		const pincodeListRes = await axios.get(`/shipping/pincode?country=${addressCountryId}&state=${businessAddressStateId}&city=${cityId}&sort=ASC&status=all&start=1&limit=50`)
		setPincodeList(pincodeListRes?.data?.data)
	}

  return (
    <>
    {/* <h5 className='mt-4 mb-0 text-section'>Business Details</h5> */}
    <div className="card-header border-bottom"><h4 className="card-title mb-0"> Business Details </h4></div>
    <div className="p-4">
      <div className='row'>
        <div className='col-md-3'>
          <div className="input_wrap w-100">
            <input name="business_owner_first_name" type="text" onChange={inputFirstLetterCapital} placeholder=" " />
            <label>Owner First Name</label>
          </div>
        </div>
        <div className='col-md-3'>
          <div className="input_wrap w-100">
            <input name="business_owner_last_name" type="text" onChange={inputFirstLetterCapital} placeholder=" " />
            <label>Owner Last Name</label>
          </div>
        </div>
        <div className='col-md-6'>
          <div className="input_wrap w-100">
            <input name="business_name" type="text" onChange={inputFirstLetterCapital} placeholder=" " />
            <label>Business Name</label>
          </div>
        </div>
        <hr />

        <div className='col-md-12'><h5 className='mt-4 mb-3'>Address</h5></div>
        {/* <div className='col-md-3 mt-1'>
          <div className="form-group">
            <select className="form-control" name="business_address_country" onChange={(e) => {onGetState(e.target.selectedOptions[0].getAttribute('name'))}}>
              <option selected>Select Country</option>
              {countryList?.data?.map((country, i) => (
                <option key={i} value={country.name} name={country.id}>{country.name}</option>
              ))}
            </select>
          </div>
        </div> */}
        <div className='col-md-2 mt-1'>
          <div className="form-group">
            <select className="form-control" name="business_address_state" onChange={(e) => {setBusinessAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name'))}}>
              <option selected>Select State</option>
              {stateList && stateList?.map((state, i) => (
                <option key={i} value={state.name} name={state.id}>{state.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-2'>
          <div className="form-group">
            <select className="form-control" name="business_address_city" onChange={(e) => {onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
              <option selected>Select City</option>
              {cityList && cityList?.map((data, i) => (
                <option key={i} value={data.name} name={data.id}>{data.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-md-2'>
          <div className="form-group">
            <select className="form-control" name="business_address_pincode">
              <option selected>Select Pincode</option>
              {pincodeList && pincodeList?.map((data, i) => (
                <option key={i} value={data.pincode}>{data.pincode}</option>
              ))}
            </select>
          </div>
        </div>
        
        <div className='col-md-6'>
          <div className="input_wrap w-100">
            <input name="business_address_locality" type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
            <label>House No., Building Name</label>
          </div>
        </div>
        <div className='col-md-6 mt-2'>
          <div className="input_wrap w-100">
            <input name="business_address_address" type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
            <label>Road Name, Area, Colony</label>
          </div>
        </div>
        <div className='col-md-6 mt-2'>
          <div className="input_wrap w-100">
            <input name="business_address_landmark" type="text" placeholder=" " autoComplete='off' onBeforeInput={inputFirstLetterCapital}/>
            <label>Landmark</label>
          </div>
        </div>
        <div className='col-md-3 mt-4'>
          <div className="input_wrap w-100">
            <input name="business_address_latitude" type="text" placeholder=" " autoComplete='off' />
            <label>latitude</label>
          </div>
        </div>
        <div className='col-md-3 mt-4'>
          <div className="input_wrap w-100">
            <input name="business_address_longitude" type="text" placeholder=" " autoComplete='off' />
            <label>longitude</label>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

/* -------------------------------
		  Affiliate Capability
----------------------------------*/
const AffiliateCapability = ({dispatch,id, handleComponent, stateList, cityList, pincodeList, categories, affiliateCapability, setAffiliateCapability,setHandleCapabilities}) => {

  const [addressCountryId, setAddressCountryId]                   = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]                       = useState([]);
  const [categoriesInArray, setCategoriesInArray]                 = useState([])
  const [isCheckAll, setIsCheckAll]                               = useState(false);
  const [isCheckAllCategories, setIsCheckAllCategories]           = useState(false);
  const [pincodeInArray, setPincodeInArray]                       = useState([])
  const [stateListGet, setStateListGet]								            = useState([]);
	const [cityListGet, setCityListGet]								              = useState([]);
	const [tempId, setTempId]								                        = useState(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0));

  useEffect(async () => {
    onGetState(addressCountryId)
  }, [])

  const onGetState = async (countryId) => {
    dispatch(getStateList(addressCountryId));
  }

  const onGetCity = async (stateId) => {
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }
  
  //PINCODE
  const onSelectPincode = (e) => {
    const {value,checked} = e.target;
      if (!checked) {
        setPincodeInArray(pincodeInArray.filter(item => item !== value));
      }
      else{
       setPincodeInArray([...pincodeInArray, value]);
      }
  }

    // CHECKED ALL CHECkEDBOX PINCODE
 const handleSelectAll = e => {
  setIsCheckAll(!isCheckAll);

  setPincodeInArray(pincodeList.data.map(li => li.pincode));
  if (isCheckAll) {
    setPincodeInArray([]);
  }

};
// ------------------------

  //CATEGORY
  const onSelectCategories = (e) => {
    const {value,checked} = e.target;
      if (!checked) {
        setCategoriesInArray(categoriesInArray.filter(item => item !== value));
      }
      else{
        setCategoriesInArray([...categoriesInArray, value]);
      }
  }

 // CHECKED ALL CHECkEDBOX CATEGORIES
 const handleSelectAllCategories = e => {
  setIsCheckAllCategories(!isCheckAllCategories);

  setCategoriesInArray(categories.map(li => li.name));
    if (isCheckAllCategories) {
      setCategoriesInArray([]);
    }
  };

  const onSubmitAffiliateCapability = async (e) => {
    e.preventDefault()

    const bodyData = {
        tempId    : tempId,
        country   : "India",
        state     :  stateListGet,
        city      :  cityListGet,
        pincode   :  pincodeInArray,
        category  :  categoriesInArray,

    }

    if(bodyData.pincode.length==0) {toast.error('Pincode is required'); return;}	
    if(bodyData.category.length==0) {toast.error('category is required'); return;}	
		if(affiliateCapability.find(e => e.city == cityListGet)) {toast.error(`${cityListGet} Already added`);return}
		setAffiliateCapability([...affiliateCapability,bodyData])
    setTempId(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0))

  }

  // ---------------DELETE AFFILIATE CAPABILITY 
  const onAffiliateDelete = (index) => {
		var filteredArray = affiliateCapability.filter(function(e) { return e.tempId != index })
		setAffiliateCapability(filteredArray); 
		return
	}

  return (
    <>
      <div id='PriceDiv' className="modal" aria-hidden="true" style={{ display: 'block' }}>
				<div className="overlayer"></div>
				<div className="modal-dialog modal-dialog-right modal-dialog-for-add-user" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h6 className="modal-title d-flex align-items-center">Affiliate Capability</h6>
						</div>
            <div className='modal-body'>
              <div className='row'>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>State</th>
                        <th>City</th>
                        <th style={{width:"40%"}}>Pincode</th>
                        <th style={{width:"50%"}}>Categories</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                  <tbody>
                  {
                    affiliateCapability.length!=0 && affiliateCapability?.map((pdata, index) => (
                      <tr key={index}>
                        <td>{index+1}</td>
                        <td> {pdata.state}</td>
                        <td> {pdata.city}</td>
                        <td style={{ width: '20%' }}>{
                              pdata.pincode.map((pin, index) =>(
                                <span key={index}>{pin}, </span>
                              ))
                            }</td> 
                        <td>{
                          pdata.category.map((categories, index) =>(
                            <span key={index}>{categories}, </span>
                          ))
                        }</td>
                        <td><button type='button' onClick={() => onAffiliateDelete(pdata.tempId)} className="btn btn-sm btn-white" title="Delete Supplier"><i className='bx bx-trash h4 m-0 text-danger'></i></button></td>
                      </tr>
                    ))
                  }
                  {affiliateCapability.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	
                  </tbody>
                  </table>
                    <hr/>
                  <div className='col-md-3'>
                    <div className="form-group">
                      <select className="form-control" name="state" id="status" onChange={(e) => {setStateListGet(e.target.value);setAddressStateId(e.target.selectedOptions[0].getAttribute('name'));onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
                        <option selected>Select State</option>
                        {stateList?.data?.map((state, i) => (
                          <option key={i} value={state?.name} name={state?.id}>{state?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className="form-group">
                      <select className="form-control" name="city" id="status" onChange={(e) => {setCityListGet(e.target.value);onGetPincode(e.target.selectedOptions[0].getAttribute('name'))}}>
                        <option selected>Select City</option>
                        {cityList?.data?.map((data, i) => (
                          <option key={i} value={data.name} name={data.id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className="form-group">
                      <label>Pincodes</label>
                      <div className='row'>
                        <div className='col-md-12'>
                          {pincodeList?.data?.length==0 && 
                          <div className='alert alert-warning'>Pincodes not available</div>
                          }
                        </div>

                        {pincodeList?.data && pincodeList?.data?.length!=0 &&
                        <div className='col-md-2'>
                            <label>
                              <input type='checkbox' name='allselect' id="status" onChange={handleSelectAll} checked={isCheckAll}/> Select All 
                            </label>
                        </div>
                        }

                        {
                          pincodeList?.data?.length!=0 && pincodeList?.data?.map((pn,i) => (
                            <>
                              <div className='col-md-2'>
                                <label>
                                  <input key={i} type='checkbox'  name={pn.pincode} defaultValue={pn?.pincode} onChange={(e) => onSelectPincode(e)} checked={pincodeInArray.includes(pn?.pincode)}/> {pn?.pincode}    
                                </label>
                              </div>
                            </>   
                          ))
                        }
                        </div>                    
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className="form-group">
                      <label>Add Categories</label>
                      <div className='row'>
                        <div className='col-md-12'>
                          {categories?.length==0 && 
                          <div className='alert alert-warning'>data not available</div>
                          }
                        </div>

                        {categories && categories?.length!=0 &&
                        <div className='col-md-2'>
                            <label>
                              <input type='checkbox' name='allselect_categories' id='status' onChange={handleSelectAllCategories} checked={isCheckAllCategories}/> Select All 
                            </label>
                        </div>
                        }

                        {
                          categories?.length!=0 && categories?.map((data,i) => (
                            <>
                              <div className='col-md-2'>
                                <label>
                                  <input key={i} type='checkbox' name={data.name} defaultValue={data?.name} onChange={(e)=>onSelectCategories(e)} checked={categoriesInArray.includes(data?.name)}/> {data?.name}    
                                </label>
                              </div>
                            </>   
                          ))
                        }
                        </div>                    
                    </div>
                  </div>
              </div>
            </div>
            <div className="modal-footer text-center">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => setHandleCapabilities(false)}>Close</div>							
              <button type="submit" className="btn ripple btn-primary" onClick={(e) => onSubmitAffiliateCapability(e)}>Add Capability</button>
            </div>
        </div>
				</div>
			</div>
    </>
  )
}

/* -------------------------------
						Documents 
----------------------------------*/
const DocumentComponent = ({id, dispatch, handleComponent, pricesData, setHandleCapabilities}) => {

	const [addressCountryId, setAddressCountryId]           	= useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]               	= useState([]);

	const onMapPrices = (e) => {

		e.preventDefault()
		const formData = new FormData(e.target)    
		const data = {}
		for (let entry of formData.entries()) {
			data[entry[0]] = entry[1]
		}

		const bodyData = {
			index 				: data.index,
			country       : "India",
			state         : data.state,
		
		}

	}


	// const onMapPriceDelete = (index) => {
	// 	var filteredArray = pricesData.filter(function(e) { return e.index !== index })
	// 	setPricesData(filteredArray); 
	// 	return
	// }

	return (
        <>
			<div id='PriceDiv' className="modal" aria-hidden="true" style={{ display: 'block' }}>
				<div className="overlayer"></div>
				<div className="modal-dialog modal-dialog-right modal-dialog-for-add-route-plan" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h6 className="modal-title d-flex align-items-center">Documents</h6>
						</div>
						<form onSubmit={(e) => onMapPrices(e) }>
						<div className="p-4">
              <table className='table table-bordered'>
                <thead>
                  <th>Document Name</th>
                  <th>Number</th>
                  <th>File</th>
                  <th>Action</th>
                </thead>
                <tbody>
                {/* {
                  customerDocList && customerDocList?.map((doc) => (
                    <tr>
                      <td>{doc.name}</td>
                      <td>{doc?.number}</td>
                      <td><a href={doc?.file} target="_blank" className='btn btn-primary btn-sm btn-flat'>preview</a></td>
                      <td>
                        <button type='button' onClick={() => { setShowDelete(true); setDeleteAddress(false); setDeleteId(doc.id || doc._id); setDeleteName(doc.name); }} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h4 m-0 text-danger'></i></button>
                      </td>
                    </tr>
                  ))
                }
                {customerDocList?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	 */}
                </tbody>
              </table>
            </div>
						<div className="modal-footer text-center">
							<button type="submit" className="btn ripple btn-primary">Add Price</button>
							<div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => setHandleCapabilities(false)}>Close</div>							
						</div>
						</form>
					</div>
				</div>
			</div>
        </>
    )

}

/* -------------------------------
						UpdateAddress 
----------------------------------*/
const UpdateAddress = ({dispatch,handleComponent, userInfo,countryList, addressList, stateList, cityList, pincodeList, setHandleAddress, addressStructureList,setAddressStructureList ,setAddressDeleteId,addressDeleteId}) => {

  const [addressCountryId, setAddressCountryId]                   = useState("6321679bc2fe1900193f9184");
	const [addressStateId, setAddressStateId]                       = useState([]);
  const [newPincode, setNewPincode]                               = useState([null])
  const [newStateList, setNewStateList]								            = useState([null]);
	const [newCityList, setNewCityList]								              = useState([null]);
	const [newLocalityList, setNewLocalityList]								      = useState();
	const [newAddressList, setNewAddressList]								        = useState();
	const [newLandmarkList, setNewLandmarkList]								      = useState();
	const [newLatitudeList, setNewLatitudeList]								      = useState();
	const [newLongitudeList, setNewLongitudeList]								    = useState();
	const [newAddrtessTypeList, setNewAddrtessTypeList]							= useState("work");
	const [newDefaultAddressList, setNewDefaultAddressList]				  = useState("yes");
	const [tempId, setTempId]								                        = useState(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0));

  useEffect(async () => {
    onGetState(addressCountryId)
    // setAddressStructureList(userInfo?.address)
  }, [])

  const onGetState = async (countryId) => {
    dispatch(getStateList(addressCountryId));
  }

  const onGetCity = async (stateId) => {
    dispatch(getCityList(addressCountryId, stateId))
  }

  const onGetPincode = (cityId) => {
    dispatch(getPincodeList(addressCountryId, addressStateId, cityId ))
  }
  
  const onSubmitAddress = async (e) => {
    e.preventDefault()

      const bodyData = {
        tempId                    : tempId,
        locality                  : newLocalityList,
        address                   : newAddressList,
        landmark                  : newLandmarkList,
        pincode                   : newPincode,
        city                      : newCityList,
        state                     : newStateList,
        country                   : "India",
        address_type              : newAddrtessTypeList,
        default_address           : newDefaultAddressList,
        latitude                  : newLatitudeList,
        longitude                 : newLongitudeList
      }

		if(addressStructureList.find(e => e.locality == newLocalityList)) {toast.error(`Address Already Fieled`);return}
    // if(bodyData==0 || bodyData.locality.length==0 || bodyData.landmark.length==0) {toast.error(`Address Field is required`); return;}	
    setAddressStructureList([...addressStructureList, bodyData])
    setTempId(Number(Math.floor(Math.random() * (1000) + 10000)).toFixed(0))

  }

// ---------------DELETE AFFILIATE CAPABILITY 
const onAddressDelete = (index, id="") => {
  if ( window.confirm("Are you sure?")) {

  if(id) {
    var filteredArray = addressStructureList.filter(function(e) { return e.id != index })
    setAddressDeleteId([...addressDeleteId, id])
    setAddressStructureList(filteredArray); 

  } else {
    var filteredArray = addressStructureList.filter(function(e) { return e.tempId != index })
    setAddressStructureList(filteredArray); 
  }
}
  return
}

  return (
    <>
    <div id='PriceDiv' className="modal" aria-hidden="true" style={{ display: 'block' }}>
				<div className="overlayer"></div>
				<div className="modal-dialog modal-dialog-right modal-dialog-for-add-user" role="document">
					<div className="modal-content modal-content-demo">
						<div className="modal-header">
							<h6 className="modal-title d-flex align-items-center">Address</h6>
						</div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-md-12'>
                <table className='table table-bordered'>
                  <thead>
                    <th>Address</th>
                    <th style={{width:"10%"}}>Action</th>
                  </thead>
                  <tbody>
                    {
                      addressStructureList && addressStructureList?.map((ad,index) => (
                        <tr>
                          <td className='' >
                            {ad.locality}, {ad.address}, {ad.landmark}, {ad.pincode}, {ad.city}, {ad.state}, {ad.country}
                            <p><strong>Type</strong> - {ad.address_type} | <strong>Default</strong> - {ad.default_address}</p>
                          </td>
                          <td className='text-center'>
                            <div className='btn-group'>
                            <td><button type='button' onClick={() => onAddressDelete(ad?.id ? ad?.id : ad?.tempId ,ad.id)} className="btn btn-sm btn-white" title="Delete Capability"><i className='bx bx-trash h5 m-0 text-danger'></i></button></td>

                              {/* <button type='button' onClick={() => {setShowUpdate(true); setUpdateId(ad.id || ad._id);loadAndRefreshList={loadAndRefreshList}}} className="btn btn-sm btn-white" data-title="Edit User"><i className='bx bx-pencil h5 m-0 text-warning'></i></button> */}
                              {/* <button type='button' onClick={() => { setShowDelete(true); setDeleteAddress(true); setDeleteId(ad.id || ad._id) }} className="btn btn-sm btn-white" data-title="Delete User"><i className='bx bx-trash h5 m-0 text-danger'></i></button> */}
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    {addressStructureList?.length==0 && <tr><td className='text-center' colSpan={6}>data not available</td></tr>}	

                  </tbody>
                </table>
              </div>
              <div className="mt-2 p-4">
                {/* {addressStructureList && addressStructureList?.map((singleAddress, index) => (
                <> */}
                  <div className='row mt-4'>
                    <div className='col-md-2'>
                      <div className="form-group">
                        <select className="form-control" name="state" id="status" onChange={(e) => {setNewStateList(e.target.value); setAddressStateId(e.target.selectedOptions[0].getAttribute('name')); onGetCity(e.target.selectedOptions[0].getAttribute('name')) }}>
                          <option selected>Select State</option>
                          {stateList?.data?.map((state, i) => (
                            <option key={i} value={state?.name} name={state?.id}>{state?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-2'>
                      <div className="form-group">
                        <select className="form-control" name="city" id="status" onChange={(e) => {setNewCityList(e.target.value); onGetPincode(e.target.selectedOptions[0].getAttribute('name')) }}>
                          <option selected>Select City</option>
                          {cityList?.data?.map((data, i) => (
                            <option key={i} value={data?.name} name={data?.id}>{data?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-2'>
                      <div className="form-group">
                        <select className="form-control" name="pincode" id="status" onChange={(e)=> setNewPincode(e.target.value)}>
                          <option selected>Select Pincode</option>
                          {pincodeList?.data?.map((data, i) => (
                            <option key={i} value={data?.pincode} name={data?.id}>{data?.pincode}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="input_wrap w-100">
                        <input name="locality" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewLocalityList(e.target.value)} onBeforeInput={inputFirstLetterCapital}/>
                        {/* <input name="locality" type="text" placeholder=" " autoComplete='off' onChange={(e)=> handleAddressStructureChange(e,index)} /> */}
                        <label>House No., Building Name</label>
                      </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                      <div className="input_wrap w-100">
                        <input name="address" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewAddressList(e.target.value)} onBeforeInput={inputFirstLetterCapital}/>
                        <label>Road Name, Area, Colony</label>
                      </div>
                    </div>
                    <div className='col-md-6 mt-3'>
                      <div className="input_wrap w-100">
                        <input name="landmark" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewLandmarkList(e.target.value)} onBeforeInput={inputFirstLetterCapital}/>
                        <label>Landmark</label>
                      </div>
                    </div>
                    <div className='col-md-3 mt-4'>
                      <div className="input_wrap w-100">
                        <input name="latitude" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewLatitudeList(e.target.value)}/>
                        <label>latitude</label>
                      </div>
                    </div>
                    <div className='col-md-3 mt-4'>
                      <div className="input_wrap w-100">
                        <input name="longitude" type="text" placeholder=" " autoComplete='off' onChange={(e)=> setNewLongitudeList(e.target.value)}/>
                        <label>longitude</label>
                      </div>
                    </div>

                    <div className='col-md-2 mt-4'>
                      <div className="form-group">
                        <select className="form-control" name="address_type" onChange={(e)=> setNewAddrtessTypeList(e.target.value)}>
                          <option value="" selected>Address Type</option>
                          <option value="home">Home</option>
                          <option value="work" selected={true}>Work</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-md-2 mt-4'>
                      <div className="form-group">
                        {/* <label className="main-content-label tx-11">Default Address</label> */}
                        <select className="form-control" name="default_address" onChange={(e)=> setNewDefaultAddressList(e.target.value)}>
                          <option value="" selected>Default Address</option>
                          <option value="no">No</option>
                          <option value="yes" selected={true}>Yes</option>
                        </select>
                      </div>
                      {/* {addressStructureList.length - 1 === index && addressStructureList.length < 5 && <button type="button" className='btn btn-primary btn-sm float-right btn-flat mt-2' onClick={handleAddressStructureAdd}>+ add More</button>} */}
                    </div>
                    {/* <div className='col-md-2 mt-4'>
                      {addressStructureList.length - 1 === index && addressStructureList.length < 5 && <button type="button" className='btn btn-primary btn-sm btn-flat' onClick={handleAffiliateAddressAdd}>+ add More</button>}
                      {addressStructureList.length !== 1 && <button type="button" className='btn btn-danger btn-xs float-right btn-flat btn-sm remove-button mt-4 mr-2' onClick={() => handleAffiliateAddressRemove(index)}>X</button>}
                    </div> */}
                    {/* <div className='col-md-1 mt-5'>
                      {addressStructureList.length !== 1 && <button type="button" className='btn btn-danger btn-xs float-right btn-flat btn-sm remove-doc' onClick={() => handleAddressStructureRemove(index)}>X</button>}
                    </div> */}
                  </div>

                {/* {addressStructureList.length !== 1 && <hr />}

                </>
                ))} */}
              </div>
            </div>
          </div>
          <div className="modal-footer text-center">
              <div className="btn ripple btn-outline-danger" data-dismiss="modal" type="button" onClick={() => setHandleAddress(false)}>Close</div>							
              <button type="submit" className="btn ripple btn-primary" onClick={(e) => onSubmitAddress(e)}>Add Address</button>
            </div>
        </div>
				</div>
			</div>
        {/* <button type="submit" className="btn ripple btn-primary" onClick={(e) => onSubmitAffiliateCapability(e)}>Add Capability</button> */}
    </>
  )
}



function mapStateToProps(state) {
  let { customerAddressById } = state.users.users
  // let customerSearchResult    = state.users.users.customerSearch
  let customerSearchResult    = state.users.users.affiliateSearchList
  let newCustomerData         = state.users.users.newCustomer
  let bankDetailList          = state.users.users.customerBankDetailList;
  let customerDocAdd          = state.users.users.customerDocAdd;   
  const { categories }        = state.categories
  const { countryList, stateList, cityList, pincodeList, } = state.shipping;

  // console.log(customerSearchResult,"--------customerSearchResult");

  return {
    customerSearchResult,
    newCustomerData,
    bankDetailList,
    customerDocAdd,
    countryList,
    pincodeList,
    stateList,
    cityList,
    categories
  };
}

export default connect(mapStateToProps)(UserAdd);